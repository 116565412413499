import React, { useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import { format } from 'date-fns';
import ModifyBtn from './ModifyBtn';
import RemoveItemBtn from './RemoveItemBtn';

const Items = ({ sales_report_list, OnlySalesData, PreviewDetails }) => {

    const [InputPriceItem, setInputPriceItem] = useState({ amount: 0, sales_stock_id: 0 })
    const [EditedItemId, setEditedItemId] = useState({ sales_stock_id: null })

    const EditedItem = (item) => {
        setEditedItemId(item);
    }

    const totalSummary = (item) => {
        let total = 0;
        item.map((item, key) => {
            let subTotal = item.stock_price * item.sales_qty
            total = total + subTotal;
        });
        return total;
    }

    const TotalQty = (item) => {
        let total = 0;
        item.map((item, key) => {
            total = total + item.sales_qty
            // total = total + subTotal;
        });
        return total;
    }
    
    const modifyitemLoop = async (items) => {
        console.log(items)
        const data = OnlySalesData.list.filter((val) => val.sales_stock_id === items?.sales_stock_id);
        OnlySalesData.list = data;
        console.log(OnlySalesData)
        // PreviewDetails(OnlySalesData);
    }

    const removeitemLoop = async (items) => {
        // const data = OnlySalesData.list;

        const intex = OnlySalesData.list.findIndex(x => x.sales_stock_id === items?.sales_stock_id);
        // data[intex].stock_status = 'removed';
        // OnlySalesData.list = data;   
        // && val.stock_status!=='removed'     
        // const data = OnlySalesData.list.filter((val) => val.sales_stock_id !== items?.sales_stock_id);
        // OnlySalesData.list = data;
        // OnlySalesData.list.options = [];
        OnlySalesData.list[intex].history = [...OnlySalesData.list[intex].history, {
            "sales_id": Number(OnlySalesData.sales_id),
            "user_id": localStorage.getItem('user_id'),
            "employee_name": localStorage.getItem('firstname'),
            "particular": null,
            "note": null,
            "date_time": format(new Date(), 'yyyy-MM-dd HH:mm:ss')
        }];
        console.log(1, OnlySalesData)
        // const data = OnlySalesData.list.filter((val) => val !== items);
        // OnlySalesData.list = data;
        // PreviewDetails(OnlySalesData);
        // console.log(items?.sales_stock_id, OnlySalesData)
        // console.log(2, OnlySalesData)

        // const data = OnlySalesData.list.filter((val) => val.sales_stock_id !== items?.sales_stock_id);
        // OnlySalesData.list = data;
        // PreviewDetails(OnlySalesData);
        // return;
        var formData = new FormData();
        formData.append("sales_item_remove", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("sales_stock_id", items?.sales_stock_id);
        formData.append("status", 'removed');
        formData.append("history", JSON.stringify(OnlySalesData.list[intex].history));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'sales_report.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            if (res.data === 'success') {
                // PreviewDetails(OnlySalesData);
                sales_report_list()
            } else {

            }
        });
    }

    const groupedData = OnlySalesData?.list?.reduce((groups, item) => {
        const { product_id, product_name, stock_status } = item;
        // console.log(product_id)
        if (product_id === 0) {
            if (!groups[product_id, product_name]) {
                groups[product_id, product_name] = [];
            }
            groups[product_id, product_name].push(item);
        } else {
            if (!groups[product_id]) {
                groups[product_id] = [];
            }
            groups[product_id].push(item);
        }
        return groups;
    }, {});

    return (
        <div style={{display:"flex", flexDirection:"column", gap:5}}>
            {
                (OnlySalesData?.list).length > 0 ?
                    <>
                        {
                            Object.entries(groupedData)?.map(([id, items]) => {
                                return (
                                    <div style={{ borderBottom: "1px solid #f0f0f0", paddingTop: 5, paddingBottom: 5, borderRadius: 5, backgroundColor: EditedItemId.sales_stock_id === items.sales_stock_id ? "#d3dde3" : "#fff", border: "1px solid #ddd", padding: 0 }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ backgroundColor: "beige", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10 }}>
                                                <div>

                                                    <>
                                                        {
                                                            items[0]?.product_id !== 0 ?
                                                                <>
                                                                    <div><b>{items[0]?.product_name}</b></div>
                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                                        {
                                                                            items[0]?.product_code !== null ?
                                                                            <div>UPC: <b>{items[0]?.product_code}</b></div>
                                                                            :null
                                                                        }
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div><b>{items[0]?.product_name}</b></div>
                                                                    {
                                                                        items[0]?.product_name !== 'Services' ?
                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                                                {
                                                                                    items[0]?.product_code !== null ?
                                                                                        <div>UPC: <b>{items[0]?.product_code}</b></div>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </>
                                                        }
                                                    </>
                                                </div>
                                                <div style={{ width: 150, display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 5 }}>
                                                    {
                                                        EditedItemId[0]?.product_name === items[0]?.product_name ?
                                                            <>
                                                                <a onClick={() => EditedItem({})} style={{ cursor: "pointer", border: "1px solid #ddd", width: 50, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", borderRadius: 5, height: 20, fontSize: 10, backgroundColor: "#eb2525", color: "#fff" }}>Cancel</a>
                                                            </>
                                                            :
                                                            <>
                                                                <a onClick={() => EditedItem(items)} style={{ cursor: "pointer", border: "1px solid #ddd", width: 50, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", borderRadius: 5, height: 20, fontSize: 10, backgroundColor: "#1fae66", color: "#fff" }}>Edit</a>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            items?.length > 0 ?
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", padding: 0 }}>
                                                    <div style={{ padding: 0 }}>
                                                        {
                                                            items?.map((item, key) => {
                                                                    return (
                                                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 1, border: "1px solid #f0f0f0", padding: 5 }}>
                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                                                    <>
                                                                                        {
                                                                                            item?.serial_number === 'NA' || item?.serial_number === 'N/A' || item?.serial_number === 'na' || item?.serial_number === '' ?
                                                                                                <>
                                                                                                {/* {
                                                                                                    console.log(String(JSON.parse(item?.options)[0]?.name))
                                                                                                } */}
                                                                                                    {
                                                                                                        item?.options.length > 0 ? 
                                                                                                        // JSON.parse(item?.options)[0]?.name
                                                                                                            <div>
                                                                                                                <div style={{ fontSize: 10 }}>{item?.options.length > 0 ? String(JSON.parse(item?.options)[0]?.name) + ' - ' + String(JSON.parse(item?.options)[0]?.option) : null}</div>
                                                                                                                <div style={{ fontSize: 10 }}>{item?.options.length > 0 ? 'Qty: '+item.sales_qty + ' | Price: '+item.stock_price : null}</div>
                                                                                                                <div style={{ fontSize: 10 }}>{item?.options.length > 0 ? 'Note: '+JSON.parse(item?.options)[0]?.note : null}</div>
                                                                                                            </div>
                                                                                                        : null
                                                                                                    }
                                                                                                    {/* {
                                                                                                        JSON.parse(...item?.options)
                                                                                                    } */}
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <div style={{ fontSize: 10 }}>SN: {item.serial_number}</div>
                                                                                                    <span>|</span>
                                                                                                    {
                                                                                                        item?.options.length > 0 ?
                                                                                                            <>
                                                                                                                {
                                                                                                                    String(JSON.parse(item?.options)[0]?.name) === "Miscellaneous" ?
                                                                                                                    <>
                                                                                                                        <div style={{ fontSize: 10 }}>QTY: {item.sales_qty}</div> | 
                                                                                                                        <div style={{ fontSize: 10 }}>Unit Price: {item.stock_price}</div>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>2</>
                                                                                                                }
                                                                                                            </>
                                                                                                        :
                                                                                                        null
                                                                                                    }
                                                                                                    {
                                                                                                        item.no_serial === 1 ?
                                                                                                            <>
                                                                                                                <div style={{ fontSize: 10 }}>QTY: {item.sales_qty}</div>
                                                                                                                <span>|</span>
                                                                                                            </>
                                                                                                            :
                                                                                                            null
        
                                                                                                    }
                                                                                                </>
                                                                                        }
                                                                                    </>
                                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItem: "center", gap: 5, fontSize: 10 }}>
                                                                                        <>
                                                                                            {
                                                                                                item.product_id !== 0 ?
                                                                                                <>
                                                                                                    <span>Unit Price:</span>
                                                                                                    {item.stock_price}
                                                                                                    <span>|</span>
                                                                                                    <div style={{ fontSize: 10 }}>Warranty: {item.warranty}</div>
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </>
                                                                                    </div>  
                                                                                </div>  
                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignSelf: "end", alignItems: "center", gap: 10 }}>
                                                                                    {
                                                                                        EditedItemId[0]?.product_name === items[0]?.product_name ?
                                                                                            <>
                                                                                                <ModifyBtn OnlySalesData={OnlySalesData} item={item} PreviewDetails={PreviewDetails} />
                                                                                                |
                                                                                                <RemoveItemBtn OnlySalesData={OnlySalesData} item={item} PreviewDetails={PreviewDetails}  />
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </div>
                                                                            </div>                                                                         
                                                                            {
                                                                                item.remarks !== '' ?
                                                                                <div style={{fontSize:10}}>
                                                                                    Remarks: {item.remarks}
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    )
                                                                // }
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10, padding: 5, backgroundColor: "beige" }}>
                                                            {
                                                                items[0]?.product_id !== 0 ?
                                                                    <div>Qty: <label>{TotalQty(items)}</label></div>
                                                                    :
                                                                    <></>
                                                            }
                                                            <div>Sub Total: <label>{totalSummary(items)}</label></div>
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <></>
                                        }
                                    </div>
                                )
                            })
                        }
                    </>
                    :
                    <div style={{textAlign:"center"}}>No Item</div>
            }
        </div>
    );
}

export default Items;

import { Dialog } from 'primereact/dialog';
import React, { useRef, useState } from 'react';
import { Menu } from 'primereact/menu';

const AddPayment = () => {
    const menuLeft = useRef(null);


    const [openDialog, setOpenDialog] = useState(false)
    const [label, setLabel] = useState('');
    const [inputvalue, setInputValue] = useState({});

    const hideDialog = () => {
        setOpenDialog(false)
        setLabel('')
    }



    const newArray = []

    let nums = 0;

    const itemArray = (item) => {
        if (label !== 'Online') {
            newArray.push({
                label: 'Online',
                icon: 'pi pi-trash',
                command: () => {
                    // setOpenDelete(true)
                    setLabel('Online')
                }
            })
        }
        if(label !== 'Bank'){
            newArray.push({
                label: 'Bank',
                icon: 'pi pi-trash',
                command: () => {
                    // setOpenDelete(true)
                    setLabel('Bank')
                }
            })
        }
        if(label !== 'Voucher'){
            newArray.push({
                label: 'Voucher',
                icon: 'pi pi-trash',
                command: () => {
                    // setOpenDelete(true)
                    setLabel('Voucher')
                }
            })
        }
        return newArray

    }


    const items = [
        {
            label: 'Select',
            items: itemArray([])
        }
    ];


    const savePayment = () => {
        console.log(inputvalue)
    }



    return (
        <>

            <Dialog 
            footer={label!==''?
                <div>
                    <button type="button" onClick={() => savePayment()} class="btn vd_btn vd_bg-facebook btn-block">Add Payment</button>
                </div>
                :null
            }
            header={
                <div>
                    <Menu model={items} popup ref={menuLeft} id="popup_menu_left" style={{ fontSize: 20, width: 300 }} />
                    <button onClick={(event) => menuLeft.current.toggle(event)} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10, alignItems: "center", border: "none", backgroundColor: "#fff", border: "1px solid #ddd" }}>
                        <span className='pi pi-bars' style={{ fontSize: 15, color: "#000" }}></span>
                        <span>{label !== '' ? label : "Select Payment Method"}</span>
                    </button>
                </div>
            }
                visible={openDialog} position={"top"} resizable={false} draggable={false} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '40vw' }} modal className="p-fluid" onHide={hideDialog}>
                <div>
                    {
                        label === 'Online' ?
                        <>
                            <div style={{fontSize: 15}}>
                                <div>
                                    <label>Bank Name:</label>
                                    <div>
                                        <select className='form-control'>
                                            <option>Select</option>
                                            <option>BPI</option>
                                            <option>BDO</option>
                                            {/* <option>GCash</option> */}
                                            {/* <option>Maya</option> */}
                                            {/* <option>PNB</option> */}
                                            {/* <option>UBP</option> */}
                                            {/* <option>Metro Bank</option> */}
                                            {/* <option>Land Bank</option> */}
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <label>Account Name:</label>
                                    <div>
                                        <input className='form-control' />
                                    </div>
                                </div>
                                <div>
                                    <label>Account Number:</label>
                                    <div>
                                        <input className='form-control' />
                                    </div>
                                </div>
                                <div>
                                    <label>Date:</label>
                                    <div>
                                        <input className='form-control' />
                                    </div>
                                </div>
                                <div>
                                    <label>Amount:</label>
                                    <div>
                                        <input className='form-control' />
                                    </div>
                                </div>
                                <div>
                                    <label>Note:</label>
                                    <textarea cols="30" rows="5" className='form-control'></textarea>
                                </div>
                                <div>
                                    <label>Receipt Picture:</label>
                                    <input type='file' className='form-control' />
                                </div>
                            </div>
                        </>
                        : null
                    }
                    {
                        label === 'Bank' ?
                        <>
                            <div style={{fontSize: 15}}>
                                <div>
                                    <label>Account Name:</label>
                                    <div>
                                        <input className='form-control' />
                                    </div>
                                </div>
                                <div>
                                    <label>Account Number:</label>
                                    <div>
                                        <input className='form-control' />
                                    </div>
                                </div>
                                <div>
                                    <label>Date:</label>
                                    <div>
                                        <input className='form-control' />
                                    </div>
                                </div>
                                <div>
                                    <label>Amount:</label>
                                    <div>
                                        <input className='form-control' />
                                    </div>
                                </div>
                                <div>
                                    <label>Note:</label>
                                    <textarea cols="30" rows="5" className='form-control'></textarea>
                                </div>
                                <div>
                                    <label>Receipt Picture:</label>
                                    <input type='file' className='form-control' />
                                </div>
                            </div>
                        </>
                        : null
                    }
                    {
                        label === 'Voucher' ?
                        <>
                            <div style={{fontSize: 15}}>
                                <div>
                                    <label>Reference No:</label>
                                    <div>
                                        <input className='form-control' name="ref_no" onChange={(e)=>setInputValue({...inputvalue, [e.target.name]: e.target.value})} />
                                    </div>
                                </div>
                                <div>
                                    <label>Date:</label>
                                    <div>
                                        <input className='form-control' name="date" onChange={(e)=>setInputValue({...inputvalue, [e.target.name]: e.target.value})}/>
                                    </div>
                                </div>
                                <div>
                                    <label>Amount:</label>
                                    <div>
                                        <input className='form-control' name="amount" onChange={(e)=>setInputValue({...inputvalue, [e.target.name]: e.target.value})} />
                                    </div>
                                </div>
                                <div>
                                    <label>Note:</label>
                                    <textarea cols="30" rows="5" className='form-control' name="note" onChange={(e)=>setInputValue({...inputvalue, [e.target.name]: e.target.value})}></textarea>
                                </div>
                                <div>
                                    <label>Receipt Picture:</label>
                                    <input type='file' className='form-control' />
                                </div>
                            </div>
                        </>
                        : null
                    }
                </div>

            </Dialog>


            <button type="button" onClick={() => setOpenDialog(true)} class="btn vd_btn vd_bg-facebook btn-block">Add Payment</button>
            <div>
                <div>
                    <div class="content-list">
                        <ul class="list-wrapper">
                            <li class="">
                                <span>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span style={{ fontWeight: "bolder" }}>Online</span>
                                        <span>0.00</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                        <span>Deposit</span>
                                    </div>
                                    {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                        <span>Transfer</span>
                                    </div> */}
                                    <span class="menu-info" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span class="menu-date">
                                            August 2003
                                        </span>
                                        <span style={{ fontSize: 15, color: "#23709e" }}>
                                            Payable
                                        </span>
                                    </span>
                                </span>
                            </li>
                            <li class="">
                                <span>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span style={{ fontWeight: "bolder" }}>Bank</span>
                                        <span>0.00</span>
                                    </div>
                                    {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                        <span>Deposit</span>
                                    </div> */}
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                        <span>Transfer / Deposit / Cheque</span>
                                    </div>
                                    <span class="menu-info" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span class="menu-date">
                                            August 2003
                                        </span>
                                        <span style={{ fontSize: 15, color: "#23709e" }}>
                                            Payable
                                        </span>
                                    </span>
                                </span>
                            </li>
                            <li class="">
                                <span>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span style={{ fontWeight: "bolder" }}>Maya</span>
                                        <span>0.00</span>
                                    </div>
                                    {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                        <span>Deposit</span>
                                    </div> */}
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                        <span>Transfer</span>
                                    </div>
                                    <span class="menu-info" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span class="menu-date">
                                            August 2003
                                        </span>
                                        <span style={{ fontSize: 15, color: "#23709e" }}>
                                            Payable
                                        </span>
                                    </span>
                                </span>
                            </li>
                            <li class="">
                                <span>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span style={{ fontWeight: "bolder" }}>GCash</span>
                                        <span>0.00</span>
                                    </div>
                                    {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                        <span>Deposit</span>
                                    </div> */}
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                        <span>Transfer</span>
                                    </div>
                                    <span class="menu-info" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span class="menu-date">
                                            August 2003
                                        </span>
                                        <span style={{ fontSize: 15, color: "#23709e" }}>
                                            Payable
                                        </span>
                                    </span>
                                </span>
                            </li>
                            <li class="">
                                <span>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span style={{ fontWeight: "bolder" }}>Voucher</span>
                                        <span>0.00</span>
                                    </div>
                                    {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                        <span>Deposit</span>
                                    </div> */}
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                        <span>Transfer</span>
                                    </div>
                                    <span class="menu-info" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span class="menu-date">
                                            August 2003
                                        </span>
                                        <span style={{ fontSize: 15, color: "#23709e" }}>
                                            Payable
                                        </span>
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddPayment;

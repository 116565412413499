import React, { useMemo, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
import StockHistory from './StockHistory';

const StockTable = ({rowData, stocks, StockListSync, setStocks, supplierlist, products, productlist, setProducts, ProductSync}) => {
    const [search, setSearch] = useState('') 
    const [qty, setQty] = useState('')
    const [serial, setSerial] = useState('')
    const [supplierName, setSupplierName] = useState('') 
    const [supplierInvoice, setSupplierInvoice] = useState('') 
    const [supplierPurchases, setSupplierPurchases] = useState('') 

    useMemo(async() => {
        // (async () => {
            var formData = new FormData();
            formData.append("stockList", 1);
            formData.append("product_id", rowData?.id);
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'product.php',
                data: formData,
            }).then((res) => {
                setStocks(res.data)
                // console.log(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
        // })
    }, [ENV, rowData]);


    const searchData = (data, search, status) => {
        // let data = sales;
        if (search) {
            data = data.filter((i) => (i?.serial.toLowerCase().includes(search.trim().toLowerCase())) 
            || (i?.supplier_name.toLowerCase().includes(search.trim().toLowerCase())) 
            || (i?.supplier_invoice.toLowerCase().includes(search.trim().toLowerCase())));
        }
        return data;
    }

    const [selectStockId, setSelectStockId] = useState(null)
    const [showdeletestock, setShowDeleteStock] = useState(false)



    const UpdateStock = async (item) => {
        
        const index = productlist?.findIndex(x => x.id === item.product_id);
        const _index = stocks?.findIndex(x => x.stock_id === item?.stock_id);
        
        // rowData.list[_index].serial = 'sdasfsd';

        // productlist[index].list[_index].serial = serial;
        // console.log(item?.product_id+' '+item?.stock_id, productlist)
        // setProducts(productlist)
        // setSelectStockId(null)
        // setSerial('')
        
        // console.log(item.product_id, productlist)
        // console.log(index, _index)
        // console.log(item.product_id)
        // console.log(rowData)
        // console.log(rowData[index])
        // console.log(rowData.list)
        // setProducts(rowData)
        // console.log('')
        // console.log(localStorage.getItem("user_id"))
        // console.log(rowData?.id)
        // console.log(rowData?.list[index]?.stock_id)
        // console.log(qty ? qty : rowData?.list[index].stock_qty)
        // console.log(serial ? serial : rowData?.list[index].serial)
        // console.log(supplierName ? supplierName : rowData?.list[index].supplier_name)
        // console.log(supplierInvoice ? supplierInvoice : rowData?.list[index].supplier_invoice)
        // console.log(supplierPurchases ? supplierPurchases : rowData?.list[index].date_purchace)

        // return;
        // console.log(selectStockId)
        var formData = new FormData();
        formData.append("updateStock", 1);
        formData.append("user_id", localStorage.getItem("user_id"));
        formData.append("stock_id", item?.stock_id);
        formData.append("product_id", rowData?.id);
        formData.append("qty", qty ? qty : stocks[_index].stock_qty);
        formData.append("serial", serial ? serial : stocks[_index].serial);
        formData.append("supplier_name", supplierName ? supplierName : stocks[_index].supplier_name);
        formData.append("supplier_invoice", supplierInvoice ? supplierInvoice : stocks[_index].supplier_invoice);
        formData.append("date_purchace", supplierPurchases ? supplierPurchases : stocks[_index].date_purchace);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            StockListSync()
            setSelectStockId({stock_id:null, action: null})
            setQty('')
            // stockList();
            // setStocks(res.data)
            // console.log(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const RemoveStock = async (item) => {
        // console.log(item)
        var formData = new FormData();
        formData.append("removeStock", 1);
        formData.append("user_id", localStorage.getItem("user_id"));
        formData.append("stock_id", item?.stock_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            StockListSync()
            ProductSync()
            console.log(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const RestoreStock = async (item) => {
        var formData = new FormData();
        formData.append("restockStock", 1);
        formData.append("user_id", localStorage.getItem("user_id"));
        formData.append("stock_id", item?.stock_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            StockListSync()
            console.log(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    // console.log(rowData?.list)

    return (
        <div>
            <div>
                <div style={{paddingTop:10, paddingBottom:5, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", gap:10}}>
                    <div style={{paddingTop:10, paddingBottom:5, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap:10}}>
                        <span style={{fontSize:15, fontWeight:600}}>Search:</span>
                        <input onChange={(e)=>setSearch(e.target.value)} value={search} style={{width: 200, height:35, borderRadius:5, padding:5, fontSize:15, border: "1px solid #ddd"}} />
                        {/* <InputText onChange={(e)=>setSearch(e.target.value)} value={search} style={{width: 200}} /> */}
                    </div>
                    {
                        rowData?.action !== 'details' ?
                        <div>
                            <button onClick={(e)=> selectStockId?.action === "deleted" ? setSelectStockId({stock_id:null, action: null}) : setSelectStockId({stock_id:null, action: 'deleted'})} style={{width:100, borderRadius:5, border:"1px solid #ddd", height:35}}>
                                {
                                    selectStockId?.action === "deleted" ?
                                    <>Hide Deleted</>
                                    :
                                    <>Show Deleted</>
                                }                            
                            </button>
                        </div>
                        :
                        null
                    }
                </div>
                <div>

                </div>
            </div>
            <div style={{display:"flex", flexDirection:"column", gap:0}}>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", border:"1px solid #ddd", padding:5}}>
                    <div style={{minWidth:50}}><label>Qty</label></div>
                    <div style={{minWidth:200}}><label>Serial</label></div>
                    <div style={{minWidth:100}}><label>Supplier</label></div>
                    <div style={{minWidth:150}}><label>Invoice</label></div>
                    <div style={{minWidth:100}}><label>Purchase</label></div>
                    <div style={{minWidth:100}}><label>Encoded</label></div>
                    <div style={{minWidth:100}}></div>
                </div>
                {
                    stocks.length>0 ?
                    <>
                        {
                            searchData(stocks, search, null).map((item, key)=>{
                                if (item?.stock_id === selectStockId?.stock_id && selectStockId?.action === "edit") {
                                    return (
                                        <div key={key} style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", border:"1px solid #ddd", backgroundColor:"#85b2f9", padding:5}}>
                                            <div style={{minWidth:50}}><input onChange={(e)=>setQty(e.target.value)} value={qty ? qty : item.stock_qty} style={{width:40, height:28.2, border:"none", borderRadius:5}} /></div>
                                            <div style={{minWidth:200}}><input onChange={(e)=>setSerial(e.target.value)} value={serial ? serial : item.serial} style={{width:"90%", height:28.2, border:"none", borderRadius:5}} /></div>
                                            <div style={{minWidth:100, display:"flex", flexDirection:"row", paddingLeft:0, justifyContent:"flex-start"}}>
                                                {/* {JSON.stringify(supplierlist)} */}
                                                <select name='suppleir' onChange={(e)=>setSupplierName(e.target.value)} style={{width:90, height:28, borderRadius:5}} value={supplierName}>
                                                    <option value={""} selected="selected">select...</option> 
                                                    {
                                                        supplierlist.map((catlist, i) => {
                                                            return (
                                                                <option value={catlist.supplier_name}>{catlist.supplier_name}</option> 
                                                            )
                                                        })
                                                    }   
                                                </select>    
                                            </div>
                                            <div style={{minWidth:150}}>
                                                <input onChange={(e)=>setSupplierInvoice(e.target.value)} value={supplierInvoice ? supplierInvoice : item.supplier_invoice} style={{width:"90%", height:28.2, borderRadius:5, border:"none"}} />
                                            </div>
                                            <div style={{minWidth:100}}>
                                                
                                                {/* <Calendar name='date_purchace' style={{width:90, padding:0, height:28.2, borderRadius:0}} value={
                                                    format(item.date_purchace, 'MM')+'/'+format(item.date_purchace, 'dd')+'/'+format(item.date_purchace, 'yyyy')
                                                } onChange={(e) => {
                                                }} /> */}
                                                <input type='date' onChange={(e)=>setSupplierPurchases(e.target.value)} value={supplierPurchases ? supplierPurchases : item.date_purchace} style={{width:90, padding:0, height:28.2, border:"none", borderRadius:5, outline:"none"}} />
                                                </div>
                                            <div style={{minWidth:100, color:"#fff"}}>{item.date_encoded}</div>
                                            <div style={{display:"flex", flexDirection:"column", gap: 5}}>
                                                <div style={{minWidth:100, display:"flex", flexDirection:"row", gap:5}}>
                                                    <button onClick={()=>UpdateStock(item)} style={{width:null, backgroundColor:"#009688", color:"#fff", border:"none", borderRadius:5}}>
                                                        <span className='pi pi-check'></span> Update
                                                    </button>
                                                    <button onClick={()=>setSelectStockId({stock_id:null, action: null})} style={{width:null, backgroundColor:"#d06835", color:"#fff", border:"none", borderRadius:5}}>
                                                        <span className='pi pi-times'></span> Cancel
                                                    </button>
                                                    <button onClick={()=>setSelectStockId({stock_id:item.stock_id, action: "remove"})} style={{width:null, backgroundColor:"#cb4747", color:"#fff", border:"none", borderRadius:5}}>
                                                        <span className='pi pi-times'></span> Remove
                                                    </button>
                                                </div>
                                                {/* <div style={{minWidth:100, display:"flex", flexDirection:"row", gap:5}}>
                                                    <button onClick={()=>setSelectStockId({stock_id:item.stock_id, action: "history"})} style={{width:null, backgroundColor:"#fff", color:"#000", border:"none", borderRadius:5}}>
                                                        <span className='pi pi-history'></span> History
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    )
                                } else if (item?.stock_id === selectStockId?.stock_id && selectStockId?.action === "remove") {
                                    return (
                                        <div key={key} style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", border:"1px solid #ddd", backgroundColor:"#ff7777", padding:5}}>
                                            <div style={{minWidth:50, color:"#fff"}}>{item.stock_qty}</div>
                                            <div style={{minWidth:200, color:"#fff"}}>{item.serial}</div>
                                            <div style={{minWidth:100, color:"#fff"}}>{item.supplier_name}</div>
                                            <div style={{minWidth:150, color:"#fff"}}>{item.supplier_invoice}</div>
                                            <div style={{minWidth:100, color:"#fff"}}>{item.date_purchace}</div>
                                            <div style={{minWidth:100, color:"#fff"}}>{item.date_encoded}</div>
                                            <div style={{minWidth:100, display:"flex", flexDirection:"row", gap:5}}>
                                                <button onClick={()=>RemoveStock(item)} style={{width:null, backgroundColor:"#cb4747", color:"#fff", border:"none", borderRadius:5}}>
                                                    <span className='pi pi-trash'></span> Yes Remove
                                                </button>
                                                <button onClick={()=>setSelectStockId({stock_id:null, action: null})} style={{width:null, backgroundColor:"#d06835", color:"#fff", border:"none", borderRadius:5}}>
                                                    <span className='pi pi-times'></span> Cancel
                                                </button>
                                            </div>
                                        </div>
                                    )
                                } else if (selectStockId?.action === "deleted") { // item?.status ==
                                    return (
                                        <div key={key} style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", border:"1px solid #ddd", backgroundColor:"#ff7777", padding:5}}>
                                            <div style={{minWidth:50, color:"#fff"}}>{item.stock_qty}</div>
                                            <div style={{minWidth:200, color:"#fff"}}>{item.serial}</div>
                                            <div style={{minWidth:100, color:"#fff"}}>{item.supplier_name}</div>
                                            <div style={{minWidth:150, color:"#fff"}}>{item.supplier_invoice}</div>
                                            <div style={{minWidth:100, color:"#fff"}}>{item.date_purchace}</div>
                                            <div style={{minWidth:100, color:"#fff"}}>{item.date_encoded}</div>
                                            <div style={{minWidth:100, display:"flex", flexDirection:"row", gap:5}}>
                                                <button onClick={()=>RestoreStock(item)} style={{width:null, backgroundColor:"#cb4747", color:"#fff", border:"none", borderRadius:5}}>
                                                    <span className='pi pi-reply'></span> Restore
                                                </button>
                                                <StockHistory setSelectStockId={setSelectStockId} item={item} />
                                                {/* <button onClick={()=>setSelectStockId({stock_id:item.stock_id, action: "history"})} style={{width:null, backgroundColor:"#fff", color:"#000", border:"none", border: "1px solid #ddd", borderRadius:5}}>
                                                    <span className='pi pi-history'></span> History
                                                </button> */}
                                                {/* <button onClick={()=>setSelectStockId({stock_id:null, action: null})} style={{width:null, backgroundColor:"#d06835", color:"#fff", border:"none", borderRadius:5}}>
                                                    <span className='pi pi-times'></span> Cancel
                                                </button> */}
                                            </div>
                                        </div>
                                    )
                                }
                                return (
                                    <div key={key} style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", border:"1px solid #ddd", padding:5}}>
                                        <div style={{minWidth:50}}>{item.stock_qty}</div>
                                        <div style={{minWidth:200}}>{item.serial}</div>
                                        <div style={{minWidth:100}}>{item.supplier_name}</div>
                                        <div style={{minWidth:150}}>{item.supplier_invoice}</div>
                                        <div style={{minWidth:100}}>{item.date_purchace}</div>
                                        <div style={{minWidth:100}}>{item.date_encoded}</div>
                                        {
                                            rowData?.action !== 'details' ?
                                            <div style={{minWidth:100, display:"flex", flexDirection:"row", gap:5}}>
                                                <button onClick={()=>{
                                                    setSelectStockId({stock_id:item.stock_id, action: "edit"})
                                                    setSupplierName(item.supplier_name)
                                                }} style={{width:null, backgroundColor:"#2196F3", color:"#fff", border:"none", borderRadius:5}}>
                                                    <span className='pi pi-pencil'></span> Edit
                                                </button>
                                                <StockHistory setSelectStockId={setSelectStockId} item={item} />
                                                {/* <button onClick={()=>setSelectStockId({stock_id:item.stock_id, action: "history"})} style={{width:null, backgroundColor:"#fff", color:"#000", border:"none", border: "1px solid #ddd", borderRadius:5}}>
                                                    <span className='pi pi-history'></span> History
                                                </button> */}
                                                <button onClick={()=>setSelectStockId({stock_id:item.stock_id, action: "remove"})} style={{width:null, backgroundColor:"#cb4747", color:"#fff", border:"none", borderRadius:5}}>
                                                    <span className='pi pi-times'></span> Remove
                                                </button>
                                            </div>
                                            :
                                            <div style={{minWidth:100, display:"flex", flexDirection:"row", gap:5}}>
                                                {/* <button onClick={()=>{
                                                    setSelectStockId({stock_id:item.stock_id, action: "edit"})
                                                    setSupplierName(item.supplier_name)
                                                }} style={{width:null, backgroundColor:"#2196F3", color:"#fff", border:"none", borderRadius:5}}>
                                                    <span className='pi pi-search'></span> Search
                                                </button> */}
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </>
                    :
                    null
                }
                
            </div>
            {/* {
                JSON.stringify(rowData.list)
            } */}
        </div>
    );
}

export default StockTable;

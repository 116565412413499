// import { Dialog } from 'primereact/dialog';
// import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useState } from 'react';
// import AddPeymentForm from './AddPeymentForm';
import MiscPanel from './MiscPanel';
import ServicesPanel from './ServicesPanel';
import PaymentReservePlanel from './Payment_Reserve_Planel';
// import DefectivePanel from './DefectivePanel';
import DefectiveForm from './DefectiveForm';
import swal from 'sweetalert';
import ENV from '../../ENV';
import axios from 'axios';

const SalesMenu = (props) => {
    const [visible, setVisible] = useState(false);
    const SubTotal = props?.salesoper?.reduce((amount, item) => amount = Number(item.qty * item.unit_srp) + amount, 0);
    // console.log(props?.salesoper)

    // TotalQty = props?.salesoper?.reduce((amount, item) => amount = Number(item.qty * item.unit_srp) + amount, 0);

    const SetDeffective = (value) => {
        let newData = [];
        props.salesoper.map((item) => {
            newData.push({ ...item, "reason": String(""), "set_defective": value, "checker_id": 0 })
        })
        props.setSalesOperation([...newData])
    }

    const [customerqueue, setCustomerQueue] = useState([])
    const FetchCustomerQueue = async () => {
        var formData = new FormData();
        formData.append("customer_queue", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/sales.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setCustomerQueue([...res.data])
        });
    }

    const CancelDefectiveBtn = () => {
        if ([...props.salesoper].filter((i) => i.reason !== "").length > 0) {
            let newData = [];
            let newDatas = [];
            [...props.salesoper].map((item) => {
                if (String(item.reason) !== "") {
                    newData.push({ ...item, "selected_row": true })
                } else {
                    newData.push({ ...item })
                }
            })

            props.setSalesOperation([...newData])

            swal({
                title: "Warning!",
                text: "Are you sure!",
                icon: "info",
                buttons: {
                    confirm: "Yes",
                    cancel: "No"
                }
            }).then((willclose) => {
                if (willclose) {
                    [...props.salesoper].map((item) => {
                        newDatas.push({ ...item, "selected_row": false })
                    })
                    props.setSalesOperation([...newDatas])
                    SetDeffective(false)
                } else {
                    [...props.salesoper].map((item) => {
                        newDatas.push({ ...item, "selected_row": false })
                    })
                    props.setSalesOperation([...newDatas])
                }
            })
        } else {
            SetDeffective(false)
        }
    }

    useEffect(()=>{
        FetchCustomerQueue()
    }, [])



    console.log(props.inputvalue)

    return (
        <div>
            <div>
                <span style={{ fontSize: 30, fontWeight: "bolder" }}>Total: </span> <span style={{ fontSize: 30, fontWeight: "bolder" }}>{Number(SubTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            </div>

            {/* <Sidebar blockScroll={true} dismissable={false} visible={visible} style={{width: "30%"}} position="right" onHide={() => setVisible(false)}>
                <div>
                    <div style={{padding: 5, border: "1px solid #ddd", borderRadius: 7, display: "flex", flexDirection: "column", gap: 10}}>
                        <div>
                            <label style={{fontSize: 15}}>Customer</label>
                            <input style={{ fontSize: 20, padding: 5, width:"100%", height: 45, borderRadius: 5, border:"1px solid #ddd"}} />
                        </div>
                        <div>
                            <label style={{fontSize: 15}}>Contact No.</label>
                            <input style={{ fontSize: 20, padding: 5, width:"100%", height: 45, borderRadius: 5, border:"1px solid #ddd"}} />
                        </div>
                        <div>
                            <label style={{fontSize: 15}}>Address</label>
                            <input style={{ fontSize: 20, padding: 5, width:"100%", height: 45, borderRadius: 5, border:"1px solid #ddd"}} />
                        </div>
                        <div>
                            <label style={{fontSize: 15}}>Note</label>
                            <textarea style={{ fontSize: 20, padding: 5, width:"100%", height: 100, borderRadius: 5, border:"1px solid #ddd"}} />
                        </div>
                    </div>

                    <div style={{padding: 10, textAlign: "center"}}>
                        <div style={{fontSize: 35, fontWeight: 600}}>
                            <label style={{fontSize: 35}}>Total 0.00</label>
                        </div>
                    </div>
                    <div>
                        <AddPeymentForm />
                    </div>
                    <div>
                        <div style={{width: "100%", borderRadius: 5, border: "1px solid #ddd"}}>
                            <div></div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <button style={{fontSize: 15, borderRadius: 5}}>
                                <span style={{fontSize: 20}}>Place Order</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Sidebar> */}
            <div style={{ display: "flex", flexDirection: "column" }}>
                {
                    props.salesoper.filter((i) => i.set_defective === true).length > 0 ?
                        (
                            <>
                                <div
                                    style={{
                                        cursor: "no-drop",
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: "#ddd",
                                        height: 45,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontWeight: 600,
                                        fontSize: 15,
                                        border: "1px solid #ddd",
                                        color: "#fff",
                                        flexGrow: 1
                                    }}>Payment / Reserve</div>
                                <div style={{ width: "100%", display: "flex", alignItems: "center", position: "relative" }}>
                                    <div onClick={() => CancelDefectiveBtn()} style={{ position: "absolute", cursor: "pointer", zIndex: 1, width: 100, height: 30, backgroundColor: "orange", borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", left: 10 }}>
                                        <span style={{ fontSize: 15, color: "#fff", fontWeight: "bolder" }}>Cancel</span>
                                    </div>
                                    {
                                        [...props.salesoper].filter((i) => i.reason !== "").length === [...props.salesoper].length ?
                                            <DefectiveForm salesoper={props.salesoper} setSalesOperation={props.setSalesOperation} />
                                            :
                                            <div
                                                onClick={() => setVisible(true)} style={{
                                                    // width: "100%",
                                                    cursor: "no-drop",
                                                    textAlign: "center",
                                                    backgroundColor: "#ddd",
                                                    height: 45,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontWeight: 600,
                                                    fontSize: 15,
                                                    border: "1px solid #ddd",
                                                    color: "#fff",
                                                    flexGrow: 1
                                                }}>Submit as Defective</div>
                                    }
                                </div>
                            </>
                        ) : (
                            <PaymentReservePlanel salesoper={props.salesoper} setSalesOperation={props.setSalesOperation} inputvalue={props.inputvalue} setInputValue={props.setInputValue} />
                        )
                }

            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                {
                    props.salesoper.filter((i) => i.set_defective === true).length > 0 ?
                        null
                        :
                        <div onClick={() => SetDeffective(true)} style={{
                            // width: "100%",
                            cursor: "pointer",
                            textAlign: "center",
                            backgroundColor: "rgb(181 88 88)",
                            height: 45,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: 600,
                            fontSize: 15,
                            border: "1px solid #ddd",
                            color: "#fff",
                            flexGrow: 1
                        }}>
                            <span>Set Defective</span>
                        </div>
                }
                {/* < /> */}
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "stretch" }}>
                <ServicesPanel />
                <MiscPanel />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <div style={{width: "100%", backgroundColor: "#fff"}}>
                    <div>
                        <div style={{padding: 5}}>
                            <span>Suspended Record</span>
                        </div>
                        <div>
                            {
                                [1,2,3].map((item)=>{
                                    return (
                                        <div key={item} style={{width: "100%", height: 45, border: "1px solid #ddd"}}></div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div style={{width: "100%", backgroundColor: "#fff"}}>
                    <div>
                        <div style={{padding: 5}}>
                            <span>Waiting Customer</span>
                        </div>
                        <div>
                            {
                                [...customerqueue].map((item, key) => {
                                        return (
                                            <div key={key} style={{ width: "100%", height: 45, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5 }}>
                                                <div>
                                                    <span>{item.customer_name}</span>
                                                </div>
                                                <div>
                                                    <PaymentReservePlanel salesoper={props.salesoper} setSalesOperation={props.setSalesOperation} status_customer="waiting" customer={item} inputvalue={props.inputvalue} setInputValue={props.setInputValue} />
                                                </div>
                                            </div>
                                        )
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SalesMenu;

import axios from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import ENV from '../../ENV';
import { format } from 'date-fns';

const EditInfoBtn = ({ OnlySalesData, PreviewDetails }) => {
    const [addcustomerdialog, setAddCustomerDialog] = useState(false);
    ///////////////////////////////////////////////////////
    const [customername, setCustomerName] = useState('')
    const [contact, setContact] = useState('')
    const [contactperson, setContactPerson] = useState('')
    const [address, setAddress] = useState('')
    const [emailaddress, setEmailAddress] = useState('')
    const [tin, setTin] = useState('')

    
    const [submitted, setSubmitted] = useState(false);
    const [SearchExistingCustomer, setSearchExistingCustomer] = useState(false)
    const [customerinfo, setCustomerInfo] = useState(JSON.parse(sessionStorage.getItem('customerinfo')) ?? {})

    
    const Proceed = () => {

    }
    
    const UpdateInfo = async () => {

        console.log(OnlySalesData.sales_id)
        OnlySalesData.customer_name = customername !== ''? customername : OnlySalesData.customer_name;
        OnlySalesData.contact = contact !== ''? contact : OnlySalesData.contact;
        OnlySalesData.customer_email = emailaddress !== ''? emailaddress : OnlySalesData.customer_email;
        OnlySalesData.address = address !== ''? address : OnlySalesData.address;
        OnlySalesData.shistory = [...OnlySalesData.shistory, 
            { 
                title: 'information', 
                type: 'update', 
                user_id: localStorage.getItem('user_id'), 
                updatedby: localStorage.getItem('firstname'), 
                date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), 
                data: {
                    customer_name: customername,
                    contact: contact,
                    customer_email: emailaddress,
                    customer_person: contactperson,
                    email_address: emailaddress,
                    tin: tin,
                    address: address
                } 
            }
        ];
        PreviewDetails(OnlySalesData)

        var formData = new FormData();
        formData.append("sales_report_update", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("sales_id", OnlySalesData.sales_id);
        formData.append("customer_name", customername);
        formData.append("contact", contact);
        formData.append("customer_email", emailaddress);
        formData.append("address", address);
        formData.append("shistory", JSON.stringify(OnlySalesData.shistory));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'sales_report.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            if (res.data === 'success') {
                // sales_report_list()
            } else {

            }
        });

    }

    // console.log(OnlySalesData)

    const hideCustomerDialog = () => {
        setSubmitted(false);
        setAddCustomerDialog(false);
        // setSearchExistingCustomer(false)
    }

    const CustomerDialogFooter = (
        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div></div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={hideCustomerDialog} />
                    <Button onClick={() => UpdateInfo()} style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" />
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <>
            <Dialog visible={addcustomerdialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                <span style={{ fontSize: 25 }}>Edit Customer Details</span>
            } modal className="p-fluid" footer={CustomerDialogFooter} onHide={hideCustomerDialog}>
                {
                    SearchExistingCustomer ?
                        <div>
                            <div style={{ paddingBottom: 5 }}>
                                {/* <label style={{ fontSize: 15 }}>Search</label> */}
                                <input placeholder='Search...' onChange={(e) => null} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                            </div>
                        </div>
                        :
                        <div>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>FullName / Company Name:</label>
                                {/* OnlySalesData?.firstname */}
                                <input onChange={(e) => setCustomerName(e.target.value)} value={customername ? customername : OnlySalesData?.customer_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>

                                <div style={{ paddingBottom: 5 }}>
                                    <label style={{ fontSize: 15 }}>Mobile / Telephone:</label>
                                    <input onChange={(e) => setContact(e.target.value)} value={contact ? contact : OnlySalesData?.contact} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                </div>
                                <div style={{ paddingBottom: 5 }}>
                                    <label style={{ fontSize: 15 }}>Contact Person / Contact Number:</label>
                                    <input onChange={(e) => setContactPerson(e.target.value)} value={contactperson ? contactperson : OnlySalesData?.contact_person} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                </div>

                            </div>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Address:</label>
                                <textarea cols="30" rows="5" onChange={(e) => setAddress(e.target.value)} value={address ? address : OnlySalesData?.address} style={{ resize: "none", width: "100%", paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} ></textarea>
                            </div>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Email <span style={{ color: "#a7a7a7" }}>(optional)</span></label>
                                <input onChange={(e) => setEmailAddress(e.target.value)} value={emailaddress ? emailaddress : OnlySalesData?.email} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                            </div>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Tin <span style={{ color: "#a7a7a7" }}>(optional)</span></label>
                                <input onChange={(e) => setTin(e.target.value)} value={tin ? tin : OnlySalesData?.tin} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                            </div>
                        </div>
                }
            </Dialog>
            <button onClick={()=>setAddCustomerDialog(true)} style={{ width: "50%" }}>Edit Information</button>
        </>
    );
}

export default EditInfoBtn;

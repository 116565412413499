import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

const AddPaymentBtn = (prop) => {

    const [visible, setVisible] = useState(false)
    return (
        <>
            <Dialog
                blockScroll={true}
                draggable={false}
                header={
                    <>
                        <div style={{fontSize: 20}}>Add Payment</div>
                    </>
                }
                footer={
                    <div style={{ padding: 5 }}>
                        <button onClick={() => setVisible(false)} className='btn btn-sm btn-info'>Add</button>
                        <button onClick={() => setVisible(false)} className='btn btn-sm btn-danger'>Close</button>
                    </div>
                }
                position={"top"}
                visible={visible} 
                style={{ width: '30vw' }} 
                onHide={() => setVisible(false)}>
                    <div>
                        <div style={{padding: 5}}>
                            <div style={{fontSize: 20, fontWeight: "bold"}}>Invoice No.: {prop.item.supplier_invoice}</div>
                        </div>
                        <div>
                            <span>
                                <label style={{fontSize: 15}}>Payment Method</label>
                                <select style={{fontSize: 15}}>
                                    <option>Cash</option>
                                    <option>Bank Online</option>
                                    <option>Maya</option>
                                    <option>GCash</option>
                                    <option>Cheque</option>
                                </select>
                            </span>
                        </div>
                        <div>
                            <span>
                                <label style={{fontSize: 15}}>Account Name</label>
                                <input className='form-control' />
                            </span>
                        </div>
                        <div>
                            <span>
                                <label style={{fontSize: 15}}>Account Number</label>
                                <input className='form-control' />
                            </span>
                        </div>
                        <div>
                            <span>
                                <label style={{fontSize: 15}}>Amount</label>
                                <input className='form-control' />
                            </span>
                        </div>
                        <div>
                            <span>
                                <label style={{fontSize: 15}}>Date</label>
                                <input className='form-control' />
                            </span>
                        </div>
                        <div>
                            <span>
                                <label style={{fontSize: 15}}>Reference</label>
                                <input className='form-control' />
                            </span>
                        </div>
                        <div>
                            <span>
                                <label style={{fontSize: 15}}>Note</label>
                                <textarea className='form-control' />
                            </span>
                        </div>
                        <div>
                            <span>
                                <label style={{fontSize: 15}}>Upload</label>
                                <input type='file' name="file" />
                            </span>
                        </div>
                    </div>
            </Dialog>

            <button onClick={() => setVisible(true)} className='btn btn-info btn-xs'>Add Payment</button>
        </>
    );
}

export default AddPaymentBtn;

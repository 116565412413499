import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import swal from 'sweetalert';
import ENV from '../../../ENV';
import axios from 'axios';

const EducationBtn = (props) => {
    const [openDialog, setOpenDialog] = useState(false)
    const [inputvalue, setInputValue] = useState({})
    const menuLeft = useRef(null);
    const menuRight = useRef(null);
    const toast = useRef(null);


    const hideDialog = () => {
        setOpenDialog(false)
    }

    const addEducated = async () => {
        // console.log(inputvalue)
        const formData = new FormData();
        formData.append("add_education", 1);
        formData.append("user_id", props.item.user_id);
        formData.append("schools", JSON.stringify(inputvalue));

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/employee.php',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            const idx = props.employeelist.findIndex(i => i.user_id == props.item.user_id);
            props.employeelist[idx].schools = res.data;
            swal({
                title: "Done!",
                text: "Successfully Deleted",
                icon: "success",
                timer: 1000,
                button: false
            }).then(() => {
                props.setSchools(res.data)
                props.setEmployeeList(props.employeelist)
                setOpenDialog(false)
                return;
            });
        }).catch((err) => {

        });
    }

    const updateEducated = async () => {
        const idx = props.employeelist.findIndex(i => i.user_id == props.item.user_id);
        const sidx = props.schools.findIndex(i => i == props.row);
        props.schools[sidx].school_name = inputvalue.school_name;
        props.schools[sidx].course = inputvalue.course;
        props.schools[sidx].school_year = inputvalue.school_year;

        const formData = new FormData();
        formData.append("update_education", 1);
        formData.append("user_id", props.item.user_id);
        formData.append("schools", JSON.stringify(props.schools));

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/employee.php',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            swal({
                title: "Done!",
                text: "Successfully Deleted",
                icon: "success",
                timer: 1000,
                button: false
            }).then(() => {
                props.employeelist[idx].schools = props.schools;
                props.setSchools(res.data)
                props.setEmployeeList(props.employeelist)
                setOpenDialog(false)
                return;
            });
        }).catch((err) => {

        });
    }

    const deleteEducated = async () => {
        const idx = props.employeelist.findIndex(i => i.user_id == props.item.user_id);
        const newData = props.schools.filter(i => i != props.row);

        const formData = new FormData();
        formData.append("delete_education", 1);
        formData.append("user_id", props.item.user_id);
        formData.append("schools", JSON.stringify(newData));
        swal({
            title: "Are you Sure?",
            icon: "info",
            buttons: {
                confirm: "Yes sure",
                cancel: "No"
            }
        }).then(async(confirm) => {
            if(confirm){
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'api/employee.php',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    swal({
                        title: "Done!",
                        text: "Successfully Deleted",
                        icon: "success",
                        timer: 1000,
                        button: false
                    }).then(() => {
                        props.employeelist[idx].schools = res.data;
                        props.setSchools(res.data)
                        props.setEmployeeList(props.employeelist)
                        setOpenDialog(false)
                        return;
                    });
                }).catch((err) => {
        
                });
            }
        });
        // console.log(props.row, newData)
    }


    
    const items = [
        {
            label: 'Options',
            items: [
                {
                    label: 'Edit',
                    icon: 'pi pi-pencil',
                    command: () => {
                        setOpenDialog(true)
                        setInputValue(props.row)
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: () => {
                        deleteEducated()
                    }
                }
            ]
        }
    ];

    const btnAction = (event) => {
        if (props.btn == "add") {
            return setOpenDialog(true);
        } else if (props.btn == "edit") {
            
        } else if (props.btn=="row") {
            menuRight.current.toggle(event)
        }
    }



    return (
        <>
            <Dialog
                position='top'
                draggable={false}
                style={{ width: '50rem', fontSize: 15 }}
                visible={openDialog}
                onHide={hideDialog}
                header={
                    <>
                        <label> {props.btn == "add" ? "Add New" : "Edit"} </label>
                    </>
                }
                footer={
                    <>
                        <button onClick={props.btn == "add" ? addEducated : updateEducated} className='btn btn-success btn-xs'>{props.btn == "add" ? "Save New" : "Update"}</button>
                    </>
                }
            >
                <div>
                    <div>
                        <label>School Name</label>
                        <input name='school_name' value={inputvalue.school_name} onChange={(e) => setInputValue({ ...inputvalue, [e.target.name]: e.target.value })} className='form-control' />
                    </div>
                    <div>
                        <label>Course</label>
                        <input name='course' value={inputvalue.course} onChange={(e) => setInputValue({ ...inputvalue, [e.target.name]: e.target.value })} className='form-control' />
                    </div>
                    <div>
                        <label>Date Graduated</label>
                        <input name='school_year' value={inputvalue.school_year} onChange={(e) => setInputValue({ ...inputvalue, [e.target.name]: e.target.value })} className='form-control' />
                    </div>
                </div>
            </Dialog>
            <Menu style={{fontSize: 15}} model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
            <Button onClick={btnAction} label={props.btn=="row" ? null : 'Add New'} icon="pi pi-align-right" className="btn btn-xs" aria-controls="popup_menu_right" aria-haspopup />
        </>
    );
}

export default EducationBtn;

import React, { useContext, useMemo, useState } from 'react';
import ENV from '../ENV';
import axios from 'axios';
import AppContext from '../context/AppContext';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Navbar = ({ userInfo }) => {
    const { state, dispatch } = useContext(AppContext);
    // const users_role = JSON.parse(localStorage.getItem('users_role'))

    const [users_role, setUserRole] = useState({})

    useMemo(async () => {
        var formData = new FormData();
        formData.append("users_role", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + '/login.php',
            data: formData,
        }).then((res) => {
            
            // console.log(JSON.parse(res.data[0].users_role))
            // module_inventory
            setUserRole(res.data[0].users_role)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    // console.log(users_role.module_inventory)


    const OpenSalesReport = async () => {
        var formData = new FormData();
        formData.append("sales_report_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'sales_report.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            sessionStorage.setItem('sales_report_list', JSON.stringify(res.data));
            // console.log(JSON.stringify(res.data))
            // dispatch({type: 'SET_SALES_REPORT_LIST', payload:res.data})
            // window.location.assign('/report/sales')
            // sessionStorage.setItem(JSON.stringify(res.data))
        })
        .catch((err) => {
            console.log(err);
        });
    }

    // console.log(state.REPORT_LIST)



    const Logout = () => {
        localStorage.removeItem('user_id')
        window.location.assign('/')
    }



    return (

        <>

            <div className="vd_navbar vd_nav-width vd_navbar-tabs-menu vd_navbar-left vd_bg-blue" style={{height: "100vh"}}>
                <div className="navbar-menu clearfix">
                    <div className="vd_panel-menu hidden-xs">
                        <span data-original-title="Expand All" data-toggle="tooltip" data-placement="bottom" data-action="expand-all" className="menu" data-intro="<strong>Expand Button</strong><br/>To expand all menu on left navigation menu." data-step={4}>
                            <i className="fa fa-sort-amount-asc" />
                        </span>
                    </div>
                    <h3 className="menu-title hide-nav-medium hide-nav-small">Menu</h3>
                    <div className="vd_menu">
                        <ul>
                            {/* <li style={{ cursor: 'pointer' }}>
                                <a href="/dashboard">
                                    <span className="menu-icon"><i className="fa fa-dashboard" /></span>
                                    <span className="menu-text">Dashboard</span>
                                </a>
                            </li> */}
                            {/* <li style={{ cursor: 'pointer' }}>
                                <a href="calendar.php">
                                    <span className="menu-icon"><i className="fa fa-calendar" /></span>
                                    <span className="menu-text">Calendar</span>
                                </a>
                            </li> */}
                            <li style={{ cursor: 'pointer' }}>
                                <a href="/customer">
                                    <span className="menu-icon"><i style={{fontSize: 18}} className="pi pi-users" /></span>
                                    <span className="menu-text">Customer</span>
                                </a>
                            </li>
                            {/* <li style={{ cursor: 'pointer' }}>
                                <a href="/product">
                                    <span className="menu-icon"><i className="fa fa-cubes" /></span>
                                    <span className="menu-text">Product</span>
                                </a>
                            </li> */}
                            <li style={{ cursor: 'pointer' }}>
                                <a href="javascript:void(0);" data-action="click-trigger" className>
                                    <span className="menu-icon"><i style={{fontSize: 18}} className="pi pi-box" /></span>
                                    <span className="menu-text">Product</span>
                                    <span className="menu-badge"><span className="badge vd_bg-black-30"><i style={{fontSize: 12}} className="pi pi-chevron-down" /></span></span>
                                </a>
                                <div className="child-menu" data-action="click-target">
                                    <ul>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="/product">
                                                <span className="menu-text">Products</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="/stockin">
                                                <span className="menu-text">Stock-In</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li style={{ cursor: 'pointer' }}>
                                <a href="/sales">
                                    <span className="menu-icon"><i style={{fontSize: 18}} className="pi pi-dollar" /></span>
                                    <span className="menu-text">Sales</span>
                                </a>
                            </li>
                            <li style={{ cursor: 'pointer' }}>
                                <a href="javascript:void(0);" data-action="click-trigger" className>
                                    <span className="menu-icon"><i style={{fontSize: 18}} className="pi pi-book" /></span>
                                    <span className="menu-text">Inventory</span>
                                    <span className="menu-badge"><span className="badge vd_bg-black-30"><i style={{fontSize: 12}} className="pi pi-chevron-down" /></span></span>
                                </a>
                                <div className="child-menu" data-action="click-target">
                                    <ul>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="report_inventory_genbrand.php">
                                                <span className="menu-icon" />
                                                <span className="menu-text">Generate By Brand</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="report_inventory.php">
                                                <span className="menu-icon" />
                                                <span className="menu-text">Generate By Category</span>
                                            </a>
                                        </li>
                                        {
                                            localStorage.getItem('user_id') == 1 || localStorage.getItem('user_id') == 2 || localStorage.getItem('user_id') == 10 ?
                                                <>
                                                    {/* <li style={{ cursor: 'pointer' }}>
                                                        <a href="/inventory/recount">
                                                            <span className="menu-icon" />
                                                            <span className="menu-text">Re-Count</span>
                                                        </a>
                                                    </li> */}
                                                    {/* <li style={{ cursor: 'pointer' }}>
                                                        <a href="/inventory/pending">
                                                            <span className="menu-icon" />
                                                            <span className="menu-text">Inventory Pending</span>
                                                        </a>
                                                    </li> */}
                                                    <li style={{ cursor: 'pointer' }}>
                                                        <a href="/inventory/current">
                                                            <span className="menu-icon" />
                                                            <span className="menu-text">Inventory Checklist</span>
                                                        </a>
                                                    </li>
                                                    <li style={{ cursor: 'pointer' }}>
                                                        <a href="/inventory_assignment">
                                                            <span className="menu-icon" />
                                                            <span className="menu-text">Assignment for Inventory</span>
                                                        </a>
                                                    </li>
                                                </>
                                                :
                                                <li style={{ cursor: 'pointer' }}>
                                                    <a href="/inventory">
                                                        <span className="menu-icon" />
                                                        <span className="menu-text">Account Inventory</span>
                                                    </a>
                                                </li>
                                        }
                                    </ul>
                                </div>
                            </li>
                            <li style={{ cursor: 'pointer' }}>
                                <a href="report_search_sales_item.php">
                                    <span className="menu-icon"><i style={{fontSize: 18}} className="pi pi-search" /></span>
                                    <span className="menu-text">Search Sales Item </span>
                                </a>
                            </li>
                            <li style={{ cursor: 'pointer' }}>
                                <a href="javascript:void(0);" data-action="click-trigger" className>
                                    <span className="menu-icon"><i style={{fontSize: 18}} className="pi pi-book" /></span>
                                    <span className="menu-text">Reservation</span>
                                    <span className="menu-badge"><span className="badge vd_bg-black-30"><i style={{fontSize: 12}} className="pi pi-chevron-down" /></span></span>
                                </a>
                                <div className="child-menu" data-action="click-target">
                                    <ul>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="reserve_online.php" data-action="click-trigger" className>
                                                {/* <span class="menu-icon"><i class="fa fa-circle-o"> </i></span> */}
                                                <span className="menu-text">Walk-in / Online</span>
                                                <span className="menu-badge">
                                                    <span className="badge vd_bg-black-30"><i style={{fontSize: 12}} className="pi pi-chevron-down" /></span>
                                                </span>
                                            </a>
                                            <div className="child-menu" data-action="click-target">
                                                <ul>
                                                    <li style={{ cursor: 'pointer' }}>
                                                        <a href="reserve_online.php?status=reserve">
                                                            {/* <span class="menu-icon"><i class="fa fa-circle-o"> </i></span> */}
                                                            <span className="menu-text">Ongoing Reservation</span>
                                                        </a>
                                                    </li>
                                                    <li style={{ cursor: 'pointer' }}>
                                                        <a href="reserve_online.php?status=complete">
                                                            {/* <span class="menu-icon"><i class="fa fa-circle-o"> </i></span> */}
                                                            <span className="menu-text">Completed Reservation</span>
                                                        </a>
                                                    </li>
                                                    <li style={{ cursor: 'pointer' }}>
                                                        <a href="reserve_online.php?status=cancel">
                                                            {/* <span class="menu-icon"><i class="fa fa-circle-o"> </i></span> */}
                                                            <span className="menu-text">Cancelled Reservation</span>
                                                        </a>
                                                    </li>
                                                    <li style={{ cursor: 'pointer' }}>
                                                        <a href="reserve_online.php?status=transferred">
                                                            <span className="menu-text">Transferred</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="reserve_shopee.php">
                                                {/* <span class="menu-icon"><i class="fa fa-circle-o"> </i></span> */}
                                                <span className="menu-text">Shopee</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li style={{ cursor: 'pointer' }}>
                                <a href="javascript:void(0);" data-action="click-trigger" className>
                                    <span className="menu-icon"><i style={{fontSize: 18}} className="pi pi-chart-line" /></span>
                                    <span className="menu-text">Reports</span>
                                    <span className="menu-badge"><span className="badge vd_bg-black-30"><i style={{fontSize: 12}} className="pi pi-chevron-down" /></span></span>
                                </a>
                                <div className="child-menu" data-action="click-target">
                                    <ul>
                                        {/*                   <li style="cursor:pointer;">
                      <a href="javascript:void(0);" data-action="click-trigger" class="">
                          <i class="fa fa-truck"></i> 
                          <span class="menu-text">Transfer Report</span>  
                              <span class="menu-badge"><span class="badge vd_bg-black-30 style={{fontSize: 18}}"><i class="pi pi-chevron-down"></i></span></span>
                      </a>
                      <div class="child-menu" data-action="click-target">
                          <ul>
                                                                  <li style="cursor:pointer;">
                                  <a href="report.product.transfer.php">
                                      <span class="menu-text">Product Transfer</span>  
                                  </a>
                              </li>
                                                                                                      <li style="cursor:pointer;">
                                  <a href="report.stock.transfer.php">
                                      <span class="menu-text">Stock Transfer</span>  
                                  </a>
                              </li>
                                                              </ul>
                      </div>
                  </li> */}
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="javascript:void(0);" data-action="click-trigger" className>
                                                <i className="fa fa-folder" />
                                                <span className="menu-text">Stock Report</span>
                                                <span className="menu-badge"><span className="badge vd_bg-black-30"><i style={{fontSize: 12}} className="pi pi-chevron-down" /></span></span>
                                            </a>
                                            <div className="child-menu" data-action="click-target">
                                                <ul>
                                                    <li style={{ cursor: 'pointer' }}>
                                                        <a href="index.php?route=report/stock-in&active=active">
                                                            <span className="menu-text">Stock-In Summary</span>
                                                        </a>
                                                    </li>
                                                    <li style={{ cursor: 'pointer' }}>
                                                        <a href="index.php?route=report/stock-out&active=active">
                                                            <span className="menu-text">Stock-Out Summary</span>
                                                        </a>
                                                    </li>
                                                    <li style={{ cursor: 'pointer' }}>
                                                        <a href="/report/invoice">
                                                            <span className="menu-text">Invoices</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="javascript:void(0);" data-action="click-trigger" className>
                                                {/* <i class="fa fa-truck"></i>  */}
                                                <span className="menu-text">Product Report</span>
                                                <span className="menu-badge"><span className="badge vd_bg-black-30"><i style={{fontSize: 12}} className="pi pi-chevron-down" /></span></span>
                                            </a>
                                            <div className="child-menu" data-action="click-target">
                                                <ul>
                                                    <li style={{ cursor: 'pointer' }}>
                                                        <a href="report.product.php">
                                                            <span className="menu-text">Product</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        {/* <li style={{ cursor: 'pointer' }}>
                                            <a href="/report/sales">
                                                <span className="menu-text">Account Receivable</span>
                                            </a>
                                        </li> */}
                                        <li style={{ cursor: 'pointer' }}>
                                            {/* <a onClick={()=>OpenSalesReport()}>
                                                <span className="menu-text">Sales Report</span>
                                            </a> */}
                                            <a href="/report/sales">
                                                <span className="menu-text">Sales Report</span>
                                            </a>
                                        </li>
                                        {/*             <li style="cursor:pointer;">
                      <a href="account_receivable.php">
                          <span class="menu-text">Accounts Receivable</span>  
                      </a>
                  </li> */}
                                        {/*          <li style="cursor:pointer;">
                      <a href="report.account_payable.php">
                          <span class="menu-text">Accounts Payable</span>  
                      </a>
                  </li> */}
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="CashierSummaryReport.php">
                                                <span className="menu-text">Cashier Summary Report</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="report.services.php">
                                                <span className="menu-text">Services Report</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="report.defective.php">
                                                <span className="menu-text">Defective Report</span>
                                            </a>
                                        </li>
                                        {/*          <li style="cursor:pointer;">
                      <a href="statistics-reports.php" id="">
                          <span class="menu-text">statistics</span>  
                      </a>
                  </li>  */}
                                        {/*        <li style="cursor:pointer;">
                      <a href="report.register.php" id="">
                          <span class="menu-text">Registers Reports</span>  
                      </a>
                  </li>  */}
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="report_inventory_stock.php" id>
                                                <span className="menu-text">Inventory Report</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="report.stockreorder.php" id="report-stockreorder">
                                                <span className="menu-text">Stock Re-order</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="report.expenses.php">
                                                <span className="menu-text">Expenses</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li style={{ cursor: 'pointer' }}>
                                <a href="javascript:void(0);" data-action="click-trigger" className>
                                    <span className="menu-icon"><i style={{fontSize: 18}} className="pi pi-history" /></span>
                                    <span className="menu-text">Activities</span>
                                    <span className="menu-badge"><span className="badge vd_bg-black-30"><i style={{fontSize: 12}} className="pi pi-chevron-down" /></span></span>
                                </a>
                                <div className="child-menu" data-action="click-target">
                                    <ul>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="activity.login.php">
                                                <span className="menu-text">Login Activity</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="activity.stock.php">
                                                <span className="menu-text">Stock Activity</span>
                                            </a>
                                        </li>
                                        {/*             <li style="cursor:pointer;">
                      <a href="activity.sales.php">
                          <span class="menu-text">Sales Activity</span>  
                      </a>
                  </li> */}
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="activity.report.sales.php">
                                                <span className="menu-text">Sales Report Activity</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="activity.category.php">
                                                <span className="menu-text">Category Activity</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="activity.supplier.php">
                                                <span className="menu-text">Supplier Activity</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="activity.product.php">
                                                <span className="menu-text">Product Activity</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="activity.customer.php">
                                                <span className="menu-text">Customer Activity</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="activity.reservation.php">
                                                <span className="menu-text">Reservation Activity</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="activity.payment.php">
                                                <span className="menu-text">Payment Activity</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="activity.shopee.php">
                                                <span className="menu-text">Shopee Activity</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="activity.defective.php">
                                                <span className="menu-text">Defective Activity</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="activity.expenses.php">
                                                <span className="menu-text">Expenses Activity</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li style={{ cursor: 'pointer' }}>
                                <a href="javascript:void(0);" data-action="click-trigger" className>
                                    <span className="menu-icon"><i style={{fontSize: 18}} className="pi pi-wrench" /></span>
                                    <span className="menu-text">System</span>
                                    <span className="menu-badge"><span className="badge vd_bg-black-30"><i style={{fontSize: 12}} className="pi pi-chevron-down" /></span></span>
                                </a>
                                <div className="child-menu" data-action="click-target" style={{ display: 'none' }}>
                                    <ul>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="create_account.php" id="addusers" style={{ cursor: 'pointer' }}>
                                                <span className="menu-text">Create Account</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="/employee" id="system-employee">
                                                <span className="menu-text">Employee</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="customer_queue.php" id="system-employee">
                                                <span className="menu-text">Customer Queue</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="index.php?route=system/supplier" id="system-employee">
                                                <span className="menu-text">Supplier</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="index.php?route=system/courier" id="system-employee">
                                                <span className="menu-text">Courier Services</span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="brand.php">
                                                <span className="menu-text">Brand</span>
                                                <span className="menu-badge"><i className="fa fa-tags" /></span>
                                            </a>
                                        </li>
                                        {/*                         <li style="cursor:pointer;">
                      <a href="branches.php">
                          <span class="menu-text">Branches</span>  
                          <span class="menu-badge"><i class="fa fa-building"></i></span> 
                      </a>
                  </li>  */}
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="/user_control">
                                                <span className="menu-text">User Control</span>
                                            </a>
                                        </li>
                                        {/*             <li style="cursor:pointer;">
                      <a href="#">
                          <span class="menu-text">Statistics (Disabled)</span>  
                          <span class="menu-badge"><i class="fa fa-bar-chart-o fw"></i></span> 
                      </a>
                  </li> */}
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="barcode.generator.php">
                                                <span className="menu-text">Barcode Generator</span>
                                                <span className="menu-badge"><i className="fa fa-barcode" /></span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="bin.php">
                                                <span className="menu-text">Recycle Bin</span>
                                                <span className="menu-badge"><i className="glyphicon glyphicon-trash" /></span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="backup_DB.php" title="Download">
                                                <span className="menu-text">Back Up - Database</span>
                                                <span className="menu-badge"><i className="fa fa-download" /></span>
                                            </a>
                                        </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a href="#">
                                                <span className="menu-text">Query</span>
                                                <span className="menu-badge"><i className="fa fa-database" /></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* Head menu search form ends */}
                </div>
                <div className="navbar-spacing clearfix" />
                <div className="vd_menu vd_navbar-bottom-widget">
                    <ul>
                        <li style={{ cursor: 'pointer' }}>
                            <a href="#">
                                <span className="menu-icon"><i className="pi pi-user" style={{fontSize: 18}} /></span>
                                <span className="menu-text">Profile</span>
                            </a>
                        </li>
                        <li style={{ cursor: 'pointer' }}>
                            <a onClick={()=>Logout()}>
                                <span className="menu-icon"><i className="pi pi-sign-out" style={{fontSize: 18}} /></span>
                                <span className="menu-text">Logout</span>
                            </a>
                        </li>
                        {/* <li style={{ cursor: 'pointer' }}>
                            <a>
                                <span className="menu-icon"><i className="icon-clock" /></span>
                                <span className="menu-text"> Timer: <time id="time" /></span>
                            </a>
                        </li> */}
                    </ul>
                </div>
            </div>


        </>

    );
}

export default Navbar;

import { Dialog } from 'primereact/dialog';
import React, { useEffect, useMemo, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import PaymentList from './PaymentList';
import AddPaymentBtn from './AddPaymentBtn';

const ViewDetails = (props) => {
    const [visible, setVisible] = useState(false)
    const [stocks, setStocks] = useState([])
    const [search, setSearch] = useState('')
    const [inputvalue, setInputValue] = useState({
        "supplier_invoice": props.item.supplier_invoice
    })


    const onClickHREF = async () => {
        setVisible(true)
        console.log(props.item.stock_id)
        var formData = new FormData();
        formData.append("stocks_invoices", 1);
        formData.append("invoiceno", props.item.supplier_invoice);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/invoices.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setStocks(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const TotalQty = stocks?.reduce((amount, item) => amount = Number(item.qty) + amount, 0);
    const SubTotal = stocks?.reduce((amount, item) => amount = Number(item.qty*item.cost) + amount, 0);


    const newstocks = stocks.filter((item)=> {        
        if (search.length >= 3) {
            return String(item.serial).toLowerCase().includes(String(search).toLowerCase()) || String(item.supplier_invoice).toLowerCase().includes(String(search).toLowerCase())
        }
        return item
    })

    const [edittext, setEditText] = useState({})

    const handleClick = (e, i, item) => {
        switch (e.detail) {
            case 1:
                console.log("click");
                // setDoubleClickinRow({})
                // setSingleClick({})
                break;
            case 2:
                console.log("double click");
                setEditText({"type":i, "text": item})
                // setDoubleClickinRow(items)
                // setEditText(items)
                // setSingleClick(items)
                // e.target.select()
                break;
            case 3:
                // console.log("triple click");
                break;
        }
    };  
    console.log(newstocks)

    const groupedData = newstocks?.reduce((groups, item) => {
        const { product_id, product_code, no_serial } = item;
        if (!groups[product_id]) {
            groups[product_id] = [];
        }
        groups[product_id].push(item);
        return groups;
    }, {});


    const removeRow = (item) => {
        alert(JSON.stringify(item))
    }

    return (
        <>

            <Dialog
                blockScroll={true}
                maximized={true}
                draggable={false}
                footer={
                    <div style={{padding: 5}}>
                        <button onClick={() => setVisible(false)} className='btn btn-sm btn-danger'>Close</button>
                    </div>
                }
                position={"top"}
                visible={visible} style={{ width: '40vw' }} onHide={() => setVisible(false)}>
                <div style={{paddingTop: 10, paddingRight: 10}}>
                    <div className='row' style={{display:""}}>
                        <div className='col-sm-4'>
                            <div style={{padding: 10, border:"2px solid #fff", boxShadow: "grey -1px 2px 5px"}}>
                                <div className="invoice-col" style={{fontSize: 18, display: "flex", flexDirection: "column", gap: 10}}>
                                    <table className='table table'>
                                        <tbody>
                                            <tr>
                                                <td style={{width: 200, textAlign:"right"}}><b>Supplier:</b></td>
                                                <td>
                                                    {
                                                        edittext.type == 'invoice' ?
                                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5}}>
                                                                <input className='form-control' name='supplier_invoice' onChange={(e)=>setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={inputvalue.supplier_invoice} style={{width: 200, height: 28}} />
                                                                {/* <button className='btn btn-default btn-sm' style={{height: 28}}>Update</button> */}
                                                            </div>
                                                            <div>
                                                                <button onClick={()=>setEditText({})} className='btn btn-danger btn-sm' style={{height: 28}}>Cancel</button>
                                                            </div>
                                                        </div>
                                                        : 
                                                        <div onClick={(e)=>handleClick(e, 'invoice', props.item.supplier_invoice)}>#{inputvalue.supplier_invoice}</div>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: 200, textAlign:"right"}}><b>Invoice:</b></td>
                                                <td>
                                                    {
                                                        edittext.type == 'invoice' ?
                                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5}}>
                                                                <input className='form-control' name='supplier_invoice' onChange={(e)=>setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={inputvalue.supplier_invoice} style={{width: 200, height: 28}} />
                                                                {/* <button className='btn btn-default btn-sm' style={{height: 28}}>Update</button> */}
                                                            </div>
                                                            <div>
                                                                <button onClick={()=>setEditText({})} className='btn btn-danger btn-sm' style={{height: 28}}>Cancel</button>
                                                            </div>
                                                        </div>
                                                        : 
                                                        <div onClick={(e)=>handleClick(e, 'invoice', props.item.supplier_invoice)}>#{inputvalue.supplier_invoice}</div>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: 200, textAlign:"right"}}><b>DR No.:</b></td>
                                                <td>
                                                    {
                                                        edittext.type == 'invoice' ?
                                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5}}>
                                                                <input className='form-control' name='supplier_invoice' onChange={(e)=>setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={inputvalue.supplier_invoice} style={{width: 200, height: 28}} />
                                                                {/* <button className='btn btn-default btn-sm' style={{height: 28}}>Update</button> */}
                                                            </div>
                                                            <div>
                                                                <button onClick={()=>setEditText({})} className='btn btn-danger btn-sm' style={{height: 28}}>Cancel</button>
                                                            </div>
                                                        </div>
                                                        : 
                                                        <div onClick={(e)=>handleClick(e, 'invoice', props.item.supplier_invoice)}>#{inputvalue.supplier_invoice}</div>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: 200, textAlign:"right"}}><b>Purchase Date:</b></td>
                                                <td>
                                                    {
                                                        edittext.type == 'invoice' ?
                                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5}}>
                                                                <input className='form-control' name='supplier_invoice' onChange={(e)=>setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={inputvalue.supplier_invoice} style={{width: 200, height: 28}} />
                                                                {/* <button className='btn btn-default btn-sm' style={{height: 28}}>Update</button> */}
                                                            </div>
                                                            <div>
                                                                <button onClick={()=>setEditText({})} className='btn btn-danger btn-sm' style={{height: 28}}>Cancel</button>
                                                            </div>
                                                        </div>
                                                        : 
                                                        <div onClick={(e)=>handleClick(e, 'invoice', props.item.supplier_invoice)}>#{inputvalue.supplier_invoice}</div>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: 200, textAlign:"right"}}><b>Due Date:</b></td>
                                                <td>
                                                    {
                                                        edittext.type == 'invoice' ?
                                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5}}>
                                                                <input className='form-control' name='supplier_invoice' onChange={(e)=>setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={inputvalue.supplier_invoice} style={{width: 200, height: 28}} />
                                                                {/* <button className='btn btn-default btn-sm' style={{height: 28}}>Update</button> */}
                                                            </div>
                                                            <div>
                                                                <button onClick={()=>setEditText({})} className='btn btn-danger btn-sm' style={{height: 28}}>Cancel</button>
                                                            </div>
                                                        </div>
                                                        : 
                                                        <div onClick={(e)=>handleClick(e, 'invoice', props.item.supplier_invoice)}>#{inputvalue.supplier_invoice}</div>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: 200, textAlign:"right"}}><b>Total Stock:</b></td>
                                                <td>{TotalQty}</td>
                                            </tr>
                                            <tr>
                                                <td style={{width: 200, textAlign:"right"}}><b>Total Due:</b></td>
                                                <td>{(SubTotal).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{width: 200, textAlign:"right"}}><b>Total Payment:</b></td>
                                                <td>#{props.item.supplier_invoice}</td>
                                            </tr>
                                            <tr>
                                                <td style={{width: 200, textAlign:"right"}}><b>Remaining Balance:</b></td>
                                                <td>
                                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                                        <span>#{props.item.supplier_invoice}</span>
                                                        <div>
                                                            <AddPaymentBtn item={props.item} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: 200, textAlign:"right"}}><b>Note:</b></td>
                                                <td>
                                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                                        <span>#{props.item.supplier_invoice}</span>
                                                        <div>
                                                            <button className='btn btn-default btn-xs'>Update</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br />
                            <div style={{padding: 10, border:"2px solid #fff", boxShadow: "grey -1px 2px 5px"}}>
                                <div>
                                    <div style={{fontSize: 20}}>Payments</div>
                                </div>
                                <div>
                                    <PaymentList item={props.item} />
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-8' style={{padding: 10, border:"2px solid #fff", boxShadow: "grey -1px 2px 5px"}}>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 5, paddingBottom: 5}}>
                                <span style={{fontSize: 20, fontWeight: "bolder"}}>
                                    {/* Records: {stocks.length}  */}
                                </span>
                                <div style={{width: 250, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 5}}>
                                    <span style={{fontSize: 12}}>Search:</span>
                                    <input onChange={(e)=>setSearch(e.target.value)} className='form-control' />
                                </div>
                            </div>
                            <table className='table table-bordered table-striped table-hover' style={{fontSize: 12}}>
                                <thead>
                                    <tr>
                                        <th style={{width: 50}}>Qty</th>
                                        <th style={{width: 50}}>Cost</th>
                                        <th style={{width: 50}}>Total</th>
                                        <th>Description</th>
                                        <th>Encoded</th>
                                        {/* <th style={{width: 150}}></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        // Object.entries(groupedData)?.map(([cost, items], key)
                                        Object.entries(groupedData)?.map(([cost, items], key)=>{
                                            return (
                                                <tr>
                                                    <td>
                                                        {
                                                            items[0].no_serial == 0?
                                                                items?.length
                                                            :
                                                                items[0].qty
                                                        }
                                                    </td>
                                                    <td>{items[0].cost}</td>
                                                    <td style={{fontWeight: "bolder"}}>
                                                        {/* {Number(items[0].cost*items[0].qty).toFixed(2)} */}
                                                        
                                                        {Number(items[0].cost) * Number(items[0].no_serial == 0 ?
                                                            items?.length
                                                            :
                                                            items[0].qty)
                                                        }
                                                        </td>
                                                    <td>
                                                        <div style={{display: "flex", flexDirection: "column", gap: 2}}>
                                                            <span style={{fontWeight: 600}}>{items[0].product_name}</span>
                                                            <span style={{fontWeight: 600}}>U.P.C.: {items[0].product_code}</span>
                                                        </div>
                                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5}}>
                                                            <div style={{width: 10}}></div>
                                                            {
                                                                items?.length>0 && items[0].no_serial == 0?
                                                                (<div style={{display: "flex", flexDirection: "column", gap: 5}}>
                                                                    {
                                                                        items?.map((item, keys) => {
                                                                            return (
                                                                                <div style={{fontSize: 15, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10}}>
                                                                                    {/* <span onClick={()=> removeRow(item)} style={{fontSize: 15, color: "green", cursor: "pointer"}} title='Edit' className='pi pi-pencil'></span> */}
                                                                                    <span onClick={()=> removeRow(item)} style={{fontSize: 15, color: "red", cursor: "pointer"}} title='Remove' className='pi pi-times-circle'></span>
                                                                                    <span key={keys} style={{width: "100%"}}>{item.serial}</span>
                                                                                    {/* {item.serial} */}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }                                                                                                    
                                                                </div>)
                                                                :
                                                                (
                                                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10}}>
                                                                        {/* <span><span onClick={null} style={{fontSize: 15, color: "green", cursor: "pointer"}} className='pi pi-pencil'></span> <span>Edit</span></span> */}
                                                                        <span><span onClick={()=> null} style={{fontSize: 15, color: "red", cursor: "pointer"}} className='pi pi-times-circle'></span> <span>Remove</span></span>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>


                                                        
                                                        {/* {
                                                            item.serial !='' ?
                                                            (
                                                                <>{item.serial}</>
                                                            )
                                                            :
                                                            (
                                                                <>{item.product_name} - (no Serial)</>
                                                            )
                                                        } */}
                                                    </td>
                                                    <td>{items[0].date_encoded}</td>
                                                    {/* <td>
                                                        {
                                                            items?.filter((item, keys)=>Number(item.no_serial)!=0).length > 0 ?
                                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5}}>
                                                                    <button className='btn btn-info btn-xs'>Edit</button>
                                                                    <button className='btn btn-danger btn-xs'>Delete</button>
                                                                </div>
                                                            :null
                                                        }
                                                    </td> */}
                                                </tr>                                                
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>



                </div>
            </Dialog>


            <a onClick={() => onClickHREF()} style={{ cursor: "pointer", border:"1px solid #ddd", padding: 5, borderRadius: 5, backgroundColor: "#23709e", color: "#fff", fontWeight:"bolder" }}>View Details</a>
        </>
    );
}

export default ViewDetails;

import axios from 'axios';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import React, { useEffect, useState } from 'react';
import ENV from '../../ENV';
import PaymentHistory from './PaymentHistory';
import PaymentDetails from './PaymentDetails';

const Payment = ({ sales_report_list, OnlySalesData, PreviewDetails, SalesDataSubTotal }) => {
    // const OOnlySalesData = OnlySalesData.lenght!==null ? OnlySalesData : []
    const [dialog, setDialog] = useState(false);
    const [removedialog, setRemoveDialog] = useState(false);
    const [removedialogitem, setRemoveDialogItem] = useState(null);
    const [payment, setPayment] = useState('');
    // console.log(previewData)
    const [inputvalue, setInputValue] = useState({});
    

    // const [PaymentData, setPaymentData] = useState(OnlySalesData?.Mpayment);


    const [showremove, setshowRemoved] = useState(0);
    const [onlineremitance, setOnlineRemittance] = useState('');

    const [cash, setCash] = useState(0);
    const [bank_name, setbank_name] = useState('');
    const [card_type, setcard_type] = useState('');
    const [account_number, setaccount_number] = useState('');
    const [gcash_number, setgcash_number] = useState('');
    const [maya_number, setmaya_number] = useState('');
    const [agent_name, setagent_name] = useState('');    
    const [account_name, setaccount_name] = useState('');
    const [reference_number, setreference_number] = useState(0);
    const [trace_number, settrace_number] = useState(0);
    const [note, setnote] = useState('');
    const [amount, setamount] = useState(0);
    const [issuername, setissuername] = useState('');
    const [cheque_number, setcheque_number] = useState('');
    const [date_issued, setdate_issued] = useState('');
    
    
    const hideDialog = () => {
        // RemoveCookie('PHPSESSID')
        setDialog(false)
        setRemoveDialog(false)
        setPayment('')


        ///////////////////////////////
        ///////////////////////////////
        setCash(0);
        setbank_name('');
        setcard_type('');
        setaccount_number('');
        setaccount_name('');
        setreference_number(0);
        settrace_number(0);
        setnote('');
        setamount(0);
        setOnlineRemittance('')



        // setTimeout(() => {
        //     openHistory(false)
        //     HistoryList([])
        // }, 500)
    };

    const SavePayment = async (e, method) => {
        
        if (method === 'Cash') { // && (e.key === 'Enter' || e.type === 'click')
            const data = {
                payment_id: "",
                reserve_id: "",
                shopee_id: "",
                sales_id: OnlySalesData?.sales_id,
                credited: "Cash",
                type: "Cash",
                date: format(new Date(), 'yyyy-MM-dd'),
                time: format(new Date(), 'HH:mm:ss'),
                amount_value: inputvalue.cash,
                monthly_install: "",
                payment_desc: [],
                status: "",
                user_id: localStorage.getItem('user_id'),
                dateRemoved: "",
                removeBy: "",
                timespan: "",
                note: '',
                reference: Number(Math.floor(new Date().getTime() / 1000)+Number(localStorage.getItem('user_id')))
            };

            const _data = {
                title: 'payment', 
                type: 'add', 
                user_id: localStorage.getItem('user_id'), 
                addedby: localStorage.getItem('firstname'), 
                date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), 
                data: data
            }

            // format(new Date(), 'yyyy-MM-dd HH:mm:ss')

            OnlySalesData.Mpayment = [...OnlySalesData?.Mpayment, data];
            OnlySalesData.payment_history = [...OnlySalesData?.payment_history, _data];
            // console.log(OnlySalesData.shistory)
            // return
            PreviewDetails(OnlySalesData)
            var formData = new FormData();
            formData.append("payment_details", 1);
            formData.append("user_id", localStorage.getItem('user_id'));
            formData.append("status", 'add');
            formData.append("sales_id", OnlySalesData?.sales_id);
            formData.append("Mpayment", JSON.stringify(data));
            formData.append("payment_history", JSON.stringify(_data));
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'sales_report.php',
                data: formData,
            }).then((res) => {
                // console.log(res.data)
                setDialog(false)
                setPayment('')
                if (res.data === 'success') {
                    
                } else {
    
                }
            });
        } else 
        if (method === 'Online / Remittance') {
            // GCash 
            let data = {}
            if (inputvalue.online_bank_name === 'GCash') {
                data = {
                    payment_id: "",
                    reserve_id: "",
                    shopee_id: "",
                    sales_id: OnlySalesData?.sales_id,
                    credited: 'GCash',
                    type: "Online / Remittance",
                    date: format(new Date(), 'yyyy-MM-dd'),
                    time: format(new Date(), 'HH:mm:ss'),
                    amount_value: inputvalue.online_amount,
                    monthly_install: "",
                    payment_desc: {
                            sales_id: OnlySalesData?.sales_id,
                            bank_name: inputvalue.online_bank_name,
                            gcash_number: inputvalue.online_gcash_number,
                            account_name: inputvalue.online_account_name,
                            amount: inputvalue.online_amount,
                            reference_number: inputvalue.online_referense_number,
                            note_date: ''
                    },
                    status: "",
                    user_id: localStorage.getItem('user_id'),
                    dateRemoved: "",
                    removeBy: localStorage.getItem('firstname'),
                    timespan: "",
                    note:'',
                    reference: Number(Math.floor(new Date().getTime() / 1000)+Number(localStorage.getItem('user_id')))
                };
            } else if (inputvalue.online_bank_name === 'Maya') {// Maya 
                data = {
                    payment_id: "",
                    reserve_id: "",
                    shopee_id: "",
                    sales_id: OnlySalesData?.sales_id,
                    credited: 'Maya',
                    type: "Online / Remittance",
                    date: format(new Date(), 'yyyy-MM-dd'),
                    time: format(new Date(), 'HH:mm:ss'),
                    amount_value: inputvalue.online_amount,
                    monthly_install: "",
                    payment_desc: {
                            sales_id: OnlySalesData?.sales_id,
                            bank_name: 'Maya',
                            maya_number: inputvalue.online_maya_number,
                            account_name: inputvalue.online_account_name,
                            amount: inputvalue.online_amount,
                            reference_number: inputvalue.online_referense_number,
                            note_date: ''
                    },
                    status: "",
                    user_id: localStorage.getItem('user_id'),
                    dateRemoved: "",
                    removeBy: localStorage.getItem('firstname'),
                    timespan: "",
                    note:note,
                    reference: Number(Math.floor(new Date().getTime() / 1000)+Number(localStorage.getItem('user_id')))
                };
            } else if (inputvalue.online_bank_name === 'HomeCredit') {// HomeCredit
                data = {
                    payment_id: "",
                    reserve_id: "",
                    shopee_id: "",
                    sales_id: OnlySalesData?.sales_id,
                    credited: 'HomeCredit',
                    type: "Online / Remittance",
                    date: format(new Date(), 'yyyy-MM-dd'),
                    time: format(new Date(), 'HH:mm:ss'),
                    amount_value: inputvalue.online_homecredit_amount,
                    monthly_install: "",
                    payment_desc: {
                            sales_id: OnlySalesData?.sales_id,
                            bank_name: 'HomeCredit',
                            agent_name: inputvalue.online_agent_name,
                            amount: inputvalue.online_homecredit_amount,
                            note_date: ''
                    },
                    status: "",
                    user_id: localStorage.getItem('user_id'),
                    dateRemoved: "",
                    removeBy: "",
                    timespan: "",
                    note:"",
                    reference: Number(Math.floor(new Date().getTime() / 1000)+Number(localStorage.getItem('user_id')))
                };
            } else if (onlineremitance === 'Others') {// Others
                // const data = {
                //     payment_id: "",
                //     reserve_id: "",
                //     shopee_id: "",
                //     sales_id: OnlySalesData?.sales_id,
                //     credited: "Online / Remittance",
                //     type: "Online",
                //     date: format(new Date(), 'yyyy-MM-dd'),
                //     time: format(new Date(), 'HH:mm:ss'),
                //     amount_value: amount,
                //     monthly_install: "",
                //     payment_desc: [
                //         {
                //             sales_id: OnlySalesData?.sales_id,
                //             bank_name: bank_name,
                //             account_number: account_number,
                //             account_name: account_name,
                //             amount: amount,
                //             reference_number: reference_number,
                //             note_date: ''
                //         }
                //     ],
                //     status: "",
                //     user_id: localStorage.getItem('user_id'),
                //     dateRemoved: "",
                //     removeBy: localStorage.getItem('firstname'),
                //     timespan: "",
                //     note:note
                // };
            }

            const _data = {
                title: 'payment', 
                type: 'add', 
                user_id: localStorage.getItem('user_id'), 
                addedby: localStorage.getItem('firstname'), 
                date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), 
                data: data
            }
            
            // console.log(data)

            OnlySalesData.Mpayment = [...OnlySalesData?.Mpayment, data];
            OnlySalesData.payment_history = [...OnlySalesData.payment_history, _data];
            PreviewDetails(OnlySalesData)
            var formData = new FormData();
            formData.append("payment_details", 1);
            formData.append("user_id", localStorage.getItem('user_id'));
            formData.append("status", 'add');
            formData.append("sales_id", OnlySalesData?.sales_id);
            formData.append("Mpayment", JSON.stringify(data));
            formData.append("payment_history", JSON.stringify(_data));
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'sales_report.php',
                data: formData,
            }).then((res) => {
                // console.log(res.data)
                setDialog(false)
                setPayment('')
                if (res.data === 'success') {
                    
                } else {
    
                }
            });
        } else 
        if (method === 'Card Swipe') {
            const data = {
                payment_id: "",
                reserve_id: "",
                shopee_id: "",
                sales_id: OnlySalesData?.sales_id,
                credited: "Card Swipe",
                type: "Card Swipe",
                date: format(new Date(), 'yyyy-MM-dd'),
                time: format(new Date(), 'HH:mm:ss'),
                amount_value: inputvalue.cardswipe_amount,
                monthly_install: "",
                payment_desc: {
                        sales_id: OnlySalesData?.sales_id,
                        bank_name: inputvalue.cardswipe_bank_name,
                        card_type: inputvalue.cardswipe_card_type,
                        account_name: inputvalue.cardswipe_account_name,
                        account_number: inputvalue.cardswipe_account_number,
                        amount: inputvalue.cardswipe_amount,
                        trace_number: inputvalue.cardswipe_trace_number,
                        reference_number: inputvalue.cardswipe_referense_number,
                        note_date: ''
                },
                status: "",
                user_id: localStorage.getItem('user_id'),
                dateRemoved: "",
                removeBy: "",
                timespan: "",
                note:""
            };

            const _data = {
                title: 'payment', 
                type: 'add', 
                user_id: localStorage.getItem('user_id'), 
                addedby: localStorage.getItem('firstname'), 
                date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), 
                data: data
            }

            OnlySalesData.Mpayment = [...OnlySalesData?.Mpayment, data];
            OnlySalesData.payment_history = [...OnlySalesData.payment_history, _data];
            PreviewDetails(OnlySalesData)
            var formData = new FormData();
            formData.append("payment_details", 1);
            formData.append("user_id", localStorage.getItem('user_id'));
            formData.append("status", 'add');
            formData.append("sales_id", OnlySalesData?.sales_id);
            formData.append("Mpayment", JSON.stringify(data));
            formData.append("payment_history", JSON.stringify(_data));
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'sales_report.php',
                data: formData,
            }).then((res) => {
                // console.log(res.data)
                setDialog(false)
                setPayment('')
                if (res.data === 'success') {
                    
                } else {
    
                }
            });
        } else 
        if (method === 'Cheque') {
            const data = {
                payment_id: "",
                reserve_id: "",
                shopee_id: "",
                sales_id: OnlySalesData?.sales_id,
                credited: "Cheque",
                type: "Cheque",
                date: format(new Date(), 'yyyy-MM-dd'),
                time: format(new Date(), 'HH:mm:ss'),
                amount_value: inputvalue.cheque_amount,
                monthly_install: "",
                payment_desc: {
                        sales_id: OnlySalesData?.sales_id,
                        issuer_name: inputvalue.cheque_issuer_name,
                        bank_name: inputvalue.cheque_bank_name,
                        cheque_number: inputvalue.cheque_number,
                        amount: inputvalue.cheque_amount,
                        date_issued: inputvalue.cheque_date_issued,
                        note: ''
                },
                status: "",
                user_id: localStorage.getItem('user_id'),
                dateRemoved: "",
                removeBy: "",
                timespan: "",
                note:""
            };

            const _data = {
                title: 'payment', 
                type: 'add', 
                user_id: localStorage.getItem('user_id'), 
                addedby: localStorage.getItem('firstname'), 
                date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), 
                data: data
            }

            OnlySalesData.Mpayment = [...OnlySalesData?.Mpayment, data];
            OnlySalesData.payment_history = [...OnlySalesData?.payment_history, _data];
            PreviewDetails(OnlySalesData)
            var formData = new FormData();
            formData.append("payment_details", 1);
            formData.append("user_id", localStorage.getItem('user_id'));
            formData.append("status", 'add');
            formData.append("sales_id", OnlySalesData?.sales_id);
            formData.append("Mpayment", JSON.stringify(data));
            formData.append("payment_history", JSON.stringify(_data));
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'sales_report.php',
                data: formData,
            }).then((res) => {
                // console.log(res.data)
                setDialog(false)
                setPayment('')
                if (res.data === 'success') {
                    
                } else {
    
                }
            });
        }
    }



    const removePayment = async (item) => {
        // setPaymentData([])
        const data = OnlySalesData.Mpayment;
        let index = OnlySalesData.Mpayment?.findIndex((val) => val === item);
        // return;
        OnlySalesData.Mpayment[index].title = 'sample';
        OnlySalesData.Mpayment[index].sales_id = OnlySalesData.sales_id;
        OnlySalesData.Mpayment[index].removeBy = 1;
        OnlySalesData.Mpayment[index].note = '';
        OnlySalesData.Mpayment[index].dateRemoved = format(new Date(), 'Y-m-d HH:mm:ss');
        OnlySalesData.payment_history = [...OnlySalesData.payment_history, {title: 'payment', type: 'remove', user_id: localStorage.getItem('user_id'), removedby: localStorage.getItem('firstname'), date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), data: OnlySalesData.Mpayment[index]}];
        // setPaymentData(data)
        // console.log(index)
        // console.log(item.reference)
        // console.log(OnlySalesData.Mpayment)
        // console.log(OnlySalesData.shistory)
        // PreviewDetails(OnlySalesData)
        // setPanelOnlySalesData(OnlySalesData)
        // console.log(OnlySalesData, data)
        // return;
        

        // return
        var formData = new FormData();
        formData.append("payment_details", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("status", 'remove');
        formData.append("sales_id", OnlySalesData?.sales_id);
        formData.append("reference", item.reference);
        formData.append("Mpayment", JSON.stringify(OnlySalesData.Mpayment));
        formData.append("payment_history", JSON.stringify({title: 'payment', type: 'remove', user_id: localStorage.getItem('user_id'), removedby: localStorage.getItem('firstname'), date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), data: OnlySalesData.Mpayment[index]}));
        // formData.append("Mpayment", data.length>0 ? JSON.stringify(OnlySalesData.Mpayment) : '');
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'sales_report.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            sales_report_list()
            setRemoveDialog(false)
            if (res.data === 'success') {
                setRemoveDialog(false)
            } else {

            }
        });
    }

    const DialogFooter = (
        <React.Fragment>
            {
                payment !== '' ?
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        
                        <Button label="Save" onClick={(e) => {
                            SavePayment(e, payment)
                            // setEditPaymentCashModalData(null)
                        }} className='btn-block' style={{ backgroundColor: "#295bac" }} />
                        
                        <Button label="Back" onClick={() => {
                            setPayment('')
                            setOnlineRemittance('')
                            // setEditPaymentCashModalData(null)
                        }} className='btn-block' style={{ backgroundColor: "#3e7645" }} />
                    </div>
                    :
                    null
            }
        </React.Fragment>
    )

    const removeDialogFooter = (
        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                
                <Button label="Yes Remove" onClick={(e) => {
                    removePayment(removedialogitem)
                }} className='btn-block' style={{ backgroundColor: "#295bac" }} />
                
                <Button label="Cancel" onClick={() => {
                    removePayment(null)
                    setRemoveDialog(false)
                    // setEditPaymentCashModalData(null)
                }} className='btn-block' style={{ backgroundColor: "#3e7645" }} />
            </div>
        </React.Fragment>
    )

    const saveCash = () => {

    }

    const totalPayment = () => {
        let total = 0;
        OnlySalesData.Mpayment?.map((item, key) => {
            if (item?.removeBy !== 1) {
                total += Number(item?.amount_value)
            }
        });
        return total;
    }

    // console.log(PaymentData)

    // useEffect(()=>{
    //     console.log(PaymentData)
    // }, [PaymentData])
    
    // removePayment(item)
    
    return (
        <>
            <Dialog visible={removedialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                <>
                    {/* {JSON.stringify(removedialogitem)} */}
                    {/* <div>{removedialogitem.type}</div> */}
                    <div>Are you sure remove this payment</div>
                </>
            } modal className="p-fluid" footer={removeDialogFooter} onHide={hideDialog}>
                <div style={{fontWeight:600, fontSize:18}}> {removedialogitem?.credited}</div>
                <div style={{fontWeight:600, fontSize:18}}> {removedialogitem?.amount_value}</div>
            </Dialog>
            <Dialog visible={dialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                <>
                    {
                        payment !== '' ?
                            <div style={{ fontSize: 15 }}>Add {payment} Payment</div>
                            :
                            null
                    }
                </>
            } modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>

                {
                    payment === 'Cash' ?
                        <div>
                            {/* SavePayment(e, 'Cash') */}
                            {/* setCash(e) */}
                            <input name='cash' onKeyPress={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} style={{ width: "100%", height: 40, textAlign: "center", fontSize: 20, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} />
                        </div>
                        :
                        null
                }
                {
                    payment === 'Online / Remittance' ?
                        <>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>

                                <div style={{ paddingBottom: 5, width: "30%" }}>
                                    <label style={{ fontSize: 15 }}>Bank Name</label>
                                    {/* setbank_name */}
                                    <select name='online_bank_name' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }}>
                                        <option value={''} selected disabled>Select</option>
                                        <option value={'GCash'}>GCash</option>
                                        <option value={'Maya'}>Maya</option>
                                        {/* <option value={'BPI'}>BPI</option>
                                        <option value={'BDO'}>BDO</option>
                                        <option value={'MetroBank'}>MetroBank</option> */}
                                        <option value={'HomeCredit'}>HomeCredit</option>
                                        <option value={'Others'}>Others</option>
                                    </select>
                                </div>
                                {
                                    inputvalue.online_bank_name === 'GCash' ?
                                    <>
                                        <div style={{ paddingBottom: 5, width: "70%" }}>
                                            <label style={{ fontSize: 15 }}>GCash Number</label>
                                            <input name='online_gcash_number' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                {
                                    inputvalue.online_bank_name === 'Maya' ?
                                    <>
                                        <div style={{ paddingBottom: 5, width: "70%" }}>
                                            <label style={{ fontSize: 15 }}>Maya Number</label>
                                            <input name='online_maya_number' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                {
                                    inputvalue.online_bank_name === 'HomeCredit' ?
                                    <>
                                        <div style={{ paddingBottom: 5, width: "70%" }}>
                                            <label style={{ fontSize: 15 }}>Agent Name</label>
                                            <input name='online_agent_name' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                {
                                    onlineremitance === 'Others' ?
                                    <div style={{ paddingBottom: 5, width: "70%" }}>
                                        <label style={{ fontSize: 15 }}>Bank Name</label>
                                        <input onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            {
                                inputvalue.online_bank_name !== 'HomeCredit' ? 
                                <>
                                    <div style={{ paddingBottom: 5 }}>
                                        <label style={{ fontSize: 15 }}>Account Name / Bank Source</label>
                                        <input name='online_account_name' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                        <div style={{ paddingBottom: 5 }}>
                                            <label style={{ fontSize: 15 }}>Amount</label>
                                            <input name='online_amount' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                        </div>
                                        <div style={{ paddingBottom: 5 }}>
                                            <label style={{ fontSize: 15 }}>Reference Number</label>
                                            <input name='online_referense_number' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                        </div>
                                    </div>
                                </>
                                :
                                <div>
                                    <div style={{ paddingBottom: 5 }}>
                                        <label style={{ fontSize: 15 }}>Amount</label>
                                        <input name='online_homecredit_amount' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                    </div>
                                </div>
                            }
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Note / Date</label>
                                <input onChange={(e) => null} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                            </div>
                        </>
                        :
                        null
                }
                {
                    payment === 'Card Swipe' ?
                        <>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10, marginBottom: 10 }}>
                                <div style={{ cursor: "pointer", borderRadius: 5, width: 100, backgroundColor: "#459c5d", color: "#fff", fontWeight: "bolder", fontSize: 18, padding: 5, border: "1px solid #ddd", textAlign: "center" }}>Maya</div>
                                <div style={{ cursor: "pointer", borderRadius: 5, width: 100, padding: 0, border: "1px solid #ddd", textAlign: "center", backgroundImage: "url(https://www.adobomagazine.com/wp-content/uploads/2019/08/BPI-new-logo.jpg)", backgroundSize: "100px 34.6px", backgroundRepeat: "no-repeat" }}>
                                    {/* <img src={"https://www.adobomagazine.com/wp-content/uploads/2019/08/BPI-new-logo.jpg"} style={{width:100, height:34.6, backgroundSize:"cover"}} /> */}
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                <div style={{ paddingBottom: 5, width: "70%" }}>
                                    <label style={{ fontSize: 15 }}>Bank Name</label>
                                    <input name='cardswipe_bank_name' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                </div>
                                <div style={{ paddingBottom: 5, width: "30%" }}>
                                    <label style={{ fontSize: 15 }}>Card Type</label>
                                    <select name='cardswipe_card_type' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }}>
                                        <option value={"Visa"}>Visa</option>
                                        <option value={"MasterCard"}>MasterCard</option>
                                        <option value={"JCB"}>JCB</option>
                                        <option value={"AMEX"}>AMEX</option>
                                        <option value={"Others"}>Others</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Account Name</label>
                                <input name='cardswipe_account_name' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                            </div>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Account Number / Card Number</label>
                                <input name='cardswipe_account_number' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                <div style={{ paddingBottom: 5, width: "50%" }}>
                                    <label style={{ fontSize: 15 }}>Amount</label>
                                    <input name='cardswipe_amount' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                </div>
                                <div style={{ paddingBottom: 5 }}>
                                    <label style={{ fontSize: 15 }}>Trace Number</label>
                                    <input name='cardswipe_trace_number' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                </div>
                            </div>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Reference Number</label>
                                <input name='cardswipe_referense_number' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                            </div>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Note / Date</label>
                                <input onChange={(e) => null} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                            </div>
                        </>
                        :
                        null
                }
                {
                    payment === 'Cheque' ?
                        <>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Issuer Name</label>
                                <input name='cheque_issuer_name' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>

                                <div style={{ paddingBottom: 5, width: "35%" }}>
                                    <label style={{ fontSize: 15 }}>Bank Name</label>
                                    <input name='cheque_bank_name' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                </div>
                                <div style={{ paddingBottom: 5, width: "65%" }}>
                                    <label style={{ fontSize: 15 }}>Cheque Number</label>
                                    <input name='cheque_number' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                <div style={{ paddingBottom: 5 }}>
                                    <label style={{ fontSize: 15 }}>Amount</label>
                                    <input name='cheque_amount' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} type='number' style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                </div>
                                <div style={{ paddingBottom: 5 }}>
                                    <label style={{ fontSize: 15 }}>Date Issued</label>
                                    <input name='cheque_date_issued' onChange={(e) => setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                </div>
                            </div>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Note</label>
                                <textarea onChange={(e) => null} value={null} style={{ width: "100%", height: 150, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()}></textarea>
                            </div>
                        </>
                        :
                        null
                }


                {
                    payment === '' ?
                        <>
                            <Button label="Cash" onClick={() => {
                                setPayment('Cash')
                                // setEditPaymentCashModalData(null)
                            }} className='btn-block' style={{ backgroundColor: "#3e7645" }} />
                            <Button label="Online / Remittance" onClick={() => {
                                setPayment('Online / Remittance')
                                // setEditPaymentOnlineModalData(null)
                            }} className='btn-block' style={{ backgroundColor: "#3e7645" }} />
                            <Button label="Card Swipe" onClick={() => {
                                setPayment('Card Swipe')
                                // setEditPaymentCardModalData(null)
                            }} className='btn-block' style={{ backgroundColor: "#3e7645" }} />
                            <Button label="Cheque" onClick={() => {
                                setPayment('Cheque')
                                // setEditPaymentChequeModalData(null)
                            }} className='btn-block' style={{ backgroundColor: "#3e7645" }} />
                        </>
                        :
                        null
                }





            </Dialog>
            {/* <div style={{ overflowY: "auto", maxHeight: 300 }} className='hidescroll'> */}
            <div>
                <div style={{ display: "flex", flexDirection: "column", paddingLeft:10, paddingRight:10, paddingTop: 5, paddingBottom: 5, borderLeft: 0, borderRight: 0 }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderLeft: 0, borderRight: 0, paddingTop: 5, paddingBottom: 5, borderBottom:"1px solid #ddd" }}>
                        <div style={{ fontWeight: "bold", display:"flex", flexDirection:"column", alignSelf:"flex-start" }}>
                            <span>Transaction Payment/s</span>
                            <div style={{ display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start", backgroundColor:null}}>
                                <div style={{fontSize: 12 }}>
                                    {
                                        Number(OnlySalesData?.shipping_details?.shipping_fee)!==0 ?
                                        <>Including Shipping: {Number(parseFloat(OnlySalesData?.shipping_details?.shipping_fee ?? 0).toFixed(2)).toLocaleString('en')}</>
                                        :
                                        null
                                    }
                                </div>
                                <div style={{ fontWeight: 600, fontSize:20 }}>Total Due: {Number(parseFloat(Number(SalesDataSubTotal)+Number(OnlySalesData?.shipping_details?.shipping_fee ?? 0)).toFixed(2)).toLocaleString('en')}</div>
                                <div style={{ fontWeight: 600 }}>Balance: {Number(parseFloat((Number(SalesDataSubTotal)+Number(OnlySalesData?.shipping_details?.shipping_fee ?? 0)) - totalPayment()).toFixed(2)).toLocaleString('en')}</div>
                                <div style={{ fontWeight: 600 }}>Total Tendered: {Number(parseFloat(totalPayment()).toFixed(2)).toLocaleString('en')}</div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignSelf: "flex-start", gap: 5 }}>
                            <div style={{ display: "flex", flexDirection: "row", alignSelf: "flex-end", gap: 5 }}>
                                <button onClick={() => setDialog(true)} style={{ borderRadius: 5, border: "1px solid rgb(35, 112, 158)", backgroundColor:"#1da750", color:"#fff" }}><i className='pi pi-plus' /> Add Payment</button>
                                <PaymentHistory OnlySalesData={OnlySalesData} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignSelf: "flex-end", gap: 5 }}>
                                <Checkbox onChange={e => setshowRemoved(showremove ? false : true)} checked={showremove}></Checkbox>
                                Show All
                            </div>
                            {/* <button style={{ borderRadius: 5, backgroundColor: "rgb(158 35 69)", color:"#fff" }}>Remove</button> */}
                        </div>
                    </div>
                </div>
                {
                    OnlySalesData?.Mpayment?.length > 0 ?

                        <div>
                            {/* {
                                console.log(previewData, 1)
                            } */}
                            {
                                OnlySalesData?.Mpayment?.map((item, key) => {
                                    // console.log(item.removeBy, 'test')
                                    // if (item !== null) {
                                        // console.log('key: ', key)
                                        if (!showremove) {
                                            if(item?.removeBy === 1){
                                                return(<></>);
                                            }
                                        }
                                        return (
                                            <div style={{backgroundColor: (item?.removeBy === 1) ? "rgb(242 201 201)" : null, padding:10, paddingTop:0}}>
                                                <div key={key} style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                                    <div onClick={() => alert(JSON.stringify(item))} style={{ fontWeight: 600, cursor: "pointer" }}>{item?.type}</div>
                                                    <div>
                                                        {
                                                            (item?.removeBy === 1)?
                                                            <span style={{color:"#7b0000", fontWeight:600}}>
                                                                removed
                                                            </span>
                                                            :
                                                            <div style={{display:"flex", flexDirection:"row", gap:5}}>
                                                                <PaymentDetails OnlySalesData={OnlySalesData} item={item} PreviewDetails={PreviewDetails} />
                                                                <span onClick={(e) => {
                                                                    setRemoveDialog(true)
                                                                    setRemoveDialogItem(item)
                                                                    // var txt;
                                                                    // if (window.confirm("Remove Payment "+item.credited)) {
                                                                    //     txt = "You pressed OK!";
                                                                    //     setRemoveDialog(true)
                                                                    // } else {
                                                                    //     txt = "You pressed Cancel!";
                                                                    // }
                                                                    // alert(txt)
                                                                    // removePayment(item)
                                                                }} style={{padding:1, paddingLeft:5, paddingRight:5, cursor: "pointer", backgroundColor:"#7b0000", borderRadius:5, color:"#fff"}}>
                                                                    <i className='pi pi-times' /> remove
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    item?.payment_desc?.length>0 ?
                                                    <>
                                                    {
                                                        item.credited === 'GCash' ?
                                                        <>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>E-Wallet: {item?.payment_desc[0]?.bank_name}</div>
                                                            {/* <div style={{ fontWeight: "normal", fontSize:10 }}>Account Name: {item?.payment_desc[0]?.account_name}</div>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>GCash Number: {item?.payment_desc[0]?.gcash_number}</div>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>Reference Number: {item?.payment_desc[0]?.reference_number}</div> */}
                                                            {/* {
                                                                item?.payment_desc[0]?.note !== '' ?
                                                                <div style={{ fontWeight: "normal", fontSize:10 }}>Note: {item?.payment_desc[0]?.note}</div>
                                                                :
                                                                null
                                                            } */}
                                                        </>
                                                        : null
                                                    }
                                                    {
                                                        item.credited === 'Maya' ?
                                                        <>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>E-Wallet: {item?.payment_desc[0]?.bank_name}</div>
                                                            {/* <div style={{ fontWeight: "normal", fontSize:10 }}>Account Name: {item?.payment_desc[0]?.account_name}</div>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>Maya Number: {item?.payment_desc[0]?.gcash_number}</div>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>Reference Number: {item?.payment_desc[0]?.reference_number}</div> */}
                                                            {/* {
                                                                item?.payment_desc[0]?.note !== '' ?
                                                                <div style={{ fontWeight: "normal", fontSize:10 }}>Note: {item?.payment_desc[0]?.note}</div>
                                                                :
                                                                null
                                                            } */}
                                                        </>
                                                        : null
                                                    }
                                                    {
                                                        item.credited === 'HomeCredit' ?
                                                        <>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>Lending: {item?.payment_desc[0]?.bank_name}</div>
                                                            {/* <div style={{ fontWeight: "normal", fontSize:10 }}>Agent Name: {item?.payment_desc[0]?.agent_name}</div>
                                                            {
                                                                item?.payment_desc[0]?.note !== '' ?
                                                                <div style={{ fontWeight: "normal", fontSize:10 }}>Note: {item?.payment_desc[0]?.note}</div>
                                                                :
                                                                null
                                                            } */}
                                                        </>
                                                        : null
                                                    }
                                                    {
                                                        item.credited === 'Card Swipe' ?
                                                        <>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>Back Name: {item?.payment_desc[0]?.bank_name}</div>
                                                            {/* <div style={{ fontWeight: "normal", fontSize:10 }}>Account Name: {item?.payment_desc[0]?.account_name}</div>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>Account Number: {item?.payment_desc[0]?.account_number}</div>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>Reference Number: {item?.payment_desc[0]?.reference_number}</div>
                                                            {
                                                                item?.payment_desc[0]?.note !== '' ?
                                                                <div style={{ fontWeight: "normal", fontSize:10 }}>Note: {item?.payment_desc[0]?.note}</div>
                                                                :
                                                                null
                                                            } */}
                                                        </>
                                                        : null
                                                    }
                                                    </>
                                                    :
                                                    null
                                                }
                                                {/* <>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>Back Name: {item?.payment_desc[0]?.bank_name}</div>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>Account Name: {item?.payment_desc[0]?.account_name}</div>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>Account Number: {item?.payment_desc[0]?.account_number}</div>
                                                            <div style={{ fontWeight: "normal", fontSize:10 }}>Reference Number: {item?.payment_desc[0]?.reference_number}</div>
                                                            {
                                                                item?.payment_desc[0]?.note !== '' ?
                                                                <div style={{ fontWeight: "normal", fontSize:10 }}>Note: {item?.payment_desc[0]?.note}</div>
                                                                :
                                                                null
                                                            }
                                                        </> */}
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #ddd" }}>
                                                    <div>{item?.date} {item?.time}</div>
                                                    <div style={{textDecoration:(item?.removeBy === 1)? "line-through" : "", fontWeight:600}}>{Number(parseFloat(item?.amount_value).toFixed(2)).toLocaleString('en')}</div>
                                                </div>
                                            </div>
                                        )
                                    // }
                                })
                            }
                        </div>
                        :
                        null
                }
            </div>
        </>
    );
}

export default Payment;

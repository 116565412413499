import axios from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import ENV from '../../ENV';
import { format } from 'date-fns';

const Shipping = ({ sales, setSales, OnlySalesData = [{}], PreviewDetails }) => {
    // console.log(JSON.parse(OnlySalesData?.shipping_details)?.shipping_courier)
    const [dialog, setDialog] = useState(false);

    const hideDialog = () => {
        // RemoveCookie('PHPSESSID')
        setDialog(false)
        // setTimeout(() => {
        //     openHistory(false)
        //     HistoryList([])
        // }, 500)
    };

    ///////////////////////////////////////////////////////
    const [shippingcourier, setShippingCourier] = useState(OnlySalesData?.shipping_details?.shipping_courier)
    const [shippingfee, setShippingFee] = useState(OnlySalesData?.shipping_details?.shipping_fee)
    const [shippingtrack, setShippingTrack] = useState(OnlySalesData?.shipping_details?.shipping_track)
    const [shippingaddress, setShippingAddress] = useState(OnlySalesData?.shipping_details?.shipping_address)
    const [shippingdetails, setShippingDetails] = useState(JSON.parse(sessionStorage.getItem('shipping_details')) ?? { "shipping_courier": "", "shipping_fee": 0, "shipping_track": "", "shipping_address": "" })
    const [submitted, setSubmitted] = useState(false);


    const [addshippingdialog, setAddShippingDialog] = useState(false);

    const AddShippingDetails = async () => {
        // const data = OnlySalesData.filter((val) => val.sales_id === items?.sales_id);
        // console.log(OnlySalesData)
        if (shippingcourier === '' && shippingfee === '' && shippingtrack === '' && shippingaddress === '') {
            return;
        }

        const data = {
            'shipping_courier': shippingcourier,
            'shipping_fee': shippingfee,
            'shipping_track': shippingtrack,
            'shipping_address': shippingaddress
        }
        const index = sales.findIndex((val) => val?.sales_id === OnlySalesData?.sales_id);
        sales[index].shipping_details = data;
        setSales(sales)

        OnlySalesData.shipping_details = data;
        OnlySalesData.shistory = [...OnlySalesData.shistory, { title: 'shipping', type: 'add', user_id: localStorage.getItem('user_id'), addedby: localStorage.getItem('firstname'), date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), data: data }];
        // console.log(OnlySalesData)
        PreviewDetails(OnlySalesData);


        // const _data = OnlySalesData.filter((val) => val.sales_id != OnlySalesData?.sales_id);
        // return;
        var formData = new FormData();
        formData.append("Add_Shipping_Details", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("sales_id", OnlySalesData?.sales_id);
        formData.append("shipping_details", JSON.stringify(data));
        formData.append("shistory", JSON.stringify(OnlySalesData.shistory));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'sales_report.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            hideDialog()
            if (res.data === 'success') {
                // PreviewDetails(OnlySalesData);
                // sales_report_list()
            } else {

            }
        });

        // console.log(sales)
    }

    const UpdateShippingDetails = async () => {
        // const data = OnlySalesData.filter((val) => val.sales_id === items?.sales_id);
        // console.log(OnlySalesData)
        if (shippingcourier === '' && shippingfee === '' && shippingtrack === '' && shippingaddress === '') {
            return;
        }
        const data = {
            'shipping_courier': shippingcourier,
            'shipping_fee': shippingfee,
            'shipping_track': shippingtrack,
            'shipping_address': shippingaddress
        }
        const index = sales.findIndex((val) => val?.sales_id === OnlySalesData?.sales_id);
        sales[index].shipping_details = data;
        setSales(sales)


        // const _data = OnlySalesData.filter((val) => val.sales_id != OnlySalesData?.sales_id);
        OnlySalesData.shipping_details = data;
        OnlySalesData.shistory = [...OnlySalesData.shistory, { title: 'shipping', type: 'update', user_id: localStorage.getItem('user_id'), updatedby: localStorage.getItem('firstname'), date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), data: data }];
        // console.log(OnlySalesData)
        PreviewDetails(OnlySalesData);

        var formData = new FormData();
        formData.append("Add_Shipping_Details", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("sales_id", OnlySalesData?.sales_id);
        formData.append("shipping_details", JSON.stringify(data));
        formData.append("shistory", JSON.stringify(OnlySalesData.shistory));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'sales_report.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            hideDialog()
            if (res.data === 'success') {
                // PreviewDetails(OnlySalesData);
                // sales_report_list()
            } else {

            }
        });


        // console.log(sales)
    }

    const RemoveShippingDetails = async () => {
        const olddata = OnlySalesData?.shipping_details;
        const data = {
            'shipping_courier': null,
            'shipping_fee': null,
            'shipping_track': null,
            'shipping_address': null
        }
        const index = sales.findIndex((val) => val?.sales_id === OnlySalesData?.sales_id);
        sales[index].shipping_details = [data];
        setSales(sales)


        // const _data = OnlySalesData.filter((val) => val.sales_id != OnlySalesData?.sales_id);
        OnlySalesData.shipping_details = [data];
        OnlySalesData.shistory = [...OnlySalesData.shistory, { title: 'shipping', type: 'remove', user_id: localStorage.getItem('user_id'), removedby: localStorage.getItem('firstname'), date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), data: olddata }];
        // console.log(OnlySalesData)
        PreviewDetails(OnlySalesData);

        var formData = new FormData();
        formData.append("Add_Shipping_Details", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("sales_id", OnlySalesData?.sales_id);
        formData.append("shipping_details", JSON.stringify(data));
        formData.append("shistory", JSON.stringify(OnlySalesData.shistory));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'sales_report.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            hideDialog()
            if (res.data === 'success') {
                // PreviewDetails(OnlySalesData);
                // sales_report_list()
            } else {

            }
        });

        console.log(data)

        setShippingCourier('')
        setShippingFee('')
        setShippingTrack('')
        setShippingAddress('')

    }

    const hideShippingDialog = () => {
        setSubmitted(false);
        setAddShippingDialog(false);
    };

    const DialogFooter = (
        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div>
                    {
                        OnlySalesData?.shipping_details?.shipping_courier != null ?
                            <Button style={{ width: 100, fontSize: 18 }} label="Remove" outlined onClick={() => {
                                RemoveShippingDetails()
                            }} />
                            :
                            null
                    }
                </div>
                <div>
                    <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={() => {
                        hideShippingDialog()
                        hideDialog()
                    }} />
                    {
                        OnlySalesData?.shipping_details?.shipping_courier != null ?
                            <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => {
                                UpdateShippingDetails()
                                // setAddShippingDialog(false)
                            }} />
                            :
                            <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={() => {
                                AddShippingDetails()
                                // setAddShippingDialog(false)
                            }} />
                    }
                </div>
            </div>
        </React.Fragment>
    )

    return (
        <div style={{ paddingLeft: 10, paddingRight: 10 }}>

            <Dialog visible={dialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                <>
                    {
                        OnlySalesData?.shipping_details?.shipping_courier != null ?
                            <div style={{ fontSize: 15 }}>Edit Shipping Details</div>
                            :
                            <div style={{ fontSize: 15 }}>Add Shipping Details</div>
                    }
                </>
            } modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>
                <div style={{ paddingBottom: 5 }}>
                    <label style={{ fontSize: 15 }}>Courier:</label>
                    <input onKeyPress={() => null} onChange={(e) => setShippingCourier(e.target.value)} value={shippingcourier} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                    <div style={{ paddingBottom: 5, width: 150 }}>
                        <label style={{ fontSize: 15 }}>Fee:</label>
                        <input onKeyPress={() => null} placeholder='0.00' onChange={(e) => setShippingFee(e.target.value)} value={shippingfee} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                    </div>
                    <div style={{ paddingBottom: 5 }}>
                        <label style={{ fontSize: 15 }}>Tracking No <span style={{ color: "#a7a7a7" }}>(optional)</span>:</label>
                        <input onKeyPress={() => null} placeholder='' onChange={(e) => setShippingTrack(e.target.value)} value={shippingtrack} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                    </div>
                </div>
                <div style={{ paddingBottom: 5 }}>
                    <label style={{ fontSize: 15 }}>Address:</label>
                    <textarea cols="30" rows="5" onKeyPress={() => null} onChange={(e) => setShippingAddress(e.target.value)} value={shippingaddress} style={{ resize: "none", width: "100%", paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} ></textarea>
                </div>
            </Dialog>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: 5, paddingBottom: 5, border: "1px solid #ddd", alignItems: "center", borderLeft: 0, borderRight: 0 }}>
                <div style={{ fontWeight: "bold" }}>Shipping Details</div>
                <div style={{ display: "flex", flexDirection: "row", alignSelf: "flex-end", gap: 5 }}>
                    {
                        OnlySalesData?.shipping_details?.shipping_courier != null ?
                            <button onClick={() => setDialog(true)} style={{ borderRadius: 5, border: "1px solid rgb(35, 112, 158)" }}>Edit Details</button>
                            :
                            <button onClick={() => setDialog(true)} style={{ borderRadius: 5, border: "1px solid rgb(35, 112, 158)" }}>Add Shipping Details</button>
                    }
                </div>
            </div>
            <div>
                {/* {
                    // OnlySalesData.length>0 ? OnlySalesData : []
                    OnlySalesData?.shipping_details?.map((item, key) => {
                        if (item != null) {
                            return (
                            )
                        }
                    })
                } */}
                {
                    OnlySalesData?.shipping_details?.shipping_fee != null ?
                        <div style={{ borderBottom: "1px solid #ddd" }}>
                            <div style={{ fontWeight: 600 }}>Courier: {OnlySalesData?.shipping_details?.shipping_courier}</div>
                            <div>Track: {OnlySalesData?.shipping_details?.shipping_track}</div>
                            <div>Fee: {Number(OnlySalesData?.shipping_details?.shipping_fee).toFixed(2)}</div>
                            <div>Address: {OnlySalesData?.shipping_details?.shipping_address}</div>
                        </div>
                        :
                        null
                }
            </div>
        </div>
    );
}

export default Shipping;

import { OverlayPanel } from 'primereact/overlaypanel';
// import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useRef, useState } from 'react';

const AddShippingPanel = (props) => {
    // const [visible, setVisible] = useState(false);
    // const [selectedPayment, setSelectedPayment] = useState(false);
    // const [selectedOnlinePayment, setSelectedOnlinePayment] = useState(false);
    const [inputvalue, setInputValue] = useState({
        "courier": "",
        "fee": 0,
        "shipping_address": "",
        "current_address": false,
    });


    const op = useRef(null);

    const OpenOverlay = (e) => {
        setInputValue(...props?.inputvalue?.shipping_details)
        op.current.show(e)
    }
    const CloseOverlay = (e) => {
        op.current.hide(e)
    }

    const SaveBtn = () => {
        if (inputvalue?.courier !== '' && inputvalue?.shipping_address !== '') {
            CloseOverlay()
            props.setInputValue(prev => ({ ...prev, "shipping_details": [inputvalue] }))
        }
    }

    const ClearShippingDetails = () => {
        setInputValue({
            "courier": "",
            "fee": 0,
            "shipping_address": "",
            "current_address": false,
        })
        props.setInputValue(prev => ({ ...prev, "shipping_details": [] }))
        // CloseOverlay()
    }

    return (
        <>

            {/* <Sidebar blockScroll={true} dismissable={false} visible={visible} style={{ width: "30%" }} position="right" onHide={() => setVisible(false)}>
                <div>
                    <div>
                        <label>Courier</label>
                    </div>
                    <div>
                        <label>Fee</label>
                    </div>
                    <div>
                        <label>Shipping Address</label>
                    </div>
                </div>
            </Sidebar> */}

            <OverlayPanel ref={op} style={{ width: 400, border: "2px solid #000" }} showCloseIcon={false} closeOnEscape={false} dismissable={false}>
                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                    <div>
                        <span style={{ fontSize: 20, fontWeight: "bolder" }}>Add Shipping</span>
                    </div>
                    <div>
                        <label style={{ fontSize: 20 }}>Courier</label>
                        <input type='text' name='courier' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue?.courier} className='form-control' />
                    </div>
                    {/* <div>
                        <label style={{fontSize: 20}}>Fee</label>
                        <input type='text' name='fee' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.fee} className='form-control' />
                    </div> */}
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <label style={{ fontSize: 20 }}>Shipping Address</label>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 5 }}>
                                <input type="checkbox" name='current_address' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.checked }))} checked={inputvalue?.current_address} />
                                <span style={{ fontSize: 12, fontWeight: "bold" }}>use current address</span>
                            </div>
                        </div>
                        <input type='text' name='shipping_address' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue?.shipping_address} className='form-control' />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                            {
                                [...props?.inputvalue?.shipping_details].length > 0 ?
                                    <button onClick={() => ClearShippingDetails()} className='btn btn-warning btn-sm'>
                                        Clear
                                    </button>
                                    : null
                            }
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                            <button onClick={() => CloseOverlay()} className='btn btn-danger btn-sm'>
                                Close
                            </button>
                            <button onClick={() => SaveBtn()} className='btn btn-info btn-sm'>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </OverlayPanel>
            {/* onClick={() => setVisible(true)} */}
            <div style={{ position: "relative" }}>
                {
                    [...props?.inputvalue?.shipping_details].length > 0 ?
                        <div style={{ width: 10, height: 10, backgroundColor: "red", position: "absolute", right: -5, top: -5, borderRadius: 100 }}></div>
                        : null
                }
                <span onClick={OpenOverlay} style={{ fontSize: 18, fontWeight: "bolder", textDecoration: "underline", cursor: "pointer" }}>Add Shipping Details</span>
            </div>
            {/* <label onClick={() => setVisible(true)} style={{ fontSize: 20, width: 200, textAlign: "center", height: 40, color: "#000", padding: 5, borderRadius: 5, border: "2px solid #ddd", backgroundColor: "#fff" }}>
                Add Payment
            </label> */}
        </>
    );
}

export default AddShippingPanel;

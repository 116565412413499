import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../../ENV';

const AddPaymentSidebar = (props) => {
    const [visibleRight, setVisibleRight] = useState(false);
    const [openform, setOpenForm] = useState("");
    const [inputvalue, setInputValue] = useState({
        "amount_value": 0,
        "online_bank_name": "",
        "online_account_name": "",
        "online_account_number": "",
        "online_amount": 0,
        "online_reference": "",
        "online_date": "",
        "cc_bank_name": "",
        "cc_account_name": "",
        "cc_account_number": "",
        "cc_amount": 0,
        "cc_date": "",
        "cheque_issuer_name": "",
        "cheque_bank_name": "",
        "cheque_amount": 0,
        "cheque_cheque_name": "",
        "cheque_date": ""
    });

    console.log(inputvalue)

    const submit = async (option) => {
        
        var formData = new FormData();

        const index = props.sales.findIndex((i) => i.sales_id === props.item.sales_id);
        if (option === 'Cash') {
            props.sales[index].paymentlist = [...props.salespayment, {
                "type": 'Cash',
                "sales_id": props.item.sales_id,
                "payment_id": 304328,
                "amount_value": Number(inputvalue?.amount_value),
                "status": "active"
            }]

            formData.append("add_payment", 1);
            formData.append("sales_id", props.item.sales_id);
            formData.append("type", 'Cash');
            formData.append("json_data", JSON.stringify([{"amount_value": inputvalue?.amount_value}]));
            formData.append("status", 'active');
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'api/sales_report.php',
                data: formData,
            }).then((res) => {
                console.log(res.data)
            });
        } else
            if (option === 'Online') {
                props.sales[index].paymentlist = [...props.salespayment, {
                    "type": 'Online',
                    "sales_id": props.item.sales_id,
                    "payment_id": 304328,
                    "online_bank_name": inputvalue?.online_bank_name,
                    "online_account_name": inputvalue?.online_account_name,
                    "online_account_number": inputvalue?.online_account_number,
                    "online_amount": inputvalue?.online_amount,
                    "online_reference": inputvalue?.online_reference,
                    "online_date": inputvalue?.online_date,
                    "status": "active"
                }]
                formData.append("add_payment", 1);
                formData.append("sales_id", props.item.sales_id);
                formData.append("type", 'Online');
                formData.append("json_data", JSON.stringify([{
                    "online_bank_name": inputvalue?.online_bank_name,
                    "online_account_name": inputvalue?.online_account_name,
                    "online_account_number": inputvalue?.online_account_number,
                    "online_amount": inputvalue?.online_amount,
                    "online_reference": inputvalue?.online_reference,
                    "online_date": inputvalue?.online_date,
                }]));
                formData.append("status", 'active');
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'api/sales_report.php',
                    data: formData,
                }).then((res) => {
                    console.log(res.data)
                });
            } else
                if (option === 'Credit Card') {
                    props.sales[index].paymentlist = [...props.salespayment, {
                        "type": 'Credit Card',
                        "sales_id": props.item.sales_id,
                        "payment_id": 304328,
                        "cc_bank_name": inputvalue?.cc_bank_name,
                        "cc_account_name": inputvalue?.cc_account_name,
                        "cc_account_number": inputvalue?.cc_account_number,
                        "cc_amount": inputvalue?.cc_amount,
                        "cc_date": inputvalue?.cc_date,
                        "status": "active"
                    }]
                    formData.append("add_payment", 1);
                    formData.append("sales_id", props.item.sales_id);
                    formData.append("type", 'Credit Card');
                    formData.append("json_data", JSON.stringify([{
                        "cc_bank_name": inputvalue?.cc_bank_name,
                        "cc_account_name": inputvalue?.cc_account_name,
                        "cc_account_number": inputvalue?.cc_account_number,
                        "cc_amount": inputvalue?.cc_amount,
                        "cc_date": inputvalue?.cc_date,
                    }]));
                    formData.append("status", 'active');
                    await axios({
                        method: "post",
                        url: ENV.DOMAIN + 'api/sales_report.php',
                        data: formData,
                    }).then((res) => {
                        console.log(res.data)
                    });
                } else
                    if (option === 'Cheque') {
                        props.sales[index].paymentlist = [...props.salespayment, {
                            "type": 'Cheque',
                            "sales_id": props.item.sales_id,
                            "payment_id": 304328,
                            "cheque_issuer_name": inputvalue?.cheque_issuer_name,
                            "cheque_bank_name": inputvalue?.cheque_bank_name,
                            "cheque_amount": inputvalue?.cheque_amount,
                            "cheque_cheque_name": inputvalue?.cheque_cheque_name,
                            "cheque_date": inputvalue?.cheque_date,
                            "status": "active"
                        }]
                        formData.append("add_payment", 1);
                        formData.append("sales_id", props.item.sales_id);
                        formData.append("type", 'Cheque');
                        formData.append("json_data", JSON.stringify([{
                            "cheque_issuer_name": inputvalue?.cheque_issuer_name,
                            "cheque_bank_name": inputvalue?.cheque_bank_name,
                            "cheque_amount": inputvalue?.cheque_amount,
                            "cheque_cheque_name": inputvalue?.cheque_cheque_name,
                            "cheque_date": inputvalue?.cheque_date
                        }]));
                        formData.append("status", 'active');
                        await axios({
                            method: "post",
                            url: ENV.DOMAIN + 'api/sales_report.php',
                            data: formData,
                        }).then((res) => {
                            console.log(res.data)
                        });
                    }

        // console.log(props.sales)
        // console.log(props.sales[index].paymentlist)
        props.setSales(props.sales)
        props.setSalesPayment(props.sales[index].paymentlist)
    }

    const handleclose = () => {
        setVisibleRight(false)
        setOpenForm("")
    }


    return (
        <>
            <Sidebar icons={
                <div style={{ width: "100%", textAlign: "center", fontSize: 20, fontWeight: "bolder", borderBottom: "1px solid #ddd", paddingBottom: 10 }}>
                    Add Payment
                </div>
            } showCloseIcon={false} blockScroll={true} visible={visibleRight} position="right" onHide={handleclose} style={{ width: "25%" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", flexWrap: "wrap", gap: 10 }}>

                    <div
                        onClick={() => setOpenForm("Cash")}
                        style={{
                            borderRadius: 5,
                            border: '3px solid rgb(255, 255, 255)',
                            width: "48%",
                            height: 35,
                            backgroundColor: openform === 'Cash' ? "#747474" : 'blue',
                            cursor: openform === 'Cash' ? "" : 'pointer',
                            color: 'rgb(255, 255, 255)',
                            fontWeight: 'bold',
                            boxShadow: 'grey -1px 2px 5px',
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center", fontSize: 18
                        }}>Cash</div>

                    <div
                        onClick={() => setOpenForm("Online")}
                        style={{
                            borderRadius: 5,
                            border: '3px solid rgb(255, 255, 255)',
                            width: "48%",
                            height: 35,
                            backgroundColor: openform === 'Online' ? "#747474" : 'blue',
                            cursor: openform === 'Online' ? "" : 'pointer',
                            color: 'rgb(255, 255, 255)',
                            fontWeight: 'bold',
                            boxShadow: 'grey -1px 2px 5px',
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center", fontSize: 18
                        }}>Online</div>

                    <div
                        onClick={() => setOpenForm("Credit Card")}
                        style={{
                            borderRadius: 5,
                            border: '3px solid rgb(255, 255, 255)',
                            width: "48%",
                            height: 35,
                            backgroundColor: openform === 'Credit Card' ? "#747474" : 'blue',
                            cursor: openform === 'Credit Card' ? "" : 'pointer',
                            color: 'rgb(255, 255, 255)',
                            fontWeight: 'bold',
                            boxShadow: 'grey -1px 2px 5px',
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center", fontSize: 18
                        }}>Credit Card</div>

                    <div
                        onClick={() => setOpenForm("Cheque")}
                        style={{
                            borderRadius: 5,
                            border: '3px solid rgb(255, 255, 255)',
                            width: "48%",
                            height: 35,
                            backgroundColor: openform === 'Cheque' ? "#747474" : 'blue',
                            cursor: openform === 'Cheque' ? "" : 'pointer',
                            color: 'rgb(255, 255, 255)',
                            fontWeight: 'bold',
                            boxShadow: 'grey -1px 2px 5px',
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center", fontSize: 18
                        }}>Cheque</div>

                </div>
                <br />

                <div>
                    {
                        openform === "Cash" ?
                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                <div>
                                    <label className='fontSize20'>Cash</label>
                                    <input name='amount_value' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                {
                                    inputvalue?.amount_value !== 0 ?
                                        <div>
                                            <button onClick={() => submit('Cash')} className='btn btn-info btn-sm fontSize15'>Add Payment</button>
                                        </div>
                                        : null
                                }
                            </div>
                            : null
                    }
                    {/* ////////////////////////////////////////////////////// */}
                    {
                        openform === "Online" ?
                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                <div>
                                    <label className='fontSize20'>Online</label>
                                </div>
                                <div>
                                    <label className='fontSize15'>Bank Name</label>
                                    <input name='online_bank_name' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Account Name</label>
                                    <input name='online_account_name' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Account Number</label>
                                    <input name='online_account_number' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Amount</label>
                                    <input name='online_amount' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Reference</label>
                                    <input name='online_reference' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Date</label>
                                    <input name='online_date' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Picture</label>
                                    <input type='file' />
                                </div>
                                {
                                    inputvalue?.online_bank_name !== '' && inputvalue?.online_account_name !== '' && inputvalue?.online_amount !== 0 && inputvalue?.online_reference !== '' && inputvalue?.online_date !== '' ?
                                        <div>
                                            <button onClick={() => submit('Online')} className='btn btn-info btn-sm fontSize15'>Add Payment</button>
                                        </div>
                                        : null
                                }
                            </div>
                            : null

                    }
                    {/* ////////////////////////////////////////////////////// 192.168.254.186 */}
                    {
                        openform === "Credit Card" ?
                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                <div>
                                    <label className='fontSize20'>Credit Card</label>
                                </div>
                                <div>
                                    <label className='fontSize15'>Bank Name</label>
                                    <input name='cc_bank_name' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Account Name</label>
                                    <input name='cc_account_name' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Account Number</label>
                                    <input name='cc_account_number' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Amount</label>
                                    <input name='cc_amount' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Date</label>
                                    <input name='cc_date' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Picture</label>
                                    <input type='file' />
                                </div>
                                {
                                    inputvalue?.cc_bank_name !== '' && inputvalue?.cc_account_name !== '' && inputvalue?.cc_account_number !== '' && inputvalue?.cc_amount !== 0 && inputvalue?.cc_date !== '' ?
                                        <div>
                                            <button onClick={() => submit('Credit Card')} className='btn btn-info btn-sm fontSize15'>Add Payment</button>
                                        </div>
                                        : null
                                }
                            </div>
                            : null
                    }
                    {/* ////////////////////////////////////////////////////// */}
                    {
                        openform === "Cheque" ?
                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                <div>
                                    <label className='fontSize20'>Cheque</label>
                                </div>
                                <div>
                                    <label className='fontSize15'>Issuer Name</label>
                                    <input name='cheque_issuer_name' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Bank Name</label>
                                    <input name='cheque_bank_name' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Amount</label>
                                    <input name='cheque_amount' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Cheque Number</label>
                                    <input name='cheque_cheque_name' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Date</label>
                                    <input name='cheque_date' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <label className='fontSize15'>Picture</label>
                                    <input type='file' />
                                </div>
                                {
                                    inputvalue?.cheque_issuer_name !== '' && inputvalue?.cheque_bank_name !== '' && inputvalue?.cheque_cheque_name !== '' && inputvalue?.cheque_amount !== 0 && inputvalue?.cheque_date !== '' ?
                                        <div>
                                            <button onClick={() => submit('Cheque')} className='btn btn-info btn-sm fontSize15'>Add Payment</button>
                                        </div>
                                        : null
                                }
                            </div>
                            : null
                    }
                </div>
            </Sidebar>

            <button onClick={() => setVisibleRight(true)} style={{ width: 100, height: 30, fontSize: 12, fontWeight: "bold", borderRadius: 5, border: "1px solid #ddd", color: "#000" }}>Add Payment</button>
        </>
    );
}

export default AddPaymentSidebar;


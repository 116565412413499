import React, { useState, useMemo, useRef, useEffect } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import Header from './component/Header';
import Navbar from './component/Navbar';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from './ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import { InputTextarea } from 'primereact/inputtextarea';
import { format } from 'date-fns';
import EnterInputValueSales from './component/sales/EnterInputValueSales';
import SalesMenu from './component/sales/Sales_menu';
import InputDefectivePanel from './component/sales/InputDefectivePanel';
import OptionPayment from './component/sales/OptionPayment';


const Sales = () => {
    document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix" data-active="dashboard ';
    document.body.id = 'dashboard';
    document.title = 'Sales';

    const [opendialogpayment, setOpenDailogPayment] = useState(false);
    const [editpaymentdialog, setEditPaymentDialog] = useState(false);
    const [addpayment, setAddPayment] = useState('');
    const [SalesAllProduct, setSalesAllProduct] = useState([]);
    const [inputstate, setInputState] = useState(null)
    // console.log(inputstate)



    const [inputvalue, setInputValue] = useState({
        "id_customer": 0,
        "customer_name": "",
        "method": "Walk-in",
        "note": "",
        "receipt_no": "",
        "item": [],
        "shipping_details": [],
        "payments": []
        // "courier": "",
        // "fee": "",
        // "shipping_address": "",
        // "current_address": false
    })


    const [products, setProducts] = useState(null);
    const [addshippingdialog, setAddShippingDialog] = useState(false);
    const [addcustomerdialog, setAddCustomerDialog] = useState(false);
    const [addcashdialog, setAddCashDialog] = useState(false);
    const [addcarddialog, setAddCardDialog] = useState(false);
    const [addonlinedialog, setAddOnlineDialog] = useState(false);
    const [addchequedialog, setAddChequeDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState([]); //emptyProduct
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    const [globalarray, setGlobalArray] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [searchpanelhide, setSearchPanelHide] = useState(false);
    const [entersales, setEnterSales] = useState('');
    const [removeitemid, setRemoveItemId] = useState({ id: 0, status: false });
    const [clickone, setClickOne] = useState(0);

    const ref = useRef(false)


    const [productData, setProductData] = useState([])

    //////////////// CASH
    const [cashvalue, setCashValue] = useState(0)

    //////////////// ONLINE/REMITTANCE
    const [OnlineBankName, setOnlineBankName] = useState('')
    const RefOnlineAccountNumber = useRef()
    const [OnlineAccountNumber, setOnlineAccountNumber] = useState('')
    const RefOnlineAccountName = useRef()
    const [OnlineAccountName, setOnlineAccountName] = useState('')
    const RefOnlineAmount = useRef()
    const [OnlineAmount, setOnlineAmount] = useState(0)
    const RefOnlineReferenceNumber = useRef()
    const [OnlineReferenceNumber, setOnlineReferenceNumber] = useState(0)
    const RefOnlineNote = useRef()
    const [OnlineNote, setOnlineNote] = useState(0)

    //////////////// CARD SWIPE
    const [CardSwipeBankName, setCardSwipeBankName] = useState('')
    const RefCardSwipeCardType = useRef()
    const [CardSwipeCardType, setCardSwipeCardType] = useState('')
    const RefCardSwipeAccountName = useRef()
    const [CardSwipeAccountName, setCardSwipeAccountName] = useState('')
    const RefCardSwipeAccountNumber = useRef()
    const [CardSwipeAccountNumber, setCardSwipeAccountNumber] = useState(0)
    const RefCardSwipeAmount = useRef()
    const [CardSwipeAmount, setCardSwipeAmount] = useState(0)
    const RefCardSwipeTraceNumber = useRef()
    const [CardSwipeTraceNumber, setCardSwipeTraceNumber] = useState('')
    const RefCardSwipeReferenceNumber = useRef()
    const [CardSwipeReferenceNumber, setCardSwipeReferenceNumber] = useState('')
    const RefCardSwipeNote = useRef()
    const [CardSwipeNote, setCardSwipeNote] = useState('')

    //////////////// CHEQUE
    const RefChequeIssuerName = useRef()
    const [ChequeIssuerName, setChequeIssuerName] = useState('')
    const RefChequeBankName = useRef()
    const [ChequeBankName, setChequeBankName] = useState('')
    const RefChequeNumber = useRef()
    const [ChequeNumber, setChequeNumber] = useState('')
    const RefChequeAmount = useRef()
    const [ChequeAmount, setChequeAmount] = useState(0)
    const RefChequeDateIssued = useRef()
    const [ChequeDateIssued, setChequeDateIssued] = useState('')
    const RefChequeNote = useRef()
    const [ChequeNote, setChequeNote] = useState('')


    const [paymentlist, setPaymentList] = useState(JSON.parse(sessionStorage.getItem("PaymentList")) ?? [])

    const [salesoper, setSalesOperation] = useState([])

    ///////////////////////////////////////////////////////
    const [customername, setCustomerName] = useState('')
    const [contact, setContact] = useState('')
    const [contactperson, setContactPerson] = useState('')
    const [address, setAddress] = useState('')
    const [emailaddress, setEmailAddress] = useState('')
    const [tin, setTin] = useState('')


    ///////////////////////////////////////////////////////
    const [shippingcourier, setShippingCourier] = useState('')
    const [shippingfee, setShippingFee] = useState(0)
    const [shippingtrack, setShippingTrack] = useState('')
    const [shippingaddress, setShippingAddress] = useState('')


    const [shippingdetails, setShippingDetails] = useState(JSON.parse(sessionStorage.getItem('shipping_details')) ?? { "shipping_courier": "", "shipping_fee": 0, "shipping_track": "", "shipping_address": "" })
    // console.log(sessionStorage.getItem('shipping_details'))

    const fetchallProduct = async () => {
        var formData = new FormData();
        formData.append("fetchallProduct", 1);
        await axios({
            method: "post",
            // url: ENV.DOMAIN + 'product.php',
            url: ENV.DOMAIN + 'api/sales.php',
            // url: 'http://192.168.1.10/stgc/sales.php',
            data: formData,
        }).then(async(res) => {
            // console.log(res.data)
            setSalesAllProduct([...res.data])
            // console.log(res.data[0].users_role.activity_category)
        })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(async () => {
        fetchallProduct()
    }, [ENV])

    useMemo(async () => {
        // (async () => {
        var formData = new FormData();
        formData.append("sales_operation", 1);
        formData.append("user_id", localStorage.getItem('user_id'))
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/sales.php',
            data: formData,
        }).then(async(res) => {
            await setSalesOperation(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
        // })
    }, [ENV]);

    const update_sales_operation = async () => {
        var formData = new FormData();
        formData.append("sales_operation", 1);
        formData.append("user_id", localStorage.getItem('user_id'))
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/sales.php',
            // url: 'http://192.168.1.10/stgc/sales.php',
            data: formData,
        }).then((res) => {
            setSalesOperation(res.data)
            setInputValue(prev => ({...prev, "item": [...res.data]}))
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const enter_sales_operation = async (e, _value) => {
        var formData = new FormData();
        if (e.key == 'Enter' || e.type == 'click') {
            // const newData = SalesAllProduct.filter((i)=>i.product_code === e.target.value)

            // console.log(newData, newData[0]?.no_serial)


            // if (newData[0]?.no_serial == "1") {
            //     alert(6950376775887)
            //     let foo = prompt(_value?.product_name, 'Enter Qty');
            //     if (foo != 0) {
            //         formData.append("enter_sales_operation_qty", 1);
            //         formData.append("entersales", value.key == 'Enter' ? value.target.value : value.type == 'click' ? _value.serial : "")
            //         formData.append("user_id", localStorage.getItem('user_id'))
            //         await axios({
            //             method: "post",
            //             url: ENV.DOMAIN + 'sales.php',
            //             // url: 'http://192.168.1.10/stgc/sales.php',
            //             data: formData,
            //         }).then((res) => {
            //             // setSalesOperation(res.data)
            //             // console.log(res.data)
            //             setGlobalFilter('')
            //             update_sales_operation()
            //         })
            //             .catch((err) => {
            //                 console.log(err);
            //             });
            //         console.log(foo, _value.stock_id)
            //     }
            // } else {
                // setvalue('')
                formData.append("enter_sales_operation", 1);
                formData.append("entersales", e.key == 'Enter' ? e.target.value : e.type == 'click' ? _value?.serial : "")
                formData.append("user_id", localStorage.getItem('user_id'))
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'sales.php',
                    // url: 'http://192.168.1.10/stgc/sales.php',
                    data: formData,
                }).then((res) => {
                    // setSalesOperation(res.data)
                    // console.log(res.data)
                    setGlobalFilter('')
                    update_sales_operation()
                })
                    .catch((err) => {
                        console.log(err);
                    });
            // }
        }
    }

    const enter_sales_input_qty = async (e, _value) => {
        if (e.key == 'Enter' || e.type == 'click') {
            alert(e.target.value)
            var formData = new FormData();
            formData.append("enter_sales_operation_qty", 1);
            formData.append("entersales", e.target.value)
            formData.append("user_id", localStorage.getItem('user_id'))
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'sales.php',
                // url: 'http://192.168.1.10/stgc/sales.php',
                data: formData,
            }).then((res) => {
                // setSalesOperation(res.data)
                // console.log(res.data)
                setGlobalFilter('')
                update_sales_operation()
            })
                .catch((err) => {
                    console.log(err);
                });

        }
    }

    const sales_save = async () => {
        var formData = new FormData();
        formData.append("sales_save", 1)
        formData.append("paymentlist", paymentlist)

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'sales.php',
            // url: 'http://192.168.1.10/stgc/sales.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setGlobalFilter('')
            update_sales_operation()
            // setSalesOperation(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const sales_remove_item = async (item) => {
        // (async () => {
        // if(sales_remove_item.status){

        // setEnterSales('')
        var formData = new FormData();
        formData.append("sales_remove_item", 1)
        formData.append("user_id", localStorage.getItem('user_id'))
        formData.append("id", item.sales_operation)
        formData.append("qty", item.qty)
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'sales.php',
            // url: 'http://192.168.1.10/stgc/sales.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setGlobalFilter('')
            update_sales_operation()
            // setSalesOperation(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
        // console.log(sales_remove_item.status, '=>>')
        // return setsales_remove_item({id:0, status:false})
        // }
        // })
    }

    // console.log(sales_remove_item)

    const searchProduct = (sales, search) => {
        // const data = SalesAllProduct;
        let data = sales;
        if (search) {
            // i?.sales_id?.padStart(10, '0').match(search.trim()) || 
            data = sales?.filter((i) => (String(i?.product_name).toLowerCase().includes(String(search).toLowerCase())) || (String(i?.product_code).toLowerCase().includes(String(search).toLowerCase())) || (String(i?.keyword_search).toLowerCase().includes(String(search).toLowerCase())));

            // (String(i?.product_name).toLowerCase().includes(String(search).toLowerCase())) || 
            // || i?.product_code.toLowerCase().includes(search.toLowerCase())
        }
        // console.log(data, search)
        return data;
    }

    const globalsearch = async (search) => {
        // SalesAllProduct


        setGlobalFilter(search)
        if (search != '') {
            setSearchPanelHide(true)
            var formData = new FormData();
            formData.append("search_global", 1);
            formData.append("globalfilter", search);
            await axios({
                method: "post",
                // url: ENV.DOMAIN + 'product.php',
                url: ENV.DOMAIN + 'sales.php',
                // url: 'http://192.168.1.10/stgc/sales.php',
                data: formData,
            }).then((res) => {
                // console.log(res.data)
                setGlobalArray(res.data)
                // console.log(res.data[0].users_role.activity_category)
            })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setSearchPanelHide(false)
            setGlobalArray([])
            setClickOne(0)
        }
    }

    // useMemo(async() => {
    //     // (async () => {
    //         if (globalFilter!='') {
    //             var formData = new FormData();
    //             formData.append("search_global", 1);
    //             formData.append("globalfilter", globalFilter);
    //             await axios({
    //                 method: "post",
    //                 // url: ENV.DOMAIN + 'product.php',
    //                 url: 'http://192.168.1.10/stgc/sales.php',
    //                 data: formData,
    //             }).then((res) => {
    //                 // console.log(res.data)
    //                 setGlobalArray(res.data)
    //                 // console.log(res.data[0].users_role.activity_category)
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //         } else {
    //             setGlobalArray([])
    //             setClickOne(0)
    //         }
    //     // })
    // }, [ENV, globalFilter]);



    useMemo(async () => {
        // (async () => {
        var formData = new FormData();
        formData.append("brandList", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            // console.log(res.data[0].users_role.activity_category)
        })
            .catch((err) => {
                console.log(err);
            });
        // })
    }, [ENV]);

    const hideAddCashDialog = () => {
        setSubmitted(false);
        setAddCashDialog(false);
        setCashValue(0)
    }

    const hideAddCardDialog = () => {
        setSubmitted(false);
        setAddCardDialog(false);
    }

    const hideAddOnlineDialog = () => {
        setSubmitted(false);
        setAddOnlineDialog(false);
    }
    const hideAddChequeDialog = () => {
        setSubmitted(false);
        setAddChequeDialog(false);
    }


    const hideCustomerDialog = () => {
        setSubmitted(false);
        setAddCustomerDialog(false);
        // setSearchExistingCustomer(false)
    }

    const hideShippingDialog = () => {
        setSubmitted(false);
        setAddShippingDialog(false);
    };

    const saveProduct = () => {
        setSubmitted(true);

        if (product.name.trim()) {
            let _products = [...products];
            let _product = { ...product };

            if (product.id) {
                const index = findIndexById(product.id);

                _products[index] = _product;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
            } else {
                _product.id = createId();
                _product.image = 'product-placeholder.svg';
                _products.push(_product);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
            }

            setProducts(_products);
            alert(false);
            setProduct([]); //emptyProduct
        }
    };


    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const saveCash = (value) => {
        // if (value.key == 'Enter' || value.type == 'click') {
        // }
        const data = {
            payment_id: "",
            reserve_id: "",
            shopee_id: "",
            sales_id: "",
            credited: "Cash",
            type: "Cash",
            date: format(new Date(), 'yyyy-MM-dd'),
            time: format(new Date(), 'HH:mm:ss'),
            amount_value: inputstate.cash,
            monthly_install: "",
            payment_desc: [],
            status: "",
            user_id: localStorage.getItem('user_id'),
            dateRemoved: "",
            removeBy: "",
            timespan: ""
        }
        if (inputstate.cash != null) {
            setPaymentList([...paymentlist, data])
            sessionStorage.setItem("PaymentList", JSON.stringify([...paymentlist, data]))
            setAddCashDialog(false)
        }
    }

    const saveOnline = () => {
        let data = {}
        if (inputstate?.online_bank_name == 'GCash') {
            data = {
                payment_id: "",
                reserve_id: "",
                shopee_id: "",
                sales_id: "",
                credited: "GCash",
                type: "Online / Remittance",
                date: format(new Date(), 'yyyy-MM-dd'),
                time: format(new Date(), 'HH:mm:ss'),
                amount_value: inputstate?.online_amount,
                monthly_install: "",
                payment_desc: {
                    sales_id: '',
                    bank_name: inputstate?.online_bank_name,
                    gcash_number: inputstate?.online_gcash_number,
                    account_name: inputstate?.online_account_name,
                    amount: inputstate?.online_amount,
                    reference_number: inputstate?.online_referense_number,
                    note_date: inputstate?.online_note_date
                },
                status: "",
                user_id: localStorage.getItem('user_id'),
                dateRemoved: "",
                removeBy: "",
                timespan: "",
                note: ''
            }
            if (inputstate?.online_bank_name != null) {
                setPaymentList([...paymentlist, data])
                sessionStorage.setItem("PaymentList", JSON.stringify([...paymentlist, data]))
                setAddCashDialog(false)
                setInputState(null)
            }
        } else if (inputstate?.online_bank_name == 'Maya') {
            data = {
                payment_id: "",
                reserve_id: "",
                shopee_id: "",
                sales_id: "",
                credited: "Maya",
                type: "Online / Remittance",
                date: format(new Date(), 'yyyy-MM-dd'),
                time: format(new Date(), 'HH:mm:ss'),
                amount_value: inputstate?.online_amount,
                monthly_install: "",
                payment_desc: {
                    sales_id: '',
                    bank_name: inputstate?.online_bank_name,
                    maya_number: inputstate?.online_maya_number,
                    account_name: inputstate?.online_account_name,
                    amount: inputstate?.online_amount,
                    reference_number: inputstate?.online_referense_number,
                    note_date: inputstate?.online_note_date
                },
                status: "",
                user_id: localStorage.getItem('user_id'),
                dateRemoved: "",
                removeBy: "",
                timespan: "",
                note: ''
            }
            if (inputstate?.online_bank_name != null) {
                setPaymentList([...paymentlist, data])
                sessionStorage.setItem("PaymentList", JSON.stringify([...paymentlist, data]))
                setAddCashDialog(false)
                setInputState(null)
            }
        } else if (inputstate?.online_bank_name == 'HomeCredit') {
            data = {
                payment_id: "",
                reserve_id: "",
                shopee_id: "",
                sales_id: "",
                credited: "HomeCredit",
                type: "Online / Remittance",
                date: format(new Date(), 'yyyy-MM-dd'),
                time: format(new Date(), 'HH:mm:ss'),
                amount_value: inputstate.online_homecredit_amount,
                monthly_install: "",
                payment_desc: {
                    sales_id: "",
                    bank_name: inputstate?.online_bank_name,
                    agent_name: inputstate?.online_agent_name,
                    amount: inputstate?.online_homecredit_amount,
                    note_date: inputstate?.online_note_date
                },
                status: "",
                user_id: localStorage.getItem('user_id'),
                dateRemoved: "",
                removeBy: "",
                timespan: "",
                note: ''
            }
            if (inputstate?.online_bank_name != null) {
                setPaymentList([...paymentlist, data])
                sessionStorage.setItem("PaymentList", JSON.stringify([...paymentlist, data]))
                setAddCashDialog(false)
                setInputState(null)
            }
        }
    }


    const saveCardSwipe = () => {
        // console.log(inputstate)
        const data = {
            payment_id: "",
            reserve_id: "",
            shopee_id: "",
            sales_id: "",
            credited: "Card Swipe",
            type: "Card Swipe",
            date: format(new Date(), 'yyyy-MM-dd'),
            time: format(new Date(), 'HH:mm:ss'),
            amount_value: inputstate.cardswipe_amount,
            monthly_install: "",
            payment_desc: {
                sales_id: "",
                bank_name: inputstate.cardswipe_bank_name,
                card_type: inputstate.cardswipe_card_type,
                account_name: inputstate.cardswipe_account_name,
                account_number: inputstate.cardswipe_account_number,
                amount: inputstate.cardswipe_amount,
                trace_number: inputstate.cardswipe_trace_number,
                reference_number: inputstate.cardswipe_reference_number,
                note_date: ""
            },
            status: "",
            user_id: localStorage.getItem('user_id'),
            dateRemoved: "",
            removeBy: "",
            timespan: "",
            note: ''
        }
        setPaymentList([...paymentlist, data])
        sessionStorage.setItem("PaymentList", JSON.stringify([...paymentlist, data]));
        setAddCashDialog(false)
    }

    const saveCheque = () => {
        // console.log(inputstate)
        const data = {
            payment_id: "",
            reserve_id: "",
            shopee_id: "",
            sales_id: "",
            credited: "Cheque",
            type: "Cheque",
            date: format(new Date(), 'yyyy-MM-dd'),
            time: format(new Date(), 'HH:mm:ss'),
            amount_value: inputstate.cheque_amount,
            monthly_install: "",
            payment_desc: {
                sales_id: "",
                issuer_name: inputstate.cheque_issuer_name,
                bank_name: inputstate.cheque_bank_name,
                cheque_number: inputstate.cheque_number,
                amount: inputstate.cheque_amount,
                date_issued: inputstate.cheque_date_issued,
                note: inputstate.cheque_note
            },
            status: "",
            user_id: localStorage.getItem('user_id'),
            dateRemoved: "",
            removeBy: "",
            timespan: "",
            note: ''
        }

        setPaymentList([...paymentlist, data])
        sessionStorage.setItem("PaymentList", JSON.stringify([...paymentlist, data]))
        setAddChequeDialog(false)
    }

    const removePayment = (item) => {
        const updatedList = paymentlist.filter((i) => {
            return i !== item;
        });
        sessionStorage.setItem("PaymentList", JSON.stringify(updatedList))
        setPaymentList(updatedList)
    }

    const [editPaymentCashModalData, setEditPaymentCashModalData] = useState(null)
    const [editPaymentOnlineModalData, setEditPaymentOnlineModalData] = useState(null)
    const [editPaymentCardModalData, setEditPaymentCardModalData] = useState(null)
    const [editPaymentChequeModalData, setEditPaymentChequeModalData] = useState(null)
    const [editPaymentModalData, setEditPaymentModalData] = useState(null)
    const [editPaymentData, setEditPaymentData] = useState({})

    const editPayment = (item) => {
        // console.log(item)
        if (item?.credited == 'Cash') {
            setInputState({
                status: "edit",
                credited: "Cash",
                cash: item.amount_value
            })
            setEditPaymentData(item)
        } else
            if (item?.type == 'Online / Remittance') {
                if (item.credited == 'GCash') {
                    setInputState({
                        status: "edit",
                        credited: 'GCash',
                        type: 'Online / Remittance',
                        online_bank_name: item.payment_desc.bank_name,
                        online_gcash_number: item.payment_desc.gcash_number,
                        online_account_name: item.payment_desc.account_name,
                        online_amount: item.payment_desc.amount,
                        online_referense_number: item.payment_desc.reference_number,
                        online_note_date: item.payment_desc.note_date
                    })
                } else if (item.credited == 'Maya') {
                    setInputState({
                        status: "edit",
                        credited: 'Maya',
                        type: 'Online / Remittance',
                        online_bank_name: item.payment_desc.bank_name,
                        online_maya_number: item.payment_desc.maya_number,
                        online_account_name: item.payment_desc.account_name,
                        online_amount: item.payment_desc.amount,
                        online_referense_number: item.payment_desc.reference_number,
                        online_note_date: item.payment_desc.note_date
                    })
                } else if (item.credited == 'HomeCredit') {
                    setInputState({
                        status: "edit",
                        credited: 'HomeCredit',
                        type: 'Online / Remittance',
                        online_bank_name: item.payment_desc.bank_name,
                        online_agent_name: item.payment_desc.agent_name,
                        online_homecredit_amount: item.payment_desc.amount,
                        online_note_date: item.payment_desc.note_date
                    })
                }
                setEditPaymentData(item)
            } else
                if (item?.credited == 'Card Swipe') {
                    setInputState({
                        status: "edit",
                        credited: 'Card Swipe',
                        type: 'Card Swipe',
                        cardswipe_bank_name: item.payment_desc.bank_name,
                        cardswipe_card_type: item.payment_desc.card_type,
                        cardswipe_account_name: item.payment_desc.account_name,
                        cardswipe_account_number: item.payment_desc.account_number,
                        cardswipe_amount: item.payment_desc.amount,
                        cardswipe_trace_number: item.payment_desc.trace_number,
                        cardswipe_reference_number: item.payment_desc.reference_number
                    })
                    setEditPaymentData(item)
                } else
                    if (item?.credited == 'Cheque') {
                        setInputState({
                            status: "edit",
                            credited: 'Cheque',
                            type: 'Cheque',
                            cheque_issuer_name: item.payment_desc.issuer_name,
                            cheque_bank_name: item.payment_desc.bank_name,
                            cheque_number: item.payment_desc.cheque_number,
                            cheque_amount: item.payment_desc.amount,
                            cheque_date_issued: item.payment_desc.date_issued
                        })
                        setEditPaymentData(item)
                    }
        setEditPaymentDialog(true)
    }

    const UpdatePayment = () => {
        // console.log(editPaymentData)
        const index = paymentlist.findIndex(x => x === editPaymentData);

        if (editPaymentData.credited == 'Cash') {
            paymentlist[index].amount_value = inputstate.cash;
            paymentlist[index].payment_desc.account_name = '';
            paymentlist[index].payment_desc.account_number = '';
            paymentlist[index].payment_desc.amount = inputstate.cash;
            paymentlist[index].payment_desc.bank_name = '';
            paymentlist[index].payment_desc.note_date = '';
            paymentlist[index].payment_desc.reference_number = '';
            setPaymentList([...paymentlist])
            sessionStorage.setItem("PaymentList", JSON.stringify([...paymentlist]));
            // console.log(index, paymentlist, inputstate, editPaymentData)
        } else
            if (editPaymentData.type == "Online / Remittance") {
                if (inputstate.online_bank_name == "GCash") {
                    paymentlist[index].credited = "GCash";
                    paymentlist[index].amount_value = inputstate.online_amount;
                    paymentlist[index].payment_desc.account_name = inputstate.online_account_name;
                    paymentlist[index].payment_desc.account_number = inputstate.online_gcash_number;
                    paymentlist[index].payment_desc.amount = inputstate.online_amount;
                    paymentlist[index].payment_desc.bank_name = inputstate.online_bank_name;
                    paymentlist[index].payment_desc.note_date = inputstate.online_note_date;
                    paymentlist[index].payment_desc.reference_number = inputstate.online_note_date;
                    setPaymentList([...paymentlist])
                    sessionStorage.setItem("PaymentList", JSON.stringify([...paymentlist]));
                    // console.log(index, paymentlist, inputstate, editPaymentData)
                } else
                    if (inputstate.online_bank_name == "Maya") {
                        paymentlist[index].credited = "Maya";
                        paymentlist[index].amount_value = inputstate.online_amount;
                        paymentlist[index].payment_desc.account_name = inputstate.online_account_name;
                        paymentlist[index].payment_desc.maya_number = inputstate.online_maya_number;
                        paymentlist[index].payment_desc.amount = inputstate.online_amount;
                        paymentlist[index].payment_desc.bank_name = inputstate.online_bank_name;
                        paymentlist[index].payment_desc.note_date = inputstate.online_note_date;
                        paymentlist[index].payment_desc.reference_number = inputstate.online_note_date;
                        setPaymentList([...paymentlist])
                        sessionStorage.setItem("PaymentList", JSON.stringify([...paymentlist]));
                        // console.log(index, paymentlist, inputstate, editPaymentData)
                    } else
                        if (inputstate.online_bank_name == "HomeCredit") {
                            paymentlist[index].credited = "HomeCredit";
                            paymentlist[index].amount_value = inputstate.online_homecredit_amount;
                            paymentlist[index].payment_desc.bank_name = inputstate?.online_bank_name;
                            paymentlist[index].payment_desc.agent_name = inputstate?.online_agent_name;
                            paymentlist[index].payment_desc.amount = inputstate.online_homecredit_amount;
                            paymentlist[index].payment_desc.note_date = inputstate?.online_note_date;
                            setPaymentList([...paymentlist])
                            sessionStorage.setItem("PaymentList", JSON.stringify([...paymentlist]));
                            // console.log(index, paymentlist, inputstate, editPaymentData)
                        }
                // console.log(paymentlist)
            } else
                if (editPaymentData.credited == "Card Swipe") {
                    paymentlist[index].amount_value = inputstate.cardswipe_amount;
                    paymentlist[index].payment_desc.account_name = inputstate?.cardswipe_account_name;
                    paymentlist[index].payment_desc.account_number = inputstate?.cardswipe_account_number;
                    paymentlist[index].payment_desc.amount = inputstate?.cardswipe_amount;
                    paymentlist[index].payment_desc.bank_name = inputstate?.cardswipe_bank_name;
                    paymentlist[index].payment_desc.card_type = inputstate?.cardswipe_card_type;
                    paymentlist[index].payment_desc.reference_number = inputstate?.cardswipe_reference_number;
                    paymentlist[index].payment_desc.trace_number = inputstate?.cardswipe_trace_number;
                    // console.log(paymentlist[index])
                    setPaymentList([...paymentlist])
                    sessionStorage.setItem("PaymentList", JSON.stringify([...paymentlist]));
                } else
                    if (editPaymentData.credited == "Cheque") {
                        paymentlist[index].amount_value = inputstate.cheque_amount;
                        paymentlist[index].payment_desc.issuer_name = inputstate?.cheque_issuer_name;
                        paymentlist[index].payment_desc.amount = inputstate?.cheque_amount;
                        paymentlist[index].payment_desc.bank_name = inputstate?.cheque_bank_name;
                        paymentlist[index].payment_desc.date_issued = inputstate?.cheque_date_issued;
                        paymentlist[index].payment_desc.cheque_number = inputstate?.cheque_number;
                        // console.log(paymentlist[index])
                        setPaymentList([...paymentlist])
                        sessionStorage.setItem("PaymentList", JSON.stringify([...paymentlist]));
                    }
        setEditPaymentDialog(false)
    }

    const totalPayment = () => {
        let total = 0;
        paymentlist.map((item, key) => {
            total += Number(item.amount_value)
        });
        return total;
    }

    const DialogFooter = (editPaymentModalData) => {
        // console.log(editPaymentModalData?.credited)
        if (addpayment == 'Cash') {
            return (<React.Fragment>
                <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={() => {
                    setOpenDailogPayment(false)
                    setAddPayment('')
                }} />
                <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={(e) => saveCash(e)} />
                {/* {
                    editPaymentModalData?.credited != undefined ?
                        <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => UpdateCash(editPaymentModalData)} />
                        :
                        <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={(e) => saveCash(e)} />
                } */}
            </React.Fragment>)
        } else if (addpayment == 'Online / Remittance') {
            return (<React.Fragment>
                <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={() => {
                    setOpenDailogPayment(false)
                    setAddPayment('')
                }} />
                <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={(e) => saveOnline()} />
                {/* {
                    editPaymentModalData?.credited != undefined ?
                        <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => UpdateCash(editPaymentModalData)} />
                        :
                        <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={(e) => saveCash(e)} />
                } */}
            </React.Fragment>)
        } else if (addpayment == 'Card Swipe') {
            return (<React.Fragment>
                <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={() => {
                    setOpenDailogPayment(false)
                    setAddPayment('')
                }} />
                <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={(e) => saveCardSwipe()} />
                {/* {
                    editPaymentModalData?.credited != undefined ?
                        <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => UpdateCash(editPaymentModalData)} />
                        :
                        <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={(e) => saveCash(e)} />
                } */}
            </React.Fragment>)
        } else if (addpayment == 'Cheque') {
            return (<React.Fragment>
                <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={() => {
                    setOpenDailogPayment(false)
                    setAddPayment('')
                }} />
                <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={(e) => saveCheque()} />
                {/* {
                    editPaymentModalData?.credited != undefined ?
                        <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => UpdateCash(editPaymentModalData)} />
                        :
                        <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={(e) => saveCash(e)} />
                } */}
            </React.Fragment>)
        }
    }

    const EditDialogFooter = (editPaymentModalData) => {
        if (inputstate?.credited == 'Cash') {
            return (
                <React.Fragment>
                    <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={hideAddOnlineDialog} />
                    {
                        editPaymentModalData?.status != 'edit' ?
                            <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => UpdatePayment()} />
                            :
                            <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={saveOnline} />
                    }
                </React.Fragment>
            )
        } else if (inputstate?.type == 'Online / Remittance') {
            if (inputstate?.online_bank_name == 'GCash') {
                return (
                    <React.Fragment>
                        <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={hideAddOnlineDialog} />
                        {
                            editPaymentModalData?.status != 'edit' ?
                                <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => UpdatePayment()} />
                                :
                                <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={saveOnline} />
                        }
                    </React.Fragment>
                )
            } else
                if (inputstate?.online_bank_name == 'Maya') {
                    return (
                        <React.Fragment>
                            <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={hideAddOnlineDialog} />
                            {
                                editPaymentModalData?.status != 'edit' ?
                                    <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => UpdatePayment()} />
                                    :
                                    <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={saveOnline} />
                            }
                        </React.Fragment>
                    )
                } else
                    if (inputstate?.online_bank_name == 'HomeCredit') {
                        return (
                            <React.Fragment>
                                <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={hideAddOnlineDialog} />
                                {
                                    editPaymentModalData?.status != 'edit' ?
                                        <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => UpdatePayment()} />
                                        :
                                        <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={saveOnline} />
                                }
                            </React.Fragment>
                        )
                    }
        } else if (inputstate?.type == 'Card Swipe') {
            return (
                <React.Fragment>
                    <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={hideAddOnlineDialog} />
                    {
                        editPaymentModalData?.status != 'edit' ?
                            <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => UpdatePayment()} />
                            :
                            <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={saveOnline} />
                    }
                </React.Fragment>
            )
        } else if (inputstate?.type == 'Cheque') {
            return (
                <React.Fragment>
                    <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={hideAddOnlineDialog} />
                    {
                        editPaymentModalData?.status != 'edit' ?
                            <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => UpdatePayment()} />
                            :
                            <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={saveOnline} />
                    }
                </React.Fragment>
            )
        }


    }

    //////////////////////////////////////////////
    //////////////////////////////////////////////
    //////////////////////////////////////////////

    // const AddOnlineDialogFooter = (editPaymentModalData) => {
    //     return (
    //         <React.Fragment>
    //             <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={hideAddOnlineDialog} />
    //             {
    //                 editPaymentModalData?.credited != undefined ?
    //                     <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => UpdatePayment(editPaymentModalData)} />
    //                     :
    //                     <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={saveOnline} />
    //             }
    //         </React.Fragment>
    //     )
    // }
    // const AddCardDialogFooter = (editPaymentModalData) => {
    //     return (
    //         <React.Fragment>
    //             <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={hideAddCardDialog} />
    //             {
    //                 editPaymentModalData?.credited != undefined ?
    //                     <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => UpdateCardSwipe(editPaymentModalData)} />
    //                     :
    //                     <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={saveCardSwipe} />
    //             }
    //         </React.Fragment>
    //     )
    // }
    // const AddChequeDialogFooter = (editPaymentModalData) => {
    //     return (
    //         <React.Fragment>
    //             <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={hideAddChequeDialog} />
    //             {
    //                 editPaymentModalData?.credited != undefined ?
    //                     <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => UpdateCheque(editPaymentModalData)} />
    //                     :
    //                     <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={saveCheque} />
    //             }
    //         </React.Fragment>
    //     )
    // }

    const [SearchExistingCustomer, setSearchExistingCustomer] = useState(false)


    const [customerinfo, setCustomerInfo] = useState(JSON.parse(sessionStorage.getItem('customerinfo')) ?? {})

    const Proceed = () => {

        const data = {
            "customer_name": customername,
            "contact": contact,
            "contact_person": contactperson,
            "address": address,
            "email": emailaddress,
            "tin": tin,
        }

        setCustomerInfo(data)

        sessionStorage.setItem('customerinfo', JSON.stringify(data))

        setCustomerName('')
        setContact('')
        setContactPerson('')
        setAddress('')
        setEmailAddress('')
        setTin('')
    }
    const UpdateInfo = () => {
        // console.log(tin)
        let _customerinfo = JSON.parse(sessionStorage.getItem('customerinfo'))

        const data = {
            "customer_name": customername ? customername : _customerinfo?.customer_name,
            "contact": contact ? contact : _customerinfo?.contact,
            "contact_person": contactperson ? contactperson : _customerinfo?.contact_person,
            "address": address ? address : _customerinfo?.address,
            "email": emailaddress ? emailaddress : _customerinfo?.email,
            "tin": tin ? tin : _customerinfo?.tin,
        }

        setCustomerInfo(data)

        sessionStorage.setItem('customerinfo', JSON.stringify(data))

        setCustomerName('')
        setContact('')
        setContactPerson('')
        setAddress('')
        setEmailAddress('')
        setTin('')


    }
    // console.log(sessionStorage.getItem('customerinfo'))

    const CustomerDialogFooter = (
        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div>
                    {
                        SearchExistingCustomer ?
                            <button style={{ width: null, fontSize: 18, height: 40, borderRadius: 5, fontWeight: "700", border: "1px solid #7b0303", backgroundColor: "#a52e2e", color: "#fff" }} onClick={() => setSearchExistingCustomer(false)}>
                                Back
                            </button>
                            :
                            <button style={{ width: null, fontSize: 18, height: 40, borderRadius: 5, fontWeight: "700", border: "1px solid #3e7645", backgroundColor: "#7fc788", color: "#fff" }} onClick={() => setSearchExistingCustomer(true)}>
                                Exist Customer
                            </button>
                    }
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={hideCustomerDialog} />
                    {
                        customername != '' ?
                            <>
                                {
                                    customerinfo?.customer_name != null ?
                                        <Button onClick={() => UpdateInfo()} style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" />
                                        :
                                        <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={() => Proceed()} />
                                }
                            </>
                            :
                            <>
                                {
                                    customerinfo?.customer_name != null ?
                                        <Button onClick={() => UpdateInfo()} style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" />
                                        :
                                        <Button style={{ width: 100, fontSize: 18, backgroundColor: "#ddd" }} label="Save" icon="pi pi-check" />
                                }
                            </>
                    }
                </div>
            </div>
        </React.Fragment>
    );
    const ShippingDialogFooter = (




        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div>
                    {
                        shippingdetails?.shipping_courier != '' ?
                            <Button style={{ width: 100, fontSize: 18 }} label="Remove" outlined onClick={() => {
                                setShippingDetails({
                                    'shipping_courier': "",
                                    'shipping_fee': "",
                                    'shipping_track': "",
                                    'shipping_address': ""
                                })
                                sessionStorage.setItem('shipping_details', JSON.stringify({
                                    'shipping_courier': "",
                                    'shipping_fee': "",
                                    'shipping_track': "",
                                    'shipping_address': ""
                                }));
                                setAddShippingDialog(false)
                                setShippingCourier('')
                                setShippingFee(0)
                                setShippingTrack('')
                                setShippingAddress('')
                            }} />
                            :
                            null
                    }
                </div>
                <div>
                    <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={() => {
                        hideShippingDialog()
                    }} />
                    {
                        shippingdetails?.shipping_courier != '' ?
                            <Button style={{ width: 100, fontSize: 18 }} label="Update" icon="pi pi-check" onClick={() => {
                                AddShippingDetails()
                                setAddShippingDialog(false)
                            }} />
                            :
                            <Button style={{ width: 100, fontSize: 18 }} label="Save" icon="pi pi-check" onClick={() => {
                                AddShippingDetails()
                                setAddShippingDialog(false)
                            }} />
                    }
                </div>
            </div>
        </React.Fragment>
    );


    const [openReserve, setOpenReserve] = useState(false);
    const [openPayment, setOpenPayment] = useState(sessionStorage.getItem('open_payment') == 'true' ? true : false);

    const onReserve = (value) => {
        setOpenReserve(value)
    }
    const onPayment = (value) => {
        sessionStorage.setItem('open_payment', String(value))
        setOpenPayment(value)
    }

    const multiList = (data) => {
        return data
    }

    const totalStock = (data) => {
        // let total = 0;
        const SubTotal = data?.reduce((qty, item) => Number(item.stock_qty) + qty, 0);
        // const _data = JSON.parse(data);
        // data.map((item, key) => {
        //     total += item.stock_qty;
        // });
        return SubTotal;
        // console.log(SubTotal)
    }

    const totalSales = (data) => {
        let total = 0;
        data.map((item, key) => {
            total += Number(item.unit_srp);
        });
        // console.log(shippingdetails?.shipping_fee)
        if (shippingdetails?.shipping_fee != 0) {
            return total + Number(shippingdetails?.shipping_fee);
        }
        return total;
    }

    const [availStock, setAvailStock] = useState([])
    const [searchbyserial, setSearchBySerial] = useState('')

    const enterStock = (data, list) => {
        setClickOne(data.product_id)
        setAvailStock(list)
        // console.log(JSON.stringify(list))
    }

    const loopSerial = (data, search) => {
        if (search != '') {
            data = data.filter((i) => (i?.serial.toLowerCase().includes(search.trim().toLowerCase())));
        }
        return data
    }

    const OnlineBankNameChange = (e) => {
        if (e.type == 'change') {
            // console.log(e.type, e)
            setOnlineBankName(e.target.value)
            RefOnlineAccountNumber.current.focus()
        }
    }

    const AddShippingDetails = (e) => {
        const data = {
            'shipping_courier': shippingcourier,
            'shipping_fee': shippingfee,
            'shipping_track': shippingtrack,
            'shipping_address': shippingaddress
        }
        setShippingDetails(data)
        sessionStorage.setItem('shipping_details', JSON.stringify(data));
    }



    const groupedData = salesoper?.reduce((groups, item) => {
        const { product_id, product_name } = item;
        // console.log(product_id)
        if (product_id == 0) {
            if (!groups[product_id, product_name]) {
                groups[product_id, product_name] = [];
            }
            groups[product_id, product_name].push(item);
        } else {
            if (!groups[product_id]) {
                groups[product_id] = [];
            }
            groups[product_id].push(item);
        }
        return groups;
    }, {});


    const CompletePurchase = async () => {
        // console.log(1, salesoper, customerinfo, paymentlist)
        // console.log(paymentlist)
        // console.log(customerinfo)
        var formData = new FormData();
        formData.append("complete_purchase", 1);
        formData.append("salesoper", JSON.stringify(salesoper));
        formData.append("paymentlist", JSON.stringify(paymentlist));
        formData.append("customer_info", JSON.stringify(customerinfo));
        formData.append("shipping_details", JSON.stringify(shippingdetails));
        // console.log(JSON.stringify([customerinfo, salesoper, paymentlist, shippingdetails]))
        await axios({
            method: "post",
            // url: ENV.DOMAIN + 'product.php',
            url: ENV.DOMAIN + 'sales.php',
            // url: 'http://192.168.1.10/stgc/sales.php',
            data: formData,
        }).then(async (res) => {
            // if(res.data == 'success'){
            // console.log(2, res.data[0].list)

            // return

            var formData = new FormData();
            formData.append("print_sales_details", 1);
            formData.append("print_details", JSON.stringify(res.data[0]));
            formData.append("product_list", JSON.stringify(res.data[0].list));
            formData.append("policy", 'wo');
            await axios({
                method: "post",
                // url: 'http://192.168.1.99/api/print_receipt.php',
                url: 'http://localhost/stgc/api/print_receipt.php',
                data: formData,
            }).then((res) => {
                // console.log(res.data)
                if (res.data == 'success') {
                    // setSuccessPrinting(true)
                    // setTimeout(() => {
                    //     setPrinting(false)
                    //     setPrintPreviewReceipt(false)
                    //     setSuccessPrinting(false)
                    //     setPanelOnlySalesData(data)
                    //     setOnlyReceiptData({ list: [{ stock_price: 0, sales_qty: 0 }] })
                    // }, 1000)
                } else {

                }
            });







            // update_sales_operation()
            // setPaymentList([])
            // setShippingDetails({})
            // setCustomerInfo({})
            // onPayment(false)
            // sessionStorage.removeItem("PaymentList")
            // sessionStorage.removeItem("shipping_details")
            // sessionStorage.removeItem("customerinfo")
            // }
            // setGlobalArray(res.data)
            // console.log(res.data[0].users_role.activity_category)
        })
            .catch((err) => {
                console.log(err);
            });
    }


    let arr = [
        { name: "string 1", value: "this", other: "that" },
        { name: "string 2", value: "this", other: "that" }
    ];

    let obj = arr.find((o, i) => {
        if (o.name === 'string 1') {
            arr[i] = { name: 'new string', value: 'this', other: 'that' };
            return true; // stop searching
        }
    });

    // console.log(arr);

    return (

        <>
            <div className="vd_body">
                {/* Header Start */}
                <Header />
                {/* Header Ends */}
                <div className="content" style={{ height: "100vh" }}>
                    <div className="container">
                        <Navbar />
                        {/* Middle Content Start */}
                        <div className="vd_content-wrapper" style={{ minHeight: 1059 }}>
                            <div className="vd_container" style={{ minHeight: 1059 }}>
                                <div className="vd_content clearfix">


                                    <div className="vd_content-section clearfix">
                                        <div className="row">

                                            <div className='col-sm-8' style={{ position: "relative" }}>
                                                <div style={{ padding: 0, paddingBottom: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 5 }}>
                                                    {/* onBlur={()=>setSearchPanelHide(false)} */}
                                                    <InputText onKeyPress={(e) => { enter_sales_operation(e) }} onChange={(e) => setGlobalFilter(e.target.value)} value={globalFilter != '' ? globalFilter : ''} onMouseDown={() => setSearchPanelHide(true)} type="search" placeholder="Enter item name or scan barcode and Serial" style={{ width: "90%", height: 50, fontSize: 18, boxShadow: searchpanelhide ? (globalarray.length > 0 ? "0px 2px 3px rgb(0,0,0,.5)" : "0px 2px 3px rgb(0,0,0,.5)") : "" }} />

                                                    <Button label="Cancel" size="large" severity="danger" />
                                                    <Button label="Suspended" size="large" severity="warning" />
                                                    {
                                                        SalesAllProduct.length > 0 ?
                                                            <>
                                                                {
                                                                    globalFilter != '' ?
                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", position: "absolute", zIndex: 1, top: 40, width: "100%", backgroundColor: "#fff", boxShadow: "0px 20px 30px rgb(0,0,0,.6)" }}>
                                                                            <div style={{ width: clickone != 0 ? "60%" : "100%", maxHeight: 400, overflow: "auto" }}>
                                                                                {
                                                                                    // searchProduct(SalesAllProduct, globalFilter)
                                                                                    searchProduct(SalesAllProduct, globalFilter)?.map((item, key) => {
                                                                                        if (totalStock(item.list) != 0) {
                                                                                            return (
                                                                                                <div key={key} onClick={() => totalStock(item?.list) != 0 ? enterStock(item, item.list) : null} style={{ cursor: "pointer", padding: 5, backgroundColor: clickone == item.product_id ? "#fff" : (clickone == 0 ? "#fff" : "#ddd"), borderBottom: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                                                    <div style={{ width: "100%" }}>
                                                                                                        <div style={{ fontWeight: "600" }}>{item.product_name}</div>
                                                                                                        <div>UPC: {item?.product_code}</div>
                                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                                                                                                            <div>
                                                                                                                <div style={{ fontWeight: "500" }}>SRP: {Number(item?.unit_price).toFixed(2)}</div>
                                                                                                                <div style={{ fontWeight: "500" }}>CASH: {Number(item?.cash_price).toFixed(2)}</div>
                                                                                                            </div>
                                                                                                            <span style={{ fontWeight: "700" }}>Qty: {totalStock(item?.list)}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <div style={{ fontSize: 15 }}></div> */}
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            {
                                                                                clickone != 0 ?
                                                                                    <div style={{ width: "40%", maxHeight: 400, overflow: "auto" }}>
                                                                                        <div style={{ padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#377575" }}>
                                                                                            <div onClick={() => setClickOne(0)} label="Cancel" size="small" style={{ width: 35, height: 35, padding: 5, borderRadius: 5, backgroundColor: "#EF4444", border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} severity="danger">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="#fff" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                                                                                    <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                                                                                </svg>
                                                                                            </div>
                                                                                            <InputText onChange={(e) => setSearchBySerial(e.target.value)} placeholder='Search' style={{ height: 35, width: "85%" }} />
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                loopSerial(availStock, searchbyserial).map((item, key) => {
                                                                                                    return (
                                                                                                        <EnterInputValueSales key={key} item={item} enter_sales_operation={enter_sales_operation} enter_sales_input_qty={enter_sales_input_qty} clickone={clickone} />
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                                    {
                                                        Object.entries(groupedData).map(([id, items]) => {
                                                            // console.log(id)
                                                            return (
                                                                <div key={id}>
                                                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, backgroundColor: "#fff" }}>
                                                                        <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
                                                                            <div style={{ fontSize: 15, fontWeight: "550" }}>{items[0].product_name}</div>
                                                                            <div>UPC: <span style={{ fontWeight: "500" }}>{items[0].product_code}</span></div>
                                                                        </div>
                                                                        <div style={{ display: "flex", width: "40%", flexDirection: "row", justifyContent: "flex-end", alignItems: "start" }}>
                                                                            <div style={{ minWidth: 70, textAlign: "center", fontSize: 18 }}><span style={{ fontSize: 15, fontWeight: "bolder" }}>Qty:</span> {Number(items.map((item) => item).length)}</div>
                                                                            <div style={{ minWidth: 100, textAlign: "center", fontSize: 18 }}><span style={{ fontSize: 15, fontWeight: "bolder" }}>Price:</span> {Number(items[0]?.unit_srp).toFixed(2)}</div>
                                                                            <div style={{ width: 150, textAlign: "right", fontSize: 18 }}><span style={{ fontSize: 15, fontWeight: "bolder" }}>Total:</span> {Number(items.map((item) => item).length * items[0]?.unit_srp).toFixed(2)}</div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        <div style={{ padding: 5, backgroundColor: "#fff" }}>
                                                                            {
                                                                                items.map((_item, _key) => {
                                                                                    return (
                                                                                        <div key={_item.sales_operation} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10, backgroundColor: _item?.selected_row ? "yellow" : "" }}>
                                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                                                                <a onClick={(e) => sales_remove_item(_item)} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: 30 }}>
                                                                                                    <svg style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="#9e0000" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                                                    </svg>
                                                                                                </a>
                                                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 15, width: 300 }}>
                                                                                                    <span>SN: {_item.serial_number}</span>
                                                                                                    <span>
                                                                                                        <OptionPayment _item={_item} salesoper={salesoper} setSalesOperation={setSalesOperation} />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <InputDefectivePanel _item={_item} salesoper={salesoper} setSalesOperation={setSalesOperation} />
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )


                                                            // return (
                                                            //     <div>
                                                            //         <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, backgroundColor: "#fff" }}>
                                                            //             <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
                                                            //                 <div style={{ fontSize: 15, fontWeight: "550" }}>{item.product_name}</div>
                                                            //                 <div>UPC: <span style={{ fontWeight: "500" }}>{item.product_code}</span></div>
                                                            //             </div>
                                                            //             <div style={{ display: "flex", width: "40%", flexDirection: "row", justifyContent: "flex-end", alignItems: "start" }}>
                                                            //                 <div style={{ minWidth: 70, textAlign: "center", fontSize:18}}>{multiList(item?.list).length}</div>
                                                            //                 <div style={{ minWidth: 70, textAlign: "center", fontSize:18 }}>{Number(item.unit_srp).toFixed(2)}</div>
                                                            //                 <div style={{ width: 100, textAlign: "right", fontSize:18 }}>{Number(item.unit_srp * multiList(item?.list).length).toFixed(2)}</div>
                                                            //             </div>
                                                            //         </div>
                                                            //         {
                                                            //             item?.product_id!=null ?
                                                            //             <div style={{ padding: 5, backgroundColor: "#fff" }}>
                                                            //                 {
                                                            //                     multiList(item?.list).map((_item, _key) => {
                                                            //                         return (
                                                            //                             <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap:10}}>

                                                            //                                 <a onClick={(e)=>sales_remove_item(_item)} style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:30}}>
                                                            //                                     <svg style={{cursor:"pointer"}} xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="#9e0000" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                            //                                         <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            //                                         <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                            //                                     </svg>   
                                                            //                                 </a>
                                                            //                                 <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>SN: {_item.serial_number}</div>
                                                            //                             </div>
                                                            //                         )
                                                            //                     })
                                                            //                 }
                                                            //             </div>
                                                            //             :
                                                            //             <>
                                                            //                 <a onClick={(e)=>sales_remove_item(item)} style={{padding:5, backgroundColor:"#fff", color:"#9e0000", fontWeight:"500", cursor:"pointer", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap:5}}>
                                                            //                     <svg style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="#9e0000" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                            //                         <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            //                         <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                            //                     </svg>  
                                                            //                     Remove
                                                            //                 </a>
                                                            //             </>
                                                            //         }
                                                            //     </div>
                                                            // )
                                                        })
                                                    }
                                                </div>

                                                {/* <DataTable ref={dt} value={salesoper} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                                                    dataKey="id">
                                                    <Column field="product_name" header="Discription" sortable style={{ minWidth: '16rem' }}></Column>
                                                    <Column field="brand_name" header="Serial" sortable style={{ minWidth: '20rem' }}></Column>
                                                    <Column field="product_code" header="U.P.C." sortable style={{ minWidth: '12rem' }}></Column>
                                                    <Column field="unit_price" header="Qty" sortable style={{ minWidth: '10rem' }}></Column>
                                                    <Column field="dcash_price" header="U.P." sortable style={{ minWidth: '10rem' }}></Column>
                                                </DataTable> */}
                                            </div>
                                            <div className='col-sm-4'>
                                                <SalesMenu salesoper={salesoper} setSalesOperation={setSalesOperation} inputvalue={inputvalue} setInputValue={setInputValue} />








                                                <Dialog visible={opendialogpayment} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                                                    <span style={{ fontSize: 25 }}>{addpayment}</span>
                                                } modal className="p-fluid" footer={() => DialogFooter(editPaymentCashModalData)} onHide={() => setOpenDailogPayment(false)}>
                                                    {
                                                        addpayment == 'Cash' ?
                                                            <div>
                                                                <input name='cash' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, textAlign: "center", fontSize: 20, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        addpayment == 'Online / Remittance' ?
                                                            <>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>

                                                                    <div style={{ paddingBottom: 5, width: "30%" }}>
                                                                        <label style={{ fontSize: 15 }}>Bank Name</label>
                                                                        {/* OnlineBankNameChange(e) */}
                                                                        <select name='online_bank_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }}>
                                                                            {/* <option>GCash</option>
                                                                            <option>Maya</option>
                                                                            <option>BPI</option>
                                                                            <option>BDO</option>
                                                                            <option>MetroBank</option>
                                                                            <option>Others</option> */}
                                                                            <option value={''} selected disabled>Select</option>
                                                                            <option value={'GCash'}>GCash</option>
                                                                            <option value={'Maya'}>Maya</option>
                                                                            {/* <option value={'BPI'}>BPI</option>
                                                                            <option value={'BDO'}>BDO</option>
                                                                            <option value={'MetroBank'}>MetroBank</option> */}
                                                                            <option value={'HomeCredit'}>HomeCredit</option>
                                                                            <option value={'Others'}>Others</option>
                                                                        </select>
                                                                    </div>
                                                                    {
                                                                        inputstate?.online_bank_name == 'GCash' ?
                                                                            <div style={{ paddingBottom: 5, width: "70%" }}>
                                                                                <label style={{ fontSize: 15 }}>GCash Number</label>
                                                                                {/* <input ref={RefOnlineAccountNumber} onKeyPress={(e) => (e.key == 'Enter') ? RefOnlineAccountName.current.focus() : null} onChange={(e) => setOnlineAccountNumber(e.target.value)} value={OnlineAccountNumber ? OnlineAccountNumber : editPaymentOnlineModalData?.payment_desc?.account_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} /> */}
                                                                                <input name='online_gcash_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        inputstate?.online_bank_name == 'Maya' ?
                                                                            <div style={{ paddingBottom: 5, width: "70%" }}>
                                                                                <label style={{ fontSize: 15 }}>Maya Number</label>
                                                                                {/* <input ref={RefOnlineAccountNumber} onKeyPress={(e) => (e.key == 'Enter') ? RefOnlineAccountName.current.focus() : null} onChange={(e) => setOnlineAccountNumber(e.target.value)} value={OnlineAccountNumber ? OnlineAccountNumber : editPaymentOnlineModalData?.payment_desc?.account_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} /> */}
                                                                                <input name='online_maya_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        inputstate?.online_bank_name == 'HomeCredit' ?
                                                                            <div style={{ paddingBottom: 5, width: "70%" }}>
                                                                                <label style={{ fontSize: 15 }}>Agent Name</label>
                                                                                {/* <input ref={RefOnlineAccountNumber} onKeyPress={(e) => (e.key == 'Enter') ? RefOnlineAccountName.current.focus() : null} onChange={(e) => setOnlineAccountNumber(e.target.value)} value={OnlineAccountNumber ? OnlineAccountNumber : editPaymentOnlineModalData?.payment_desc?.account_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} /> */}
                                                                                <input name='online_agent_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                                {
                                                                    inputstate?.online_bank_name == 'HomeCredit' ?
                                                                        <>
                                                                            <div style={{ paddingBottom: 5 }}>
                                                                                <label style={{ fontSize: 15 }}>Amount</label>
                                                                                <input name='online_homecredit_amount' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                            <div style={{ paddingBottom: 5 }}>
                                                                                <label style={{ fontSize: 15 }}>Note / Date</label>
                                                                                <input name='online_note_date' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div style={{ paddingBottom: 5 }}>
                                                                                <label style={{ fontSize: 15 }}>Account Name / Bank Source</label>
                                                                                <input name='online_account_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.online_account_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                                <div style={{ paddingBottom: 5 }}>
                                                                                    <label style={{ fontSize: 15 }}>Amount</label>
                                                                                    <input name='online_amount' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.online_amount} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                                </div>
                                                                                <div style={{ paddingBottom: 5 }}>
                                                                                    <label style={{ fontSize: 15 }}>Reference Number</label>
                                                                                    <input name='online_referense_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.online_referense_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ paddingBottom: 5 }}>
                                                                                <label style={{ fontSize: 15 }}>Note / Date</label>
                                                                                <input name='online_note_date' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.online_note_date} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                        </>
                                                                }
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        addpayment == 'Card Swipe' ?
                                                            <>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10, marginBottom: 10 }}>
                                                                    {/* <div style={{ cursor: "pointer", borderRadius: 5, width: 100, backgroundColor: "#459c5d", color: "#fff", fontWeight: "bolder", fontSize: 18, padding: 5, border: "1px solid #ddd", textAlign: "center" }}>Maya</div> */}
                                                                    {/* <div style={{ cursor: "pointer", borderRadius: 5, width: 100, padding: 0, border: "1px solid #ddd", textAlign: "center", backgroundImage: "url(https://www.adobomagazine.com/wp-content/uploads/2019/08/BPI-new-logo.jpg)", backgroundSize: "100px 34.6px", backgroundRepeat: "no-repeat" }}>
                                                                        <img src={"https://www.adobomagazine.com/wp-content/uploads/2019/08/BPI-new-logo.jpg"} style={{width:100, height:34.6, backgroundSize:"cover"}} />
                                                                    </div> */}
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                    <div style={{ paddingBottom: 5, width: "70%" }}>
                                                                        <label style={{ fontSize: 15 }}>Bank Name</label>
                                                                        <input name='cardswipe_bank_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                    <div style={{ paddingBottom: 5, width: "30%" }}>
                                                                        <label style={{ fontSize: 15 }}>Card Type</label>
                                                                        {/* ref={RefCardSwipeCardType} onChange={(e) => {
                                                                            setCardSwipeCardType(e.target.value)
                                                                            RefCardSwipeAccountName.current.focus()
                                                                        }} */}
                                                                        <select name='cardswipe_card_type' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }}>
                                                                            <option selected disabled>Select...</option>
                                                                            <option value={"Visa"}>Visa</option>
                                                                            <option value={"MasterCard"}>MasterCard</option>
                                                                            <option value={"JCB"}>JCB</option>
                                                                            <option value={"AMEX"}>AMEX</option>
                                                                            <option value={"Others"}>Others</option>
                                                                        </select>

                                                                    </div>
                                                                </div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Account Name</label>
                                                                    <input name='cardswipe_account_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                </div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Account Number / Card Number</label>
                                                                    <input name='cardswipe_account_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                    <div style={{ paddingBottom: 5, width: "50%" }}>
                                                                        <label style={{ fontSize: 15 }}>Amount</label>
                                                                        <input name='cardswipe_amount' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                    <div style={{ paddingBottom: 5 }}>
                                                                        <label style={{ fontSize: 15 }}>Trace Number</label>
                                                                        <input name='cardswipe_trace_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Reference Number</label>
                                                                    <input name='cardswipe_reference_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                </div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Note / Date</label>
                                                                    <input name='cardswipe_note_date' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        addpayment == 'Cheque' ?
                                                            <>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Issuer Name</label>
                                                                    <input name='cheque_issuer_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>

                                                                    <div style={{ paddingBottom: 5, width: "35%" }}>
                                                                        <label style={{ fontSize: 15 }}>Bank Name</label>
                                                                        <input name='cheque_bank_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                    <div style={{ paddingBottom: 5, width: "65%" }}>
                                                                        <label style={{ fontSize: 15 }}>Cheque Number</label>
                                                                        <input name='cheque_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                    <div style={{ paddingBottom: 5 }}>
                                                                        <label style={{ fontSize: 15 }}>Amount</label>
                                                                        <input name='cheque_amount' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} type='number' style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                    <div style={{ paddingBottom: 5 }}>
                                                                        <label style={{ fontSize: 15 }}>Date Issued</label>
                                                                        <input name='cheque_date_issued' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Note</label>
                                                                    <textarea name='cheque_note' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 150, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()}></textarea>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </Dialog>
                                                <Dialog visible={editpaymentdialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                                                    <span style={{ fontSize: 25 }}>Edit Payment Details</span>
                                                } modal className="p-fluid" footer={EditDialogFooter} onHide={() => setEditPaymentDialog(false)}>
                                                    {/* footer={EditDialogFooter} onHide={hideCustomerDialog} */}
                                                    {/* {JSON.stringify(inputstate)} */}
                                                    {
                                                        inputstate?.credited == 'Cash' ?
                                                            <input name='cash' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate?.cash} style={{ width: "100%", height: 40, textAlign: "center", fontSize: 20, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                            :
                                                            <></>
                                                    }
                                                    {/* inputstate?.credited == 'cash' */}
                                                    {/* inputstate?.type == 'Online / Remittance' */}
                                                    {/* inputstate?.online_bank_name == 'GCash' */}
                                                    {/* inputstate?.online_bank_name == 'Maya' */}
                                                    {/* inputstate?.online_bank_name == 'HomeCredit' */}
                                                    {/* inputstate?.type == 'Card Swipe' */}
                                                    {/* inputstate?.type == 'Cheque' */}
                                                    {
                                                        inputstate?.type == 'Online / Remittance' ?
                                                            <>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>

                                                                    <div style={{ paddingBottom: 5, width: "30%" }}>
                                                                        <label style={{ fontSize: 15 }}>Bank Name</label>
                                                                        {/* OnlineBankNameChange(e) */}
                                                                        <select name='online_bank_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.online_bank_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }}>
                                                                            <option value={''} selected disabled>Select</option>
                                                                            <option value={'GCash'}>GCash</option>
                                                                            <option value={'Maya'}>Maya</option>
                                                                            <option value={'HomeCredit'}>HomeCredit</option>
                                                                            <option value={'Others'}>Others</option>
                                                                        </select>
                                                                    </div>
                                                                    {
                                                                        inputstate?.online_bank_name == 'GCash' ?
                                                                            <div style={{ paddingBottom: 5, width: "70%" }}>
                                                                                <label style={{ fontSize: 15 }}>GCash Number</label>
                                                                                {/* <input ref={RefOnlineAccountNumber} onKeyPress={(e) => (e.key == 'Enter') ? RefOnlineAccountName.current.focus() : null} onChange={(e) => setOnlineAccountNumber(e.target.value)} value={OnlineAccountNumber ? OnlineAccountNumber : editPaymentOnlineModalData?.payment_desc?.account_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} /> */}
                                                                                <input name='online_gcash_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.online_gcash_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        inputstate?.online_bank_name == 'Maya' ?
                                                                            <div style={{ paddingBottom: 5, width: "70%" }}>
                                                                                <label style={{ fontSize: 15 }}>Maya Number</label>
                                                                                {/* <input ref={RefOnlineAccountNumber} onKeyPress={(e) => (e.key == 'Enter') ? RefOnlineAccountName.current.focus() : null} onChange={(e) => setOnlineAccountNumber(e.target.value)} value={OnlineAccountNumber ? OnlineAccountNumber : editPaymentOnlineModalData?.payment_desc?.account_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} /> */}
                                                                                <input name='online_maya_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.online_maya_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        inputstate?.online_bank_name == 'HomeCredit' ?
                                                                            <div style={{ paddingBottom: 5, width: "70%" }}>
                                                                                <label style={{ fontSize: 15 }}>Agent Name</label>
                                                                                {/* <input ref={RefOnlineAccountNumber} onKeyPress={(e) => (e.key == 'Enter') ? RefOnlineAccountName.current.focus() : null} onChange={(e) => setOnlineAccountNumber(e.target.value)} value={OnlineAccountNumber ? OnlineAccountNumber : editPaymentOnlineModalData?.payment_desc?.account_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} /> */}
                                                                                <input name='online_agent_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.online_agent_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                                {
                                                                    inputstate?.online_bank_name == 'HomeCredit' ?
                                                                        <>
                                                                            <div style={{ paddingBottom: 5 }}>
                                                                                <label style={{ fontSize: 15 }}>Amount</label>
                                                                                <input name='online_homecredit_amount' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.online_homecredit_amount} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                            <div style={{ paddingBottom: 5 }}>
                                                                                <label style={{ fontSize: 15 }}>Note / Date</label>
                                                                                <input name='online_note_date' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.online_note_date} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div style={{ paddingBottom: 5 }}>
                                                                                <label style={{ fontSize: 15 }}>Account Name / Bank Source</label>
                                                                                <input name='online_account_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.online_account_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                                <div style={{ paddingBottom: 5 }}>
                                                                                    <label style={{ fontSize: 15 }}>Amount</label>
                                                                                    <input name='online_amount' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.online_amount} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                                </div>
                                                                                <div style={{ paddingBottom: 5 }}>
                                                                                    <label style={{ fontSize: 15 }}>Reference Number</label>
                                                                                    <input name='online_referense_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.online_referense_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ paddingBottom: 5 }}>
                                                                                <label style={{ fontSize: 15 }}>Note / Date</label>
                                                                                <input name='online_note_date' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                            </div>
                                                                        </>
                                                                }
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        inputstate?.type == 'Card Swipe' ?
                                                            <>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                    <div style={{ paddingBottom: 5, width: "70%" }}>
                                                                        <label style={{ fontSize: 15 }}>Bank Name</label>
                                                                        <input name='cardswipe_bank_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.cardswipe_bank_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                    <div style={{ paddingBottom: 5, width: "30%" }}>
                                                                        <label style={{ fontSize: 15 }}>Card Type</label>
                                                                        <select name='cardswipe_card_type' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.cardswipe_card_type} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }}>
                                                                            <option selected disabled>Select...</option>
                                                                            <option value={"Visa"}>Visa</option>
                                                                            <option value={"MasterCard"}>MasterCard</option>
                                                                            <option value={"JCB"}>JCB</option>
                                                                            <option value={"AMEX"}>AMEX</option>
                                                                            <option value={"Others"}>Others</option>
                                                                        </select>

                                                                    </div>
                                                                </div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Account Name</label>
                                                                    <input name='cardswipe_account_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.cardswipe_account_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                </div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Account Number / Card Number</label>
                                                                    <input name='cardswipe_account_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.cardswipe_account_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                    <div style={{ paddingBottom: 5, width: "50%" }}>
                                                                        <label style={{ fontSize: 15 }}>Amount</label>
                                                                        <input name='cardswipe_amount' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.cardswipe_amount} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                    <div style={{ paddingBottom: 5 }}>
                                                                        <label style={{ fontSize: 15 }}>Trace Number</label>
                                                                        <input name='cardswipe_trace_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.cardswipe_trace_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Reference Number</label>
                                                                    <input name='cardswipe_reference_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.cardswipe_reference_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                </div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Note / Date</label>
                                                                    <input name='cardswipe_note_date' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        inputstate?.type == 'Cheque' ?
                                                            <>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Issuer Name</label>
                                                                    <input name='cheque_issuer_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.cheque_issuer_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>

                                                                    <div style={{ paddingBottom: 5, width: "35%" }}>
                                                                        <label style={{ fontSize: 15 }}>Bank Name</label>
                                                                        <input name='cheque_bank_name' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.cheque_bank_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                    <div style={{ paddingBottom: 5, width: "65%" }}>
                                                                        <label style={{ fontSize: 15 }}>Cheque Number</label>
                                                                        <input name='cheque_number' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.cheque_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                    <div style={{ paddingBottom: 5 }}>
                                                                        <label style={{ fontSize: 15 }}>Amount</label>
                                                                        <input name='cheque_amount' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.cheque_amount} type='number' style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                    <div style={{ paddingBottom: 5 }}>
                                                                        <label style={{ fontSize: 15 }}>Date Issued</label>
                                                                        <input name='cheque_date_issued' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} value={inputstate.cheque_date_issued} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Note</label>
                                                                    <textarea name='cheque_note' onChange={(e) => setInputState({ ...inputstate, [e.target.name]: e.target.value })} style={{ width: "100%", height: 150, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()}></textarea>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </Dialog>

                                                {/* /////////////////////////////////////// */}
                                                {/* /////////////////////////////////////// */}
                                                {/* /////////////////////////////////////// */}



                                                <Dialog visible={addcustomerdialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                                                    <span style={{ fontSize: 25 }}>Add Customer Details</span>
                                                } modal className="p-fluid" footer={CustomerDialogFooter} onHide={hideCustomerDialog}>
                                                    {
                                                        SearchExistingCustomer ?
                                                            <div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    {/* <label style={{ fontSize: 15 }}>Search</label> */}
                                                                    <input placeholder='Search...' onChange={(e) => setChequeIssuerName(e.target.value)} value={ChequeIssuerName ? ChequeIssuerName : editPaymentChequeModalData?.payment_desc.issuer_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>FullName / Company Name:</label>
                                                                    {/* customerinfo?.firstname */}
                                                                    <input onChange={(e) => setCustomerName(e.target.value)} value={customername ? customername : customerinfo?.customer_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} />
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>

                                                                    <div style={{ paddingBottom: 5 }}>
                                                                        <label style={{ fontSize: 15 }}>Mobile / Telephone:</label>
                                                                        <input onChange={(e) => setContact(e.target.value)} value={contact ? contact : customerinfo?.contact} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>
                                                                    <div style={{ paddingBottom: 5 }}>
                                                                        <label style={{ fontSize: 15 }}>Contact Person / Contact Number:</label>
                                                                        <input onChange={(e) => setContactPerson(e.target.value)} value={contactperson ? contactperson : customerinfo?.contact_person} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                    </div>

                                                                </div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Address:</label>
                                                                    <textarea cols="30" rows="5" onChange={(e) => setAddress(e.target.value)} value={address ? address : customerinfo?.address} style={{ resize: "none", width: "100%", paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} ></textarea>
                                                                </div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Email <span style={{ color: "#a7a7a7" }}>(optional)</span></label>
                                                                    <input onChange={(e) => setEmailAddress(e.target.value)} value={emailaddress ? emailaddress : customerinfo?.email} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                </div>
                                                                <div style={{ paddingBottom: 5 }}>
                                                                    <label style={{ fontSize: 15 }}>Tin <span style={{ color: "#a7a7a7" }}>(optional)</span></label>
                                                                    <input onChange={(e) => setTin(e.target.value)} value={tin ? tin : customerinfo?.tin} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                                </div>
                                                            </div>
                                                    }



                                                </Dialog>
                                                <Dialog visible={addshippingdialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={

                                                    shippingdetails?.shipping_courier != '' ?
                                                        <span style={{ fontSize: 25 }}>Edit Shipping Details</span>
                                                        :
                                                        <span style={{ fontSize: 25 }}>Add Shipping Details</span>

                                                } modal className="p-fluid" footer={ShippingDialogFooter} onHide={hideShippingDialog}>

                                                    <div style={{ paddingBottom: 5 }}>
                                                        <label style={{ fontSize: 15 }}>Courier:</label>
                                                        <input onKeyPress={() => null} onChange={(e) => setShippingCourier(e.target.value)} value={shippingcourier ? shippingcourier : shippingdetails?.shipping_courier} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                        <div style={{ paddingBottom: 5, width: 150 }}>
                                                            <label style={{ fontSize: 15 }}>Fee:</label>
                                                            <input onKeyPress={() => null} placeholder='0.00' onChange={(e) => setShippingFee(e.target.value)} value={shippingfee ? shippingfee : shippingdetails?.shipping_fee} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                        </div>
                                                        <div style={{ paddingBottom: 5 }}>
                                                            <label style={{ fontSize: 15 }}>Tracking No <span style={{ color: "#a7a7a7" }}>(optional)</span>:</label>
                                                            <input onKeyPress={() => null} placeholder='' onChange={(e) => setShippingTrack(e.target.value)} value={shippingtrack ? shippingtrack : shippingdetails?.shipping_track} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                        </div>
                                                    </div>
                                                    <div style={{ paddingBottom: 5 }}>
                                                        <label style={{ fontSize: 15 }}>Address:</label>
                                                        <textarea cols="30" rows="5" onKeyPress={() => null} onChange={(e) => setShippingAddress(e.target.value)} value={shippingaddress ? shippingaddress : shippingdetails?.shipping_address} style={{ resize: "none", width: "100%", paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} ></textarea>
                                                    </div>
                                                </Dialog>
                                            </div>


                                        </div>
                                    </div>
                                    {/* .vd_content-section */}
                                </div>
                                {/* .vd_content */}
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
                {/* .content */}
                {/* Footer Start */}
                <footer className="footer-1" id="footer">
                    <div className="vd_bottom ">
                        <div className="container">
                            <div className="row">
                                <div className=" col-xs-12">
                                    <div className="copyright">
                                        Copyright ©2014 Venmond Inc. All Rights Reserved
                                    </div>
                                </div>
                            </div>{/* row */}
                        </div>{/* container */}
                    </div>
                </footer>
            </div>



        </>

    );
}

export default Sales;

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import AddProductBtn from './AddProductBtn';
import ShowDetails from './showDetails';
import ViewStockBtn from './ViewStockBtn';
import EditProductBtn from './EditProductBtn';
import DeleteProductBtn from './DeleteProductBtn';
import ENV from '../../ENV';
import axios from 'axios';
import { Button } from 'primereact/button';
import PanelAddStock from './PanelAddStock';
import $ from "jquery";
import OnStock from './onStock';
import Edit from './extra/product/Edit';
import View from './extra/product/View';
import Delete from './extra/product/Delete';
import FormPanel from './extra/product/FormPanel';
import { Paginator } from 'primereact/paginator';
import Categories from './extra/product/Categories';

const Table = (props) => {
    // { openpanel, setOpenPanel, products, productlist, setProducts, ProductSync, setShowStockStatus, show_stock_status, productsforcat, setProductsforCat }

    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [page, setPage] = useState(10);
    const [search, setSearch] = useState('');
    const [totalrecord, setTotalRecord] = useState('');
    const dt = useRef(null);
    // const [openpanel, setOpenPanel] = useState(null)
    const [userstatus, setUserStatus] = useState(null)
    const [showstock, setshowStock] = useState('all_active')
    
    $.DataTable = require('datatables.net');
    const tableRef = useRef()
    const tableName = "table1"



    useMemo(async () => {
        var formData = new FormData();
        formData.append("checkUserStatus", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        // setInterval(async()=> {
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/user.php',
            data: formData,
        }).then((res) => {
            setUserStatus(JSON.parse(res?.data))
            // console.log(JSON.parse(res.data))
        })
            .catch((err) => {
                console.log(err);
            });
        // }, 5000);
    }, [ENV]);


    // useEffect(() => {
    //     // console.log(tableRef.current)
    //     const table = $(`#${tableName}`).DataTable(
    //         {
    //             data: props.products,
    //             columns: [
    //                 {
    //                     "render": function (data, type, JsonResultRow, meta) {
    //                         // console.log(JsonResultRow)
    //                         // if (JsonResultRow.file_exist === 1) {
    //                         return '<img id="zoomimage" class="imgzm" src="' + ENV.IMG_URL + JsonResultRow.product_image + '" loading="lazy" style="width:100px;height:50.3px;cursor:pointer;" onerror="this.style.display=\'none\'" loading="lazy">';
    //                         // }
    //                         // else
    //                         // {
    //                         //     return 'No Image';
    //                         // }
    //                     }, "width": "100px"
    //                 },
    //                 { mData: 'cat_name', "className": "menu-action", "width": "300px" },
    //                 { mData: 'brand_name', "className": "menu-action no-print" },
    //                 { mData: 'product_code', "className": "menu-action no-print" },
    //                 { mData: 'product_name', "className": "menu-action table-product_name" },
    //                 { mData: 'unit_price', "className": "menu-action no-print retail-action" },
    //                 { mData: 'cash_price', "className": "menu-action no-print hide-column-dc" },
    //                 {
    //                     "render": function (da3ta, type, JsonResultRow, meta) {
    //                         console.log(JsonResultRow)
    //                         if (JsonResultRow.id === JsonResultRow.id) {
    //                             // if (JsonResultRow.totalss <= JsonResultRow.reorder) {
    //                             //     if (JsonResultRow.totalss === 0) {
    //                             return `<i class="fa fa-warning" style="color:red;"></i>` + JsonResultRow.totalss;
    //                             //     }
    //                             //     else
    //                             //     {
    //                             //         return `<i class="fa fa-warning" style="color:orange;"></i>`+JsonResultRow.totalss;
    //                             //     }
    //                             // }
    //                             // else
    //                             // {
    //                             //     return JsonResultRow.totalss;
    //                             // }
    //                         }
    //                         else {
    //                             if (JsonResultRow.id !== 0) {
    //                                 return `<i class="fa fa-warning" style="color:red;"></i>` + JsonResultRow.totalss;
    //                             }
    //                             else {
    //                                 return `<i class="fa fa-warning" style="color:orange;"></i>` + JsonResultRow.totalss;
    //                             }
    //                         }
    //                     }, "className": "menu-action table-product_name"
    //                 },
    //                 { mData: 'button-option', "className": "menu-action text-right btn-action" },
    //             ],
    //             // destroy: true,  // I think some clean up is happening here
    //             searching: false,
    //         }
    //     )
    //     // Extra step to do extra clean-up.
    //     return function () {
    //         // console.log("Table destroyed")
    //         table.destroy()
    //     }
    // }, [props])

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = props.products.slice(firstIndex, lastIndex);
    const npage = Math.ceil(props.products.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const changePage = (n) => {
        setCurrentPage(n)
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const pagesRecord = [
        { "number": 10 },
        { "number": 15 },
        { "number": 25 },
        { "number": 50 },
        { "number": 100 },
        { "number": 500 }
    ]

    const data = props.products.filter((item) => {
        if (showstock === 'all_active') {
            if (search.length >= 3) {
                return String(item.product_name).toLowerCase().includes(String(search).toLowerCase()) || String(item.product_code).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '0'
            }
        } else 
        if (showstock === 'active_inactive') {
            if (search.length >= 3) {
                return String(item.product_name).toLowerCase().includes(String(search).toLowerCase()) || String(item.product_code).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '0' || item.pstatus === '2'
            }
        } else 
        if (showstock === 'stock') {
            if (search.length >= 3) {
                return (String(item.product_name).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '0' && item.stock <= 0) || (String(item.product_code).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '0' && item.stock === 0)
            }
            return item.pstatus === '0' && item.stock === 0
        }
        return item
    })

    // console.log(data)

    const [sortOrder, setSortOrder] = useState('asc');

    const sortChange = (title) => {
        const sortedData = [...data];
        if (sortOrder === 'asc') {
            if (title === 'brand_name') {
                sortedData.sort((a, b) => a.brand_name?.localeCompare(b.brand_name));
            } else if (title === 'product_name') {
                sortedData.sort((a, b) => a.product_name?.localeCompare(b.product_name));
            } else if (title === 'stock') {
                sortedData.sort((a, b) => a.stock - b.stock);
            } else if (title === 'price') {
                sortedData.sort((a, b) => a.unit_price - b.unit_price);
            }
            setSortOrder('desc');
        } else {
            if (title === 'brand_name') {
                sortedData.sort((a, b) => b.brand_name?.localeCompare(a.brand_name));
            } else if (title === 'product_name') {
                sortedData.sort((a, b) => b.product_name?.localeCompare(a.product_name));
            } else if (title === 'stock') {
                sortedData.sort((a, b) => b.stock - a.stock);
            } else if (title === 'price') {
                sortedData.sort((a, b) => b.unit_price - a.unit_price);
            }
            setSortOrder('asc');
        }
        props.setProducts(sortedData);
        console.log(sortedData);
    }


    useEffect(() => {
        if (search.length >= 3) {
            setTotalRecord(data.length)
        } else {
            setTotalRecord(props.products.length)
        }
    }, [props, data, page, search])

     const isBase64UrlImage = async (base64String) => {
        let image = new Image()
        image.src = base64String
        return await (new Promise((resolve)=>{
          image.onload = function () {
            if (image.height === 0 || image.width === 0) {
              resolve(false);
              return;
            }
            resolve(true)
          }
          image.onerror = () =>{
            resolve(false)
          }
        }))
      }

    // const showStock = (e) => {
    //     console.log(e.target.value, props.productlist)
    //     const data = props.productlist.filter((item) => {
    //         if (e.target.value === 'all_active') {
    //             return item.pstatus === '0'
    //         } else
    //         if (e.target.value === 'active_inactive') {
    //             return item.pstatus === '2'
    //         }
    //         return item;
    //     })
    //     console.log(data)
    //     props.setProducts([...data]);
    // }

    if (props.openpanel) {
        // console.log(0, openpanel)
        return (
            <>
                <div>
                    <PanelAddStock rowData={props.openpanel} setOpenPanel={props.setOpenPanel} products={props.products} productlist={props.productlist} setProducts={props.setProducts} ProductSync={props.ProductSync} show_stock_status={props.show_stock_status} />
                </div>
            </>
        )
    } else {
        return (
            <div className='panel'>
                <div className='panel-body'>
                    <div>
                        <div style={{ padding: 5 }}>
                            <div className='row'>
                                <div className='col-sm-8'>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                            <select className='form-control' style={{ width: 100 }} onChange={(e) => setTimeout(() => setPage(e.target.value), 1000)}>
                                                {
                                                    pagesRecord.map((item) => {
                                                        return (
                                                            <option key={item} value={item.number}>{item.number}</option>
                                                        )
                                                    })
                                                }
                                                {
                                                    search.length >= 3 ?
                                                        <option value={totalrecord}>{totalrecord}</option>
                                                        : null
                                                }
                                            </select>
                                            <span>records per page</span>
                                        </div>
                                        <div>
                                                <Categories products={props.products} setProducts={props.setProducts} productsforcat={props.productsforcat} setProductsforCat={props.setProductsforCat} selectedKeys={props.selectedKeys} setSelectedKeys={props.setSelectedKeys} />
                                        </div>
                                        <div>
                                            
                                            <select className="btn btn-default btn-sm" onChange={(e)=>setshowStock(e.target.value)} style={{ width: 200, padding: '4.5px' }}>
                                                <option value="all_active">Show All Active</option>
                                                <option value="active_inactive">Show All (Active And Inactive)</option>
                                                <option value="stock">Show Stock only</option>
                                                <option value="inactive">Show Inactive</option>
                                                <option value="zero_stock">Show Zero Stock Only</option>
                                                <option value="reorderpoint">Show Re-order Point</option>
                                            </select>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                                        <FormPanel btn={"add"} products={props.products} setProducts={props.setProducts} />
                                        <span>Search:</span>
                                        <input className='form-control' onChange={(e) => setSearch(e.target.value)} value={search} style={{ width: 200 }} />
                                        {
                                            search.length > 0 ?
                                                <span onClick={() => setSearch('')} style={{ cursor: "pointer", width: 20, height: 20, border: "1px solid red", backgroundColor: "#ddd", borderRadius: 100, display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", right: 20 }}><i className='pi pi-times' style={{ color: "red", fontWeight: "bolder" }} /></span>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className='table table-bordered table-hover'>
                        <thead className='vd_bg-dark-blue'>
                            <tr className='heading'>
                                <td style={{ color: "#fff", fontWeight: 600 }}>Img</td>
                                <td style={{ color: "#fff", fontWeight: 600 }}>Category / SubCategory</td>
                                {/* <td style={{ color: "#fff", fontWeight: 600 }}>Product Code</td> */}
                                <td style={{ color: "#fff", fontWeight: 600 }}>
                                    <span onClick={() => sortChange('product_name')}>Product Name</span>
                                </td>
                                <td style={{ color: "#fff", fontWeight: 600 }}>
                                    <span onClick={() => sortChange('price')}>Price</span>
                                </td>
                                <td style={{ color: "#fff", fontWeight: 600 }}>
                                    <span onClick={() => sortChange('stock')}>Stock</span>
                                </td>
                                <td style={{ color: "#fff", fontWeight: 600, width: 100 }}>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.products.filter((item) => {
                                    // if (item.pstatus === 0) {
                                        if (showstock === 'all_active') {
                                            if (search.length >= 3) {
                                                return String(item.product_name).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '0' || String(item.product_code).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '0'
                                            }
                                            return (item.pstatus === '0')
                                        } else 
                                        if (showstock === 'active_inactive') {
                                            if (search.length >= 3) {
                                                return (String(item.product_name).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '0' || String(item.product_name).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '2') || (String(item.product_code).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '0' || String(item.product_code).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '2')
                                            }
                                            return (item.pstatus === '0' || item.pstatus === '2')
                                        } else 
                                        if (showstock === 'stock') {
                                            if (search.length >= 3) {
                                                return (String(item.product_name).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '0' && item.stock !== 0 || String(item.product_name).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '2' && item.stock !== 0) || (String(item.product_code).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '0' && item.stock !== 0 || String(item.product_code).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '2' && item.stock !== 0)
                                            }
                                            return (item.pstatus === '0' && item.stock !== 0) || (item.pstatus === '2' && item.stock !== 0) 
                                        } else 
                                        if (showstock === 'inactive') {
                                            if (search.length >= 3) {
                                                return (String(item.product_name).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '2') || (String(item.product_code).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '2')
                                            }
                                            return (item.pstatus === '2' )
                                        } else 
                                        if (showstock === 'zero_stock') {
                                            if (search.length >= 3) {
                                                return (String(item.product_name).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '2' && item.stock === 0) || (String(item.product_code).toLowerCase().includes(String(search).toLowerCase()) && item.pstatus === '2' && item.stock === 0)
                                            }
                                            return (item.pstatus === '0' && item.stock === 0) 
                                        }
                                        // return item
                                    // }
                                }).map((item, key) => {
                                    return (
                                        <tr loading='lazy' key={key}>
                                            <td onKeyDown={(event) => {
                                                event.preventDefault();
                                                console.log(event)
                                            }} style={{ padding: 0, width: 50, height: 50 }}>
                                                <img src={ENV.IMG_URL + item.product_image} style={{ width: 50, height: 50 }} />
                                            </td>
                                            <td>
                                                <div>{item.cat_name} / {item.subcat_name}</div>
                                                <div>Brand: {item.brand_name}</div>
                                            </td>
                                            {/* <td>{item.product_code}</td> */}
                                            <td>
                                                <div style={{fontWeight: "bolder", fontSize: 15}}>{item.product_name}</div>
                                                <div style={{fontSize: 15}}>UPC: {item.product_code}</div>
                                                {/* <div>
                                                    {
                                                        item.pstatus === 0 ?
                                                            (
                                                                <span style={{ color: "green", fontWeight: 600 }}>ACTIVE</span>
                                                            )
                                                            : null
                                                    }
                                                    {
                                                        item.pstatus === 1 ?
                                                            (
                                                                <span style={{ color: "red", fontWeight: 600 }}>INACTIVE</span>
                                                            )
                                                            : null
                                                    }
                                                    {
                                                        item.pstatus === 2 ?
                                                            (
                                                                <span style={{ color: "red", fontWeight: 600 }}>INACTIVE</span>
                                                            )
                                                            : null
                                                    }
                                                </div> */}
                                            </td>
                                            <td style={{textAlign: "center", fontSize: 15, fontWeight: "bolder"}}>{Number(item.unit_price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                            <td style={{textAlign: "center", fontSize: 15, fontWeight: "bolder"}}>{item.stock}</td>
                                            <td>
                                                <span style={{ display: "flex", flexDirection: "row", gap: 5, alignItems: "center", justifyContent: "flex-end" }}>
                                                    <View item={item} products={props.products} setProducts={props.setProducts} />
                                                    {
                                                        userstatus?.dashboard_announcement_send !== 0 ?
                                                            (
                                                                <>
                                                                    {
                                                                        userstatus?.product_add !== 0 && userstatus?.product_edit !== 0 ?
                                                                            (
                                                                                // productlist={products} setProducts={setProducts}
                                                                                <FormPanel btn={"edit"} item={item} products={props.products} setProducts={props.setProducts} />
                                                                            ) : null
                                                                    }
                                                                    {/* {
                                                                        userstatus?.product_delete !== 0 ?
                                                                            (
                                                                                <Delete item={item} />
                                                                            ) : null
                                                                    } */}
                                                                </>
                                                            )
                                                            : null
                                                    }
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                }).slice(0, page) //page
                            }
                        </tbody>
                    </table>
                    <div className="dataTables_info" id="data-tables_info" role="status" aria-live="polite">
                        {
                            search.length >= 3 ?
                                <>Showing 1 to {totalrecord <= page ? totalrecord : page} of {totalrecord} entries (filtered from {props.products.filter((item) => {
                                    if (item.pstatus === 0) {
                                        return item
                                    }
                                }).length} total entries)</>
                                :
                                <>Showing 1 to {page} of {props.products.filter((item) => {
                                    if (item.pstatus === 0) {
                                        return item
                                    }
                                }).length} entries</>
                        }
                    </div>

                </div>
            </div>
        )
    }

}

export default Table;

import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useMemo, useState } from 'react';
import AddPeymentForm from './AddPeymentForm';
import AddShippingPanel from './AddShippingPanel';
import AddCustomerForm from './AddCustomerForm';
import CustomerList from './CustomerList';
import axios from 'axios';
import ENV from '../../ENV';
import CustomerReviewDetails from './CustomerReviewDetails';

const PaymentReservePlanel = (props) => {
    const [visible, setVisible] = useState(false);
    const SubTotal = props?.salesoper?.reduce((amount, item) => amount = Number(item.qty * item.unit_srp) + amount, 0);
    // const [inputvalue, setInputValue] = useState({
    //     "id_customer": Number,
    //     "customer_name": String,
    //     "method": String,
    //     "note": String,
    //     "receipt_no": String,
    //     "courier": String,
    //     "fee": Number,
    //     "shipping_address": String,
    //     "current_address": Boolean
    // })

    const OpenPanel = () => {
        // console.log(Number(props.customer.id_customer))
        if (Number(props?.customer?.id_customer)!==0) {
            props.setInputValue(prev => ({...prev, ...props.inputvalue, ...props.customer, "item": [...props.salesoper]}))
        } else {
            props.setInputValue(prev => ({...prev, ...props.inputvalue, "item": [...props.salesoper]}))
        }
        setVisible(true)
    }

    const placeOrder = async () => {
        // props.setInputValue(prev => ({...prev, ...props?.customer, "method":"Walk-in", "item": [...props.salesoper]}))
        if (props.inputvalue?.customer_name!=='') {
            var formData = new FormData();
            formData.append("PlaceOrder", 1);
            formData.append("inputvalue", JSON.stringify(props.inputvalue));
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'api/sales.php',
                data: formData,
            }).then((res) => {
                console.log(res.data)
                // setCustomerList([...res.data])
            });
        }






        // console.log(inputvalue, {"item":props?.salesoper})
    }

    const reserveOrder = () => {
        console.log(...props.inputvalue, [...props.salesoper])
    }


    // useMemo(async()=>{
    //     await props.setInputValue(prev=>({...prev, ...props?.customer}))
    // }, [props]);




    return (
        <div style={{flexGrow: 1}}>
            <Sidebar
            showCloseIcon={false}
            blockScroll={true} dismissable={false} visible={visible} style={{width: window.innerWidth === 1920 ? "25%" : "30%"}} position="right" onHide={() => setVisible(false)}>
                <div style={{position: "relative", height: "93vh"}}>
                    {/* <div>
                        <div>
                            <span style={{fontSize:15}}>Reserve</span>
                        </div>
                    </div> */}
                    <div style={{padding: 5, border: "1px solid #ddd", borderRadius: 7, display: "flex", flexDirection: "column", gap: 10}}>
                        <div>
                            <label style={{fontSize: 15}}>Customer</label>
                            <div style={{display: " flex", flexDirection: "row", gap: 5}}>
                                <input name='customer_name' onChange={(e)=>props.setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={props?.inputvalue?.customer_name} style={{ fontSize: 20, padding: 5, width:"100%", height: 45, borderRadius: 5, border:"1px solid #ddd"}} />
                                {/* <button style={{ width: 45, height: 45, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center" }}><i className='pi pi-eye' style={{fontSize: 18}} /></button> */}
                                {/* <button style={{width: 45, height: 45, borderRadius: 5, border: "1px solid #ddd"}}><i className='pi pi-plus' style={{fontSize: 18}} /></button> */}
                                <CustomerReviewDetails inputvalue={props.inputvalue} setInputValue={props?.setInputValue} />
                                <CustomerList setInputValue={props?.setInputValue} />
                                <AddCustomerForm setInputValue={props?.setInputValue} />
                            </div>
                        </div>
                        {/* <div>
                            <label style={{fontSize: 15}}>Contact No.</label>
                            <input style={{ fontSize: 20, padding: 5, width:"100%", height: 45, borderRadius: 5, border:"1px solid #ddd"}} />
                        </div> */}
                        {/* <div>
                            <label style={{fontSize: 15}}>Address</label>
                            <input style={{ fontSize: 20, padding: 5, width:"100%", height: 45, borderRadius: 5, border:"1px solid #ddd"}} />
                        </div> */}
                        <div>
                            <label style={{fontSize: 18}}>Note</label>
                            <textarea name='note' onChange={(e)=>props.setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={props?.inputvalue?.note} style={{ fontSize: 20, padding: 5, width:"100%", height: 50, borderRadius: 5, border:"1px solid #ddd"}} />
                        </div>
                        <div>
                            <label style={{fontSize: 18}}>Receipt No. / Sale Invoice No. / DR Number:</label>
                            <input name='receipt_no' onChange={(e)=>props.setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={props?.inputvalue?.receipt_no} style={{ fontSize: 20, padding: 5, width:"100%", height: 45, borderRadius: 5, border:"1px solid #ddd"}} />
                        </div>
                    </div>
                    <hr />
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10}}>
                        <select name='method' onChange={(e)=>props?.setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} style={{fontSize: 20, width: 200, height: 40, borderRadius: 5}}>
                            <option value={"Walk-in"}>Walk-in</option>
                            <option value={"Shopee"}>Shopee</option>
                            <option value={"Online"}>Online</option>
                            <option value={"Home Credit"}>Home Credit</option>
                        </select>
                        <div>
                            <AddShippingPanel inputvalue={props?.inputvalue} setInputValue={props?.setInputValue} />
                        </div>
                        {/* <div style={{fontSize: 20, display: "flex", gap: 5}}>
                            <label style={{display: "flex", gap: 10}}><input type='radio' name='method' value={"Walk-in"} onChange={(e)=>props?.setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} checked={props?.inputvalue?.method === 'Walk-in' ? true : false} style={{width: 18, height: 18}} /> Walk-in </label>
                        </div>
                        <div style={{fontSize: 20, display: "flex", gap: 5}}>
                            <label style={{display: "flex", gap: 10}}><input type='radio' name='method' value={"Shopee"} onChange={(e)=>props?.setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} checked={props?.inputvalue?.method === 'Shopee' ? true : false} style={{width: 18, height: 18}} /> Shopee</label>
                        </div>
                        <div style={{fontSize: 20, display: "flex", gap: 5}}>
                            <label style={{display: "flex", gap: 10}}><input type='radio' name='method' value={"Online"} onChange={(e)=>props?.setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} checked={props?.inputvalue?.method === 'Online' ? true : false} style={{width: 18, height: 18}} /> Online</label>
                        </div>
                        <div style={{fontSize: 20, display: "flex", gap: 5}}>
                            <label style={{display: "flex", gap: 10}}><input type='radio' name='method' value={"Home Credit"} onChange={(e)=>props?.setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} checked={props?.inputvalue?.method === 'Home Credit' ? true : false} style={{width: 18, height: 18}} /> Home Credit</label>
                        </div> */}
                    </div>
                    <hr />
                    <div style={{textAlign: "right"}}>
                        <div style={{fontSize: 35, fontWeight: 600}}>
                            <label style={{fontSize: 22}}>Total Due: {Number(SubTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>
                        </div>
                        <div style={{fontSize: 35, fontWeight: 600}}>
                            <label style={{fontSize: 22, color: "green"}}>Amount Paid: 0.00</label>
                            {/* {Number(SubTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                        </div>
                        <div style={{fontSize: 35, fontWeight: 600, display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                            <span style={{minWidth: 250, borderRadius: 5, border: "1px solid #ddd"}}>
                                <label style={{fontSize: 22, color: "red"}}>Balance: 0.00</label>
                                {/* {Number(SubTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                            </span>
                        </div>
                    </div>

                    <div style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", position: "absolute", bottom: 0}}>            
                        <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                            <AddPeymentForm inputvalue={props?.inputvalue} setInputValue={props?.setInputValue} />
                        </div>            
                        <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "stretch"}}>                            
                            <div onClick={()=>placeOrder()} style={{ cursor: "pointer", fontSize: 15, borderRadius: 5, flexGrow: 1, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#418429", height: 40 }}>
                                <span style={{ fontSize: 20, color: "#fff" }}>Place Order</span>
                            </div>
                            <div onClick={()=>reserveOrder()} style={{ cursor: "pointer", fontSize: 15, borderRadius: 5, flexGrow: 1, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#23709e", height: 40 }}>
                                <span style={{ fontSize: 20, color: "#fff" }}>Reserve</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
            <div
                onClick={() => OpenPanel()} style={{
                    width: props?.status_customer === 'waiting' ? 80 : "100%",
                    cursor: "pointer",
                    textAlign: "center",
                    backgroundColor: "rgb(104 169 81)",
                    height: props?.status_customer === 'waiting' ? 30 : 45,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 600,
                    fontSize: 15,
                    border: "1px solid #ddd",
                    color: "#fff",
                    flexGrow: 1,
                    borderRadius: props?.status_customer === 'waiting' ? 5 : null
                }}>
                {
                    props?.status_customer === 'waiting' ?
                        <>select</>
                        :
                        <>Payment / Reserve</>
                }
            </div>
        </div>
    );
}

export default PaymentReservePlanel;

import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';

const PrintReceipt = (props) => {
    const [visibleRight, setVisibleRight] = useState(false);
    return (
        <>
            <Sidebar showCloseIcon={false} blockScroll={true} visible={visibleRight} position="right" onHide={() => setVisibleRight(false)} style={{ width: "25%" }}>

            </Sidebar>
            
            <div className='btn btn-info' onClick={()=>setVisibleRight(true)} style={{ width: "50%" }}>Print</div>
        </>
    );
}

export default PrintReceipt;

import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useMemo, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import swal from 'sweetalert';
import OverlayPanelDefective from './OverlayPanelDefective';

const InputDefectivePanel = (props) => {
    const [visible, setVisible] = useState(false);
    const [inputvalue, setInputValue] = useState(Object);
    const [user, setUser] = useState([]);

    const SaveBtn = (item) => {
        console.log(inputvalue.reason)
        const index = props.salesoper.findIndex((i)=>{
            return i.sales_operation === item.sales_operation
        })
        if (inputvalue.reason.trim() && Number(inputvalue.checker_id)!==0) {
            props.salesoper[index].reason = String(inputvalue.reason);
            props.salesoper[index].checker_id = Number(inputvalue.checker_id);
            
            props.setSalesOperation([...props.salesoper])
            console.log(index, props.salesoper, inputvalue.reason.trim())
            setVisible(false)
        } else {            
            swal({
                title: "Warning!",
                text: "Please complete the Details",
                icon: "info",
                button: false,
                timer: 1000
            })
        }
    }

    const ClearBtn = (item) => {
        const index = props.salesoper.findIndex((i)=>{
            return i.sales_operation === item.sales_operation
        })
        props.salesoper[index].reason = String("");
        props.salesoper[index].selected_row = false;
        props.salesoper[index].checker_id = 0;
        props.setSalesOperation([...props.salesoper])
        setVisible(false)

    }

    const OpenPanel = (status, item) => {
        setVisible(status)
        setInputValue({...item})
    }

    useEffect(async()=>{
        var formData = new FormData();
        formData.append("employee", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/sales.php',
            data: formData,
        }).then((res) => {
            setUser([...res.data])
        });
    }, [ENV])




    return (
        <>
            <Dialog 
            draggable={false}
            header={
                <>
                        <div style={{fontSize: 18}}>
                            {props._item.product_name}
                        </div>
                        <div>
                            {
                                props._item.no_serial === '0' ?
                                    <span style={{fontSize: 18}}>SN: {props._item.serial_number}</span>
                                    : null
                            }
                        </div>
                </>
            }
            footer={
                String(props?._item?.reason) !== "" && Number(props?._item?.checker_id) !== 0 ? 
                <>
                    <button onClick={() => ClearBtn(props._item)} style={{ borderRadius: 5, width: 150, border: "1px solid #ddd", fontWeight: "bolder", fontSize: 18, color: "#fff", height: 40, backgroundColor: "rgb(181, 88, 88)" }}>Clear</button>
                    <button onClick={() => SaveBtn(props._item)} style={{ borderRadius: 5, width: 150, border: "1px solid #ddd", fontWeight: "bolder", fontSize: 18, color: "#fff", height: 40, backgroundColor: "rgb(80 82 156)" }}>Save Change</button>
                </>
                :
                <>
                        {
                            String(inputvalue.reason) !== "" && Number(inputvalue.checker_id) !== 0 ?
                                <button onClick={() => SaveBtn(props._item)} style={{ borderRadius: 5, width: 100, border: "1px solid #ddd", fontWeight: "bolder", fontSize: 18, color: "#fff", height: 40, backgroundColor: "rgb(80 82 156)" }}>Save</button>
                                :
                                <button style={{ cursor: "no-drop", borderRadius: 5, width: 100, border: "1px solid #ddd", fontWeight: "bolder", fontSize: 18, color: "#fff", height: 40, backgroundColor: "#ddd" }}>Save</button>
                        }
                </>
            }
            blockScroll={true} dismissable={false} visible={visible} style={{width: "30%", maxHeight: '70%'}} position="center" onHide={() => setVisible(false)}>
                

                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    <div>
                        <span style={{fontSize: 18, fontWeight: "bolder"}}>Reason:</span>
                        <textarea rows={10} name='reason' onChange={(e)=>setInputValue(prev => ({...prev, [e.target.name]: e.target.value}))} value={inputvalue.reason} className='form-control' style={{padding: 5, fontSize: 15}}></textarea>
                    </div>
                    <div>
                        <span style={{fontSize: 18, fontWeight: "bolder"}}>Checker:</span>
                        <select className='form-control' name='checker_id' onChange={(e)=>setInputValue(prev => ({...prev, [e.target.name]: e.target.value}))} value={inputvalue.checker_id}>
                            <option value={0}>Select...</option>
                            {
                                [...user].map((item, key)=>{
                                    return (<option key={key} value={item.user_id}>{item.firstname}</option>)
                                })
                            }
                        </select>
                    </div>
                    <div>
                        <span style={{fontSize: 18, fontWeight: "bolder"}}>Upload File:</span>
                        <input type='file' />
                    </div>
                </div>


            </Dialog>
        
            {
                props?._item?.set_defective ?
                (
                    props?._item?.reason === "" ?
                        <div onClick={() => OpenPanel(true, props._item)} style={{ cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", textDecoration: "underline", color: "red" }}>Input Reason</div>
                        : (
                            props?._item?.reason !== "" ?
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10 }}>
                                    <OverlayPanelDefective _item={props._item} salesoper={props.salesoper} setSalesOperation={props.setSalesOperation}/>
                                    <div onClick={() => OpenPanel(true, props._item)} style={{ cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", textDecoration: "underline", color: "red" }}>Edit Reason</div>
                                </div>
                                : null
                        )
                )
                :
                null
            }
        </>
    );
}

export default InputDefectivePanel;

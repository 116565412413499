import React, { useMemo, useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import ENV from '../../ENV';
import StockTable from '../stock/StockTable';
import axios from 'axios';
import ShowDetails from './showDetails';
import { format } from 'date-fns';

const PanelAddStock = ({ rowData, setOpenPanel, products, productlist, setProducts, ProductSync, show_stock_status }) => {
    const [date, setDate] = useState(null);
    console.log(rowData)
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [input, setInput] = useState({ unit_price: rowData?.unit_price });
    const [categorylist, setCatgoryList] = useState([]);
    const [supplierlist, setSupplierList] = useState([]);
    const [stocklist, setStockList] = useState(rowData);
    const [stocks, setStocks] = useState([])

    const countries = [
        { name: 'Australia', code: 'AU' },
        { name: 'Brazil', code: 'BR' },
        { name: 'China', code: 'CN' },
        { name: 'Egypt', code: 'EG' },
        { name: 'France', code: 'FR' },
        { name: 'Germany', code: 'DE' },
        { name: 'India', code: 'IN' },
        { name: 'Japan', code: 'JP' },
        { name: 'Spain', code: 'ES' },
        { name: 'United States', code: 'US' }
    ];

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            console.log(option)
            return (
                <div className="flex align-items-center">
                    {/* <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} /> */}
                    <div>{option.cat_name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} /> */}
                <div>{option.cat_name}</div>
            </div>
        );
    };

    useMemo(async () => {
        // (async () => {
        var formData = new FormData();
        formData.append("supplierList", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'product.php',
            // url: 'http://192.168.1.15/stgc/product.php',
            data: formData,
        }).then((res) => {
            setSupplierList(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
        // })
    }, [ENV]);

    useMemo(async () => {
        // (async () => {
        var formData = new FormData();
        formData.append("categoryList", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'product.php',
            // url: 'http://192.168.1.15/stgc/product.php',
            data: formData,
        }).then((res) => {
            setCatgoryList(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
        // })
    }, [ENV]);

    useMemo(async () => {
        // (async () => {
        var formData = new FormData();
        formData.append("stock_list", 1);
        formData.append("product_id", rowData.id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'product.php',
            // url: 'http://192.168.1.16/stgc/product.php',
            data: formData,
        }).then((res) => {
            // setStockList(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
        // })
    }, [ENV]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("stockList", 1);
        formData.append("product_id", rowData?.id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            setStocks(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    const StockListSync = async () => {
        var formData = new FormData();
        formData.append("stockList", 1);
        formData.append("product_id", rowData?.id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            setStocks(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const enterSerial = async (e, _e) => {
        // console.log(e.key)
        if (e.key == 'Enter') {
            alert(e.target.value)
        }
    }


    const Stockin = async (e) => {


        // const index = productlist?.findIndex(x => x.id == rowData.id);
        // // productlist[index].list[_index].serial_code = serial;

        // const data = {
        //     "stock_id": 66895,
        //     "product_id": 4049,
        //     "serial_code": "12313241324fffff",
        //     "supplier_name": "ITI",
        //     "supplier_invoice": "337336",
        //     "date_purchace": "2023-08-17",
        //     "date_encoded": "2023-08-22",
        //     "time_encode": "11:50:33",
        //     "stock_qty": 1,
        //     "no_serial": 0
        // }
        // productlist[index].list = [...productlist[index].list, data];
        // // console.log(rowData.id, productlist)

        // // console.log('sample 2 ',productlist[index])
        // setStockList(productlist[index])
        // setProducts(productlist)
        // console.log(productlist[index])


        console.log(input)
        // return;
        var formData = new FormData();
        formData.append("newstockin", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("product_id", rowData.id);
        formData.append("cat_id", input?.category?.id_category);
        formData.append("supplier_name", input?.supplier);
        formData.append("invoice", input.invoice);
        formData.append("stock_qty", rowData?.no_serial == 0 ? 1 : input.quantity);
        formData.append("serial", input.serial);
        formData.append("date_purchace", input.date_purchace);
        if (String(input.serial) != '' && String(input.invoice) != '' && Number(input.quantity) != 0 && String(input.date_purchace) != '') {
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'product.php',
                // url: "http://192.168.1.16/stgc/product.php",
                data: formData,
            }).then((res) => {
                // setCatgoryList(res.data)
                // console.log(res.data)
                StockListSync()
                ProductSync()
                setInput({
                    "supplier": input?.supplier,
                    "invoice": input.invoice,
                    "date_purchace": input.date_purchace,
                    "quantity": rowData?.no_serial == 0 ? 1 : 0,
                    "serial": ""
                })
                // setDate(null)
            })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            if (String(input.serial) == '') {
                
            } else if (String(input.invoice) == '') {
                
            } else if (Number(input.quantity) == 0) {
                
            } else if (String(input.date_purchace) == '') {
                
            }
        }
    }
    // console.log('sample ', stocklist)

    const totalStock = stocks?.reduce((total, item) => total = total + Number(item.stock_qty), 0);
    // console.log(input)

    return (
        <div className=''>
            <div className='col-sm-12' style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Button icon="pi pi-chevron-left" label="Back" style={{ borderColor: "red", color: "red" }} outlined className="mr-2" onClick={() => setOpenPanel(null)} />
            </div>
            {
                rowData?.action != 'details' ?
                    <div className='col-sm-3'>
                        <Card>
                            <div className="form-group">
                                <select name='supplier' onChange={(e)=> setInput({ ...input, [e.target.name]: e.target.value })} style={{height: 40, borderRadius:10}}>
                                    <option selected disabled>Select Supplier</option>
                                    {
                                       supplierlist.map((item)=>{
                                            return (
                                                <option value={item.supplier_name}>{item.supplier_name}</option>
                                            )
                                       }) 
                                    }
                                </select>
                                {/* <Dropdown name='supplier' value={input.supplier} onChange={(e) => {
                                    setSelectedCountry(e.target.value)
                                    setInput({ ...input, [e.target.name]: e.target.value })
                                }} options={supplierlist} optionLabel="name" placeholder="Select Supplier"
                                    filter valueTemplate={(option, props) => {
                                        if (option) {
                                            return (
                                                <div className="flex align-items-center">
                                                    
                                                    <div>{option.supplier_name}</div>
                                                </div>
                                            );
                                        }

                                        return <span>{props.placeholder}</span>;
                                    }} itemTemplate={(option) => {
                                        return (
                                            <div className="flex align-items-center">
                                                
                                                <div>{option.supplier_name}</div>
                                            </div>
                                        );
                                    }} className="" style={{ width: "100%" }} /> */}
                            </div>
                            <div className="form-group">
                                <label>Supplier Invoice / Receipt</label>
                                <div>
                                    <InputText name='invoice' onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} value={input.invoice} style={{ width: "100%" }} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Date Purchase (From Supplier)</label>
                                <div>
                                    <Calendar name='date_purchace' style={{ width: "100%" }} value={date} onChange={(e) => {
                                        setDate(e.value)
                                        setInput({ ...input, [e.target.name]: format(e.value, 'yyyy') + '-' + format(e.value, 'MM') + '-' + format(e.value, 'dd') })
                                        // new Date(e.value).getFullYear()
                                    }} />
                                </div>
                            </div>
                            {
                                rowData?.no_serial != 0 ?
                                    <div className="form-group">
                                        <label>Quantity</label>
                                        <div>
                                            <InputText type='number' name='quantity' onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} value={input.quantity} style={{ width: "100%" }} />
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <div className="form-group">
                                <label>Serial No. / Batch</label>
                                <div>
                                    <InputText onKeyPress={(e) => enterSerial(e)} name='serial' value={input.serial} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} style={{ width: "100%" }} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Price SRP</label>
                                <div>
                                    <InputText name='unit_price' style={{ width: "100%" }} value={rowData?.unit_price} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div>
                                    <Button label="Submit" onClick={(e) => Stockin(e)} />
                                </div>
                            </div>
                        </Card>
                    </div>
                    :
                    null
            }
            <div className={rowData?.action != 'details' ? 'col-sm-9' : 'col-sm-12'}>
                <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                    {
                        rowData?.action != 'details' ?
                            <Card style={{ padding: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                    <div style={{ padding: 5, backgroundColor: "#9ebff3", borderRadius: 5 }}>
                                        <span style={{ fontSize: 15, fontWeight: 600 }}>SRP: {Number(rowData.unit_price).toFixed(2)}</span>
                                    </div>
                                    <div style={{ padding: 5, backgroundColor: "#9ebff3", borderRadius: 5 }}>
                                        <span style={{ fontSize: 15, fontWeight: 600 }}>Cash Price: {Number(rowData.cash_price).toFixed(2)}</span>
                                    </div>
                                    <div style={{ padding: 5, backgroundColor: "#9ebff3", borderRadius: 5 }}>
                                        <span style={{ fontSize: 15, fontWeight: 600 }}>Cost Price: {Number(rowData.cost_price).toFixed(2)}</span>
                                    </div>
                                </div>
                            </Card>
                            :
                            null
                    }
                    <Card >

                        <div className='' style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 15 }}>
                            <div style={{ width: 200, height: 200 }}>
                                {/* {ENV?.IMG_URL+rowData?.product_image} */}
                                {/* <ShowDetails rowData={rowData} /> */}
                                {/* <div style={{ width: 200, height: 200, backgroundImage: 'url('+ENV?.IMG_URL+rowData?.product_image+')', backgroundRepeat: 'no-repeat', backgroundSize: "cover", backgroundPosition: 'center' }}></div> */}
                                <img style={{width: 200, height: 200}} src={ENV.IMG_URL+rowData.product_image} alt={rowData.product_image} className="shadow-2 border-round" />
                                {/* <img style={{width:"100%", height: "100%", imageResolution:""}} src={`https://stgc.tech/pages/${rowData?.product_image}`} alt={rowData?.product_image} className="shadow-2 border-round" /> */}
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                <div>
                                    <label style={{ fontSize: 18 }}>{rowData?.product_name}</label>
                                </div>
                                <div>
                                    <span style={{ fontSize: 13 }}><span><b>UPC:</b></span> {rowData?.product_code}</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                    <span style={{ fontSize: 13 }}><b>Brand Name:</b> </span> <span style={{ fontSize: 13 }}>{rowData?.brand_name}</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                    <span style={{ fontSize: 13 }}><b>Category:</b></span>
                                    {
                                        rowData?.subcat_name != '' ?
                                            <span style={{ fontSize: 13 }}>{rowData?.cat_name} / {rowData?.subcat_name}</span>
                                            :
                                            <span style={{ fontSize: 13 }}>{rowData?.cat_name}</span>
                                    }
                                </div>
                                <div>
                                    <span style={{ fontSize: 13 }}><b>Warranty:</b> {rowData?.warranty}</span>
                                </div>
                                {
                                    rowData?.action == 'details' ?
                                    <div>
                                        <span style={{ fontSize: 13 }}><b>SRP:</b> {rowData?.unit_price}</span>
                                    </div>
                                    :null
                                }
                                <div>
                                    <span style={{ fontSize: 13 }}><b>OnStock:</b> {totalStock}</span>
                                </div>
                                <div style={{ fontSize: 13 }}><label>Keywords:</label>
                                    <div style={{ padding: 0, display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 3 }}>
                                        {/* {
                                        rowData?.keyword
                                    } */}
                                        {
                                            rowData?.keyword?.map((item) => {
                                                return (
                                                    <div style={{ paddingLeft: 10, border: "1px solid #b3b3b3", paddingRight: 10, backgroundColor: "#ddd", borderRadius: 100 }}>{item}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {/* <div>
                                    <span style={{ fontSize: 15 }}>Keyword: 
                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap:5, flexWrap:"wrap"}}>
                                            {
                                                rowData?.keyword.map((item) => {
                                                    return (
                                                        <span style={{ padding: 5, paddingLeft:10, paddingRight:10, minWidth:50, textAlign:"center", fontSize:10, borderRadius: 100, backgroundColor: "#ddd" }}>{item}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </span>
                                </div> */}

                            </div>
                        </div>
                        <div>
                            <div>
                                <StockTable rowData={rowData} StockListSync={StockListSync} stocks={stocks} setStocks={setStocks} supplierlist={supplierlist} products={products} productlist={productlist} setProducts={setProducts} ProductSync={ProductSync} />
                            </div>
                        </div>




                    </Card>
                </div>
            </div>
        </div>
    );
}

export default PanelAddStock;

import React from 'react';

const Information = ({OnlySalesData}) => {
    return (
        <>
            <div style={{paddingLeft:10, paddingRight:10}}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: 5, paddingBottom: 5 }}>
                    <div>Sales No.:</div>
                    <div>
                        <b>{OnlySalesData?.sales_id?.padStart(10, '0')}</b>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: 5, paddingBottom: 5 }}>
                    <div>Client Name:</div>
                    <div>
                        {OnlySalesData?.customer_name}
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: 5, paddingBottom: 5 }}>
                    <div>Contact:</div>
                    <div>
                        {OnlySalesData?.contact}
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: 5, paddingBottom: 5 }}>
                    <div>Email Address:</div>
                    <div>
                        {OnlySalesData?.customer_email}
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: 5, paddingBottom: 5 }}>
                    <div>Address:</div>
                    <div>
                        {OnlySalesData?.address}
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: 5, paddingBottom: 5 }}>
                    <div>Note:</div>
                    <div>
                        {OnlySalesData?.note}
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: 5, paddingBottom: 5 }}>
                    <div>Receipt No.: {OnlySalesData?.receipt_no}</div>
                    <div>
                        {OnlySalesData?.sales_date}
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: 5, paddingBottom: 5 }}>
                    <div>Cashier: {OnlySalesData?.cashier}</div>
                    <div>
                        Assisted By: {OnlySalesData?.sellers}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Information;

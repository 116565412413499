import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

const HistoryBtn = ({OnlySalesData}) => {
    const [addcustomerdialog, setAddCustomerDialog] = useState(false);

    const hideCustomerDialog = () => {
        setAddCustomerDialog(false);
        // setSearchExistingCustomer(false)
    }

    const CustomerDialogFooter = (
        <React.Fragment>
            
        </React.Fragment>
    );
    return (
        <div>
            <Dialog visible={addcustomerdialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                <span style={{ fontSize: 25 }}>History</span>
            } modal className="p-fluid" footer={CustomerDialogFooter} onHide={hideCustomerDialog}>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center"}}>
                    <div style={{fontWeight:"bold", fontSize:15}}>Record: {OnlySalesData.shistory.length}</div>
                </div>
                <div style={{maxHeight:500, overflowY:"auto"}} className='hidescroll'>
                    <div style={{display:"flex", flexDirection:"column", gap:3}}>
                        {
                            OnlySalesData.shistory.map((item)=>{
                                return (
                                    <>
                                        {
                                            item.title === 'payment' && item.type === 'add' ?
                                            <div>
                                                <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                                    <div>{item.title}</div>
                                                    <div>{item.data.date}</div>
                                                </div>
                                                {/* {
                                                    item.title + ' ' + item.data.date
                                                } */}
                                            </div>
                                            :null
                                        }
                                        {
                                            item.title === 'payment' && item.type === 'remove' ?
                                            <div>
                                                {
                                                    item.title + ' ' + item.data.dateRemoved
                                                }
                                            </div>
                                            :null
                                        }
                                        {
                                            item.title === 'shipping' && item.type === 'add' ?
                                            <div>
                                                <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#bbbbbb", padding:5}}>
                                                    <div style={{fontWeight:600, fontSize:18, color:"#fff"}}>SHIPPING Added</div>
                                                    <div>{item.date_time}</div>
                                                </div>
                                                <div style={{backgroundColor:"#ddd", padding:5}}>
                                                    <div>Courier: {item.data.shipping_courier}</div>
                                                    <div>Fee: {item.data.shipping_fee}</div>
                                                    <div>Track No: {item.data.shipping_track}</div>
                                                    <div>Address: {item.data.shipping_address}</div>
                                                </div>
                                            </div>
                                            :null
                                        }
                                        {
                                            item.title === 'shipping' && item.type === 'update' ?
                                            <div>
                                                <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#bbbbbb", padding:5}}>
                                                    <div style={{fontWeight:600, fontSize:18, color:"#fff"}}>SHIPPING Updated</div>
                                                    <div>{item.date_time}</div>
                                                </div>
                                                <div style={{backgroundColor:"#ddd", padding:5}}>
                                                    <div>Courier: {item.data.shipping_courier}</div>
                                                    <div>Fee: {item.data.shipping_fee}</div>
                                                    <div>Track No: {item.data.shipping_track}</div>
                                                    <div>Address: {item.data.shipping_address}</div>
                                                </div>
                                            </div>
                                            :null
                                        }
                                        {
                                            item.title === 'shipping' && item.type === 'remove' ?
                                            <div>
                                                <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#bbbbbb", padding:5}}>
                                                    <div style={{fontWeight:600, fontSize:18, color:"#fff"}}>SHIPPING Removed</div>
                                                    <div>{item.date_time}</div>
                                                </div>
                                                <div style={{backgroundColor:"#ddd", padding:5}}>
                                                    <div>Courier: {item.data.shipping_courier}</div>
                                                    <div>Fee: {item.data.shipping_fee}</div>
                                                    <div>Track No: {item.data.shipping_track}</div>
                                                    <div>Address: {item.data.shipping_address}</div>
                                                </div>
                                            </div>
                                            :null
                                        }
                                        {
                                            item.title === 'item' && item.type === 'add' ?
                                            <div>
                                                <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#bbbbbb", padding:5}}>
                                                    <div style={{fontWeight:600, fontSize:18, color:"#fff"}}>ITEM Added</div>
                                                    <div>{item.date_time}</div>
                                                </div>
                                            </div>
                                            :null
                                        }
                                        {
                                            item.title === 'item' && item.type === 'remove' ?
                                            <div>
                                                <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#bbbbbb", padding:5}}>
                                                    <div style={{fontWeight:600, fontSize:18, color:"#fff"}}>ITEM Removed</div>
                                                    <div style={{fontWeight:600, fontSize:12, color:"#fff"}}>{item.date_time}</div>
                                                </div>
                                                <div style={{backgroundColor:"#ddd", padding:5}}>
                                                    <div style={{fontWeight:"bold", fontSize:15}}>{item.data.options ?? null}</div>                                                    
                                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                                        <div style={{width:"45%"}}>
                                                            <div>Product Name: {item.data.product_name}</div>
                                                            <div>Product Code: {item.data.product_code}</div>
                                                            <div>Serial: {item.data.serial_number}</div>
                                                        </div>
                                                        <div style={{width:"45%"}}>
                                                            <div>Qty: {item.data.sales_qty}</div>
                                                            <div>Date/Time: {item.data.date_time}</div>
                                                            <div>Removed By: {item.data.employee_name}</div>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            :null
                                        }
                                        {
                                            item.title === 'information' && item.type === 'update' ?
                                                <div>
                                                    <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#bbbbbb", padding:5}}>
                                                        <div style={{fontWeight:600, fontSize:18, color:"#fff"}}>UPDATE Information</div>
                                                        <div>{item.date_time}</div>
                                                    </div>
                                                    <div style={{backgroundColor:"#ddd", padding:5}}>                                                   
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-start"}}>
                                                            <div style={{width:"45%"}}>
                                                                <div>Customer Name: {item.data.customer_name}</div>
                                                                <div>Contact: {item.data.contact}</div>
                                                                <div>Email: {item.data.customer_email}</div>
                                                                <div>Contact Person: {item.data.customer_person}</div>
                                                            </div>
                                                            <div style={{width:"45%"}}>
                                                                <div>Tin: {item.data.tin}</div>
                                                                <div>Address: {item.data.address}</div>
                                                                <div>Updated By: {item.updatedby}</div>
                                                            </div>                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            :null
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                </div>



            </Dialog>
            <button onClick={()=>setAddCustomerDialog(true)} style={{ height: 40, backgroundColor: "#295bac", borderRadius: 8, border: "1px solid #ddd", color: "#fff" }}>History</button>
        </div>
    );
}

export default HistoryBtn;

import { Button } from 'primereact/button';
import React, { useState } from 'react';
import EducationBtn from './extra/EducationBtn';

const EducationTable = (props) => {
    const [schools, setSchools] = useState(props?.item?.schools.length > 0 ? props?.item?.schools : [])
    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <h3 className="font-semibold"> EDUCATION</h3>
                {
                    schools.length > 0 ? (
                        <EducationBtn btn="add" item={props?.item} schools={schools} setSchools={setSchools} employeelist={props.employeelist} setEmployeeList={props.setEmployeeList} />
                    ) : null
                }
            </div>
            <div className="content-list content-menu">
                {
                    schools.length > 0 ? (
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th style={{fontSize: 15}}>School Name</th>
                                    <th style={{fontSize: 15}}>Courses</th>
                                    <th style={{fontSize: 15}}>School Year</th>
                                    <th style={{width: 50}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    schools?.reverse().map((i, k) => {
                                        return (
                                            <tr key={k}>
                                                <td style={{fontSize: 15}}>{i.school_name}</td>
                                                <td style={{fontSize: 15}}>{i.course}</td>
                                                <td style={{fontSize: 15}}>{i.school_year}</td>
                                                <td>
                                                    <EducationBtn btn="row" row={i} item={props?.item} schools={schools} setSchools={setSchools} employeelist={props.employeelist} setEmployeeList={props.setEmployeeList} />    
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    ) : (
                        <div style={{width:"100%", backgroundColor: "#f5f5f5", height: 100, display:"flex", flexDirection: "row", justifyContent:"center", alignItems: "center"}}>
                            <EducationBtn btn="add" item={props?.item} schools={schools} setSchools={setSchools} employeelist={props.employeelist} setEmployeeList={props.setEmployeeList} />
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default EducationTable;

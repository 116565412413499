import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ENV from '../../ENV';
import { Toast } from 'primereact/toast';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import ItemDetails from './ItemDetails';
import TableGroupName from './TableGroupName';
import Header from '../Header';
import Navbar from '../Navbar';
import RemoveCookie from '../hooks/removeCookie';

const TableByCategory = ({ opentable, setOpenTable }) => {
    const [InventoryList, setInventoryList] = useState([])
    const [openrowdata, setOpenRowData] = useState({})
    const [fix, setFix] = useState(true)

    
    useEffect(()=> {
        RemoveCookie('PHPSESSID')
    }, [])

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth)
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth)
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, [])

        return (width <= 768)
    }


    useMemo(async () => {
        var formData = new FormData()
        formData.append("inventorylist", 1)
        formData.append("user_id", localStorage.getItem('user_id'))
        await axios({
            method: "post",
            // url: 'http://localhost/stgc.local/api/sales_report.php',
            url: ENV.DOMAIN + 'inventory.php',
            data: formData,
        }).then((res) => {
            setInventoryList(res.data)
            console.log(res.data)
        })
            .catch((err) => {
                console.log(err)
            })
    }, [ENV])

    const syncData = async () => {
        // console.log(Number(localStorage.getItem('user_id')), 'asdasdasd')
        var formData = new FormData()
        formData.append("inventorylist", 1)
        formData.append("user_id", Number(localStorage.getItem('user_id')))
        await axios({
            method: "post",
            // url: 'http://localhost/stgc.local/api/sales_report.php',
            url: ENV.DOMAIN + 'inventory.php',
            data: formData,
        }).then((res) => {
            setInventoryList(res.data)
            // console.log((res.data))
        })
            .catch((err) => {
                console.log(err)
            })
    }



    function setFixed() {
        if (window.scrollY >= 50) {
            // console.log(false)
            setFix(true)
        } else {
            setFix(true)
            // console.log(false)
        }
    }

    useMemo(() => {
        window.addEventListener('scroll', setFixed)
    }, [])

    return (






        <div className="vd_body">
            {/* Header Start */}
            {
                fix ?
                    <Header />
                    :
                    null
            }
            {/* Header Ends */}
            <div className={!useCheckMobileScreen() ? "content" : ""}>
                <div className="container">
                    {/* {
                        !useCheckMobileScreen() ?
                            <Navbar />
                            :
                            null
                    } */}
                    {/* Middle Content Start */}
                    {/* className={!useCheckMobileScreen() ? "vd_content-wrapper" : ""}  */}
                    {/* className={!useCheckMobileScreen() ? "vd_container" : ""} */}
                    {/* className={!useCheckMobileScreen() ? "vd_content clearfix" : ""} */}
                    {/* className={!useCheckMobileScreen() ? "vd_content-section clearfix" : ""} */}
                    <div style={{ minHeight: 1059 }}>
                        <div style={{ minHeight: 1059 }}>
                            <div>


                                <div>
                                    <div className={!useCheckMobileScreen() ? '' : "row"}>




                                        <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                            <div style={{ width: "100%" }}>
                                                <div style={{ backgroundColor: "#f0f0f0", transition: ".3s ease-n-out", position: "relative" }}>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                        <div style={{ padding: 10, fontSize: 18 }}>
                                                            {/* Acc.: <label>{localStorage.getItem('firstname')}</label> */}
                                                        </div>
                                                        <div style={{ padding: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5, paddingBottom: 10 }}>
                                                            <button onClick={() => setOpenTable('all')} style={{ border: "none", border: "1px solid rgb(167 167 167)", minWidth: 50, backgroundColor: opentable == 'all' ? "#30aba9" : null, color: opentable == 'all' ? "#fff" : null, borderRadius: 5 }}>All</button>
                                                            <button onClick={() => setOpenTable('by_cat')} style={{ border: "none", border: "1px solid rgb(167 167 167)", minWidth: 50, backgroundColor: opentable == 'by_cat' ? "#30aba9" : null, color: opentable == 'by_cat' ? "#fff" : null, borderRadius: 5 }}>By Category</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                InventoryList.length > 0 ?
                                                    <>
                                                        {
                                                            InventoryList.map((item, key) => {
                                                                return (
                                                                    <TableGroupName key={key} syncData={syncData} rowData={item} openrowdata={openrowdata} setOpenRowData={setOpenRowData} />
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <>Loading</>
                                            }
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* .vd_container */}
                    </div>
                    {/* .vd_content-wrapper */}
                    {/* Middle Content End */}
                </div>
                {/* .container */}
            </div>
            {/* .content */}
            {/* Footer Start */}
            {/* <footer className="footer-1" id="footer">
                <div className="vd_bottom ">
                    <div className="container">
                        <div className="row">
                            <div className=" col-xs-12">
                                <div className="copyright">
                                    Copyright ©2014 Venmond Inc. All Rights Reserved
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer> */}
        </div>
    );
}

export default TableByCategory;

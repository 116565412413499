import axios from 'axios';
import React, { useRef, useState } from 'react';
import swal from 'sweetalert';
import ENV from '../../../ENV';

const FileUpload = (props) => {
    const fileRef = useRef();

    // console.log(props.item.user_id)
    // console.log(props.item)
    // console.log(props.item.files)

    const [listfiles, setListFiles] = useState(props.item.files.length>0 ? props.item.files : [])


    const changeHandlerImage = async (event) => {        
        const formData = new FormData();
        formData.append("fileupload", 1);
        formData.append("user_id", props.item.user_id);
        formData.append("existing_file", JSON.stringify(props.item.files));
        let productimages = [];
        for (let i = 0; i < event.target.files.length; i++) {
            productimages.push(event.target.files[i]);
            formData.append('file[]', event.target.files[i])
        }
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/employee.php',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            const idx = props.employeelist.findIndex(i => i.user_id == props.item.user_id);                
            props.employeelist[idx].files = res.data;
            props.setEmployeeList(props.employeelist)

            console.log(res.data)
            swal({
                title: "Done!",
                text: "Successfully Uploaded",
                icon: "success",
                timer: 1000,
                button: false,
            }).then(() => {
                setListFiles(res.data)
                fileRef.current.value = ''
                return;
            });
        }).catch((err) => {

        });
    };

    const removeFile = async (file) => {        
        swal({
            title: "Are you Sure?",
            icon: "info",
            buttons: {
                confirm: "Yes sure",
                cancel: "No"
            }
        }).then(async(confirm) => {
            if(confirm){
                const data = listfiles.filter((f)=>f != file);
                const idx = props.employeelist.findIndex(i => i.user_id == props.item.user_id);
                props.employeelist[idx].files = data;
    
                const formData = new FormData();
                formData.append("updatefile", 1);
                formData.append("user_id", props.item.user_id);
                formData.append("file", JSON.stringify(data));

                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'api/employee.php',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    swal({
                        title: "Done!",
                        text: "Successfully Deleted",
                        icon: "success",
                        timer: 1000,
                        button: false
                    }).then(() => {
                        props.setEmployeeList(props.employeelist)
                        setListFiles(data)
                        return;
                    });
                }).catch((err) => {
        
                });
            } else {
                swal({
                    text: "Cancelled!",
                    icon: "error",
                    timer: 1000,
                    button: false
                })
            }
        });
    }

    // console.log(listfiles, props.employeelist)

    return (
        <div className="col-sm-12">
            <h3 className="mgbt-xs-15 font-semibold">FILES</h3>
            <div>
                <label className="btn fileinput-button">
                    <input ref={fileRef} type="file" name="files" onChange={(e)=>changeHandlerImage(e)} multiple />
                </label>
            </div>
            <div className>
                <div className="content-list">
                    <div data-rel="scroll">
                        <div className="" id="mCSB_6" style={{ position: 'relative', height: '100%', overflow: 'auto', maxWidth: '100%', maxHeight: 400 }}>
                            <div className="mCSB_container" style={{ position: 'relative', top: 0 }}>
                                <ul className="list-wrapper">
                                    {
                                        listfiles?.map((file, key) => {
                                            return (
                                                <li key={key}>
                                                    <span className="menu-icon vd_yellow">
                                                        <i className="fa fa-suitcase" />
                                                    </span>
                                                    <span className="menu-text">
                                                        <label style={{ fontSize: 15}}>{file.name}</label>
                                                        <span className="menu-info">
                                                            <span className="menu-date">
                                                                {file.datetime}
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <span class="menu-badge"><span class="badge vd_bg-red" onClick={()=>removeFile(file)}>Remove</span></span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FileUpload;

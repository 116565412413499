import React, { useEffect, useState, useMemo, useRef } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import Header from './component/Header';
import Navbar from './component/Navbar';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from './ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from "primereact/chips";
import ViewStockBtn from './component/product/ViewStockBtn';
import EditProductBtn from './component/product/EditProductBtn';
import DeleteProductBtn from './component/product/DeleteProductBtn';
import AddProductBtn from './component/product/AddProductBtn';
import ShowDetails from './component/product/showDetails';
import Table from './component/product/Table';
import FormPanel from './component/product/extra/product/FormPanel';


const Product = () => {
    document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix" data-active="dashboard ';
    document.body.id = 'dashboard';

    const [products, setProducts] = useState([]);
    const [productsforcat, setProductsforCat] = useState([]);
    const [search, setSearch] = useState('');
    // const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [productlist, setProductlist] = useState([]);

    const [openpanel, setOpenPanel] = useState(null)

    const [show_stock_status, setShowStockStatus] = useState(null)

    const [nodes, setNodes] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState({});

    useMemo(async () => {
        var formData = new FormData();
        formData.append("product_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            setProducts([...res.data])
            setProductsforCat([...res.data])
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    const searchData = (search, data) => {
        if (search != '') {
            data = data?.filter((i) => i?.product_name?.toLowerCase().includes(search?.toLowerCase()) || i?.product_code?.toLowerCase().includes(search?.toLowerCase()) || i?.keyword_search?.toLowerCase().includes(search?.toLowerCase()));
        }
        console.log(data)
        return data;
    }

    const ProductSync = async () => {
        var formData = new FormData();
        formData.append("product_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            setProducts([...res.data])
            setProductsforCat([...res.data])
        })
            .catch((err) => {
                console.log(err);
            });
    }

    useMemo(async () => {
        const mainData = Object.keys(selectedKeys).map((e) => {
            return productsforcat.filter((item) => {
                if (e.split('-')[0] != undefined && e.split('-')[1] != undefined) {
                    return item.id_category === e.split('-')[0] && item.subcat_id === e.split('-')[1]
                } else if (e.split('-')[1] != undefined) {
                    return item.id_category === e.split('-')[0]
                }
            });
        }).filter((item) => {
            if (item.subcat_id != 0) {
                if (item.id_category != 0) {
                    return item;
                }
            }
        }).slice(1);
        setProducts([].concat(...mainData).length > 0 ? [...[].concat(...mainData)] : productsforcat);
    }, [selectedKeys])

    return (
        <>
            <div className="vd_body">
                {/* Header Start */}
                {/* <Header /> */}
                {/* Header Ends */}
                <div className="content">
                    <div className="container">
                        <Navbar />
                        {/* Middle Content Start */}
                        <div className="vd_content-wrapper" style={{ minHeight: 1059 }}>
                            <div className="vd_container" style={{ minHeight: 1059 }}>
                                <div className="vd_content clearfix">
                                    {/* vd_head-section */}
                                    {/* <div className="vd_title-section clearfix" style={{ padding: 5 }}>
                                        <div className="vd_panel-header" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <span style={{ fontSize: 30, fontWeight: 600 }}>Product</span>
                                            <FormPanel btn={"add"} products={products} setProducts={setProducts} />
                                        </div>
                                    </div> */}
                                    <div className="vd_content-section clearfix" style={{ paddingTop: 0, minHeight: "100vh" }}>
                                        <div className="row">
                                            <Table openpanel={openpanel} setOpenPanel={setOpenPanel} products={searchData(search, products)} productlist={products} setProducts={setProducts} ProductSync={ProductSync} productsforcat={productsforcat} setProductsforCat={setProductsforCat} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} />
                                        </div>
                                    </div>
                                    {/* .vd_content-section */}
                                </div>
                                {/* .vd_content */}
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
                {/* .content */}
                {/* Footer Start */}
                <footer className="footer-1" id="footer">
                    <div className="vd_bottom ">
                        <div className="container">
                            <div className="row">
                                <div className=" col-xs-12">
                                    <div className="copyright">
                                        Copyright ©2024 STGC Inc. All Rights Reserved
                                    </div>
                                </div>
                            </div>{/* row */}
                        </div>{/* container */}
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Product;

import React, { useState, useEffect, useMemo } from 'react';
import { Tree } from 'primereact/tree';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { NodeService } from './NodeService';
import axios, { Axios } from 'axios';
import ENV from '../../../../ENV';

const Categories = (props) => {
    const [visible, setVisible] = useState(false);
    const [catlist, setCatList] = useState([]);
    const [search, setSearch] = useState('');
    // const [selectedKeys, setSelectedKeys] = useState({});
    

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("category_tree", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            setCatList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])


    const search_cat = (nodes, e) => {
        if (e !== '') {
            return nodes.filter((item) => item.label.toLowerCase().includes(e.toLowerCase()) )
            .map((item) => ({
                ...item,
                children: item?.children ? search_cat(item?.children, e) : [],
            }));
        }
        return nodes
    }

    
    console.log(search_cat(catlist, search))
    // return item.label.toLowerCase().includes(e.toLowerCase())         

    // const data = Object.keys(selectedKeys).map((e)=>{
    //     if (e.split('-')[1]!=0) {
    //         return [{"id_category": Number(e.split('-')[0]), "subcat_id": Number(e.split('-')[1] || 0)}]
    //     }
    // })

    // useEffect(async()=> {
    //     // props.products.map((item)=>{
    //     //     console.log(item)
    //     // });
    //     const mainData = Object.keys(selectedKeys).map((e)=>{
    //         return props.products.filter((item)=>{
    //             if (e.split('-')[0]!=undefined && e.split('-')[1]!=undefined) {
    //                 return item.id_category === e.split('-')[0] && item.subcat_id === e.split('-')[1]
    //             } else if (e.split('-')[1]!=undefined) {
    //                 return item.id_category === e.split('-')[0]
    //             }
    //         })
    //     })


    //     // const arr = Array()
    //     const datas =  mainData.filter((cat)=> {
    //         if (cat.subcat_id !== 0) {
    //             if (cat.id_category !== 0) {
    //                 return cat;
    //             }
    //         }
    //     }).slice(1);
    //     // const n = [...datas].map((item)=>JSON.stringify([...item]));
    //     console.log([].concat(...datas).length);

    //     // console.log([...datas].map((item)=>item))
    //     // console.log(JSON.stringify(datas))
    //     // console.log(...);

    //     // props.setProducts([].concat(...datas).length>0 ? [].concat(...datas) : props.products)
    //     // productsforcat={productsforcat} setProductsforCat={setProductsforCat}
    // }, [props, selectedKeys])

    // useMemo(async()=>{
    //     const mainData = Object.keys(selectedKeys).map((e)=>{
    //         return props.products.filter((item)=>{
    //             if (e.split('-')[0]!=undefined && e.split('-')[1]!=undefined) {
    //                 return item.id_category === e.split('-')[0] && item.subcat_id === e.split('-')[1]
    //             } else if (e.split('-')[1]!=undefined) {
    //                 return item.id_category === e.split('-')[0]
    //             }
    //         })
    //     }); 
    //     const datas =  mainData.filter((cat)=> {
    //         if (cat.subcat_id !== 0) {
    //             if (cat.id_category !== 0) {
    //                 return cat;
    //             }
    //         }
    //     }).slice(1);

    //     console.log([].concat(...datas).length, [].concat(...datas));
    //     // props.setProducts([].concat(...datas))

    // }, [props, selectedKeys])
    
    // console.log(selectedKeys, props.productsforcat)

    // console.log(selectedKeys ? JSON.stringify([selectedKeys], true) : '')
    // console.log(Object.keys([...selectedKeys]))
    // console.log(JSON.stringify([selectedKeys], true))

    // useEffect(() => {
    //     NodeService.getTreeNodes().then((data) => setCatList(data));
    // }, []);
    return (
        <>
            <Sidebar position={"right"} visible={visible} onHide={() => setVisible(false)} style={{width: 400}}>
                <h2>Categories</h2>
                {/* <div style={{padding: "5px 0px 5px 0px"}}>
                    <input placeholder='Search...' onChange={(e)=>setSearch(e.target.value)} style={{width: "100%", height: 40, fontSize: 18, padding: 5, borderRadius: 5, border: "1px solid #ddd"}} />
                </div> */}
                <div>
                    <Tree filter style={{fontSize: 15, width: 350}} value={catlist} selectionMode="checkbox" selectionKeys={props.selectedKeys} onSelectionChange={(e) => props.setSelectedKeys(e.value)} />
                </div>
            </Sidebar>

        
            {
                // JSON.stringify(Object.keys(props.selectedKeys).length)
                Object.keys(props.selectedKeys).length > 0 ?
                    <button style={{ position: "absolute", top: 5, left: 330, borderRadius: 100, zIndex: 1, width: 25, height: 25, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#ddd", color: "red", border:"1px solid red" }} onClick={()=>props.setSelectedKeys({})}><i className='pi pi-times' style={{fontWeight: "bolder"}} /></button>
                    : null
            }
            <Button label="Select Category" style={{width: Object.keys(props.selectedKeys).length > 0 ? 130 : null, textAlign: "left"}} className='form-control' icon="pi pi-list-check" onClick={() => setVisible(true)} />
        </>
    );
}

export default Categories;
import React, { useState } from 'react';
import JobExpPanel from './extra/JobExpPanel';

const JobExpTable = (props) => {
    const [experiences, setExperiences] = useState(props?.item?.experiences.length > 0 ? props?.item?.experiences : [])
    return (
        <>
            <h3 className="mgbt-xs-15 font-semibold">JOB EXPERIENCE</h3>
            <div className="content-list content-menu">
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th style={{fontSize: 15}}>Title</th>
                            <th style={{fontSize: 15}}>Position</th>
                            <th style={{fontSize: 15}}>Industry</th>
                            <th style={{fontSize: 15}}>Company Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            experiences?.reverse().map((i, k) => {
                                return (
                                    <tr key={k}>
                                        <td style={{fontSize: 15}}>{i.job_title}</td>
                                        <td style={{fontSize: 15}}>{i.job_position}</td>
                                        <td style={{fontSize: 15}}>{i.industries}</td>
                                        <td style={{fontSize: 15}}>{i.company_name}</td>
                                        <td><JobExpPanel item={props?.item} row={i} employeelist={props.employeelist} setEmployeeList={props.setEmployeeList} setExperiences={setExperiences} /></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default JobExpTable;

import axios from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import ENV from '../../ENV';
import { format } from 'date-fns';

const MiscellaneousBtn = ({ OnlySalesData, PreviewDetails }) => {
    const [addcustomerdialog, setAddCustomerDialog] = useState(false);
    const [productname, setProductName] = useState('');
    const [productcode, setProductCode] = useState('');
    const [serial, setSerial] = useState('');
    const [qty, setQty] = useState('');
    const [unitprice, setUnitPrice] = useState('');

    // console.log(OnlySalesData)
    const [submitted, setSubmitted] = useState(false);
    const [SearchExistingCustomer, setSearchExistingCustomer] = useState(false)
    const [customerinfo, setCustomerInfo] = useState(JSON.parse(sessionStorage.getItem('customerinfo')) ?? {})

    const Add = async () => {






        const data = [{
            "sales_stock_id": null,
            "sales_id": Number(OnlySalesData.sales_id),
            "product_id": 0,
            "product_name": productname,
            "product_code": productcode,
            "serial_number": serial,
            "stock_price": unitprice,
            "sales_qty": Number(qty),
            "warranty": "",
            "no_serial": 0,
            "options": JSON.stringify([{
                "name": 'Miscellaneous',
                "option": '',
                "note": ''
            }]),
            "history": JSON.stringify([{
                "sales_id": Number(OnlySalesData.sales_id),
                "user_id": Number(localStorage.getItem('user_id')),
                "employee_name": localStorage.getItem('firstname'),
                "particular": null,
                "note": null,
                "date_time": format(new Date(), 'yyyy-MM-dd HH:mm:ss')
            }]),
            "user_id": Number(localStorage.getItem('user_id')), 
            "remarks":""
        }]

        var formData = new FormData();
        formData.append("sales_report_item_miscellaneous", 1);
        formData.append("sales_id", Number(OnlySalesData.sales_id));
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("product_name", productname);
        formData.append("product_code", productcode);
        formData.append("serial", serial);
        formData.append("qty", qty);
        formData.append("unitprice", unitprice);
        formData.append("options", JSON.stringify([{ "name": "Miscellaneous", "option": "", "note": "" }]));
        formData.append("history", JSON.stringify([{
            "sales_id": Number(OnlySalesData.sales_id),
            "user_id": Number(localStorage.getItem('user_id')),
            "employee_name": localStorage.getItem('firstname'),
            "particular": null,
            "note": null,
            "date_time": format(new Date(), 'yyyy-MM-dd HH:mm:ss')
        }]));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'sales_report.php',
            data: formData,
        }).then((res) => {
            data[0].sales_stock_id = res.data;
            OnlySalesData.list = [...OnlySalesData?.list, ...data];
            console.log(OnlySalesData)
            PreviewDetails(OnlySalesData);
        });
    }
    const hideCustomerDialog = () => {
        // setSubmitted(false);
        setAddCustomerDialog(false);
        // setSearchExistingCustomer(false)
    }

    const CustomerDialogFooter = (
        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div></div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={hideCustomerDialog} />
                    <Button onClick={() => Add()} style={{ width: 100, fontSize: 18 }} label="Add" icon="pi pi-check" />
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <>
            <Dialog visible={addcustomerdialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                <span style={{ fontSize: 18 }}>Add Miscellaneous</span>
            } modal className="p-fluid" footer={CustomerDialogFooter} onHide={hideCustomerDialog}>
                <div>
                    <div>Product Name</div>
                    <input onChange={(e) => setProductName(e.target.value)} style={{ width: "100%", padding: 5, height: 35, borderRadius: 5, border: "1px solid #ddd" }} />
                </div>
                <div>
                    <div>Product Code</div>
                    <input onChange={(e) => setProductCode(e.target.value)} style={{ width: "100%", padding: 5, height: 35, borderRadius: 5, border: "1px solid #ddd" }} />
                </div>
                <div>
                    <div>Serial</div>
                    <input onChange={(e) => setSerial(e.target.value)} style={{ width: "100%", padding: 5, height: 35, borderRadius: 5, border: "1px solid #ddd" }} />
                </div>
                <div>
                    <div>Qty</div>
                    <input onChange={(e) => setQty(e.target.value)} style={{ width: "20%", padding: 5, height: 35, borderRadius: 5, border: "1px solid #ddd" }} />
                </div>
                <div>
                    <div>Unit Price</div>
                    <input onChange={(e) => setUnitPrice(e.target.value)} style={{ width: "30%", padding: 5, height: 35, borderRadius: 5, border: "1px solid #ddd" }} />
                </div>

            </Dialog>
            <button onClick={() => setAddCustomerDialog(true)} style={{ width: "50%" }}>Add Miscellaneous</button>
        </>
    );
}

export default MiscellaneousBtn;

import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import ViewStock from './ViewStock';
import FormPanel from './FormPanel';
import { Tooltip } from 'primereact/tooltip';
import ENV from '../../../../ENV';

const View = (props) => {
    
    const [visible, setVisible] = useState(false)
    const [visibleexpand, setVisibleExpand] = useState(null)

    const onVisible = (v) => {
        setVisible(v)
    }


    // console.log(visibleexpand)
    return (
        <>
            <Dialog 
            blockScroll={true}
            maximized={false}
            draggable={false} 
            // header={"Header"} 
            footer={
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 5}}>
                    <FormPanel btn={"edit"} setVisible={setVisible} item={props.item} products={props.products} setProducts={props.setProducts} />
                    <span onClick={()=>{
                        setVisibleExpand(true)
                        setVisible(false)
                    }} className='btn btn-sm btn-info'>Show with Stock</span>
                    <span onClick={()=>setVisible(false)} className='btn btn-sm btn-danger'>Close</span>
                </div>
            }
            position={"top"} 
            visible={visible} style={{ width: '40vw' }} onHide={() => setVisible(false)}>
                <div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                        <div style={{width: "50%"}}>
                            <img src={ENV.IMG_URL + props.item.product_image} style={{border:"1px solid #ddd"}} />
                        </div>
                        <div>
                            <div style={{fontSize: 15, padding: 5}}><span style={{fontSize: 18, fontWeight: "bolder"}}>{props.item.product_name}</span></div>
                            <div style={{ fontSize: 15, padding: 5 }}>
                                Status: <span style={{ fontSize: 18, fontWeight: "bolder" }}>
                                    {props.item.pstatus === 0 ? <>ACTIVE</> : <></>}
                                    {props.item.pstatus === 2 ? <>INACTIVE</> : <></>}
                                </span>
                            </div>
                            <div style={{fontSize: 15, padding: 5}}>Code: <span style={{fontSize: 18, fontWeight: "bolder"}}>{props.item.product_code}</span></div>
                            <div style={{fontSize: 15, padding: 5}}>Brand: <span style={{fontSize: 18, fontWeight: "bolder"}}>{props.item.brand_name}</span></div>
                            <div style={{fontSize: 15, padding: 5}}>Category: <span style={{fontSize: 18, fontWeight: "bolder"}}>{props.item.cat_name}</span></div>
                            <div style={{fontSize: 15, padding: 5}}>SubCategory: <span style={{fontSize: 18, fontWeight: "bolder"}}>{props.item.subcat_name}</span></div>
                            <div style={{fontSize: 15, padding: 5}}>Description: <span>{props.item.specs}</span></div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <ViewStock item={props.item} visibleexpand={visibleexpand} setVisibleExpand={setVisibleExpand} />
            <>
                <Tooltip target=".custom-target-icon" style={{ fontSize: 15 }} />
                <span onClick={()=>setVisible(true)} className='btn btn-success btn-sm custom-target-icon' style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}} data-pr-tooltip="View" data-pr-position="left"><i className='pi pi-eye' style={{ fontSize: 15 }}></i></span>
                {/* btn btn-sm btn-info */}
            </>
        </>
    );
}

export default View;

import axios from 'axios';
import React, { useState } from 'react';
import ENV from '../../ENV';

const PrintReceipt = ({OnlySalesData}) => {
    const [printing, setPrinting] = useState(false)
    const [printpreviewereceipt, setPrintPreviewReceipt] = useState(false)
    const [previewDataId, setPreviewDataId] = useState('')
    const [successprinting, setSuccessPrinting] = useState(false)
    const [ItemList, setItemList] = useState([])
    const [OnlyReceiptData, setOnlyReceiptData] = useState({ list: [{ stock_price: 0, sales_qty: 0 }] })
    // const [OnlySalesData, setPanelOnlySalesData] = useState([])


    const PrintPreview = async (item) => {
        setPreviewDataId(item.sales_id)

        setPrintPreviewReceipt(true)

        setItemList(item)
        setOnlyReceiptData(item)
        // setPanelOnlySalesData(item)
        console.log(item)
        // event.preventDefault();
        // var formData = new FormData();
        // formData.append("sales_details", 1);
        // formData.append("sales_id", item.sales_id);

        // await axios({
        //     method: "post",
        //     url: 'https://stgc.tech/api/sales_details.php',
        //     data: formData,
        // }).then((res) => {
        // console.log(res.data)
        // setPanelOnlySalesData(res.data)
        // console.log(JSON.stringify(res.data))
        // });
    }

    const printReceipt = async (data, val) => {
        setPrinting(true)

        // console.log(data?.list)

        var formData = new FormData();
        formData.append("print_sales_details", 1);
        formData.append("print_details", JSON.stringify(data));
        // formData.append("product_list", data?.list);
        formData.append("policy", val);
        await axios({
            method: "post",
            // ENV.DOMAIN + 
            // url: 'http://localhost/stgc.local/api/print_receipt.php',
            // url: 'http://stgc.com/api/print_receipt.php',
            url: 'http://localhost/stgc/api/print_receipt.php',
            // url: ENV.DOMAIN + 'product.php',
            // url: 'https://send.stgc.tech/api/print_receipt.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            if (res.data === 'success') {
                setSuccessPrinting(true)
                setTimeout(() => {
                    setPrinting(false)
                    setPrintPreviewReceipt(false)
                    setSuccessPrinting(false)
                    // setPanelOnlySalesData(data)
                }, 1000)
                // if(directory === 'sales') {
                // window.location.assign('https://stgc.tech/pages/index.php?route=sales')
                // window.location.assign('/sales')
                // } else if(directory === 'report') {
                // window.location.assign('https://stgc.tech/pages/index.php?route=report/sales&active=active')
                // window.location.assign('/report/sales')
                // }
            } else {

            }
        });

    }

    const totalSummary = (item) => {
        let total = 0;
        item.map((item, key) => {
            let subTotal = item.stock_price * item.sales_qty
            total = total + subTotal;
        });
        return total;
    }

    const TotalQty = (item) => {
        let total = 0;
        item.map((item, key) => {
            total = total + item.sales_qty
            // total = total + subTotal;
        });
        return total;
    }


    const ReceiptSubTotal = OnlyReceiptData.list?.reduce((amount, item) => amount = (item.stock_price * item.sales_qty) + amount, 0);
    const SalesDataSubTotal = OnlySalesData.list?.reduce((amount, item) => amount = (item.stock_price * item.sales_qty) + amount, 0);
    

    const groupedData = OnlySalesData?.list?.reduce((groups, item) => {
        const { product_id, product_name } = item;
        // console.log(product_id)
        if (product_id === 0) {
            if (!groups[product_id, product_name]) {
                groups[product_id, product_name] = [];
            }
            groups[product_id, product_name].push(item);
        } else {
            if (!groups[product_id]) {
                groups[product_id] = [];
            }
            groups[product_id].push(item);
        }
        return groups;
    }, {});



    const totalPayment = () => {
        let total = 0;
        OnlySalesData.Mpayment?.map((item, key) => {
            if (item?.removeBy !== 1) {
                total += Number(item?.amount_value)
            }
        });
        return total;
    }

    return (
        <>
        {printpreviewereceipt ?
                <div onClick={() => { 
                    setPrintPreviewReceipt(); 
                    setSuccessPrinting(false); 
                    setPrinting(false); 
                }} 
                style={{ position: "absolute", width: "100vw", height: "100%", zIndex: 4, backgroundColor: "#000", opacity: 0.5 }}></div>
            :
            null
        }
        <div style={{ height: "100%", width: printpreviewereceipt ? 350 : 0, border: "1px solid #ddd", transition: "width 0.2s", overflow: "scroll", backgroundColor: "#fff", position: "fixed", zIndex: 4, right: 0, top: 0, paddingTop: 55 }}>
            {/* <div style={{padding:10}}><button style={{ border:'none', width:200, height:40, backgroundColor:"red", color:"#fff"}} onClick={()=> {setPrintPreviewReceipt(); setPanelOnlySalesData([]); }}>Close</button></div> */}


            {
                printing ?
                    <>
                        {
                            successprinting ?
                                <div style={{ height: 200, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                    </svg>
                                    <span>Successfully Printed</span>
                                </div>
                                :
                                <div style={{ height: 200, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                                    </svg>
                                    <span>Printing</span>
                                </div>
                        }
                    </>
                    :
                    <>
                        {
                            OnlyReceiptData?.sales_id > 0 ?
                                <>
                                    <div style={{ backgroundColor: "#fff", padding: 10 }}>
                                        <div style={{ padding: 10, textAlign: "center", fontSize: 20 }}>Print Preview</div>
                                        {
                                            Object.entries(groupedData)?.map(([id, items]) => {
                                                return (
                                                    <div style={{ borderBottom: "1px solid #f0f0f0", paddingTop: 5, paddingBottom: 5 }}>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            <div>
                                                                <label>{items[0].product_name}</label>
                                                            </div>
                                                        </div>

                                                        {
                                                            items.map((item) => {
                                                                if (item.product_id !== 0) {
                                                                    return (
                                                                        <>
                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                                <div style={{ fontSize: 12 }}>SN: {item.serial_number}</div>
                                                                                <div style={{ fontSize: 12 }}>{item.warranty}</div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                // console.log(JSON.parse(item.options))
                                                                                JSON.parse(item.options)?.map((_item, key)=>{
                                                                                    return (
                                                                                        <>
                                                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap:10}}>
                                                                                                {
                                                                                                    _item.name === 'Miscellaneous' ?
                                                                                                    <div>
                                                                                                        <div style={{ fontSize: 12 }}>U.P.C.: {item.product_code}</div>
                                                                                                        <div style={{ fontSize: 12 }}>SN: {item.serial_number}</div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div>
                                                                                                        <div>x{item.sales_qty} {_item.name} - {_item.option} - {Number(parseFloat(item.stock_price).toFixed(2) ?? 0).toLocaleString('en')}</div>
                                                                                                        {/* Number(parseFloat(item.stock_price).toFixed(2) ?? 0).toLocaleString('en') */}
                                                                                                        {/* <div></div> */}
                                                                                                    </div>
                                                                                                }
                                                                                                {/* <div>{_item.note}</div> */}
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                        {/* {
                                                            item.serial !== '-------------' ?
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                    <div>{item.serial_number}</div>
                                                                    <div>{item.warranty}</div>
                                                                </div>
                                                                :
                                                                null
                                                        } */}
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                                
                                                                {
                                                                    items[0].product_id !== 0 ?
                                                                    <>
                                                                        <div>{TotalQty(items)}</div>
                                                                        <>x</>
                                                                        <div>{items[0].stock_price}</div>
                                                                    </>
                                                                    :
                                                                    <div>x{TotalQty(items)}</div>
                                                                }
                                                            </div>
                                                            <div>{Number(totalSummary(items))?.toFixed(2)}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            // OnlyReceiptData?.list.map((item, key) => {
                                            //     return (
                                            //         <div style={{ borderBottom: "1px solid #f0f0f0", paddingTop: 5, paddingBottom: 5 }}>
                                            //             <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            //                 <div>{item.product_name}</div>
                                            //             </div>
                                            //             {
                                            //                 item.serial !== '-------------' ?
                                            //                     <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            //                         <div>{item.serial_number}</div>
                                            //                         <div>{item.warranty}</div>
                                            //                     </div>
                                            //                     :
                                            //                     null
                                            //             }
                                            //             <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            //                 <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                            //                     <div>{item.sales_qty}</div>
                                            //                     <>x</>
                                            //                     <div>{item.stock_price}</div>
                                            //                 </div>
                                            //                 <div>{Number(item?.stock_price * item?.sales_qty)?.toFixed(2)}</div>
                                            //             </div>
                                            //         </div>
                                            //     )
                                            // })
                                        }
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                <div>{OnlyReceiptData?.item}</div>
                                                <>Item's</>
                                            </div>
                                            <div>Total: {Number(parseFloat(ReceiptSubTotal).toFixed(2) ?? 0).toLocaleString('en')}</div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div></div>
                                            <div>Tendered: {Number(parseFloat(totalPayment()).toFixed(2) ?? 0).toLocaleString('en')}</div>
                                            {/* Number(parseFloat(ReceiptSubTotal).toFixed(2) ?? 0).toLocaleString('en') */}
                                        </div>
                                        <div style={{ paddingTop: 10 }}>
                                            <div style={{ fontSize: 12 }}>Customer: {OnlyReceiptData?.customer_name}</div>
                                            <div style={{ fontSize: 12 }}>Contact: {OnlyReceiptData?.contact}</div>
                                            <div style={{ fontSize: 12 }}>Address: {OnlyReceiptData?.address}</div>
                                        </div>
                                        <div style={{ paddingTop: 10, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ fontSize: 12 }}>Cashier: {OnlyReceiptData?.cashier}</div>
                                            <div style={{ fontSize: 12 }}>Sales: {OnlyReceiptData?.sellers}</div>
                                        </div>
                                        <div style={{ paddingTop: 10, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ fontSize: 12 }}>No.: {OnlyReceiptData?.sales_id.padStart(10, '0')}</div>
                                            <div style={{ fontSize: 12, display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end" }}>
                                                <div>{OnlyReceiptData?.sales_date}</div>
                                            </div>
                                        </div>

                                        <div style={{ padding: 20, display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 5 }}>

                                            <button onClick={() => printReceipt(OnlyReceiptData, 'w')} className="btn vd_bg-blue vd_white">Print W/ Policy</button>
                                            <button onClick={() => {
                                                printReceipt(OnlyReceiptData, 'wo');
                                                // setOnlyReceiptData(OnlyReceiptData)
                                                // console.log(OnlyReceiptData);
                                            }} className="btn vd_bg-red vd_white">Print W/O Policy</button>
                                        </div>
                                    </div>
                                </>

                                :
                                <>
                                    <div style={{ height: 200, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>Fetching...</div>
                                </>
                        }
                    </>
            }



        </div>
            <button onClick={() => { PrintPreview(OnlySalesData); }} style={{ width: "50%" }}>Print</button>
        </>
    );
}

export default PrintReceipt;

import React, { useEffect, useState, useMemo, useRef } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import Header from './component/Header';
import Navbar from './component/Navbar';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from './ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from "primereact/chips";
import ViewStockBtn from './component/product/ViewStockBtn';
import EditProductBtn from './component/product/EditProductBtn';
import DeleteProductBtn from './component/product/DeleteProductBtn';
import AddProductBtn from './component/product/AddProductBtn';
import ShowDetails from './component/product/showDetails';
import Table from './component/product/Table';
import StockinInfo from './component/stock/StockinInfo';
import { InputText } from "primereact/inputtext";
import AddPayment from './component/stock/AddPayment';
// import { FloatLabel } from "primereact/floatlabel";


const Stockin = () => {
    const toast = useRef(null);
    document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix" data-active="dashboard ';
    document.body.id = 'dashboard';

    const [products, setProducts] = useState([]);

    const [inputValue, setInputValue] = useState({ supplier: "", date_arrived: "", invoice_number: "", dr_number: "", date_purchase: "", total_due: "", due_date: "" });
    const [stockValue, setStockValue] = useState({});
    const [stockTable, setStockTable] = useState([]);
    const [productdetails, setProductDetails] = useState({});
    const [productserial, setProductSerial] = useState([]);
    const [error, setError] = useState({ "title": "" });
    const [SerialList, setSerialList] = useState([]);
    const [userstatus, setUserStatus] = useState(null)
    const [activesearch, setActiveSearch] = useState('')
    const [selecteditem, setSelectedItem] = useState(0);

    const [inputform, setInputForm] = useState({ "search": "" })

    useMemo(async () => {
        var formData = new FormData();
        formData.append("checkUserStatus", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        // setInterval(async()=> {
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/user.php',
            data: formData,
        }).then((res) => {
            setUserStatus(res.data)
            console.log(JSON.parse(res.data))
        })
            .catch((err) => {
                console.log(err);
            });
        // }, 5000);
    }, [ENV]);


    /////////////////

    useMemo(async () => {
        var formData = new FormData();
        formData.append("product_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            setProducts(res.data)
            console.log(res.data)
            // console.log(res.data[0].users_role.activity_category)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("serial_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            setProductSerial(res.data)
            // console.log(res.data)
            // console.log(res.data[0].users_role.activity_category)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    const serialSync = async () => {
        var formData = new FormData();
        formData.append("serial_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            setProductSerial(res.data)
            // console.log(res.data)
            // console.log(res.data[0].users_role.activity_category)
        })
            .catch((err) => {
                console.log(err);
            });
    }


    const searchData = (search, data) => {
        if (search != '') {
            data = data?.filter((i) => i?.product_name?.toLowerCase().includes(search?.toLowerCase()) || i?.product_code?.toLowerCase().includes(search?.toLowerCase()));
        } else {
            data = [];
        }
        // console.log(data)
        setProductDetails(data);
    }

    // const 

    const ProductSync = async () => {
        var formData = new FormData();
        formData.append("product_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            // setProducts(res.data)
            // console.log(res.data[0].users_role.activity_category)
        })
            .catch((err) => {
                console.log(err);
            });
    }


    // const serialCheck = async (value) => {

    //     var formData = new FormData();
    //     formData.append("serial_check", 1);
    //     formData.append("serial", value);
    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN + 'api/product.php',
    //         data: formData,
    //     }).then((res) => {
    //         // return res.data
    //         console.log(res.data)
    //     })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }

    const groupedData = stockTable?.reduce((groups, item) => {
        const { pid, product_code, no_serial } = item;
        if (!groups[pid]) {
            groups[pid] = [];
        }
        groups[pid].push(item);
        return groups;
    }, {});

    const onPressInputField = async (e) => {
        if (e.which == 13 && e.shiftKey) {
            // console.log(e)
        } else if (e.which == 13) {

            if (e.target.name == 'serial') {
                if (e.target.value != '') {
                    const indx = productserial?.filter(i => String(i.serial).toLowerCase() === String(e.target.value).toLowerCase()).slice(0, 10);
                    if (!indx.length) {
                        const idx = stockTable.filter(i => String(i.serial).toLowerCase() == String(e.target.value).toLowerCase()).slice(0, 10);
                        if (!idx.length) {
                            setStockTable([...stockTable, { ...productdetails[0], [e.target.name]: String(e.target.value), ...inputValue, "qty": 1 }])
                            setError({ "title": "" })
                            setInputValue((prev) => ({ ...prev, [e.target.name]: '' }))
                            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Done', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Existing Serial', life: 3000 });
                            setInputValue((prev) => ({ ...prev, [e.target.name]: '' }))
                            setError({ "title": "serial", "message": "Existing Serial", "serial": e.target.value, "infodetails": Array() })
                        }
                    }
                    else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Existing Serial', life: 3000 });
                        setError({ "title": "serial", "message": "Existing Serial", "infodetails": indx })
                    }
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Empty field', life: 3000 });
                }
            } else if (e.target.name == 'qty') {
                const idx = stockTable.findIndex(i => i.pid == productdetails[0].pid);
                // console.log(index.length>0)
                if (stockTable.filter(i => i.pid == productdetails[0].pid).length > 0) {
                    stockTable[idx].qty = Number(stockTable[idx].qty) + Number(e.target.value);
                    setStockTable([...stockTable])
                    setInputValue((prev) => ({ ...prev, [e.target.name]: '' }))
                } else {
                    setInputValue((prev) => ({ ...prev, [e.target.name]: '' }))
                    setStockTable([...stockTable, { ...productdetails[0], [e.target.name]: Number(e.target.value), ...inputValue }])
                }
            }
        }
    }

    const removeRow = (item) => {
        // console.log(item)
        const indx = stockTable?.filter(i => i != item);
        console.log(item, indx)
        setStockTable(indx)
    }


    const TotalQty = stockTable?.reduce((amount, item) => amount = Number(item.qty) + amount, 0);
    const SubTotal = stockTable?.reduce((amount, item) => amount = (item.cost * item.qty) + amount, 0);


    const onSubmit = async () => {
        // formData.append("supplier", inputValue.supplier);
        // formData.append("date_arrived", inputValue.date_arrived);
        // formData.append("invoice_number", inputValue.invoice_number);
        // formData.append("dr_number", inputValue.dr_number);
        // formData.append("date_purchase", inputValue.date_purchase);
        // formData.append("due_date", inputValue.due_date);
        // formData.append("total_due", inputValue.total_due);

        const testArray = [];
        if (inputValue.supplier != "") {
            testArray.push(1)
        }
        if (inputValue.date_arrived != "") {
            testArray.push(2)
        }
        if (inputValue.invoice_number != "") {
            testArray.push(3)
        }
        if (inputValue.dr_number != "") {
            testArray.push(4)
        }
        if (inputValue.due_date != "") {
            testArray.push(5)
        }
        if (inputValue.date_purchase != "") {
            testArray.push(6)
        }
        console.log(Object.keys(testArray).length, testArray, inputValue, 0)
        if(Object.keys(testArray).length==6){
            var formData = new FormData();
            formData.append("save_stock", 1);
            formData.append("productDetails", JSON.stringify(productdetails[0]));
            formData.append("value", JSON.stringify(inputValue));
            formData.append("stockTable", JSON.stringify(stockTable));
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'api/product.php',
                data: formData,
            }).then((res) => {
                serialSync()
                setStockTable([])
                setInputValue((prev) => ({...prev,
                    supplier:"",
                    date_arrived:"",
                    invoice_number:"",
                    dr_number:"",
                    date_purchase:"",
                    total_due:"",
                    cost:""
                }))
                setProductDetails([]);
                setInputForm((prev) => ({ ...prev, 'search': '' }))
                console.log(res.data)
            })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            alert("")
        }

    }



    const _data = products.filter(item => {
        // return item.name.toLowerCase().includes(String(inputform.medication).trim().toLowerCase())
        const searchItem = String(inputform.search).toLowerCase()
        const product_name = String(item.product_name).toLowerCase()
        const product_code = String(item.product_code).toLowerCase()
        return (searchItem &&
            product_name.toLowerCase().includes(String(searchItem).toLowerCase()) && product_name != searchItem) || (searchItem && product_code.toLowerCase().includes(String(searchItem).toLowerCase()))
    }).slice(0, 10);

    const handlekey = (e) => {
        if (selecteditem < _data.length) {
            if (e.key === 'ArrowUp' && selecteditem > 0) {
                setSelectedItem(prev => prev - 1)
            } else if (e.key === 'ArrowDown' && selecteditem < _data.length - 1) {
                setSelectedItem(prev => prev + 1)
            } else if (e.key === 'Enter' && selecteditem >= 0) {
                setActiveSearch('')
                setInputForm({ ...inputform, 'search': _data[selecteditem].product_name })
                setProductDetails(Array(_data[selecteditem]));
                console.log(Array(_data[selecteditem]))
            }
        } else {
            setSelectedItem(-1)
        }
    }

    const keypress = (e) => {
        if (e.key === 'Enter') {
            const index = products.findIndex((i) => i.product_code == inputform.search || i.product_name == inputform.search)


            setActiveSearch('')
            setProductDetails(Array(products[index]));
            console.log(index, Array(products[index]));
            setInputForm({ ...inputform, 'search': e.target.value })
        }
    }

    const search = (e) => {
        setActiveSearch(e.target.value)
        setInputForm({ ...inputform, [e.target.name]: e.target.value })
    }

    console.log(inputValue)


    return (
        <>
            <Toast ref={toast} />
            <div className="vd_body">
                {/* Header Start */}
                <Header />
                {/* Header Ends */}
                <div className="content">
                    <div className="container">
                        <Navbar />

                        {/* Middle Content Start */}
                        <div className="vd_content-wrapper" style={{ minHeight: 1059 }}>
                            <div className="vd_container" style={{ minHeight: 1059 }}>
                                <div className="vd_content clearfix">
                                    <div className="vd_content-section clearfix" style={{ paddingTop: 0 }}>
                                        <div className="row" style={{ padding: 10 }}>

                                            <div className="col-sm-3">
                                                <div className="panel widget light-widget panel-bd-top">
                                                    <div className='panel-body' style={{ padding: 10, display: "flex", flexDirection: "column", gap: 10 }}>
                                                        <div>

                                                            {/* <FloatLabel>
                                                                <InputText id="username" value="" onChange={(e) => ""} />
                                                                <label for="username">Username</label>
                                                            </FloatLabel> */}
                                                            <input type="text" name='supplier' value={inputValue.supplier} onChange={(e) => setInputValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder='Supplier' />
                                                        </div>
                                                        <div>
                                                            <input type="text" name='date_arrived' value={inputValue.date_arrived} onChange={(e) => setInputValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder='Date Arrived' />
                                                        </div>
                                                        <div>
                                                            <input type="text" name='invoice_number' value={inputValue.invoice_number} onChange={(e) => setInputValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder='Invoice #' />
                                                        </div>
                                                        <div>
                                                            <input type="text" name='dr_number' value={inputValue.dr_number} onChange={(e) => setInputValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder='DR #' />
                                                        </div>
                                                        <div>
                                                            <input type="text" name='date_purchase' value={inputValue.date_purchase} onChange={(e) => setInputValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder='Date Purchase' />
                                                        </div>
                                                        <div>
                                                            <input type="text" name='due_date' value={inputValue.due_date} onChange={(e) => setInputValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder='Due Date' />
                                                        </div>
                                                        {/* <div>
                                                            <input type="text" name='total_due' onChange={(e)=>setInputValue({...inputValue, [e.target.name]: e.target.value})} placeholder='Total Due' />
                                                        </div> */}
                                                        <div>
                                                            <textarea type="text" rows={5} name='note' onChange={(e) => setInputValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder='Note' />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="panel widget light-widget panel-bd-top">
                                                    <div className='panel-body' style={{ padding: 10, display: "flex", flexDirection: "column", gap: 10 }}>
                                                        <AddPayment />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-sm-9'>
                                                <div className="panel widget light-widget panel-bd-top">
                                                    <div className='panel-body' style={{ padding: 10, display: "flex", flexDirection: "column", gap: 10 }}>
                                                        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                                                            <div>
                                                                <input className='form-control' onKeyDown={handlekey} onKeyPress={keypress} autoComplete="off" name='search' value={inputform.search} onChange={(e) => search(e)} style={{ width: 300 }} />

                                                                {/* <input name='search' onChange={(e)=>setInputForm(e.target.value)} className='form-control' placeholder='Search' style={{width: 300}} /> */}
                                                                <div>
                                                                    <div style={{ position: "absolute", zIndex: 999, width: "100%", boxShadow: "-1px 2px 5px grey" }}>
                                                                        {
                                                                            activesearch != '' ?
                                                                                _data.map((item, key) => {
                                                                                    return (
                                                                                        <div style={{ padding: 5, border: "1px solid #ddd", backgroundColor: selecteditem == key ? "#ddd" : "#fff" }} key={key} onClick={() => {
                                                                                            setInputForm((prev) => ({ ...prev, 'search': item.product_name }))
                                                                                            setProductDetails(Array(item));
                                                                                        }}>{item.product_name}</div>
                                                                                    )
                                                                                })
                                                                                : null
                                                                        }
                                                                    </div>


                                                                    {/* <select name='ifSerial' onChange={(e)=>setStockValue({...stockValue, [e.target.name]: Number(e.target.value)})} className='form-control'>
                                                                        <option value={0}>No Serial</option>
                                                                        <option value={1}>Serial</option>
                                                                    </select> */}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <input type="text" name='cost' onChange={(e) => setInputValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))} value={inputValue.cost} className='form-control' placeholder='Cost' />
                                                            </div>
                                                            {/* {productdetails.product_name} */}
                                                            {
                                                                productdetails[0]?.no_serial == 0 ?
                                                                    <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center" }}>
                                                                        <input type="text" name='serial' onChange={(e) => setInputValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))} onKeyPress={(e) => onPressInputField(e)} value={inputValue.serial} className='form-control' placeholder='Serial' />
                                                                        {
                                                                            error.title == "serial" ?
                                                                                <div style={{ width: 200, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                                                    {
                                                                                        error.infodetails.length > 0 ?
                                                                                            <StockinInfo item={error.infodetails} />
                                                                                            : null
                                                                                    }
                                                                                    <span style={{ color: "red" }}>{error.message}</span>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <input type="text" name='qty' onKeyPress={(e) => onPressInputField(e)} onChange={(e) => setInputValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))} value={inputValue.qty} className='form-control' placeholder='Qty' />
                                                                    </div>
                                                            }
                                                        </div>
                                                        {
                                                            productdetails.length > 0 ?
                                                                <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                                                    <div>
                                                                        <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5 }}>
                                                                            <span style={{ fontSize: 20, fontWeight: 800 }}>{productdetails[0].product_name}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                                                        <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5 }}>
                                                                            U.P.C.: <span style={{ fontSize: 15, fontWeight: 600 }}>{productdetails[0]?.product_code}</span>
                                                                        </div>
                                                                        <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5 }}>
                                                                            CATEGORY: <span style={{ fontSize: 15, fontWeight: 600 }}>{productdetails[0]?.cat_name}</span>
                                                                        </div>
                                                                        <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5 }}>
                                                                            BRAND: <span style={{ fontSize: 15, fontWeight: 600 }}>{productdetails[0]?.brand_name}</span>
                                                                        </div>
                                                                        <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5 }}>
                                                                            WARRANTY: <span style={{ fontSize: 15, fontWeight: 600 }}>{productdetails[0]?.warranty}</span>
                                                                        </div>
                                                                        {
                                                                            productdetails[0]?.pstatus != 0 ?
                                                                                (
                                                                                    <div style={{ padding: 5, border: "1px solid red", color: "red", borderRadius: 5 }}>
                                                                                        STATUS: INACTIVE
                                                                                    </div>
                                                                                ) : (
                                                                                    <></>
                                                                                )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="panel widget light-widget panel-bd-top">
                                                    <div className='panel-body' style={{ padding: 10 }}>
                                                        <div className='panel-body' style={{ padding: 10 }}>
                                                            <table className='table table-bordered'>
                                                                <thead>
                                                                    <tr>
                                                                        <td style={{ width: 100 }}>Qty</td>
                                                                        <td>Item Description</td>
                                                                        <td style={{ width: 200 }}>Cost</td>
                                                                        <td style={{ width: 200 }}>Sub-Total</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        Object.entries(groupedData)?.map(([cost, items], key) => {
                                                                            return (
                                                                                <tr key={key}>
                                                                                    <td>
                                                                                        {
                                                                                            items[0].no_serial == 0 ?
                                                                                                items?.length
                                                                                                :
                                                                                                items[0].qty
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                                                                            <span style={{ fontWeight: 600 }}>{items[0].product_name}</span>
                                                                                            <span style={{ fontWeight: 600 }}>U.P.C.: {items[0].product_code}</span>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5 }}>
                                                                                            <div style={{ width: 10 }}></div>
                                                                                            {
                                                                                                items?.length > 0 && items[0].no_serial == 0 ?
                                                                                                    (<div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                                                                                        {
                                                                                                            items?.map((item, keys) => {
                                                                                                                return (
                                                                                                                    <div key={keys} style={{ fontSize: 15, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                                                                                        {/* <span onClick={()=> removeRow(item)} style={{fontSize: 15, color: "green", cursor: "pointer"}} title='Edit' className='pi pi-pencil'></span> */}
                                                                                                                        <span onClick={() => removeRow(item)} style={{ fontSize: 15, color: "red", cursor: "pointer" }} title='Remove' className='pi pi-times-circle'></span>
                                                                                                                        <span key={keys} style={{
                                                                                                                            backgroundColor: item.serial?.toLowerCase() == error.serial?.toLowerCase() ? "yellow" : "",
                                                                                                                            fontWeight: item.serial?.toLowerCase() == error.serial?.toLowerCase() ? 600 : "normal",
                                                                                                                            width: "100%"
                                                                                                                        }}>{item.serial}</span>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>)
                                                                                                    :
                                                                                                    (
                                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                                                                            {/* <span><span onClick={null} style={{fontSize: 15, color: "green", cursor: "pointer"}} className='pi pi-pencil'></span> <span>Edit</span></span> */}
                                                                                                            <span><span onClick={() => removeRow(items[0])} style={{ fontSize: 15, color: "red", cursor: "pointer" }} className='pi pi-times-circle'></span> <span>Remove</span></span>
                                                                                                        </div>
                                                                                                    )
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {items[0].cost}
                                                                                    </td>
                                                                                    <td>
                                                                                        {Number(items[0].cost) * Number(items[0].no_serial == 0 ?
                                                                                            items?.length
                                                                                            :
                                                                                            items[0].qty)
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                <div>
                                                                    {
                                                                        stockTable.length != 0 ? (
                                                                            stockTable.length > 0 ? <span><span style={{ fontSize: 15, fontWeight: 600 }}>Item: </span>{TotalQty}</span> : 0
                                                                        ) : null
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <span style={{ fontSize: 30, fontWeight: 800 }}>{stockTable.length > 0 ? Number(SubTotal).toFixed(2) : null}</span>
                                                                </div>
                                                            </div>
                                                            {
                                                                stockTable.length != 0 ? (
                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                        <div></div>
                                                                        <button className='btn vd_btn vd_bg-facebook' onClick={() => onSubmit()} style={{ alignSelf: "flex-end" }}>Submit</button>
                                                                    </div>
                                                                ) : null
                                                            }
                                                            {/* {
                                                                SerialList.map((item)=>{
                                                                    return (
                                                                        <div>{item.product_id} {item.stock_id}</div>
                                                                    )
                                                                }).slice(0,1000)
                                                            } */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>





                                        </div>
                                    </div>
                                    {/* .vd_content-section */}
                                </div>
                                {/* .vd_content */}
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
                {/* .content */}
                {/* Footer Start */}
                {/* <footer className="footer-1" id="footer">
                    <div className="vd_bottom ">
                        <div className="container">
                            <div className="row">
                                <div className=" col-xs-12">
                                    <div className="copyright">
                                        Copyright ©2014 Venmond Inc. All Rights Reserved
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer> */}
            </div>



        </>

    );
}

export default Stockin;

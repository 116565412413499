import React, { useEffect, useState, useMemo, useRef } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import Header from './component/Header';
import Navbar from './component/Navbar';
import { format } from 'date-fns';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from './ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from "primereact/chips";
// import { QrReader } from 'react-qr-reader';
import QrReader from 'modern-react-qr-reader'
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';


const InventoryCurrent = () => {
    const history = useHistory()
    const { user_id, cat_id, product_id, year } = useParams();

    document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix" data-active="dashboard ';
    document.body.id = 'dashboard';
    document.title = 'Inventory';

    // console.log(JSON.parse(localStorage.getItem('users_role')).dashboard_login)



    const [InventoryList, setInventoryList] = useState([])
    const [Data, setData] = useState([])
    const [openrowdata, setOpenRowData] = useState({})
    const [openpanel, setOpenPanel] = useState({ data: [], status: false, item: {} })
    const [handleScanEnable, sethandleScanEnable] = useState(false)
    const [search, setSearch] = useState('')
    const [searchproduct, setSearchProduct] = useState('')
    const [panelTab, setPanelTab] = useState('pending')
    const [checklist, setChecklist] = useState('')

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth)
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth)
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, [])

        return (width <= 768)
    }

    // console.log(useCheckMobileScreen())

    useMemo(async () => {
        var formData = new FormData()
        formData.append("getInvRecordCurrent", 1)
        // formData.append("user_id", user_id)
        // formData.append("date", cat_id,)
        await axios({
            method: "post",
            // url: 'http://localhost/stgc.local/api/sales_report.php',
            url: ENV.DOMAIN + 'inventory.php',
            data: formData,
        }).then((res) => {
            setData(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err)
            })
    }, [ENV])

    const searchData = (data, search) => {
        // if (search) {
        //     data = data?.filter((i) => (i?.firstname?.toLowerCase().includes(search?.trim().toLowerCase())));
        // }
        // console.log(data?.list)
        // console.log(data)
        // console.log(data[0]?.list?.map((i, k) => Number(i?.count) != Number(i?.stock)), 123)
        // console.log(data?.list?.map((i) =>Number(i?.count) != Number(i?.stock)), 123)
        return data
    }

    const searchProduct = (data, search, checklist) => {
        console.log(checklist)
        if (checklist == 'complete') {
            if (search) {
                data = data.filter((i) => (i?.product_name?.toLowerCase().includes(search?.trim().toLowerCase()) && Number(i?.count) == Number(i?.stock))
                    || (i?.product_code?.toLowerCase().includes(search?.trim().toLowerCase()) && Number(i?.count) == Number(i?.stock))
                    || (i?.catname?.toLowerCase().includes(search?.trim().toLowerCase()) && Number(i?.count) == Number(i?.stock)));
            } else {
                data = data.filter((i) => (i?.product_name?.toLowerCase().includes(search?.trim().toLowerCase()) && Number(i?.count) == Number(i?.stock))
                    || (i?.product_code?.toLowerCase().includes(search?.trim().toLowerCase()) && Number(i?.count) == Number(i?.stock))
                    || (i?.catname?.toLowerCase().includes(search?.trim().toLowerCase()) && Number(i?.count) == Number(i?.stock)));
            }
        } else if (checklist == 'recount') {
            if (search) {
                data = data.filter((i) => (i?.product_name?.toLowerCase().includes(search?.trim().toLowerCase()) && Number(i?.count) != Number(i?.stock))
                    || (i?.product_code?.toLowerCase().includes(search?.trim().toLowerCase()) && Number(i?.count) != Number(i?.stock))
                    || (i?.catname?.toLowerCase().includes(search?.trim().toLowerCase()) && Number(i?.count) != Number(i?.stock)));
            } else {
                data = data.filter((i) => (i?.product_name?.toLowerCase().includes(search?.trim().toLowerCase()) && Number(i?.count) != Number(i?.stock))
                    || (i?.product_code?.toLowerCase().includes(search?.trim().toLowerCase()) && Number(i?.count) != Number(i?.stock))
                    || (i?.catname?.toLowerCase().includes(search?.trim().toLowerCase()) && Number(i?.count) != Number(i?.stock)));
            }
        } else {
            data = data.filter((i) => (i?.product_name?.toLowerCase().includes(search?.trim().toLowerCase()))
                || (i?.product_code?.toLowerCase().includes(search?.trim().toLowerCase()))
                || (i?.catname?.toLowerCase().includes(search?.trim().toLowerCase())));
        }
        // const result = Object.groupBy(data, ({ catid }) => catid);
        const cats = data.reduce((catsSoFar, { catid, catname, product_name }) => {
            if (!catsSoFar[catid]) catsSoFar[catid] = [];
            catsSoFar[catid].push({ catid, catname, product_name });
            return catsSoFar;
        }, {});
        console.log(data.length)
        return data
    }

    const [OpenBottomPanel, setOpenBottomPanel] = useState(0)

    const byCategory = (data, search) => {
        const groupedData = data?.reduce((groups, item) => {
            const { catid, product_name } = item;
            // console.log(catid)
            if (catid == 0) {
                if (!groups[catid, product_name]) {
                    groups[catid, product_name] = [];
                }
                groups[catid, product_name].push(item);
            } else {
                if (!groups[catid]) {
                    groups[catid] = [];
                }
                groups[catid].push(item);
            }
            return groups;
        }, {});
        // console.log(groupedData)
        return groupedData;
    }

    // const groupedData = OnlySalesData?.list?.reduce((groups, item) => {
    //     const { product_id, product_name } = item;
    //     // console.log(product_id)
    //     if(product_id == 0) {
    //         if (!groups[product_id, product_name]) {
    //             groups[product_id, product_name] = [];
    //         }
    //         groups[product_id, product_name].push(item);
    //     } else {
    //         if (!groups[product_id]) {
    //             groups[product_id] = [];
    //         }
    //         groups[product_id].push(item);
    //     }
    //     return groups;
    // }, {});
    // console.log(checklist)
    return (

        <>
            <div className="vd_body">
                {/* Header Start */}
                <Header />
                {/* Header Ends */}
                <div className={!useCheckMobileScreen() ? "content" : ""}>
                    <div className="container">
                        {/* {
                            !useCheckMobileScreen() ?
                                <Navbar />
                                :
                                null
                        } */}
                        {/* Middle Content Start */}
                        {/* className={!useCheckMobileScreen() ? "vd_content-wrapper" : ""} style={{ minHeight: 1059 }} */}
                        {/* className={!useCheckMobileScreen() ? "vd_container" : ""} style={{ minHeight: 1059 }} */}
                        {/* className={!useCheckMobileScreen() ? "vd_content clearfix" : ""} */}
                        {/* className={!useCheckMobileScreen() ? "vd_content-section clearfix" : ""} */}
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div className={!useCheckMobileScreen() ? '' : "row"} style={{ display: "flex", flexDirection: "column", gap: 2 }}>

                                            {

                                                window.innerWidth <= 768 ?
                                                    <div>
                                                        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", padding: 10, gap: 5, borderBottom: null }}>
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
                                                                <input onChange={(e) => setSearch(e.target.value)} type="" placeholder={Data.length + " Products"} style={{ width: 200, border: "2px solid #ddd", height: 35, paddingLeft: 5, borderRadius: 5, outline: "none" }} />
                                                                <span style={{ fontSize: 15 }}>Search</span>
                                                            </div>
                                                        </div> */}
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", padding: 10, gap: 5, borderBottom: null, width: "100%" }}>
                                                            <div style={{ width: "33%" }}>
                                                                {/* style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:5}} */}
                                                                <button onClick={() => checklist == 'all' ? setChecklist('') : setChecklist('all')} style={{ borderRadius: 5, border: checklist == 'all' ? "1px solid #609af8" : "1px solid #000", width: "100%", backgroundColor: checklist == 'all' ? '#609af8' : null, color: checklist == 'all' ? '#fff' : '' }}>All</button>
                                                            </div>
                                                            <div style={{ width: "33%" }}>
                                                                <button onClick={() => checklist == 'recount' ? setChecklist('') : setChecklist('recount')} style={{ borderRadius: 5, border: checklist == 'recount' ? "1px solid #609af8" : "1px solid #000", width: "100%", backgroundColor: checklist == 'recount' ? '#609af8' : null, color: checklist == 'recount' ? '#fff' : '' }}>Re-Count</button>
                                                            </div>
                                                            <div style={{ width: "33%" }}>
                                                                <button onClick={() => checklist == 'complete' ? setChecklist('') : setChecklist('complete')} style={{ borderRadius: 5, border: checklist == 'complete' ? "1px solid #609af8" : "1px solid #000", width: "100%", backgroundColor: checklist == 'complete' ? '#609af8' : null, color: checklist == 'complete' ? '#fff' : '' }}>Completed</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", padding: 10, gap: 5, borderBottom: null }}>
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
                                                            <input onChange={(e) => setSearch(e.target.value)} type="" placeholder={Data.length + " Products"} style={{ width: 200, border: "2px solid #ddd", height: 35, paddingLeft: 5, borderRadius: 5, outline: "none" }} />
                                                            <span style={{ fontSize: 15 }}>Search</span>
                                                        </div>
                                                        <div style={{ width: 5, height: 5, backgroundColor: "#333", borderRadius: 100 }}></div>
                                                        <div>
                                                            {/* style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:5}} */}
                                                            <button onClick={() => checklist == 'all' ? setChecklist('') : setChecklist('all')} style={{ borderRadius: 5, border: checklist == 'all' ? "1px solid #609af8" : "1px solid #000", width: 100, backgroundColor: checklist == 'all' ? '#609af8' : null, color: checklist == 'all' ? '#fff' : '' }}>All</button>
                                                        </div>
                                                        <div>
                                                            <button onClick={() => checklist == 'recount' ? setChecklist('') : setChecklist('recount')} style={{ borderRadius: 5, border: checklist == 'recount' ? "1px solid #609af8" : "1px solid #000", width: 100, backgroundColor: checklist == 'recount' ? '#609af8' : null, color: checklist == 'recount' ? '#fff' : '' }}>Re-Count</button>
                                                        </div>
                                                        <div>
                                                            <button onClick={() => checklist == 'complete' ? setChecklist('') : setChecklist('complete')} style={{ borderRadius: 5, border: checklist == 'complete' ? "1px solid #609af8" : "1px solid #000", width: 100, backgroundColor: checklist == 'complete' ? '#609af8' : null, color: checklist == 'complete' ? '#fff' : '' }}>Completed</button>
                                                        </div>
                                                    </div>
                                            }


                                            <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                                {
                                                    Data.length > 0 ?
                                                        searchData(Data, search).map((item, key) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        window.innerWidth <= 768 ?
                                                                            <div style={{ padding: 5 }}>
                                                                                <div onClick={() => {
                                                                                    setOpenBottomPanel(OpenBottomPanel == item?.user_id ? 0 : item?.user_id)
                                                                                    setSearchProduct('')
                                                                                }} key={key + 1} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", minHeight: 40, padding: 5, width: "100%", backgroundColor: "#fff", borderRadius: 5, paddingLeft: 10, borderLeft: "5px solid #23709e", borderBottom: "2px solid #23709e", cursor: "pointer", gap: 10 }}>
                                                                                    <span style={{ minWidth: 20, fontWeight: "600" }}>{item?.list.length}</span>
                                                                                    <span style={{ minWidth: 20, fontWeight: "600" }}>{item?.firstname}</span>
                                                                                    <span style={{ minWidth: 20, fontWeight: "600" }}>{item?.recount}</span>
                                                                                    <span>(Completed & Recount) asdas</span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div onClick={() => {
                                                                                setOpenBottomPanel(OpenBottomPanel == item?.user_id ? 0 : item?.user_id)
                                                                                setSearchProduct('')
                                                                            }} key={key + 1} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", minHeight: 40, padding: 5, width: "100%", backgroundColor: "#fff", borderRadius: 5, paddingLeft: 10, borderLeft: "5px solid #23709e", borderBottom: "2px solid #23709e", cursor: "pointer", gap: 10 }}>
                                                                                <span style={{ minWidth: 20, fontWeight: "600" }}>{item?.list.length}</span>
                                                                                <span style={{ minWidth: 20, fontWeight: "600" }}>{item?.firstname}</span>
                                                                                <span style={{ minWidth: 20, fontWeight: "600" }}>{item?.recount}</span>
                                                                                <span>(Completed & Recount)</span>
                                                                            </div>
                                                                    }
                                                                    <>

                                                                        {
                                                                            checklist == 'all' || checklist == 'recount' || checklist == 'complete' || OpenBottomPanel == item?.user_id ?
                                                                                <div style={{ paddingLeft:  window.innerWidth <= 768 ? 0 : 10 }}>
                                                                                    {/* <div style={{ padding: 5, borderBottom: "1px solid #16db89" }}>
                                                                                        <input onChange={(e)=>setSearchProduct(e.target.value)} style={{height:30, borderRadius:5, border:"1px solid #23709e", padding:5}} placeholder='Search...' />
                                                                                    </div> */}
                                                                                    <div>
                                                                                        {
                                                                                            // byCategory
                                                                                            // searchProduct
                                                                                            // Object.entries(groupedData)?.map(([id, items]) => {
                                                                                            // byCategory(item?.list, searchproduct).map((_item, _key) => {
                                                                                            // items.map((item) => {
                                                                                            Object.entries(byCategory(item?.list, searchproduct))?.map(([_key, _item]) => {
                                                                                                if (searchProduct(_item, search, checklist).length > 0) {
                                                                                                    return (
                                                                                                        <div style={{ borderRadius: 5, borderLeft: window.innerWidth <= 768 ? "" : checklist == 'recount' ? '5px solid #FF5722' : "5px solid #16db89", borderBottom: checklist == 'recount' ? '2px solid #FF5722' : "2px solid #16db89", fontSize: 15 }}>
                                                                                                            <div style={{ padding: 5, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", backgroundColor: checklist == 'recount' ? '#FF5722' : "#16db89" }}>
                                                                                                                {/* <div style={{ minWidth: 50 }}>{_key + 1}</div> */}
                                                                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                                    <span style={{ fontSize: 18, color: "#fff" }}>{_item[0].catname} <span style={{ fontSize: 18 }}>| {searchProduct(_item, search, checklist)?.length} Products</span> </span>
                                                                                                                    <span>{_item?.catname}</span>
                                                                                                                </div>
                                                                                                                {/* <input onChange={(e)=>setSearchProduct(e.target.value)} style={{height:30, borderRadius:5, border:"1px solid #23709e", padding:5}} placeholder='Search...' /> */}
                                                                                                            </div>
                                                                                                            <div style={{ display: "flex", flexDirection: "column", backgroundColor:"#fff" }}>
                                                                                                                {
                                                                                                                    searchProduct(_item, search, checklist).map((item) => {
                                                                                                                        return (
                                                                                                                            <div style={{ padding: 10, border: "1px solid #ddd" }}>
                                                                                                                                <div style={{ fontWeight: 600 }}>{item?.product_name}</div>
                                                                                                                                {
                                                                                                                                    window.innerWidth <= 768 ?
                                                                                                                                        <div>
                                                                                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
                                                                                                                                                <span style={{ fontSize: 12 }}>Actual Count: {item?.count}</span>
                                                                                                                                                <span style={{ fontSize: 12 }}>System Stock: {item?.stock}</span>
                                                                                                                                            </div>
                                                                                                                                            <div style={{ borderTop: "1px solid #fff", display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
                                                                                                                                                <span style={{ fontSize: 10, textAlign: "center" }}>
                                                                                                                                                    <div>Last Count</div>
                                                                                                                                                    <div>{item?.counts}</div>
                                                                                                                                                </span>
                                                                                                                                                <span style={{ fontSize: 10, textAlign: "center" }}>
                                                                                                                                                    <div>Last Updated</div>
                                                                                                                                                    <div>{item?.last_update} 1</div>
                                                                                                                                                </span>
                                                                                                                                            </div>
                                                                                                                                            <div style={{ fontSize: 10, textAlign: "center", borderTop: "1px solid #fff" }}>
                                                                                                                                                <span>Current Updated: {item?.current_update}</span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        <div style={{ display: "flex", flexDirection: "row", gap: 15, fontSize: 12 }}>
                                                                                                                                            <span>Actual Count: {item?.count}</span>
                                                                                                                                            <span>System Stock: {item?.stock}</span>
                                                                                                                                            <span>Last Count: {item?.counts}</span>
                                                                                                                                            <span>Last Updated: {item?.last_update != '' ? item?.last_update : 'undifined'} 2</span>
                                                                                                                                            <span>Current Updated: {item?.current_update}</span>
                                                                                                                                        </div>
                                                                                                                                }
                                                                                                                                {/* <div style={{color: item?.status == "complete" ? "#16db89" : item?.status == "recount" ? "red" : null, textTransform:"capitalize"}}>Status: {item?.status}</div> */}
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                } else {
                                                                                                    // return (
                                                                                                    //     <div>No Data</div>
                                                                                                    // )
                                                                                                }
                                                                                                // return (
                                                                                                //     <div style={{padding:5, borderRadius:5, borderLeft: "5px solid #16db89", borderBottom: "2px solid #16db89", fontSize:15}}>
                                                                                                //         <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                                                                //             <div style={{minWidth:50}}>{_key+1}</div>
                                                                                                //             <div style={{display:"flex", flexDirection:"column"}}>
                                                                                                //                 <span style={{fontSize:15}}>{_item?.product_name}</span>
                                                                                                //                 <span>{_item?.catname}</span>
                                                                                                //             </div>
                                                                                                //         </div>
                                                                                                //         <div style={{display:"flex", flexDirection:"row", gap:15, fontSize:12}}>
                                                                                                //             <span>Last Count: {_item?.count}</span>
                                                                                                //             <span>Count: {_item?.counts}</span>
                                                                                                //             <span>Stock: {_item?.stock}</span>
                                                                                                //             <span>Last Updated: {_item?.date_counts} {_item?.time}</span>
                                                                                                //         </div>
                                                                                                //     </div>
                                                                                                // )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </>
                                                                </>
                                                            )
                                                        })
                                                        :
                                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                                            <div>Please wait...</div>
                                                        </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
                {/* .content */}
                {/* Footer Start */}
                <footer className="footer-1" id="footer">
                    <div className="vd_bottom ">
                        <div className="container">
                            <div className="row">
                                <div className=" col-xs-12">
                                    <div className="copyright">
                                        Copyright ©2014 Venmond Inc. All Rights Reserved
                                    </div>
                                </div>
                            </div>{/* row */}
                        </div>{/* container */}
                    </div>
                </footer>
            </div>


        </>

    );
}

export default InventoryCurrent;

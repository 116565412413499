import React, { useEffect, useState, useMemo, useRef } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import Header from './component/Header';
import Navbar from './component/Navbar';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from './ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from "primereact/chips";
import ViewStockBtn from './component/product/ViewStockBtn';
import EditProductBtn from './component/product/EditProductBtn';
import DeleteProductBtn from './component/product/DeleteProductBtn';
import AddProductBtn from './component/product/AddProductBtn';
import ShowDetails from './component/product/showDetails';
import Table from './component/product/Table';
import StockinInfo from './component/stock/StockinInfo';
import { InputText } from "primereact/inputtext";
import AddPayment from './component/stock/AddPayment';
import Profile from './component/employee/Profile';
// import { FloatLabel } from "primereact/floatlabel";


const Employee = () => {
    const toast = useRef(null);
    document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix" data-active="dashboard ';
    document.body.id = 'dashboard';

    const [products, setProducts] = useState([]);

    const [inputValue, setInputValue] = useState({});
    const [stockValue, setStockValue] = useState({});
    const [stockTable, setStockTable] = useState([]);
    const [productdetails, setProductDetails] = useState({});
    const [productserial, setProductSerial] = useState([]);
    const [error, setError] = useState({"title":""});
    const [SerialList, setSerialList] = useState([]);
    const [employeelist, setEmployeeList] = useState([]);


    // useMemo(async () => {
    //     var formData = new FormData();
    //     formData.append("sample_table", 1);
    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN + 'api/product.php',
    //         data: formData,
    //     }).then((res) => {

    //         let element = [];
    //         for (let index = 0; index < res.data.length; index++) {
    //             element.push(...res.data[index]);
    //         }
    //         // const newData = element.filter((i) => i) // .data == 6
    //         console.log(element)
    //         setSerialList(element)
    //     })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, [ENV]);


    /////////////////

    useMemo(async () => {
        var formData = new FormData();
        formData.append("employee_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/employee.php',
            data: formData,
        }).then((res) => {
            setEmployeeList(res.data)
            console.log(res.data)
            // console.log(res.data[0].users_role.activity_category)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("product_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            // setProducts(res.data)
            // console.log(res.data)
            // console.log(res.data[0].users_role.activity_category)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("serial_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            // setProductSerial(res.data)
            // console.log(res.data)
            // console.log(res.data[0].users_role.activity_category)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);


    const searchData = (search, data) => {
        if (search != '') {
            data = data?.filter((i) => i?.product_name?.toLowerCase().includes(search?.toLowerCase()) || i?.product_code?.toLowerCase().includes(search?.toLowerCase()));
        } else {
            data = [];
        }
        // console.log(data)
        // setProductDetails(data);
    }

    // const 

    const ProductSync = async () => {
        var formData = new FormData();
        formData.append("product_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            // setProducts(res.data)
            // console.log(res.data[0].users_role.activity_category)
        })
            .catch((err) => {
                console.log(err);
            });
    }


    // const serialCheck = async (value) => {
                    
    //     var formData = new FormData();
    //     formData.append("serial_check", 1);
    //     formData.append("serial", value);
    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN + 'api/product.php',
    //         data: formData,
    //     }).then((res) => {
    //         // return res.data
    //         console.log(res.data)
    //     })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }

    const groupedData = stockTable?.reduce((groups, item) => {
        const { pid, product_code, no_serial } = item;
        if (!groups[pid]) {
            groups[pid] = [];
        }
        groups[pid].push(item);
        return groups;
    }, {});

    const onPressInputField = async (e) => {
        if (e.which == 13 && e.shiftKey) {
            // console.log(e)
        } else if (e.which == 13) {
            if (e.target.name == 'serial') {
                const indx = productserial?.filter(i => i.serial.toLowerCase() === e.target.value.toLowerCase()).slice(0, 10);
                if (!indx.length) {
                    const idx = stockTable.filter(i => i.serial.toLowerCase() == e.target.value.toLowerCase()).slice(0, 10);
                    if (!idx.length) {
                        setStockTable([...stockTable, {...productdetails[0], [e.target.name]: e.target.value, 'qty': 1, ...inputValue}])
                        setError({"title":""})
                        toast.current.show({severity:'success', summary: 'Success', detail:'Done', life: 3000});
                    }
                    else {
                        toast.current.show({severity:'warn', summary: 'Warning', detail:'Existing Serial', life: 3000});
                        setError({"title":"serial", "message":"Existing Serial", "serial": e.target.value, "infodetails":Array()})
                    }
                }
                else {
                    toast.current.show({severity:'error', summary: 'Error', detail:'Existing Serial', life: 3000});
                    setError({"title":"serial", "message":"Existing Serial", "infodetails":indx})
                }        
            } else if (e.target.name == 'qty') {
                const idx = stockTable.findIndex(i => i.pid == productdetails[0].pid);                
                // console.log(index.length>0)
                if (stockTable.filter(i => i.pid == productdetails[0].pid).length>0) {
                    stockTable[idx].qty = Number(stockTable[idx].qty)+Number(e.target.value);
                    setStockTable([...stockTable])
                } else {
                    setStockTable([...stockTable, {...productdetails[0], [e.target.name]: e.target.value, ...inputValue}])
                }
            }
        }
    }

    const removeRow = (item) => {
        const indx = stockTable?.filter(i => i != item);
        console.log(item, indx)
        setStockTable(indx)
    }

    
    const TotalQty = stockTable?.reduce((amount, item) => amount = Number(item.qty) + amount, 0);
    const SubTotal = stockTable?.reduce((amount, item) => amount = (item.cost * item.qty) + amount, 0);


    const onSubmit = async () => {
        // SerialList
        
        // const newData = SerialList.filter((i) => i.product_id == 2104) // .data == 6
        // console.log(JSON.stringify([...newData, ...stockTable])) //, JSON.stringify([...newData, ...stockTable])

        console.log(productdetails)
        var formData = new FormData();
        formData.append("save_stock", 1);
        // formData.append("supplier", inputValue.supplier);
        // formData.append("date_arrived", inputValue.date_arrived);
        // formData.append("invoice_number", inputValue.invoice_number);
        // formData.append("dr_number", inputValue.dr_number);
        // formData.append("date_purchase", inputValue.date_purchase);
        // formData.append("due_date", inputValue.due_date);
        // formData.append("total_due", inputValue.total_due);
        
        formData.append("productDetails", JSON.stringify(productdetails[0]));
        formData.append("value", JSON.stringify(inputValue));
        formData.append("data", JSON.stringify(stockTable));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            console.log(stockTable)
            console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }


    return (

        <>
            <Toast ref={toast} />
            <div className="vd_body">
                {/* Header Start */}
                <Header />
                {/* Header Ends */}
                <div className="content">
                    <div className="container">
                        <Navbar />

                        {/* Middle Content Start */}
                        <div className="vd_content-wrapper" style={{ minHeight: 1059 }}>
                            <div className="vd_container" style={{ minHeight: 1059 }}>
                                <div className="vd_content clearfix">
                                    <div className="vd_content-section clearfix" style={{ paddingTop: 0 }}>
                                        <div className="row" style={{ padding: 10 }}>

                                            <div className='col-sm-12'>
                                                
                                                <div className="panel widget light-widget panel-bd-top">
                                                    <div className='panel-body' style={{ padding: 10 }}>
                                                        <button className='btn btn-success btn-sm'>Add New Employee</button>
                                                        <div className='panel-body' style={{ padding: 10 }}>
                                                            <table className='table table-bordered'>
                                                                <thead>
                                                                    <tr>
                                                                        <td style={{ fontSize: 15, width: 100}}></td>
                                                                        <td style={{ fontSize: 15, width: 'auto'}}>Fullname</td>
                                                                        <td style={{ fontSize: 15, width: 100}}>Action</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        employeelist?.map((item, key) => {
                                                                            return (
                                                                                <tr key={key} 
                                                                                    // style={{backgroundColor: "#62a8d3"}}
                                                                                    >
                                                                                    <td>
                                                                                        <img src='' style={{width:50, height:50}} />
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.Fullname}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Profile item={item} employeelist={employeelist} setEmployeeList={setEmployeeList} />
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>





                                        </div>
                                    </div>
                                    {/* .vd_content-section */}
                                </div>
                                {/* .vd_content */}
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
            </div>



        </>

    );
}

export default Employee;

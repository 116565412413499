import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ENV from '../../ENV';
import { Button } from 'primereact/button';
import ItemDetails from './ItemDetails';
import RemoveCookie from '../hooks/removeCookie';

const TableGroupName = ({ syncData, rowData, openrowdata, setOpenRowData }) => {

    const toast = useRef(null);
    const [dialog, setDialog] = useState(false);

    // console.log(JSON.parse(localStorage.getItem('users_role')).dashboard_login)


    const [openpanel, setOpenPanel] = useState({ data: [], status: false, item: {} })
    const [handleScanEnable, sethandleScanEnable] = useState(false)
    const [search, setSearch] = useState('')
    const [panelTab, setPanelTab] = useState('pending')

    useEffect(()=> {
        RemoveCookie('PHPSESSID')
    }, [])

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth)
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth)
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, [])

        return (width <= 768)
    }

    // console.log(useCheckMobileScreen())

    // useEffect(()=>{
    //     syncData()
    // }, [])


    const listserials = (data) => {
        const list = '[' + data.listserial + ']'
        return JSON.parse(list).map((item, key) => {
            return (
                <div key={key}>{item?.serial} {item?.checked}</div>
                // <div>{item?.stock_id} {item?.product_code} {item?.serial} {item?.date_encoded} {item?.time_encode}</div>
                // <div>{item?.stock_id} {item?.product_code} {item?.serial} {item?.date_encoded} {item?.time_encode}</div>
            )
        });
        // console.log(listserial.serial);
    }


    const searchData = (rowData, search, status) => {
        let data = rowData;
        console.log(rowData)
        if (status == 'pending') {
            if (search) {
                data = rowData?.filter((i) =>
                    (String(i?.product_name).toLowerCase().includes(String(search).toLowerCase()) && (i?.inv_status) == 'pending')
                    ||
                    (i?.product_code.toLowerCase().includes(String(search).toLowerCase()) && (i?.inv_status) == 'pending'));
            } else {
                data = rowData?.filter((i) => (i?.inv_status) == 'pending');
            }
        } else if (status == 'completed') {
            if (search) {
                data = rowData?.filter((i) =>
                    (String(i?.product_name).toLowerCase().includes(String(search).toLowerCase())) && (i?.inv_status) == 'complete'
                    ||
                    (i?.product_code.toLowerCase().includes(String(search).toLowerCase())) && (i?.inv_status) == 'complete');

            } else {
                data = rowData?.filter((i) => (i?.inv_status) == 'complete');
            }
        } else if (status == 'recount') {
            if (search) {
                data = rowData?.filter((i) =>
                    (String(i?.product_name).toLowerCase().includes(String(search).toLowerCase())) && (i?.inv_status) == 'recount'
                    ||
                    (i?.product_code.toLowerCase().includes(String(search).toLowerCase())) && (i?.inv_status) == 'recount');

            } else {
                data = rowData?.filter((i) => (i?.inv_status) == 'recount');
            }
        } else {
            if (search) {
                data = rowData?.filter((i) => (String(i?.product_name).toLowerCase().includes(String(search).toLowerCase()))
                    || (i?.product_code.toLowerCase().includes(String(search).toLowerCase())));
            }
        }
        // console.log(data)
        return data
    }


    const [InputInv, setInputInv] = useState(0)

    const pending = (data) => {
        // const _data = data.filter((i) => (String(i?.curr_date) != String(i?.current_date))
        //     && (Number(i?.id) == Number(i?.product_id))
        //     && (Number(i?.user_id) == Number(localStorage.getItem('user_id'))) && (i?.inv_status != 'recount')).length;

        // console.log(data, _data, localStorage.getItem('user_id'))
        const _data = data.filter((i) => (i?.inv_status) == 'pending').length;
        return _data
    }

    const completed = (data) => {
        const _data = data.filter((i) => (i?.inv_status) == 'complete').length;
        // syncData()
        return _data
    }

    const recount = (data) => {
        // const _data = data.reverse().filter((i) => (i?.inv_status == 'recount')).length;
        // const _data = data.reverse().filter((i) => ((Number(i?.curr_stock) != Number(i?.curr_count))
        //     && String(i?.curr_date) == String(i?.current_date)
        //     && Number(i?.user_id) == Number(localStorage.getItem('user_id')))).length;
        const _data = data.filter((i) => (i?.inv_status) == 'recount').length;
        // syncData()
        return _data
    }

    const lastCount = (data) => {
        return data.filter((i) => (i?.stock != i?.count) && (i?.date == i?.current_date) && (i?.user_id == localStorage.getItem('user_id'))).length;
    }



    // const handleClickWide = (event) => {
    //     if (event.detail === 2) {
    //         setWideImage(wideImage ? false : true)
    //         console.log('double click');
    //     }
    // };
    // console.log(openrowdata, rowData)
    return (

        <div>
            <div onClick={() => { openrowdata.cat_id == rowData.cat_id ? setOpenRowData({}) : setOpenRowData(rowData); setPanelTab('pending'); }} style={{ cursor: "pointer", backgroundColor: "#30aba9"}}>
                <div style={{ padding: 10, cursor: "pointer", backgroundColor: "#30aba9", color: "#fff", fontSize: 15, fontWeight: "800", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 5 }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            {
                                openrowdata.cat_id == rowData.cat_id ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-caret-up" viewBox="0 0 16 16">
                                        <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                        <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                    </svg>
                            }
                        </div>
                        <span>{rowData.cat_name}</span>
                        
                    </div>
                    <span style={{display:"flex", flexDirection:"row", alignSelf:"flex-end"}}>{rowData.list.length}</span>
                </div>
                {
                    openrowdata.cat_id != rowData.cat_id ?
                        <>
                            {
                                recount(rowData.list) != 0 ?
                                    <div style={{paddingLeft:10}}>
                                        <span style={{color:"#af1818"}}>
                                            {
                                                'please recount: ' + recount(rowData.list) + ' Product/s'
                                            }
                                        </span>
                                    </div>
                                    :
                                    null
                            }
                        </>
                    :
                    null
                }
            </div>
            <div style={{ display: openrowdata.cat_id == rowData.cat_id ? "inline-block" : "none", width: "100%" }}>
                <div style={{
                    display: "flex", flexDirection: "row", backgroundColor: "#fff", justifyContent: "flex-start", alignItems:
                        'center', height: 55
                }}>
                    <div onClick={() => setPanelTab('pending')} style={{ backgroundColor: panelTab == 'pending' ? "#c0e9ea" : "#fff", borderBottom: "1px solid #fff", width: 150, paddingTop: 5, paddingBottom: 5, textAlign: "center" }}>
                        <b>Pending</b>
                        <br />
                        {pending(rowData.list)}
                    </div>
                    <div onClick={() => setPanelTab('completed')} style={{ backgroundColor: panelTab == 'completed' ? "#c0e9ea" : "#fff", borderBottom: "1px solid #fff", width: 150, paddingTop: 5, paddingBottom: 5, textAlign: "center", borderLeft: "1px solid #ddd", borderRight: "1px solid #ddd" }}>
                        <b>Completed</b>
                        <br />
                        {completed(rowData.list)}
                    </div>
                    <div onClick={() => setPanelTab('recount')} style={{ backgroundColor: panelTab == 'recount' ? "#df5000" : recount(rowData.list) == 0 ? "#fff" : "#ffe1d1", color: panelTab == 'recount' ? "#fff" : recount(rowData.list) == 0 ? "#000" : "#df5000", borderBottom: "1px solid #fff", width: 150, paddingTop: 5, paddingBottom: 5, textAlign: "center" }}>
                        <b>Re-Count</b>
                        <br />
                        {
                            recount(rowData.list)
                            // ((item.list.recount == 'true').length)
                        }
                    </div>
                </div>
                <div style={{ padding: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10, backgroundColor: "rgb(192 233 234)" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                        <div>
                            <div style={{ width: 30, height: 30, border: "1px solid #ddd", display: "flex", borderRadius: 5, justifyContent: "center", alignItems: "center" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-upc-scan" viewBox="0 0 16 16">
                                    <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
                                </svg>
                            </div>
                        </div>
                        <div>
                            <input onChange={(e) => setSearch(e.target.value)} placeholder={"Search " + rowData.cat_name} style={{ height: 35, width: 300, padding: 5, outline: "none", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                <div style={{ maxHeight: 350, overflow: "scroll" }}>
                    {
                        searchData(rowData?.list, search, panelTab).map((_item, _key) => {
                            if (window.innerWidth <= 768) {
                                return (
                                    <>
                                        <ItemDetails key={_key} rowData={_item} panelTab={panelTab} syncData={syncData} />
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        <ItemDetails key={_key} rowData={_item} panelTab={panelTab} syncData={syncData} />
                                    </>
                                )
                            }
                        })
                    }
                </div>

            </div>
        </div>
    );
}

export default TableGroupName;

import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ENV from '../../../../ENV';
import { Dropdown } from 'primereact/dropdown';
import Delete from './Delete';
import { Tooltip } from 'primereact/tooltip';
import './FormPanel.css'
import swal from 'sweetalert';

const FormPanel = (props) => {

    const upcfield = useRef(null)
    const upcfieldlabel = useRef(null)

    const [openDialog, setOpenDialog] = useState({ open: false })
    const [avatarImage, setAvatarImage] = useState(null)
    const [arrayImage, setimageArray] = useState(null)


    // {
    //     "id_category": props?.item,
    //     "cat_name": props?.item,
    //     "subcat_id": props?.item,
    //     "category_id": props?.item,
    //     "subcat_name": props?.item,
    //     "unit_price": props?.item,
    //     "product_name": props?.item,
    //     "cost_price": props?.item,
    //     "cash_price": props?.item,
    //     "dcash_price": props?.item,
    //     "product_code": props?.item,
    //     "brand_id": props?.item,
    //     "brand_name": props?.item,
    //     "warranty": props?.item,
    //     "specs": props?.item,
    //     "no_serial": props?.item,
    //     "reorder": props?.item
    // }
    const [inputvalue, setInputValue] = useState({
        "pid": Number,
        "cash_price": Number,
        "vat_price": Number,
        "unit_price": Number,
        "dcash_price": Number,
        "id_category": Number,
        "cat_name": String,
        "subcat_id": Number,
        "category_id": Number,
        "subcat_name": String,
        "product_name": String,
        "product_code": String,
        "brand_id": Number,
        "brand_name": String,
        "warranty": String,
        "no_serial": Number,
        "reorder": Number,
        "specs": String,
        "pstatus": Number,
        ...props?.item
    });

    console.log(inputvalue)


    const [suggestedCategory, setSuggesstedCategory] = useState([]);
    const [suggestedSubcategory, setSuggesstedSubcategory] = useState([]);
    const [suggestedBrand, setSuggesstedBrand] = useState([]);
    const [activesearch, setActiveSearch] = useState('');
    const [selecteditemCategory, setSelectedItemCategory] = useState(0);
    const [selecteditemSubcategory, setSelectedItemSubcategory] = useState(0);
    const [selecteditemBrand, setSelectedItemBrand] = useState(0);

    const [focusaction, setFocusAction] = useState({ "cat_name": 0 });

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubCategory] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedWarranty, setSelectedWarranty] = useState(null);



    useMemo(async () => {
        var formData = new FormData();
        formData.append("categorylist", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            setSuggesstedCategory(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("subcategorylist", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            setSuggesstedSubcategory(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("brandlist", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            setSuggesstedBrand(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);



    const hideDialog = (event) => {
        // alert("")
        // console.log(event)
        swal({
            title: props.btn === 'add' ? "Close Add Product!" : "Close Edit Product!",
            text: "are you sure?",
            icon: "info",
            closeOnEsc: false,
            closeOnClickOutside: false,
            buttons: {
                cancel: "No",
                confirm: "Yes"
            }
        }).then((willclose) => {
            if (willclose) {
                setOpenDialog(prev => ({ ...prev, open: false }))
                setInputValue({ pid: Number(0), cash_price: Number(0), vat_price: Number(0), unit_price: Number(0), dcash_price: Number(0) })
                setAvatarImage(null)
            }
        });
    }

    const RefreshWindow = () => {
        swal({
            title: "Reload Page!",
            text: "are you sure?",
            icon: "info",
            closeOnEsc: false,
            closeOnClickOutside: false,
            buttons: {
                cancel: "No",
                confirm: "Yes"
            }
        }).then((willclose) => {
            if (willclose) {
                window.location.reload()
            }
        });
    }

    const _dataCategory = suggestedCategory.filter(item => {
        return item;
    });

    const _dataSubcategory = suggestedSubcategory.filter(item => {
        return item.category_id === inputvalue.id_category
    }); //.slice(0, 10);

    const _dataBrand = suggestedBrand.filter(item => {
        return item
    }); //.slice(0, 10);

    //////////////////////////////////////////
    //////////////////////////////////////////
    //////////////////////////////////////////

    // const _dataBrand = suggestedBrand.filter(item => {
    //     // return item.name.toLowerCase().includes(String(inputvalue.category).trim().toLowerCase())
    //     const searchItem = String(inputvalue.brand_name).toLowerCase()
    //     const name = item.brand_name.toLowerCase()
    //     return searchItem && name.toLowerCase().includes(String(searchItem).trim().toLowerCase()) && item.category_id === inputvalue.id_category && name !== searchItem
    // }).slice(0, 10);

    const handlekeyBrand = (e) => {
        if (selecteditemBrand < _dataBrand.length) {
            if (e.key === 'ArrowUp' && selecteditemBrand > 0) {
                setSelectedItemBrand(prev => prev - 1)
            } else if (e.key === 'ArrowDown' && selecteditemBrand < _dataBrand.length - 1) {
                setSelectedItemBrand(prev => prev + 1)
            } else if (e.key === 'Enter' && selecteditemBrand >= 0) {
                setActiveSearch('')
                setInputValue({ ...inputvalue, 'brand_id': _dataBrand[selecteditemBrand].brand_id, 'brand_name': _dataBrand[selecteditemBrand].brand_name })
            }
        } else {
            setSelectedItemBrand(-1)
        }
    }

    const keypressBrand = (e) => {
        if (e.key === 'Enter') {
            setActiveSearch('')
            setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))
            // console.log(e.target.value)
        }
    }

    const searchBrand = (e) => {
        setActiveSearch(e.target.value)
        setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    // console.log(_dataCategory, inputvalue)

    const editbtn = () => {
        setOpenDialog(prev => ({ ...prev, open: true }))
        setInputValue(props.item)
        // props.setVisible(false);
        // console.log(props.item)
    }

    const AddSubmit = async () => {
        // console.log(inputvalue, inputvalue?.product_name)
        // return;
        if (inputvalue?.product_name !== undefined) {
            var formData = new FormData();
            formData.append("add_product", 1);
            formData.append("data", JSON.stringify(inputvalue));
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'api/product.php',
                // url: 'http://192.168.1.15/stgc/product.php',
                data: formData,
            }).then((res) => {
                if (res.data[0].status === 'success') {
                    swal({
                        title: "Done!",
                        text: "Successfull Saved",
                        icon: "success",
                        timer: 1000,
                        button: false,
                    }).then(() => {
                        props.setProducts([...props.products, {
                            "pid": res.data[0].pid,
                            "id_category": inputvalue.id_category,
                            "cat_name": inputvalue.cat_name,
                            "subcat_id": inputvalue.subcat_id,
                            "subcat_name": inputvalue.subcat_name,
                            "product_name": inputvalue.product_name,
                            "product_code": inputvalue.product_code,
                            "warranty": inputvalue.warranty,
                            "no_serial": inputvalue.no_serial,
                            "reorder": inputvalue.reorder,
                            "unit_price": inputvalue.unit_price,
                            "vat_price": inputvalue.vat_price,
                            "cash_price": inputvalue.cash_price,
                            "dcash_price": inputvalue.dcash_price,
                            "specs": inputvalue.specs,
                            "pstatus": inputvalue.pstatus,
                            "product_image": inputvalue.product_image
                        }]);
                        setInputValue({
                            "pid": Number,
                            "id_category": Number,
                            "cat_name": String,
                            "subcat_id": Number,
                            "subcat_name": String,
                            "product_name": String,
                            "product_code": String,
                            "warranty": String,
                            "no_serial": Number,
                            "reorder": String,
                            "unit_price": Number,
                            "vat_price": Number,
                            "cash_price": Number,
                            "dcash_price": Number,
                            "specs": String,
                            "pstatus": Number,
                            "product_image": String
                        });
                    });
                    setSelectedCategory(Number)
                    setSelectedBrand(Number)
                    setAvatarImage(String)

                } else
                    if (res.data[0].status === 'existing_upc') {
                        upcfield.current.style.border = "1px solid red";
                        upcfieldlabel.current.style.display = "block";
                        upcfieldlabel.current.style.color = "red";
                    }

                console.log(res.data)
            })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            alert("")
        }
    }

    const UpdateSubmit = async () => {

        const index = props.products.findIndex((i) => Number(i.pid) === Number(inputvalue.pid));
        props.products[index].id_category = inputvalue.id_category;
        props.products[index].cat_name = inputvalue.cat_name;
        props.products[index].subcat_id = inputvalue.subcat_id;
        props.products[index].subcat_name = inputvalue.subcat_name;
        props.products[index].brand_id = inputvalue.brand_id;
        props.products[index].brand_name = inputvalue.brand_name;
        props.products[index].product_name = inputvalue.product_name;
        props.products[index].product_code = inputvalue.product_code;
        props.products[index].no_serial = inputvalue.no_serial;
        props.products[index].unit_price = inputvalue.unit_price;
        props.products[index].vat_price = inputvalue.vat_price;
        props.products[index].cash_price = inputvalue.cash_price;
        props.products[index].dcash_price = inputvalue.dcash_price;
        props.products[index].reorder = inputvalue.reorder;
        props.products[index].pstatus = inputvalue.pstatus;
        props.products[index].specs = inputvalue.specs;
        // console.log(props.products)
        var formData = new FormData();
        formData.append("update_product", 1);
        formData.append("data", JSON.stringify(inputvalue));
        formData.append("imagenull", avatarImage);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            // url: 'http://192.168.1.15/stgc/product.php',
            data: formData,
        }).then((res) => {
            swal({
                title: "Done!",
                text: "Successfull Saved",
                icon: "success",
                timer: 1000,
                button: false,
            }).then(() => {
                console.log(res.data)
                if (avatarImage !== null) {
                    props.products[index].product_image = res.data[0].image_link;
                }
                props.setProducts([...props.products]);
                setOpenDialog(prev => ({ ...prev, open: false }))
            });

        })
            .catch((err) => {
                console.log(err);
            });
    }




    // console.log(userInfo)


    const changeHandlerImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            let files = event.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = async (e) => {
                setAvatarImage(e.target.result);
                setInputValue((prev) => ({ ...prev, "product_image": e.target.result }));
            }
        }
    };


    const [displayhover, setDislayHover] = useState(false)
    const MouseOver = (event) => {
        if (event.target.id === 'editimage') {
            setDislayHover(true)
        }
        // console.log(event.target.id)
    }
    const MouseOut = (event) => {
        if (event.target.id === 'editimage') {
            setDislayHover(false)
        }
        // event.target.style.background = "";
        // event.target.style.padding = '';
    }

    const calculate = () => {
        // $('[name="product_price"]').val((parseFloat(cash_price)+(parseFloat(cash_price)*.04)).toFixed(0));
        // $('[name="edit_vat_price"]').val((parseFloat(cash_price)+(parseFloat(cash_price)*.12)).toFixed(0));
        // $('[name="edit_dcash_price"]').val((parseFloat(cash_price)-50).toFixed(0));
        if (inputvalue.cash_price !== 0) {
            setInputValue(prev => ({ ...prev, "unit_price": parseFloat(inputvalue.cash_price) + parseFloat(inputvalue.cash_price * 0.04) }))
            setInputValue(prev => ({ ...prev, "vat_price": parseFloat(inputvalue.cash_price) + parseFloat(inputvalue.cash_price * 0.12) }))
            setInputValue(prev => ({ ...prev, "dcash_price": parseFloat(inputvalue.cash_price - 50) }))
        }
    }

    const isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (e) {
            return false;
        }
    }


    const KEY_NAME_ESC = 'Escape';
    const KEY_EVENT_TYPE = 'keydown';
    const handleEscKey = useCallback((event) => {
        // event.preventDefault();
        if (event.key === KEY_NAME_ESC) {
            hideDialog();
        } else
            if (event.key === 'F5') {
                RefreshWindow()
            }
    }, [hideDialog]);

    useEffect(() => {
        if (openDialog.open === true) {

            window.onbeforeunload = (event) => {
                event.preventDefault();
                return true;
            }

            window.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
            return () => {
                window.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
            };
        }
    }, [openDialog, handleEscKey]);





    return (
        <>

            <Dialog
                blockScroll={true}
                position='top'
                draggable={false}
                style={{ width: '100rem', fontSize: 15 }}
                visible={openDialog.open}
                onHide={hideDialog}
                // onKeyDown={alert("")}
                header={
                    props.btn === 'edit' ?
                        <span style={{ fontSize: 25 }}>Edit Product</span>
                        :
                        <span style={{ fontSize: 25 }}>Add Product</span>
                }
                footer={
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div>
                            {
                                props.item !== undefined ?
                                    <Delete item={props.item} products={props.products} setProducts={props.setProducts} hideDialog={hideDialog} />
                                    : null
                            }
                        </div>
                        <div>
                            {
                                props.btn === "add" ?
                                    <button onClick={AddSubmit} className='btn btn-success btn-sm'>{"Save New"}</button>
                                    : null
                            }
                            {
                                props.btn === "edit" ?
                                    <button onClick={UpdateSubmit} className='btn btn-info btn-sm'>{"Update"}</button>
                                    : null
                            }
                            <button onClick={hideDialog} className='btn btn-danger btn-sm'>{"Close "}</button>
                        </div>
                    </div>
                }
            >
                <div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>

                        <label>
                            <div className='containerimages' style={{ width: 300, height: 300 }}>
                                <input type='file' onChange={(e) => changeHandlerImage(e)} style={{ display: "none" }} />
                                <div className='image' style={{ display: "flex", flexDirection: "row", cursor: "pointer", justifyContent: "center", alignItems: "center", width: 300, height: 300, position: "relative", border: "1px solid #ddd", backgroundImage: props.btn === 'add' ? avatarImage !== null ? `url(${avatarImage})` : `url(${require('./add-image-icon-2048x1908-0v5fxcb2.png')})` : avatarImage !== null ? `url(${avatarImage})` : `url(${ENV.IMG_URL + inputvalue.product_image})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>

                                </div>
                                <div className='middle'>
                                    <div className='text textrow'>
                                        <i className={props.btn === 'add' ? 'pi pi-upload text' : 'pi pi-pencil text'} />
                                        {
                                            props.btn === 'add' ?
                                                (
                                                    avatarImage !== null ?
                                                        <span>Change</span>
                                                        :
                                                        <span>Upload</span>
                                                )
                                                :
                                                <span>Edit</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </label>


                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className='mgtp-10'>
                                        <label>Category</label>


                                        <div style={{ fontSize: 15, width: "100%" }}>
                                            <Dropdown
                                                inputvalue={selectedCategory}
                                                style={{ padding: 0 }}
                                                value={selectedCategory} onChange={(e) => setSelectedCategory(e.value)} options={_dataCategory} optionLabel="cat_name" placeholder="Select Category"
                                                filter valueTemplate={(option, props) => {
                                                    if (option) {
                                                        return (
                                                            <div>
                                                                <div style={{ fontSize: 15 }}>{option?.cat_name}</div>
                                                            </div>
                                                        );
                                                    }

                                                    const index = props.options.findIndex((i) => i.id_category === inputvalue?.id_category)

                                                    return <span style={{ fontSize: 15 }}>{props?.options[index]?.cat_name ?? props.placeholder}</span>;
                                                }} itemTemplate={(option) => {
                                                    return (
                                                        <div>
                                                            <div onClick={() => setInputValue(prev => ({ ...prev, ...option }))} style={{ fontSize: 15 }}>{option.cat_name}</div>
                                                        </div>
                                                    );
                                                }} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='mgtp-10'>
                                        <label>Sub Category</label>


                                        <div style={{ fontSize: 15, width: "100%" }}>
                                            <Dropdown
                                                style={{ padding: 0 }}
                                                value={selectedSubcategory} onChange={(e) => setSelectedSubCategory(e.value)} options={_dataSubcategory} optionLabel="subcat_name" placeholder="Select Category"
                                                filter valueTemplate={(option, props) => {
                                                    if (option) {
                                                        return (
                                                            <div>
                                                                <div onClick={() => setInputValue(prev => ({ ...prev, ...option }))} style={{ fontSize: 15 }}>{option.subcat_name}</div>
                                                            </div>
                                                        );
                                                    }

                                                    const index = props?.options?.findIndex((i) => i.subcat_id === inputvalue?.subcat_id)

                                                    return <span style={{ fontSize: 15 }}>{props?.options[index]?.subcat_name ?? props.placeholder}</span>;
                                                }} itemTemplate={(option) => {
                                                    return (
                                                        <div>
                                                            <div onClick={() => setInputValue(prev => ({ ...prev, ...option }))} style={{ fontSize: 15 }}>{option.subcat_name}</div>
                                                        </div>
                                                    );
                                                }} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className='mgtp-10'>
                                        <label>Product Name</label>
                                        <input name='product_name' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.product_name} className='form-control' />
                                    </div>
                                </div>
                                <div className='col-sm-12'>
                                    <div className='mgtp-10'>
                                        <label>Product Code</label>
                                        <input ref={upcfield} name='product_code' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.product_code} className='form-control' />
                                        <div>
                                            <span ref={upcfieldlabel} style={{ display: "none" }}>Existing Product Code</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className='mgtp-10'>
                                        <label>Brand Name</label>


                                        <div style={{ fontSize: 15, width: "100%" }}>
                                            <Dropdown
                                                style={{ padding: 0 }}
                                                value={selectedBrand} onChange={(e) => setSelectedBrand(e.value)} options={_dataBrand} optionLabel="brand_name" placeholder="Select Brand"
                                                filter valueTemplate={(option, props) => {
                                                    if (option) {
                                                        return (
                                                            <div>
                                                                <div onClick={() => setInputValue(prev => ({ ...prev, ...option }))} style={{ fontSize: 15 }}>{option.brand_name}</div>
                                                            </div>
                                                        );
                                                    }

                                                    const index = props?.options?.findIndex((i) => i.brand_id === inputvalue?.brand_id)

                                                    return <span style={{ fontSize: 15 }}>{props?.options[index]?.brand_name ?? props.placeholder}</span>;
                                                }} itemTemplate={(option) => {
                                                    return (
                                                        <div>
                                                            <div onClick={() => setInputValue(prev => ({ ...prev, ...option }))} style={{ fontSize: 15 }}>{option.brand_name}</div>
                                                        </div>
                                                    );
                                                }} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='mgtp-10'>
                                        <label>Warranty</label>
                                        <select className="form-control" name="warranty" id="warranty"
                                            onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                                            value={inputvalue.no_serial}>
                                            <option value="No Warranty">No Warranty</option>
                                            <option value="7 Days">7 Days</option>
                                            <option value="1 Month">1 Month</option>
                                            <option value="3 Month">3 Month</option>
                                            <option value="6 Months">6 Months</option>
                                            <option value="1 Year">1 Year</option>
                                            <option value="3 Years Warranty Service Center">3 Years Warranty Service Center</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className='mgtp-10'>
                                        <label>Serial</label>
                                        <select className="form-control" style={{ width: '100%' }} id="no_serial" name="no_serial"
                                            onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                                            value={inputvalue.no_serial}>
                                            <option value selected />
                                            <option value={0}>Serial</option>
                                            <option value={1}>Qty</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='mgtp-10'>
                                        <label>Reorder Point</label>
                                        <input type='number' name='reorder' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }))} value={inputvalue.reorder} className='form-control' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className='mgtp-10'>
                                        <label style={{ fontSize: 15 }}>Disc. Cash Price</label>
                                        <input name='dcash_price' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={Math.round(inputvalue.dcash_price)} className='form-control' />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <div className='mgtp-10'>
                                        <label>Vat Price</label>
                                        <input name='vat_price' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={Math.round(inputvalue.vat_price)} className='form-control' />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='mgtp-10'>
                                        <label>SRP Store Price</label>
                                        <input name='unit_price' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={Math.round(inputvalue.unit_price)} className='form-control' />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='mgtp-10'>
                                        <label>Cash Price</label>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <Tooltip target=".custom-target-icon" style={{ fontSize: 15 }} />
                                            <input name='cash_price' style={{ border: "2px solid #000" }} onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={Math.round(inputvalue.cash_price)} className='form-control' />
                                            <button className='custom-target-icon form-control' data-pr-position="left" data-pr-tooltip="Calculate" onClick={(e) => calculate()} style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: 40 }}><i className='pi pi-calculator' style={{ fontSize: 20 }} /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className='mgtp-10'>
                                        <label>Specification </label>
                                        <textarea name='specs' style={{ height: 106, minHeight: 106 }} onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.specs} className='form-control' />
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='mgtp-10'>
                                        <label>Status</label>
                                        <select className="form-control" style={{ width: '100%' }} id="pstatus" name="pstatus"
                                            onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                                            value={inputvalue.pstatus}>
                                            <option value selected />
                                            <option value="0">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className='col-sm-12'>
                                    <div>
                                        <label>Measurement Unit </label>

                                        <div className='col-sm-12' style={{display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between"}}>
                                            <div style={{display:"grid", justifyContent: "center"}}>
                                                <label>Litre</label>
                                                <input name='dcash_price' type='checkbox' onChange={(e) => setInputValue(prev=>({ ...prev, [e.target.name]: e.target.value }))} />
                                            </div>
                                            <div style={{display:"grid", justifyContent: "center"}}>
                                                <label>Millimeter</label>
                                                <input name='dcash_price' type='checkbox' onChange={(e) => setInputValue(prev=>({ ...prev, [e.target.name]: e.target.value }))} />
                                            </div>
                                            <div style={{display:"grid", justifyContent: "center"}}>
                                                <label>Kilogram</label>
                                                <input name='dcash_price' type='checkbox' onChange={(e) => setInputValue(prev=>({ ...prev, [e.target.name]: e.target.value }))} />
                                            </div>
                                            <div style={{display:"grid", justifyContent: "center"}}>
                                                <label>Milligram</label>
                                                <input name='dcash_price' type='checkbox' onChange={(e) => setInputValue(prev=>({ ...prev, [e.target.name]: e.target.value }))} />
                                            </div>
                                            <div style={{display:"grid", justifyContent: "center"}}>
                                                <label>Kilometer</label>
                                                <input name='dcash_price' type='checkbox' onChange={(e) => setInputValue(prev=>({ ...prev, [e.target.name]: e.target.value }))} />
                                            </div>
                                            <div style={{display:"grid", justifyContent: "center"}}>
                                                <label>Meter</label>
                                                <input name='dcash_price' type='checkbox' onChange={(e) => setInputValue(prev=>({ ...prev, [e.target.name]: e.target.value }))} />
                                            </div>
                                        </div>                                    
                                    </div>
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>
            </Dialog>





            {
                props.btn === 'edit' ?
                    <>
                        <Tooltip target=".custom-target-icon" style={{ fontSize: 15 }} />
                        <span onClick={() => {
                            editbtn();
                        }} onMouseOver={MouseOver} onMouseOut={MouseOut} className='custom-target-icon btn btn-sm btn-info'
                            data-pr-position="left" data-pr-tooltip="Edit" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}><i className='pi pi-pencil' style={{ fontSize: 15 }}></i></span>
                    </>
                    :
                    <>
                        <span onClick={() => {
                            setOpenDialog(prev => ({ ...prev, open: true, btn: props.btn === 'add' ? 'add' : 'edit' }))
                            setSelectedCategory(null)
                        }} className='btn btn-sm btn-primary' > Add New Product</span >
                    </>
                // btn btn-sm btn-primary
            }
        </>
    );
}

export default FormPanel;

import { SplitButton } from 'primereact/splitbutton';
import React, { useRef, useState } from 'react';

const SplitButtonSalesStock = (props) => {

    //const router = useRouter();
    const toast = useRef(null);
    const items = [
        {
            label: 'Edit',
            icon: 'pi pi-pencil',
            command: () => {
                console.log(props.item)
            }
        },
        {
            label: 'Remove',
            icon: 'pi pi-times',
            command: () => {
                toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted' });
            }
        }
    ];

    const [loading, setLoading] = useState(false);

    const save = () => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Data Saved' });
        }, 2000);
    };


    return (
        <>
            <SplitButton className='btn btn-xs' label="Details" menuStyle={{ fontSize: 13, width: 200 }} onClick={save} model={items} />
        </>
    );
}

export default SplitButtonSalesStock;

import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useMemo, useState } from 'react';
import ENV from '../../../ENV';

const EditInformation = (props) => {
    const [visibleRight, setVisibleRight] = useState(false);
    const [inputvalue, setInputValue] = useState(props.item);
    const [users, setUsers] = useState([]);

    // sales, setSales
    // console.log(inputvalue)

    useMemo(async()=>{
        var formData = new FormData();
        formData.append("users", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/sales_report.php',
            data: formData,
        }).then((res) => {
            setUsers(res.data)
            // console.log(res.data)
        });
    }, [ENV])

    const Salesname = useMemo(() => {
        return users.filter((i)=>i.user_id === props.item.sale_id)[0]?.firstname
    }, [users, props])


    const btnupdate = async () => {

        const index = props.sales.findIndex((e)=>e.sales_id === props.item.sales_id);
        props.sales[index].customer_name = inputvalue.customer_name;
        props.sales[index].contact = inputvalue.contact;
        props.sales[index].address = inputvalue.address;
        props.sales[index].user_id = inputvalue.user_id;
        props.sales[index].sale_id = inputvalue.sale_id;
        props.sales[index].note = inputvalue.note;
        props.sales[index].receipt_no = inputvalue.receipt_no;
        console.log(props.sales)
        
        props.setInputValue(inputvalue)
        props.setSales(props.sales)
        // var formData = new FormData();
        // formData.append("users", 1);
        // await axios({
        //     method: "post",
        //     url: ENV.DOMAIN + 'api/sales_report.php',
        //     data: formData,
        // }).then((res) => {
        //     setUsers(res.data)
        //     // console.log(res.data)
        // });
        setVisibleRight(false)
    }

    const handleclose = () => {
        setVisibleRight(false)
        setInputValue(props.item)
    }


    return (
        <>
            <Sidebar icons={
                <div style={{fontSize: 20, fontWeight: "bolder"}}>
                    Edit Details
                </div>
            } showCloseIcon={false} blockScroll={true} visible={visibleRight} position="right" onHide={handleclose} style={{ width: "25%" }}>
                <div>
                    <div style={{fontSize: 18, fontWeight: "bolder"}}>Client Information</div>
                    <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                        <div>
                            <label style={{fontSize: 13}}>Client Name</label>
                            <input name='customer_name' onChange={(e)=>setInputValue((prev)=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.customer_name} className='form-control font' />
                        </div>
                        <div>
                            <label style={{fontSize: 13}}>Contact</label>
                            <input name='contact' onChange={(e)=>setInputValue((prev)=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.contact} className='form-control' />
                        </div>
                        <div>
                            <label style={{fontSize: 13}}>Address</label>
                            <textarea rows={5} name='address' onChange={(e)=>setInputValue((prev)=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.address} className='form-control' />
                        </div>
                        <div>
                            <label style={{fontSize: 13}}>Note</label>
                            <textarea rows={5} name='note' onChange={(e)=>setInputValue((prev)=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.note} className='form-control' />
                        </div>
                    </div>
                    <hr />
                    <div style={{fontSize: 18, fontWeight: "bolder"}}>Receipt Information</div>
                    <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                        <div>
                            <label style={{fontSize: 13}}>Cashier</label>
                            <select name='user_id' onChange={(e)=>setInputValue((prev)=>({...prev, [e.target.name]: e.target.value}))} defaultValue={inputvalue.user_id} className='form-control'>
                                {
                                    users.map((item, key)=>{
                                        return (
                                            <option value={item.user_id}>{item.firstname}</option>
                                        )
                                    })
                                }
                                <option></option>
                            </select>
                        </div>
                        <div>
                            <label style={{fontSize: 13}}>Assisted By</label>
                            <select name='sale_id' onChange={(e)=>setInputValue((prev)=>({...prev, [e.target.name]: e.target.value}))} defaultValue={inputvalue.sale_id} className='form-control'>
                                {
                                    users.map((item, key)=>{
                                        return (
                                            <option value={item.user_id}>{item.firstname}</option>
                                        )
                                    })
                                }
                                <option></option>
                            </select>
                        </div>
                        <div>
                            <label style={{fontSize: 13}}>Receipt No</label>
                            <input name='receipt_no' onChange={(e)=>setInputValue((prev)=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.receipt_no} className='form-control' />
                        </div>
                        <div style={{padding: "10px 5px 0px 0px"}}>
                            <button onClick={()=>btnupdate()} className='btn btn-info btn-block btn-sm fontSize18'>Update</button>
                        </div>
                    </div>
                </div>
            </Sidebar>
            <button onClick={()=>setVisibleRight(true)} style={{ width: 100, height: 30, fontSize: 12, fontWeight: "bold", borderRadius: 5, border: "1px solid #ddd", color: "#000" }}>Edit</button>
        </>
    );
}

export default EditInformation;

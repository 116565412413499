import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

const PaymentHistory = ({OnlySalesData}) => {
    const [addcustomerdialog, setAddCustomerDialog] = useState(false);

    const hideCustomerDialog = () => {
        setAddCustomerDialog(false);
        // setSearchExistingCustomer(false)
    }

    const CustomerDialogFooter = (
        <React.Fragment>
            
        </React.Fragment>
    );
    return (
        <>
            <Dialog visible={addcustomerdialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                <span style={{ fontSize: 25 }}>Payment History</span>
            } modal className="p-fluid" footer={CustomerDialogFooter} onHide={hideCustomerDialog}>
                {/* {JSON.stringify(OnlySalesData.shistory)} */}
                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center"}}>
                    <div style={{fontWeight:"bold", fontSize:15}}>Record: {OnlySalesData.payment_history.length}</div>
                </div>
                <div style={{maxHeight:500, overflowY:"auto"}} className='hidescroll'>
                    <div style={{display:"flex", flexDirection:"column", gap:3}}>
                        {
                            OnlySalesData.payment_history.map((item)=>{
                                return (
                                    <>
                                        {
                                            item.title === 'payment' && item.type === 'add' ?
                                            <div>
                                                <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#a0faa9", padding:5}}>
                                                    <div style={{fontWeight:600, fontSize:18, color:"#fff"}}>PAYMENT Added</div>
                                                    <div style={{fontWeight:600, fontSize:12, color:"#fff"}}>{item.date_time}</div>
                                                </div>
                                                <div style={{backgroundColor:"#ddd", padding:5}}>
                                                    {
                                                        item.data.credited === "Cash" ?
                                                        <>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.credited ?? null}</div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                                                <div style={{width:"45%"}}>
                                                                    <div>Amount: {item.data.amount_value}</div>
                                                                    <div>Date/Time: {item.data.date} {item.data.time}</div>
                                                                    <div>Added By: {item.addedby}</div>
                                                                </div>                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        item.data.credited === "GCash" ?
                                                        <>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.type ?? null}</div>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.credited ?? null}</div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-start"}}>
                                                                <div style={{width:"45%"}}>
                                                                    <div>GCash Account Name: {item.data.payment_desc.account_name}</div>
                                                                    <div>GCash Number: {item.data.payment_desc.gcash_number}</div>
                                                                    <div>GCash Ref: {item.data.payment_desc.reference_number}</div>
                                                                    <div>Amount: {item.data.amount_value}</div>
                                                                </div>   
                                                                <div style={{width:"45%"}}>
                                                                    <div>Date/Time: {item.data.date} {item.data.time}</div>
                                                                    <div>Added By: {item.addedby}</div>
                                                                </div>                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        item.data.credited === "Maya" ?
                                                        <>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.type ?? null}</div>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.credited ?? null}</div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-start"}}>
                                                                <div style={{width:"45%"}}>
                                                                    <div>Maya Account Name: {item.data.payment_desc.account_name}</div>
                                                                    <div>Maya Number: {item.data.payment_desc.maya_number}</div>
                                                                    <div>Maya Ref: {item.data.payment_desc.reference_number}</div>
                                                                    <div>Amount: {item.data.amount_value}</div>
                                                                </div> 
                                                                <div style={{width:"45%"}}>
                                                                    <div>Date/Time: {item.data.date} {item.data.time}</div>
                                                                    <div>Added By: {item.addedby}</div>
                                                                </div>                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        item.data.credited === "HomeCredit" ?
                                                        <>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.type ?? null}</div>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.credited ?? null}</div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-start"}}>
                                                                <div style={{width:"45%"}}>
                                                                    <div>Agent Name: {item.data.payment_desc.agent_name}</div>
                                                                    <div>Amount: {item.data.amount_value}</div>
                                                                </div>   
                                                                <div style={{width:"45%"}}>
                                                                    <div>Date/Time: {item.data.date} {item.data.time}</div>
                                                                    <div>Added By: {item.addedby}</div>
                                                                </div>                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        item.data.credited === "Card Swipe" ?
                                                        <>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.credited ?? null}</div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-start"}}>
                                                                <div style={{width:"45%"}}>
                                                                    <div>Bank Name: {item.data.payment_desc.bank_name}</div>
                                                                    <div>Card Type: {item.data.payment_desc.card_type}</div>
                                                                    <div>Account Name: {item.data.payment_desc.account_name}</div>
                                                                    <div>Account Number: {item.data.payment_desc.account_number}</div>
                                                                    <div>Amount: {item.data.amount_value}</div>
                                                                </div>  
                                                                <div style={{width:"45%"}}>
                                                                    <div>Trace Number: {item.data.payment_desc.trace_number}</div>
                                                                    <div>Reference Number: {item.data.payment_desc.reference_number}</div>
                                                                    <div>Date/Time: {item.data.date} {item.data.time}</div>
                                                                    <div>Added By: {item.addedby}</div>
                                                                </div>                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        item.data.credited === "Cheque" ?
                                                        <>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.credited ?? null}</div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-start"}}>
                                                                <div style={{width:"45%"}}>
                                                                    <div>Bank Name: {item.data.payment_desc.bank_name}</div>
                                                                    <div>Issuer Name: {item.data.payment_desc.issuer_name}</div>
                                                                    <div>Cheque Number: {item.data.payment_desc.cheque_number}</div>
                                                                    <div>Amount: {item.data.amount_value}</div>
                                                                </div> 
                                                                <div style={{width:"45%"}}>
                                                                    <div>Date/Time: {item.data.date} {item.data.time}</div>
                                                                    <div>Date Issued: {item.data.payment_desc.date_issued}</div>
                                                                    <div>Added By: {item.addedby}</div>
                                                                </div>                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            :null
                                        }
                                        {
                                            item.title === 'payment' && item.type === 'update' ?
                                                <div>
                                                    <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#326fd1", padding:5}}>
                                                        <div style={{fontWeight:600, fontSize:18, color:"#fff"}}>PAYMENT Updated</div>
                                                        <div style={{fontWeight:600, fontSize:12, color:"#fff"}}>{item.date_time}</div>
                                                    </div>
                                                    <div style={{backgroundColor:"#ddd", padding:5}}>
                                                        {
                                                            item.data.credited === "Cash" ?
                                                                <>
                                                                    <div style={{ fontWeight: "bold", fontSize: 15 }}>{item.data.credited ?? null}</div>
                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <div style={{ width: "45%" }}>
                                                                            <div>Amount: {item.data.amount_value}</div>
                                                                            <div>Date/Time: {item.data.date} {item.data.time}</div>
                                                                            <div>Updated By: {item.addedby}</div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        {
                                            item.title === 'payment' && item.type === 'remove' ?
                                            <div>
                                                <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#f0afbc", padding:5}}>
                                                    <div style={{fontWeight:600, fontSize:18, color:"#fff"}}>PAYMENT Removed</div>
                                                    <div style={{fontWeight:600, fontSize:12, color:"#fff"}}>{item.date_time}</div>
                                                </div>
                                                <div style={{backgroundColor:"#ddd", padding:5}}>
                                                    {
                                                        item.data.credited === "Cash" ?
                                                        <>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.credited ?? null}</div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                                                <div style={{width:"45%"}}>
                                                                    <div>Amount: {item.data.amount_value}</div>
                                                                    <div>Date/Time: {item.data.date} {item.data.time}</div>
                                                                    <div>Removed By: {item.removedby}</div>
                                                                </div>                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        item.data.credited === "GCash" ?
                                                        <>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.type ?? null}</div>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.credited ?? null}</div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-start"}}>
                                                                <div style={{width:"45%"}}>
                                                                    <div>GCash Account Name: {item.data.payment_desc.account_name}</div>
                                                                    <div>GCash Number: {item.data.payment_desc.gcash_number}</div>
                                                                    <div>GCash Ref: {item.data.payment_desc.reference_number}</div>
                                                                    <div>Amount: {item.data.amount_value}</div>
                                                                </div>   
                                                                <div style={{width:"45%"}}>
                                                                    <div>Date/Time: {item.data.date} {item.data.time}</div>
                                                                    <div>Removed By: {item.removedby}</div>
                                                                </div>                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        item.data.credited === "Maya" ?
                                                        <>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.type ?? null}</div>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.credited ?? null}</div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-start"}}>
                                                                <div style={{width:"45%"}}>
                                                                    <div>Maya Account Name: {item.data.payment_desc.account_name}</div>
                                                                    <div>Maya Number: {item.data.payment_desc.maya_number}</div>
                                                                    <div>Maya Ref: {item.data.payment_desc.reference_number}</div>
                                                                    <div>Amount: {item.data.amount_value}</div>
                                                                </div> 
                                                                <div style={{width:"45%"}}>
                                                                    <div>Date/Time: {item.data.date} {item.data.time}</div>
                                                                    <div>Removed By: {item.removedby}</div>
                                                                </div>                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        item.data.credited === "HomeCredit" ?
                                                        <>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.type ?? null}</div>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.credited ?? null}</div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-start"}}>
                                                                <div style={{width:"45%"}}>
                                                                    <div>Agent Name: {item.data.payment_desc.agent_name}</div>
                                                                    <div>Amount: {item.data.amount_value}</div>
                                                                </div>   
                                                                <div style={{width:"45%"}}>
                                                                    <div>Date/Time: {item.data.date} {item.data.time}</div>
                                                                    <div>Removed By: {item.removedby}</div>
                                                                </div>                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        item.data.credited === "Card Swipe" ?
                                                        <>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.credited ?? null}</div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-start"}}>
                                                                <div style={{width:"45%"}}>
                                                                    <div>Bank Name: {item.data.payment_desc.bank_name}</div>
                                                                    <div>Card Type: {item.data.payment_desc.card_type}</div>
                                                                    <div>Account Name: {item.data.payment_desc.account_name}</div>
                                                                    <div>Account Number: {item.data.payment_desc.account_number}</div>
                                                                    <div>Amount: {item.data.amount_value}</div>
                                                                </div>  
                                                                <div style={{width:"45%"}}>
                                                                    <div>Trace Number: {item.data.payment_desc.trace_number}</div>
                                                                    <div>Reference Number: {item.data.payment_desc.reference_number}</div>
                                                                    <div>Date/Time: {item.data.date} {item.data.time}</div>
                                                                    <div>Removed By: {item.removedby}</div>
                                                                </div>                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        item.data.credited === "Cheque" ?
                                                        <>
                                                            <div style={{fontWeight:"bold", fontSize:15}}>{item.data.credited ?? null}</div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-start"}}>
                                                                <div style={{width:"45%"}}>
                                                                    <div>Bank Name: {item.data.payment_desc.bank_name}</div>
                                                                    <div>Issuer Name: {item.data.payment_desc.issuer_name}</div>
                                                                    <div>Cheque Number: {item.data.payment_desc.cheque_number}</div>
                                                                    <div>Amount: {item.data.amount_value}</div>
                                                                </div> 
                                                                <div style={{width:"45%"}}>
                                                                    <div>Date/Time: {item.data.date} {item.data.time}</div>
                                                                    <div>Date Issued: {item.data.payment_desc.date_issued}</div>
                                                                    <div>Removed By: {item.removedby}</div>
                                                                </div>                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            :null
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                </div>



            </Dialog>
            <button onClick={() => setAddCustomerDialog(true)} style={{ borderRadius: 5, border: "1px solid rgb(35, 112, 158)" }}><i className='pi pi-list' /> History</button>
        </>
    );
}

export default PaymentHistory;

import axios from 'axios';
import React from 'react';
import ENV from '../../ENV';
import { format } from 'date-fns';

const RemoveItemBtn = ({ OnlySalesData, item, PreviewDetails }) => {



    const removeitemLoop = async (items) => {
        // const data = OnlySalesData.list;

        const intex = OnlySalesData.list.findIndex(x => x.sales_stock_id === items?.sales_stock_id);
        // data[intex].stock_status = 'removed';
        // OnlySalesData.list = data;   
        // && val.stock_status!='removed'     
        // const data = OnlySalesData.list.filter((val) => val.sales_stock_id !== items?.sales_stock_id);
        // OnlySalesData.list = data;
        // OnlySalesData.list.options = [];
        console.log(items?.options)
        OnlySalesData.list[intex].history = [...OnlySalesData.list[intex].history, {
            "sales_id": Number(OnlySalesData.sales_id),
            "user_id": localStorage.getItem('user_id'),
            "employee_name": localStorage.getItem('firstname'),
            "particular": null,
            "note": null,
            "date_time": format(new Date(), 'yyyy-MM-dd HH:mm:ss')
        }];
        
        OnlySalesData.shistory = [...OnlySalesData.shistory, {title: 'item', type: 'remove', date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), data: {
            "sales_id": Number(OnlySalesData.sales_id),
            "user_id": localStorage.getItem('user_id'),
            "employee_name": localStorage.getItem('firstname'),
            "particular": null,
            "note": null,
            "date_time": items?.datee,
            "sales_stock_id": items?.sales_stock_id,
            "product_id": items?.product_id,
            "product_name": items?.product_name,
            "product_code": items?.product_code,
            "serial_number": items?.serial_number,
            "sales_qty": items?.sales_qty,
            "options": items?.product_name === 'Services' ? items?.product_name : items?.options.length>0 ? JSON.parse(items?.options)[0].name : null //items?.options?.name
        }}];
        // console.log(1, OnlySalesData)
        // console.log(items?.sales_stock_id, OnlySalesData)
        // console.log(2, OnlySalesData)

        // const data = OnlySalesData.list.filter((val) => val.sales_stock_id !== items?.sales_stock_id);
        // OnlySalesData.list = data;
        // PreviewDetails(OnlySalesData);
        // return;


        //////////////////
        var formData = new FormData();
        formData.append("sales_item_remove", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("sales_id", Number(OnlySalesData.sales_id));
        formData.append("sales_stock_id", items?.sales_stock_id);
        formData.append("status", 'removed');
        formData.append("history", JSON.stringify(OnlySalesData.list[intex].history));
        formData.append("shistory", JSON.stringify(OnlySalesData.shistory));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'sales_report.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            const data = OnlySalesData.list.filter((val) => val !== items);
            OnlySalesData.list = data;
            PreviewDetails(OnlySalesData);
            
            if (res.data === 'success') {
                // PreviewDetails(OnlySalesData);
                // sales_report_list()
            } else {

            }
        });
    }


    return (
        <div>
            <a onClick={() => removeitemLoop(item)} style={{ color: "#eb2525", cursor: "pointer", textDecoration: "underline" }}>remove</a>
        </div>
    );
}

export default RemoveItemBtn;

import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

const ViewSalesStockReport = (props) => {
    const [visible, setVisible] = useState(null)




    return (
        <>
            <Dialog
                blockScroll={true}
                draggable={false}
                position={"top"}
                visible={visible} 
                style={{ width: '40vw' }} 
                onHide={() => setVisible(false)}
            >



            </Dialog>
            <div style={{ border: "1px solid #ddd", backgroundColor: "rgb(232 230 230)", borderRadius: 5, padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", position: "relative" }}>
                <div>
                    <div>
                        <span style={{ fontWeight: "bolder", fontSize: 20 }}>{props.stock.customer_name}</span>
                    </div>
                    <div>
                        <span style={{ fontSize: 15 }}>SALES No.: </span>
                        <span style={{ fontWeight: "bolder", fontSize: 15, textDecoration: "underline", color: "green" }}>{props.stock.sales_id.padStart(10, '0')}</span>
                    </div>
                    <div>
                        <span style={{ fontSize: 15 }}>S.N.: </span>
                        <span style={{ fontWeight: "bolder", fontSize: 15 }}>{props.stock.serial_number}</span>
                    </div>
                    <div>
                        <span style={{ fontSize: 15 }}>date: </span>
                        <span style={{ fontWeight: "bolder", fontSize: 15 }}>{props.stock.sales_date} {props.stock.sales_time}</span>
                    </div>
                    {/* <div>
                        <span style={{ fontSize: 15 }}>
                            <a onClick={() => setVisible(true)} style={{ textDecoration: "underline", cursor: "pointer" }}>View Details</a>
                        </span>
                    </div> */}
                </div>
                <div style={{padding: 10, position: "absolute", right: 0, bottom: 0}}>
                    <div>
                        <span style={{ fontSize: 20 }}>Qty: </span>
                        <span style={{ fontWeight: "bolder", fontSize: 50 }}>{props.stock.sales_qty}</span>
                    </div>
                </div>
                {/* <hr />
                <div>
                    <div>
                        <span style={{fontSize: 15}}>Return Date: </span>
                        <span style={{fontWeight: "bolder", fontSize: 15}}>0000-00-00</span>
                    </div>
                    <div style={{display: "flex", flexDirection:"row", gap: 10}}>
                        <span style={{fontSize: 15}}>Action: </span>
                        <span style={{fontSize: 15}}>Replace</span>
                        <span style={{fontSize: 15}}>RMA</span>
                    </div>
                </div>
                <div>
                    <span style={{fontSize: 15}}>Notes: </span>
                    <span style={{fontWeight: "bolder", fontSize: 15}}>-----</span>
                </div> */}
            </div>
        </>
    );
}

export default ViewSalesStockReport;

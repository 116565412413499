import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

const StockHistory = ({setSelectStockId, item}) => {
    const [historyDialog, setHistoryDialog] = useState(false)

    const hideDialog = () => {
        setHistoryDialog(false)
    }



    


    return (
        <div>
            <Dialog visible={historyDialog} position={"top"} resizable={false} draggable={false} style={{ width: '100rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={"History [Serial: "+item.serial+"]"} modal className="p-fluid" onHide={hideDialog}>
                <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                    {
                        [1, 2, 3, 4, 5].map((item) => {
                            return (
                                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", border: "1px solid #ddd", borderRadius: 5 }}>
                                    <div style={{ width: "100%" }}>
                                        <div style={{ padding: 5 }}>
                                            asdas hdasdas dasd asdasa sd jashda
                                            daa sdkabshda sb addad haskdbjas kaskdj aj sdasfsddadkbsdf
                                            sdfsdfsdfsf
                                            sdfsdfsdfsffas;kdjf lsjdnf
                                            sfslfsdflksdk nsd
                                            fsdkf hsk flskdhfkjsbdkjf sldf sbdfkj sdf kjsdfl sf
                                            sfls fsldfjsbdfksfkjskdfhksdfksf
                                            sfls fsdjfksk jhfksjhdf ks hd kfsk dfhshfs
                                            fsfhs fksjdfksdfshdfksdf jshghdfs fs
                                            fskfbskdf

                                        </div>
                                        <div style={{ paddingLeft: 5, backgroundColor: "#f1f1f1", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                            <div style={{ width: 100 }}>
                                                type: {item === 1 ? 'Add' : null} {item === 2 ? 'Edit' : null} {item === 3 ? 'Delete' : null}
                                            </div>
                                            <div style={{ width: 150 }}>
                                                name: name
                                            </div>
                                            <div style={{ width: 150 }}>
                                                0000-00-00 00:00:00
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Dialog>
            
            <button onClick={() => setHistoryDialog(true)} style={{ width: null, backgroundColor: "#fff", color: "#000", border: "none", border: "1px solid #ddd", borderRadius: 5 }}>
                <span className='pi pi-history'></span> History
            </button>
        </div>
    );
}

export default StockHistory;

import React, { useState } from 'react';
import RelativeBtn from './extra/RelativeBtn';
import { Button } from 'primereact/button';

const RelativesTable = (props) => {
    const [relativelist, setRelativesList] = useState(props?.item?.relatives.length > 0 ? props?.item?.relatives : [])


    console.log(relativelist)
    return (
        <>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                <h3 className="font-semibold"> RELATIVES</h3>
                {
                    relativelist.length>0 ? (
                        <RelativeBtn item={props?.item} label={"Add New"} employeelist={props.employeelist} setEmployeeList={props.setEmployeeList} setRelativesList={setRelativesList} />
                    ) : null
                }
            </div>
            <div className="content-list content-menu">
                {
                    
                    relativelist.length>0 ? (
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th style={{fontSize: 15}}>Full Name</th>
                                    <th style={{fontSize: 15}}>Contact No.</th>
                                    <th style={{fontSize: 15}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    relativelist.map((i, k) => {
                                        return (
                                            <tr key={k}>
                                                <td style={{fontSize: 15}}>{i.firstname} {i.middlename} {i.lastname}</td>
                                                <td style={{fontSize: 15}}>{i.contact}</td>
                                                <td style={{width: 50}}>
                                                    <RelativeBtn item={props?.item} row={i} relativelist={relativelist} employeelist={props.employeelist} setEmployeeList={props.setEmployeeList} setRelativesList={setRelativesList} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>  
                    ) : (
                        <div style={{width:"100%", backgroundColor: "#f5f5f5", height: 100, display:"flex", flexDirection: "row", justifyContent:"center", alignItems: "center"}}>
                            <RelativeBtn item={props?.item} label={"Add New"} employeelist={props.employeelist} setEmployeeList={props.setEmployeeList} setRelativesList={setRelativesList} />
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default RelativesTable;

import { OverlayPanel } from 'primereact/overlaypanel';
// import { Sidebar } from 'primereact/sidebar';
import React, { useMemo, useRef, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';

const CustomerReviewDetails = (props) => {
    // const [visible, setVisible] = useState(false);
    // const [selectedPayment, setSelectedPayment] = useState(false);
    // const [selectedOnlinePayment, setSelectedOnlinePayment] = useState(false);


    const op = useRef(null);

    const OpenOverlay = (e) => {
        op.current.toggle(e)
    }
    const CloseOverlay = (e) => {
        op.current.hide(e)
    }

    return (
        <>
            <OverlayPanel ref={op} style={{ width: 600, border: "2px solid #000" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                    <div>
                        <span style={{ fontSize: 20, fontWeight: "bolder" }}>Customer Review</span>
                    </div>
                    <div className='well' style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                        <div>
                            <span style={{ fontSize: 20 }}>Fullname / Company Name</span>
                            <div style={{ fontSize: 25 }}>
                                <a href={`/report/purchases/${props.inputvalue?.id_customer}`} target='_blank' style={{ color: "rgb(35, 112, 158)", textDecoration: "underline" }}>{props.inputvalue?.customer_name}</a>
                            </div>
                        </div>
                        <div>
                            <span style={{ fontSize: 20 }}>Mobile / Telephone</span>
                            <div style={{ fontSize: 25 }}>{props.inputvalue?.customer_contact}</div>
                        </div>
                        {
                            props.inputvalue?.customer_address !== '' ?
                                <div>
                                    <span style={{ fontSize: 20 }}>Address</span>
                                    <div style={{ fontSize: 25 }}>{props.inputvalue?.customer_address}</div>
                                </div>
                                : null
                        }
                        {
                            props.inputvalue?.contact_person !== '' ?
                                <div>
                                    <span style={{ fontSize: 20 }}>Contact Person / Contact Number</span>
                                    <div style={{ fontSize: 25 }}>{props.inputvalue?.contact_person}</div>
                                </div>
                                : null
                        }
                        {
                            props.inputvalue?.email_address !== '' ?
                                <div>
                                    <span style={{ fontSize: 20 }}>Email Address</span>
                                    <div style={{ fontSize: 25 }}>{props.inputvalue?.email_address}</div>
                                </div>
                                : null
                        }
                        {
                            props.inputvalue?.tin !== '' ?
                                <div>
                                    <span style={{ fontSize: 20 }}>Tin</span>
                                    <div style={{ fontSize: 25 }}>{props.inputvalue?.tin}</div>
                                </div>
                                : null
                        }
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                            <button onClick={() => CloseOverlay()} className='btn btn-info'>
                                Edit
                            </button>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                            <button onClick={() => CloseOverlay()} className='btn btn-danger'>
                                Close
                            </button>
                            {/* <button onClick={() => SaveNewBtn()} className='btn btn-info btn-sm'>
                                Save New
                            </button> */}
                        </div>
                    </div>
                </div>
            </OverlayPanel>
            {/* onClick={() => setVisible(true)} */}
            {/* <button style={{ width: 45, height: 45, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center" }}><i className='pi pi-list' style={{fontSize: 18}} /></button> */}
            {
                props.inputvalue.id_customer !== 0 ?
                    <button onClick={OpenOverlay} title='Review' style={{ width: 45, height: 45, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center" }}><i className='pi pi-eye' style={{ fontSize: 18 }} /></button>
                    : null
            }
            {/* <button onClick={OpenOverlay} style={{width: 45, height: 45, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <i className='pi pi-plus' style={{fontSize: 18}} />
            </button> */}
            {/* <label onClick={() => setVisible(true)} style={{ fontSize: 20, width: 200, textAlign: "center", height: 40, color: "#000", padding: 5, borderRadius: 5, border: "2px solid #ddd", backgroundColor: "#fff" }}>
                Add Payment
            </label> */}
        </>
    );
}

export default CustomerReviewDetails;

import React, { useEffect, useState, useMemo, useRef } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import Header from './component/Header';
import Navbar from './component/Navbar';
import DataTable from 'datatables.net-dt';
import $ from 'jquery';












import { classNames } from 'primereact/utils';
// import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from './ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from "primereact/chips";
import ViewStockBtn from './component/product/ViewStockBtn';
import EditProductBtn from './component/product/EditProductBtn';
import DeleteProductBtn from './component/product/DeleteProductBtn';
import AddProductBtn from './component/product/AddProductBtn';
import ShowDetails from './component/product/showDetails';
import Table from './component/product/Table';
import FormPanel from './component/product/extra/product/FormPanel';
import TableButton from './component/customer/TableButton';
import Panel from './component/customer/Panel';


const Customer = () => {
    document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix" data-active="dashboard ';
    document.body.id = 'dashboard';

    const [customer, setCustomer] = useState([]);
    const [productsforcat, setProductsforCat] = useState([]);
    const [search, setSearch] = useState('');
    // const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [productlist, setProductlist] = useState([]);

    const [openpanel, setOpenPanel] = useState(null)

    const [show_stock_status, setShowStockStatus] = useState(null)

    const [nodes, setNodes] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState({});

    useMemo(async () => {
        var formData = new FormData();
        formData.append("customer", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/customer.php',
            data: formData,
        }).then((res) => {
            setCustomer(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    const searchData = (search, data) => {
        if (search != '') {
            data = data?.filter((i) => i?.product_name?.toLowerCase().includes(search?.toLowerCase()) || i?.product_code?.toLowerCase().includes(search?.toLowerCase()) || i?.keyword_search?.toLowerCase().includes(search?.toLowerCase()));
        }
        console.log(data)
        return data;
    }

    const ProductSync = async () => {
        var formData = new FormData();
        formData.append("product_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            // setProducts([...res.data])
            setProductsforCat([...res.data])
        })
            .catch((err) => {
                console.log(err);
            });
    }

    // useMemo(async () => {
    //     const mainData = Object.keys(selectedKeys).map((e) => {
    //         return productsforcat.filter((item) => {
    //             if (e.split('-')[0] != undefined && e.split('-')[1] != undefined) {
    //                 return item.id_category === e.split('-')[0] && item.subcat_id === e.split('-')[1]
    //             } else if (e.split('-')[1] != undefined) {
    //                 return item.id_category === e.split('-')[0]
    //             }
    //         });
    //     }).filter((item) => {
    //         if (item.subcat_id != 0) {
    //             if (item.id_category != 0) {
    //                 return item;
    //             }
    //         }
    //     }).slice(1);
    //     setProducts([].concat(...mainData).length > 0 ? [...[].concat(...mainData)] : productsforcat);
    // }, [selectedKeys])


    const test = () => {
        alert()
    }
    
    const reftest = useRef()
    console.log(reftest.current)
    $(document).on('click', '#view', (e) => {
        console.log(e)
        console.log(e.currentTarget.attributes.idd)
    })



    const [dialog, setDialog] = useState(false);
    const hideDialog = () => {
        setDialog(false)
    };
        
    useEffect(()=> {
        // var tmpl = _.template($('#tmpl_Grid').html());
        // const DataTables = $('#data-table').DataTable({
        //     "dom": "<'row'<'col-md-6 col-sm-12'l><'col-md-6 col-sm-12'f>r>t<'row'<'col-md-5 col-sm-12'i><'col-md-7 col-sm-12'p>>",
        //     "processing": true,
        //     "serverside": true,
        //     "deferRender": true,
        //     "deferLoading": [50, 15000],
        //     "type": "GET",
        //     "contentType": "application/json;charset=utf-8",
        //     "dataType": "json",
        // });
        const DataTables = $('#data-table').DataTable({
            "dom": "<'row'<'col-md-6 col-sm-12'l><'col-md-6 col-sm-12'f>r>t<'row'<'col-md-5 col-sm-12'i><'col-md-7 col-sm-12'p>>",
            "processing": true,
            "serverside": true,
            "deferRender": true,
            "deferLoading": [50, 15000],
            "type": "GET",
            "contentType": "application/json;charset=utf-8",
            "dataType": "json",
            "ajax": {
                "url": ENV.DOMAIN+'api/customer.php?customer', //ENV.DOMAIN+'api/customer.php?customer',
            },
            "columns": [
                { title: 'Customer Name', mData: 'customer_name' },
                { title: 'Contact', mData: 'customer_contact' },
                { title: 'Address', mData: 'customer_name' },
                { title: 'Email Address', mData: 'email_address' },
                {
                    title: "Action",
                    className: 'dt-control text-center',
                    orderable: false,
                    data: null,
                    defaultContent: '<i class="pi pi-bars" />',
                    width: 20
                },
                {
                    className: 'dt-control text-center',
                    orderable: false,
                    data: null,
                    defaultContent: '<i class="pi pi-bars" />',
                    width: 20
                },
            ],
            "order": [[0, "asc"]],
            "pageLength": 10,
            "aLengthMenu": [[10, 25, 50, 100, 5000], [10,25,50,100, 5000]],
            "oLanguage": {
                "sLoadingRecords": "<i class='fa fa-spinner fa-spin mgr-10'></i> Loading...</li>Please wait (may take up to a minute or longer depending on your connection)",
                "sSearch": "Search:",
                "sZeroRecords": "<i class='append-icon fa fa-fw fa-database'></i> No records Database to display"
            },
            "rowCallback": function (row, data) {

            },
            "initComplete": function (settings, json) {

            }
        });

        function format(d) {
            // return <button>View</button>
            return (
                'Full name: ' +
                d.customer_name +'<br>' +
                'Salary: ' +
                d.customer_name +
                '<br>' +
                'The child row can contain any data you wish, including links, images, inner tables etc.'
            );
        }

        const detailRows = [];
 
        DataTables.on('click', 'tbody td.dt-control', function (event) {
            let tr = event.target.closest('tr');
            let row = DataTables.row(tr);
            let idx = detailRows.indexOf(tr.id_customer);
            console.log(row.data())
            setDialog(true)

            // if (row.child.isShown()) {
            //     tr.classList.remove('details');
            //     row.child.hide();
         
            //     // Remove from the 'open' array
            //     detailRows.splice(idx, 1);
            // }
            // else {
            //     tr.classList.add('details');
            //     row.child(format(row.data())).show();
         
            //     // Add to the 'open' array
            //     if (idx === -1) {
            //         detailRows.push(tr.id);
            //     }
            // }
        });
        
        // DataTables.on('draw', () => {
        //     detailRows.forEach((id, i) => {
        //         let el = document.querySelector('#' + id + ' td.dt-control');
         
        //         if (el) {
        //             el.dispatchEvent(new Event('click', { bubbles: true }));
        //         }
        //     });
        // });






        return () => {
            DataTables.destroy();
        }
    }, [ENV])
    return (
        <>
            <Dialog draggable={false} visible={dialog} style={{ width: '40rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                <>
                    {/* <div style={{ fontSize: 15 }}>{rowData?.product_name}</div>
                    <div><b>UPC: {rowData?.product_code}</b></div> */}
                </>
            } modal className="p-fluid" footer={null} onHide={hideDialog}>

            </Dialog>
            <div className="vd_body">
                {/* Header Start */}
                {/* <Header /> */}
                {/* Header Ends */}
                <div className="content">
                    <div className="container">
                        <Navbar />
                        {/* Middle Content Start */}
                        <div className="vd_content-wrapper" style={{ minHeight: 1059 }}>
                            <div className="vd_container" style={{ minHeight: 1059 }}>
                                <div className="vd_content clearfix">
                                    {/* vd_head-section */}
                                    {/* <div className="vd_title-section clearfix" style={{ padding: 5 }}>
                                        <div className="vd_panel-header" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <span style={{ fontSize: 30, fontWeight: 600 }}>Product</span>
                                            <FormPanel btn={"add"} products={products} setProducts={setProducts} />
                                        </div>
                                    </div> */}
                                    <div className="vd_content-section clearfix" style={{ paddingTop: 0, minHeight: "100vh" }}>
                                        <div className="row">
                                            <div className="panel-body table-responsive">
                                                
                                                <table className="table table-striped dataTable no-footer" id="data-table">
                                                    <thead>
                                                        <tr role="row">
                                                            <th style={{ width: 267 }}></th>
                                                            <th style={{ width: 388 }}></th>
                                                            <th style={{ width: 353 }}></th>
                                                            <th style={{ width: 228 }}></th>
                                                            <th style={{ width: 165 }}></th>
                                                            <th style={{ width: 165 }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* {
                                                            customer.map((item)=>{
                                                                return (
                                                                    <tr>
                                                                        <td>{item.customer_name}</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                )
                                                            }).slice(0,10)
                                                        } */}
                                                    </tbody>
                                                </table>
                                                
                                            </div>

                                        </div>
                                    </div>
                                    {/* .vd_content-section */}
                                </div>
                                {/* .vd_content */}
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
                {/* .content */}
                {/* Footer Start */}
                <footer className="footer-1" id="footer">
                    <div className="vd_bottom ">
                        <div className="container">
                            <div className="row">
                                <div className=" col-xs-12">
                                    <div className="copyright">
                                        Copyright ©2024 STGC Inc. All Rights Reserved
                                    </div>
                                </div>
                            </div>{/* row */}
                        </div>{/* container */}
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Customer;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import axios from 'axios';
import ENV from '../../ENV';

const OverlayPanelDefective = (props) => {
    const op = useRef(null);
    const [user, setUser] = useState([]);
    const [item, setItem] = useState({});

    const OpenOverlay = (e) => {
        op.current.toggle(e)
        setItem(props?._item)
    }


    useMemo(async () => {
        var formData = new FormData();
        formData.append("employee", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/sales.php',
            data: formData,
        }).then((res) => {
            setUser([...res.data])
        });
    }, [ENV])

    return (
        <>
            <OverlayPanel ref={op}>
                <div style={{ maxWidth: 300, fontSize: 18 }}>
                    <div style={{ border: "1px solid #ddd", padding: 5 }}>Checker:  {[...user].filter((i) => Number(i.user_id) === Number(item?.checker_id))[0]?.firstname} </div>
                    <div style={{ border: "1px solid #ddd", padding: 5, wordWrap: "break-word" }}>Reason: {
                        props._item.reason.split("\n").map((item) => {
                            return (
                                <>
                                    {item}
                                    < br />
                                </>
                            )
                        })
                    }</div>
                </div>
            </OverlayPanel>
            <div onClick={OpenOverlay} style={{ borderRadius: 5, cursor: "pointer", padding: 5, border: "1px solid #ddd", height: 20, width: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: 15, fontWeight: "bolder" }}>...</div>
        </>
    );
}

export default OverlayPanelDefective;

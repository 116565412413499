import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import AddPeymentForm from './AddPeymentForm';

const DefectiveForm = (props) => {
    const [visible, setVisible] = useState(false);









    return (
        <div style={{flexGrow: 1}}>
            <Sidebar blockScroll={true} dismissable={false} visible={visible} style={{width: "30%"}} position="right" onHide={() => setVisible(false)}>
                <div>
                    {/* <div>
                        <div>
                            <span style={{fontSize:15}}>Reserve</span>
                        </div>
                    </div> */}
                    <div style={{padding: 5, border: "1px solid #ddd", borderRadius: 7, display: "flex", flexDirection: "column", gap: 10}}>
                        <div>
                            <label style={{fontSize: 15}}>Customer</label>
                            <input style={{ fontSize: 20, padding: 5, width:"100%", height: 45, borderRadius: 5, border:"1px solid #ddd"}} />
                        </div>
                        <div>
                            <label style={{fontSize: 15}}>Contact No.</label>
                            <input style={{ fontSize: 20, padding: 5, width:"100%", height: 45, borderRadius: 5, border:"1px solid #ddd"}} />
                        </div>
                        <div>
                            <label style={{fontSize: 15}}>Address</label>
                            <input style={{ fontSize: 20, padding: 5, width:"100%", height: 45, borderRadius: 5, border:"1px solid #ddd"}} />
                        </div>
                        <div>
                            <label style={{fontSize: 15}}>Note</label>
                            <textarea style={{ fontSize: 20, padding: 5, width:"100%", height: 100, borderRadius: 5, border:"1px solid #ddd"}} />
                        </div>
                    </div>

                    <div style={{padding: 10, textAlign: "center"}}>
                        <div style={{fontSize: 35, fontWeight: 600}}>
                            <label style={{fontSize: 35}}>Total 0.00</label>
                        </div>
                    </div>
                    <div>
                        <AddPeymentForm />
                    </div>
                    <div>
                        <div style={{width: "100%", borderRadius: 5, border: "1px solid #ddd"}}>
                            <div></div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <button style={{fontSize: 15, borderRadius: 5}}>
                                <span style={{fontSize: 20}}>Place Order</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Sidebar>
            <div
                onClick={() => setVisible(true)} style={{
                    // width: "100%",
                    cursor: "pointer",
                    textAlign: "center",
                    backgroundColor: "rgb(181 88 88)",
                    height: 45,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 600,
                    fontSize: 15,
                    border: "1px solid #ddd",
                    color: "#fff",
                    flexGrow: 1
                }}>Submit as Defective</div>
        </div>
    );
}

export default DefectiveForm;

import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import FileUpload from './extra/FileUpload';
import 'primeicons/primeicons.css';
import JobExpTable from './JobExpTable';
import RelativesTable from './RelativesTable';
import EducationTable from './EducationTable';
import DocViewerPanel from './extra/DocViewerPanel';

const Profile = (props) => {
    const [openDialog, setOpenDialog] = useState(false)
    const [fullscreen, setFullScreen] = useState(false)

    const hideDialog = () => {
        setOpenDialog(false)
    }

    const maximizeDialog = (e) => {
        console.log(e)
    }
    return (
        <>
            <Dialog 
            position='top'
            draggable={false}
            blockScroll={true}
            maximizable={true}
            maximized={true}
            // footer={
            //     <div>
            //         <button onMaximize={maximizeDialog} className='btn btn-info btn-xs'>Information</button>
            //     </div>
            // }
            visible={openDialog} onHide={hideDialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '40vw' }} modal>
                
                <div>
                    <div className="vd_content-section clearfix">
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="panel widget light-widget panel-bd-top">
                                    <div className="panel-body">
                                        <div className="text-center vd_info-parent"> <img alt="example image" src="img/avatar/big.jpg" /> </div>
                                        <div className="row">
                                            {/* <div className="col-xs-12"> <a className="btn vd_btn vd_bg-green btn-xs btn-block no-br"><i className="pi pi-pen-to-square" />Edit Information</a> </div> */}
                                            {/* <div className="col-xs-12"> <a className="btn vd_btn vd_bg-grey btn-xs btn-block no-br"><i className="fa fa-envelope append-icon" />Send Message</a> </div> */}
                                        </div>
                                        {/* {props?.item?.experiences?.map((i)=>i.job_title).slice(-1)} */}
                                        <h2 className="font-semibold mgbt-xs-5">{props.item.Fullname}</h2>
                                        {/* <h4>Owner at Our Company, Inc.</h4> */}
                                        {/* <p>Ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p> */}
                                        <div className="mgtp-20">
                                            <table className="table table-striped table-hover">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '60%', fontSize: 15 }}>Position</td>
                                                        <td style={{ fontSize: 15}}><span className="label label-success">Active</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontSize: 15}}>Age</td>
                                                        <td style={{ fontSize: 15}}>{props.item.age} year Old</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontSize: 15}}>Since</td>
                                                        <td style={{ fontSize: 15}}> {props.item.datetime} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/* panel widget */}
                            </div>
                            <div className="col-sm-9">
                                <div className="tabs widget">
                                    <ul className="nav nav-tabs widget">
                                        <li className="active"> <a data-toggle="tab" href="#profile-tab" style={{fontSize: 15}}> Profile  </a></li>
                                        <li> <a data-toggle="tab" href="#sales-tab" style={{fontSize: 15}}> Sales  </a></li>
                                        <li> <a data-toggle="tab" href="#items-tab" style={{fontSize: 15}}> Items  </a></li>
                                        <li> <a data-toggle="tab" href="#activity-tab" style={{fontSize: 15}}> Activity  </a></li>
                                        <li> <a data-toggle="tab" href="#files-tab" style={{fontSize: 15}}> Files  </a></li>
                                    </ul>





                                    <div className="tab-content">
                                        <div id="profile-tab" className="tab-pane active">
                                            <div className="pd-20">
                                                {/* <div className="vd_info tr"> <a className="btn vd_btn btn-xs vd_bg-yellow"> <i className="fa fa-pencil append-icon" /> Edit </a> </div> */}
                                                <h3 className="mgbt-xs-15 font-semibold"> ABOUT</h3>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="row mgbt-xs-0">
                                                            <label className="col-xs-5 control-label" style={{fontSize:15}}>First Name:</label>
                                                            <div className="col-xs-7 controls" style={{fontSize:15}}>{props.item.firstname}</div>
                                                            {/* col-sm-10 */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="row mgbt-xs-0">
                                                            <label className="col-xs-5 control-label" style={{fontSize:15}}>Last Name:</label>
                                                            <div className="col-xs-7 controls" style={{fontSize:15}}>{props.item.lastname}</div>
                                                            {/* col-sm-10 */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="row mgbt-xs-0">
                                                            <label className="col-xs-5 control-label" style={{fontSize:15}}>User Name:</label>
                                                            <div className="col-xs-7 controls" style={{fontSize:15}}>{props.item.username}</div>
                                                            {/* col-sm-10 */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="row mgbt-xs-0">
                                                            <label className="col-xs-5 control-label" style={{fontSize:15}}>Email:</label>
                                                            <div className="col-xs-7 controls" style={{fontSize:15}}>{props.item.email}</div>
                                                            {/* col-sm-10 */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="row mgbt-xs-0">
                                                            <label className="col-xs-5 control-label" style={{fontSize:15}}>Phone:</label>
                                                            <div className="col-xs-7 controls" style={{fontSize:15}}>+1-234-5678</div>
                                                            {/* col-sm-10 */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="row mgbt-xs-0">
                                                            <label className="col-xs-5 control-label" style={{fontSize:15}}>Birthday:</label>
                                                            <div className="col-xs-7 controls" style={{fontSize:15}}>{props.item.birthday}</div>
                                                            {/* col-sm-10 */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="row mgbt-xs-0">
                                                            <label className="col-xs-5 control-label" style={{fontSize:15}}>Interests:</label>
                                                            <div className="col-xs-7 controls" style={{fontSize:15}}>Basketball, Web, Design, etc.</div>
                                                            {/* col-sm-10 */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="row mgbt-xs-0">
                                                            <label className="col-xs-5 control-label" style={{fontSize:15}}>Website:</label>
                                                            <div className="col-xs-7 controls" style={{fontSize:15}}><a href="http://Vendroid.venmond.com">Vendroid.venmond.com</a></div>
                                                            {/* col-sm-10 */}
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-sm-6">
                                                        <div className="row mgbt-xs-0">
                                                            <label className="col-xs-5 control-label" style={{fontSize:15}}>City:</label>
                                                            <div className="col-xs-7 controls" style={{fontSize:15}}>Los Angeles</div>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-sm-6">
                                                        <div className="row mgbt-xs-0">
                                                            <label className="col-xs-5 control-label" style={{fontSize:15}}>Address:</label>
                                                            <div className="col-xs-7 controls" style={{fontSize:15}}>{props.item.address}</div>
                                                            {/* col-sm-10 */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="pd-10" />
                                                <div className="row">
                                                    <div className="col-sm-5">
                                                        <RelativesTable item={props?.item} employeelist={props.employeelist} setEmployeeList={props.setEmployeeList} />
                                                    </div>
                                                    <div className="col-sm-7">
                                                        <EducationTable item={props?.item} employeelist={props.employeelist} setEmployeeList={props.setEmployeeList} />
                                                    </div>
                                                </div>
                                                <hr className="pd-10" />
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <JobExpTable item={props?.item} employeelist={props.employeelist} setEmployeeList={props.setEmployeeList} />
                                                    </div>
                                                </div>
                                                {/* row */}
                                            </div>
                                            {/* pd-20 */}
                                        </div>
                                        {/* home-tab */}
                                        
                                        <div id="sales-tab" className="tab-pane">
                                            <div className="pd-20">
                                                <div className='row'>
                                                    <div className="col-sm-5">
                                                        <h3 className="mgbt-xs-15 font-semibold"> SALES REPORT </h3>
                                                        <div className="content-list content-menu">
                                                            {/* <table className='table table-bordered'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>School Name</th>
                                                                                    <th>Cources</th>
                                                                                    <th>School Year</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    props?.item?.schools?.reverse().map((i, k) => {
                                                                                        return (
                                                                                            <tr key={k}>
                                                                                                <td>{i.school_name}</td>
                                                                                                <td>{i.cource}</td>
                                                                                                <td>{i.school_year}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table> */}
                                                            <ul className="list-wrapper">
                                                                {
                                                                    props?.item?.schools?.reverse().map((i) => {
                                                                        return (
                                                                            <li className="mgbt-xs-10">
                                                                                <span className="menu-text">

                                                                                </span>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="items-tab" className="tab-pane">
                                            <div className="pd-20">
                                                <div className='row'>
                                                    <div className="col-sm-5">
                                                        <h3 className="mgbt-xs-15 font-semibold"> ITEMS </h3>
                                                        <div className="content-list content-menu">
                                                            {/* <table className='table table-bordered'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>School Name</th>
                                                                                    <th>Cources</th>
                                                                                    <th>School Year</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    props?.item?.schools?.reverse().map((i, k) => {
                                                                                        return (
                                                                                            <tr key={k}>
                                                                                                <td>{i.school_name}</td>
                                                                                                <td>{i.cource}</td>
                                                                                                <td>{i.school_year}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table> */}
                                                            <ul className="list-wrapper">
                                                                {
                                                                    props?.item?.schools?.reverse().map((i) => {
                                                                        return (
                                                                            <li className="mgbt-xs-10">
                                                                                <span className="menu-text">

                                                                                </span>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="activity-tab" className="tab-pane">
                                            <div className="pd-20">
                                                <div className='row'>
                                                    <div className="col-sm-5">
                                                        <h3 className="mgbt-xs-15 font-semibold"> ACTIVITY </h3>
                                                        <div className="content-list content-menu">
                                                            {/* <table className='table table-bordered'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>School Name</th>
                                                                                    <th>Cources</th>
                                                                                    <th>School Year</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    props?.item?.schools?.reverse().map((i, k) => {
                                                                                        return (
                                                                                            <tr key={k}>
                                                                                                <td>{i.school_name}</td>
                                                                                                <td>{i.cource}</td>
                                                                                                <td>{i.school_year}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table> */}
                                                            <ul className="list-wrapper">
                                                                {
                                                                    props?.item?.schools?.reverse().map((i) => {
                                                                        return (
                                                                            <li className="mgbt-xs-10">
                                                                                <span className="menu-text">

                                                                                </span>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="files-tab" className="tab-pane">
                                            <div className="pd-20">
                                                <div className='row'>
                                                    <FileUpload item={props.item} employeelist={props.employeelist} setEmployeeList={props.setEmployeeList}  />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    {/* tab-content */}
                                </div>
                                {/* tabs-widget */}            
                            </div>
                        </div>
                        {/* row */}
                    </div>

                </div>
                
                {/* <div>
                    <div style={{ width: "100%", display: "flex", flexDirection:"row", justifyContent: "center"}}>
                        <div style={{border:"1px solid #ddd", height: 300, width: 300}}></div>
                    </div>
                    <div style={{border:"1px solid #fff", padding: 2}}>
                        <div style={{fontSize: 20, fontWeight:"bolder"}}>{props.item.Fullname}</div>
                    </div>
                    <div style={{border:"1px solid #fff", padding: 2}}>
                        <div style={{fontSize: 20, fontWeight:"normal"}}>Civil Status: {props.item.marital_status}</div>
                    </div>
                    <div style={{border:"1px solid #fff", padding: 2}}>
                        <div style={{fontSize: 20, fontWeight:"normal"}}>Age: {props.item.age}</div>
                    </div>
                </div> */}

            </Dialog>
            <button onClick={()=>setOpenDialog(true)} className='btn btn-xs btn-info'>Profile</button>
        </>
    );
}

export default Profile;

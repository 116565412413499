import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import QrReader from 'react-weblineindia-qrcode-scanner'
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const Scanner = (props) => {

    const [result, setresult] = useState("result")
    const [data, setData] = useState("Not Found");
    const [visible, setVisible] = useState(false);
    const handleScan = (data) => {
      setresult(data)
      window.location.assign(data)
    }

    const handleError = (err) => {
      console.error(err)
    }
    return (
        <div>
            
            {/* <QrReader
            delay={100}
            style={{width:"100%"}}
            onError={(e)=>handleError(e)}
            onScan={(e)=>{if (e){handleScan(e)} {
                
            }}}
            />
            <div>
                <p>{result}</p>
            </div> */}

            


            <Sidebar blockScroll={true} dismissable={false} style={{height: 500}} visible={visible} position="bottom" onHide={() => setVisible(false)}>
                {
                    visible ? 
                        <BarcodeScannerComponent
                        width={"100%"}
                        // height={"100%"}
                        onUpdate={(err, result) => {
                            if (result) {
                                props.setSearch(result.text)
                                setData(result.text);
                                setVisible(false)
                            }
                        }}
                        />
                    :null
                }
                <p>{props.search}</p>
            </Sidebar>


            <span onClick={()=>setVisible(true)} style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderRadius: 5, border: "1px solid #fff", height: "100%", width: 30}}>
                <i className='pi pi-qrcode' style={{fontSize: 18}} />
            </span>
        </div>
    );
}

export default Scanner;

import React from 'react';

const PaymentRow = (props) => {
    return (
        <>
            <div style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 5, alignItems: "center", padding: 5 }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5, alignItems: "center" }}>
                    <div style={{ width: 100, fontSize: 13, fontWeight: "bold" }}>Date Time</div>
                    <div style={{ width: 100, fontSize: 13, fontWeight: "bold" }}>{props.pay_item.type}</div>
                </div>
                <div style={{ fontSize: 13, fontWeight: "bold" }}>
                    {/* {item.amount_value} */}
                    {
                        props.pay_item.type === 'Cash' ?
                            Number(props.pay_item.amount_value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : null
                    }
                    {
                        props.pay_item.type === 'Online' ?
                            Number(props.pay_item.online_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : null
                    }
                    {
                        props.pay_item.type === 'Credit Card' ?
                            Number(props.pay_item.cc_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : null
                    }
                    {
                        props.pay_item.type === 'Cheque' ?
                            Number(props.pay_item.cheque_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : null
                    }

                </div>
            </div>
        </>
    )
}

export default PaymentRow;

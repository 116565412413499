import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useMemo, useState } from 'react';
import ENV from '../../ENV';
import { useRef } from 'react';

const EnterInputValueSales = (props) => {
    // {key, item, enter_sales_operation, clickone}
    const [productDialog, setproductDialog] = useState(false);
    const [inputvalue, setInputValue] = useState({ "input_qty": "", 'status': false });
    const [stock, setStock] = useState([]);
    const error = useRef()




    const hideDialog = () => {
        setproductDialog(false)
        setInputValue({ "input_qty": 0 });
        // return (
        //     <></>
        // )
    }


    const VerifyingStock = async (props) => {
        var formData = new FormData();
        formData.append("verifyingstock", 1);
        formData.append("stock_id", props.item.stock_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/sales.php',
            // url: 'http://192.168.1.10/stgc/sales.php',
            data: formData,
        }).then((res) => {
            setStock(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    };


    const submit = async (props) => {
        console.log(inputvalue.input_qty, props.item.stock_id)
        var formData = new FormData();
        formData.append("enter_sales_operation", 1);
        formData.append("stock_id", props.item.stock_id);
        // formData.append("entersales", e.key === 'Enter' ? e.target.value : e.type === 'click' ? _value?.serial : "")
        formData.append("user_id", localStorage.getItem('user_id'))
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'sales.php',
            // url: 'http://192.168.1.10/stgc/sales.php',
            data: formData,
        }).then((res) => {
            // setSalesOperation(res.data)
            // console.log(res.data)
            // setGlobalFilter('')
            // update_sales_operation()
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const productDialogFooter = () => {
        return (
            <></>
        )
    }

    const openpanelDialog = (props) => {
        setproductDialog({ "open": true, "stock_id": props.item.stock_id })
        VerifyingStock(props)
    }

    const inputQty = (e) => {
        // setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))

        if (productDialog.open && props.item.stock_id === productDialog.stock_id) {
            setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))
            if (Number(e.target.value) !== 0 && Number(e.target.value) !== '') {
                VerifyingStock(props)
                if (Number(stock.stock_qty) >= Number(e.target.value)) {
                    error.current.style.border = "2px solid #ddd"
                    setInputValue(prev => ({ ...prev, 'status': false }))
                } else {
                    error.current.style.border = "2px solid red"
                    setInputValue(prev => ({ ...prev, 'status': true }))
                }
            } else {
                error.current.style.border = "2px solid #ddd"
                setInputValue(prev => ({ ...prev, 'status': true }))
            }
        }
    }

    // console.log(error)

    //  if (productDialog.open && props.item.stock_id === productDialog.stock_id) {
    //     if (inputvalue.input_qty !== 0 && inputvalue.input_qty !== '') {
    //         console.log("Success", inputvalue.input_qty)
    //         VerifyingStock(props)
    //         if (stock.stock_qty >= inputvalue.input_qty) {
    //             console.log("Success", inputvalue.input_qty)
    //         } else {
    //             console.log("not Enough!")
    //         }
    //     }
    // }
    // console.log("Success", inputvalue.input_qty)

    //  ValueQuantity(e, item);

    if (props.item.stock_qty !== 0) {
        if (props.item.no_serial === 0) {
            return (
                <>
                    <div onClick={(e) => props.enter_sales_operation(e, props.item)} key={props.key} style={{ cursor: "pointer", padding: 5, backgroundColor: props.clickone === props.item.product_id ? "#fff" : (props.clickone === 0 ? "#fff" : "#ddd"), borderBottom: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div>{props.item.serial}</div>
                        <div>{props.item.stock_qty}</div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <Dialog
                        footer={
                            <>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <a href='#show' style={{ textDecoration: "underline", fontSize: 18, fontWeight: 600 }}>Report Stock</a>
                                    {
                                        inputvalue.input_qty > 0 ?
                                            <>
                                                {
                                                    inputvalue.status ?
                                                        <button className='btn btn-default'>
                                                            <i className='pi pi-check' style={{ fontSize: 20 }} />
                                                        </button>
                                                        :
                                                        <button onClick={() => submit(props)} className='btn btn-success'>
                                                            <i className='pi pi-check' style={{ fontSize: 20 }} />
                                                        </button>
                                                }
                                            </>
                                            :
                                            <button className='btn btn-default'>
                                                <i className='pi pi-check' style={{ fontSize: 20 }} />
                                            </button>
                                    }
                                </div>
                            </>
                        } onHide={hideDialog}
                        visible={productDialog.open} draggable={false} maximized={false} blockScroll={false} resizable={false} position={"top"} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 10 }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: 5, justifyContent: "center", alignItems: "center" }}>
                                <span style={{ fontSize: 12, fontWeight: "normal" }}>Available Stock</span>
                                <span style={{ fontSize: 50, fontWeight: "bolder" }}>{stock?.stock_qty}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <input ref={error} type={"number"} name='input_qty' onKeyPress={(e) => props.enter_sales_input_qty(e, props.item)} onChange={(e) => props.enter_sales_input_qty(e, props.item)} placeholder='0' style={{ outline: "none", width: "50%", height: 50, borderRadius: 5, border: "2px solid #ddd", fontSize: 20, textAlign: "center" }} />
                            </div>
                        </div>
                    </Dialog>

                    <div onClick={(e) => openpanelDialog(props)} key={props.key} style={{ cursor: "pointer", padding: 5, backgroundColor: props.clickone === props.item.product_id ? "#fff" : (props.clickone === 0 ? "#fff" : "#ddd"), borderBottom: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div>{props.item.serial} {props.item.stock_id}</div>
                        <div>{props.item.stock_qty}</div>
                    </div>
                </>
            );
        }
    }
    return (<></>);
}

export default EnterInputValueSales;

import { Sidebar } from 'primereact/sidebar';
import { SplitButton } from 'primereact/splitbutton';
import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { SpeedDial } from 'primereact/speeddial';
import { OverlayPanel } from 'primereact/overlaypanel';

const AddPeymentForm = (props) => {
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState('');
    const [selectedOnlinePayment, setSelectedOnlinePayment] = useState(false);
    const [inputvalue, setInputValue] = useState({});

    const items = [
        {
            label: 'Update',
            icon: 'pi pi-refresh',
            command: () => {
                toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
            }
        },
        {
            label: 'Delete',
            icon: 'pi pi-times',
            command: () => {
                toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
            }
        },
        {
            label: 'React Website',
            icon: 'pi pi-external-link',
            command: () => {
                // window.location.href = 'https://react.dev/';
            }
        },
        {
            label: 'Upload',
            icon: 'pi pi-upload',
            command: () => {
                // router.push('/fileupload');
            }
        }
    ];

    const op = useRef(null);

    const OpenOverlay = (e) => {
        op.current.toggle(e)
    }
    const CloseOverlay = (e) => {
        op.current.hide(e)
    }

    console.log(window.innerWidth)

    return (
        <>

            <Sidebar blockScroll={true} dismissable={false} visible={visible} style={{ width: window.innerWidth === 1920 ? "15%" : "20%", height: 700, marginRight: 100 }} position="right" onHide={() => setVisible(false)}>
                <div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 10, flexWrap: "wrap" }}>
                        <label onClick={() => setSelectedPayment('Cash')} style={{ padding: 5, fontSize: 20, padding: 5, borderRadius: 5, border: "1px solid #ddd" }}>
                            Cash
                        </label>
                        <label onClick={() => setSelectedPayment('Online / Remittance')} style={{ padding: 5, fontSize: 20, padding: 5, borderRadius: 5, border: "1px solid #ddd" }}>
                            Online / Remittance
                        </label>
                        <label onClick={() => setSelectedPayment('Credit Card')} style={{ padding: 5, fontSize: 20, padding: 5, borderRadius: 5, border: "1px solid #ddd" }}>
                            Credit Card
                        </label>
                        <label onClick={() => setSelectedPayment('Cheque')} style={{ padding: 5, fontSize: 20, padding: 5, borderRadius: 5, border: "1px solid #ddd" }}>
                            Cheque
                        </label>
                    </div>

                    <div>
                        {
                            selectedPayment === 'Cash' ?
                                <div style={{display: "flex", flexDirection: "row", gap: 5}}>
                                    <input type='number' style={{width: "70%", height: 45, borderRadius: 5, fontSize: 20, border:"1px solid #ddd"}} />
                                    {
                                        <button style={{width: 100, borderRadius: 5, border: "1px solid #ddd", fontSize: 18, fontWeight: "bolder"}}>Save</button>
                                    }
                                </div>
                                : null
                        }
                        {
                            selectedPayment === 'Online / Remittance' ?
                                <>
                                    {
                                        selectedOnlinePayment ?
                                            <>
                                                {
                                                    selectedOnlinePayment === 'GCash' ?
                                                        <>
                                                            <div>
                                                                <div style={{padding: "10px 0px 10px 0px"}}>
                                                                    <span onClick={() => setSelectedOnlinePayment('')} style={{fontSize: 20, color: "red", textDecoration: "underline"}}>Back</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontSize: 40, fontWeight: 800, color: "#003f91"}}>GCash</span>
                                                                </div>
                                                                <div>
                                                                    <label style={{fontSize: 20}}>GCash Owner</label>
                                                                    <div style={{width: "100%"}}>
                                                                        <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <label style={{fontSize: 20}}>GCash Number</label>
                                                                    <div style={{width: "100%"}}>
                                                                        <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <label style={{fontSize: 20}}>Reference Number</label>
                                                                    <div style={{width: "100%"}}>
                                                                        <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                                {
                                                    selectedOnlinePayment === 'Maya' ?
                                                        <>
                                                            <div>
                                                                <div style={{padding: "10px 0px 10px 0px"}}>
                                                                    <span onClick={() => setSelectedOnlinePayment('')} style={{fontSize: 20, color: "red", textDecoration: "underline"}}>Back</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontSize: 40, fontWeight: 800, color: "#2ff29e"}}>Maya</span>
                                                                </div>
                                                                <div>
                                                                    <label style={{fontSize: 20}}>Maya Owner</label>
                                                                    <div style={{width: "100%"}}>
                                                                        <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <label style={{fontSize: 20}}>Maya Number</label>
                                                                    <div style={{width: "100%"}}>
                                                                        <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <label style={{fontSize: 20}}>Reference Number</label>
                                                                    <div style={{width: "100%"}}>
                                                                        <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                                {
                                                    selectedOnlinePayment === 'Home Credit' ?
                                                        <>
                                                            <div>
                                                                <div style={{padding: "10px 0px 10px 0px"}}>
                                                                    <span onClick={() => setSelectedOnlinePayment('')} style={{fontSize: 20, color: "red", textDecoration: "underline"}}>Back</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontSize: 40, fontWeight: 800, color: "#e11930"}}>Home Credit</span>
                                                                </div>
                                                                <div>
                                                                    <label style={{fontSize: 20}}>Agent:</label>
                                                                    <div style={{width: "100%"}}>
                                                                        <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <label style={{fontSize: 20}}>Date:</label>
                                                                    <div style={{width: "100%"}}>
                                                                        <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <label style={{fontSize: 20}}>Amount:</label>
                                                                    <div style={{width: "100%"}}>
                                                                        <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </>
                                            :
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5 }}>
                                                <span onClick={() => setSelectedOnlinePayment('GCash')}
                                                    style={{ padding: 5, borderRadius: 5, border: "1px solid #ddd", fontSize: 20 }}
                                                >
                                                    GCash
                                                </span>
                                                <span onClick={() => setSelectedOnlinePayment('Maya')}
                                                    style={{ padding: 5, borderRadius: 5, border: "1px solid #ddd", fontSize: 20 }}
                                                >
                                                    Maya
                                                </span>
                                                <span onClick={() => setSelectedOnlinePayment('Home Credit')}
                                                    style={{ padding: 5, borderRadius: 5, border: "1px solid #ddd", fontSize: 20 }}
                                                >
                                                    Home Credit
                                                </span>
                                            </div>
                                    }
                                </>
                                : null
                        }
                        {
                            selectedPayment === 'Credit Card' ?
                                <>
                                <div style={{width: "100%"}}>
                                    <div style={{width: "100%", textAlign: "center"}}>
                                        <span>Credit Card Payment</span>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent:"center", alignItems: "center"}}>
                                        <div>Straight Value</div>
                                        <div>Installment</div>
                                    </div>
                                </div>
                                </>
                                : null
                        }
                        {
                            selectedPayment === 'Cheque' ?
                                <>

                                </>
                                : null
                        }




                    </div>

                </div>
            </Sidebar>

            <OverlayPanel ref={op} style={{ border: "2px solid #000" }} showCloseIcon={false} closeOnEscape={false} dismissable={true}>
                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                    {
                        selectedPayment !== '' ?
                            <>
                                {
                                    selectedPayment === 'Cash' ?
                                        <div>
                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0px 0px 10px 0px",}}>
                                                <span style={{fontSize: 20, fontWeight: "bolder"}}>CASH</span>
                                                <button onClick={()=>setSelectedPayment('')} className='btn btn-danger btn-xs'>Back</button>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                                                <input type='number' placeholder='0.00' style={{ width: "70%", height: 45, borderRadius: 5, fontSize: 20, border: "1px solid #ddd" }} />
                                                {
                                                    <button style={{ width: 100, borderRadius: 5, border: "1px solid #ddd", fontSize: 18, fontWeight: "bolder" }}>Save</button>
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    selectedPayment === 'Online / Remittance' ?
                                        <>
                                            {
                                                selectedOnlinePayment ?
                                                    <>
                                                        {
                                                            selectedOnlinePayment === 'GCash' ?
                                                                <>
                                                                    <div>
                                                                        <div style={{padding: "10px 0px 10px 0px"}}>
                                                                            <span onClick={() => setSelectedOnlinePayment('')} style={{fontSize: 20, color: "red", textDecoration: "underline"}}>Back</span>
                                                                        </div>
                                                                        <div>
                                                                            <span style={{fontSize: 40, fontWeight: 800, color: "#003f91"}}>GCash</span>
                                                                        </div>
                                                                        <div>
                                                                            <label style={{fontSize: 20}}>GCash Owner</label>
                                                                            <div style={{width: "100%"}}>
                                                                                <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <label style={{fontSize: 20}}>GCash Number</label>
                                                                            <div style={{width: "100%"}}>
                                                                                <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <label style={{fontSize: 20}}>Reference Number</label>
                                                                            <div style={{width: "100%"}}>
                                                                                <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : null
                                                        }
                                                        {
                                                            selectedOnlinePayment === 'Maya' ?
                                                                <>
                                                                    <div>
                                                                        <div style={{padding: "10px 0px 10px 0px"}}>
                                                                            <span onClick={() => setSelectedOnlinePayment('')} style={{fontSize: 20, color: "red", textDecoration: "underline"}}>Back</span>
                                                                        </div>
                                                                        <div>
                                                                            <span style={{fontSize: 40, fontWeight: 800, color: "#2ff29e"}}>Maya</span>
                                                                        </div>
                                                                        <div>
                                                                            <label style={{fontSize: 20}}>Maya Owner</label>
                                                                            <div style={{width: "100%"}}>
                                                                                <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <label style={{fontSize: 20}}>Maya Number</label>
                                                                            <div style={{width: "100%"}}>
                                                                                <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <label style={{fontSize: 20}}>Reference Number</label>
                                                                            <div style={{width: "100%"}}>
                                                                                <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <label style={{fontSize: 20}}>Reference Number</label>
                                                                            <div style={{width: "100%"}}>
                                                                                <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <label style={{fontSize: 20}}>Reference Number</label>
                                                                            <div style={{width: "100%"}}>
                                                                                <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : null
                                                        }
                                                        {
                                                            selectedOnlinePayment === 'Home Credit' ?
                                                                <>
                                                                    <div>
                                                                        <div style={{padding: "10px 0px 10px 0px"}}>
                                                                            <span onClick={() => setSelectedOnlinePayment('')} style={{fontSize: 20, color: "red", textDecoration: "underline"}}>Back</span>
                                                                        </div>
                                                                        <div>
                                                                            <span style={{fontSize: 40, fontWeight: 800, color: "#e11930"}}>Home Credit</span>
                                                                        </div>
                                                                        <div>
                                                                            <label style={{fontSize: 20}}>Agent:</label>
                                                                            <div style={{width: "100%"}}>
                                                                                <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <label style={{fontSize: 20}}>Date:</label>
                                                                            <div style={{width: "100%"}}>
                                                                                <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <label style={{fontSize: 20}}>Amount:</label>
                                                                            <div style={{width: "100%"}}>
                                                                                <input style={{width: "70%", padding: 5, fontSize: 20, fontWeight: 800, borderRadius: 5, height: 45}}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : null
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <button onClick={() => setSelectedPayment('')} className='btn btn-danger btn-xs'>Back</button>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5 }}>
                                                            <span onClick={() => setSelectedOnlinePayment('GCash')}
                                                                style={{ padding: 5, borderRadius: 5, border: "1px solid #ddd", fontSize: 20 }}
                                                            >
                                                                GCash
                                                            </span>
                                                            <span onClick={() => setSelectedOnlinePayment('Maya')}
                                                                style={{ padding: 5, borderRadius: 5, border: "1px solid #ddd", fontSize: 20 }}
                                                            >
                                                                Maya
                                                            </span>
                                                            <span onClick={() => setSelectedOnlinePayment('Home Credit')}
                                                                style={{ padding: 5, borderRadius: 5, border: "1px solid #ddd", fontSize: 20 }}
                                                            >
                                                                Home Credit
                                                            </span>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                        : <button onClick={() => setSelectedPayment('')} className='btn btn-danger btn-xs'>Back</button>
                                }
                            </>
                        :
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 10, flexWrap: "wrap" }}>
                            <label onClick={() => setSelectedPayment('Cash')} style={{ padding: 5, fontSize: 20, cursor: "pointer", borderRadius: 5, border: "1px solid #ddd" }}>
                                Cash
                            </label>
                            <label onClick={() => setSelectedPayment('Online / Remittance')} style={{ padding: 5, fontSize: 20, cursor: "pointer", borderRadius: 5, border: "1px solid #ddd" }}>
                                Online / Remittance
                            </label>
                            <label onClick={() => setSelectedPayment('Credit Card')} style={{ padding: 5, fontSize: 20, cursor: "pointer", borderRadius: 5, border: "1px solid #ddd" }}>
                                Credit Card
                            </label>
                            <label onClick={() => setSelectedPayment('Cheque')} style={{ padding: 5, fontSize: 20, cursor: "pointer", borderRadius: 5, border: "1px solid #ddd" }}>
                                Cheque
                            </label>
                        </div>
                    }
                </div>
            </OverlayPanel>

            <label onClick={()=>setVisible(true)} style={{ fontSize: 20, width: 200, textAlign: "center", height: 40, color: "#000", padding: 5, borderRadius: 5, border: "2px solid #ddd", backgroundColor: "#fff" }}>
                Add Payment
            </label>
        </>
    );
}

export default AddPeymentForm;

import React from 'react';

const PaymentList = () => {
    return (
        <div className="content-list">
            <ul className="list-wrapper">
                <li className>
                    <span>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><span style={{ fontWeight: 'bolder' }}>Online</span><span>0.00</span></div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}><span>Deposit</span></div>
                        <span className="menu-info" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><span className="menu-date">August 2003</span><span style={{ fontSize: 15, color: 'rgb(35, 112, 158)' }}>Payable</span></span>
                    </span>
                </li>
                <li className>
                    <span>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><span style={{ fontWeight: 'bolder' }}>Bank</span><span>0.00</span></div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}><span>Transfer / Deposit / Cheque</span></div>
                        <span className="menu-info" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><span className="menu-date">August 2003</span><span style={{ fontSize: 15, color: 'rgb(35, 112, 158)' }}>Payable</span></span>
                    </span>
                </li>
                <li className>
                    <span>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><span style={{ fontWeight: 'bolder' }}>Maya</span><span>0.00</span></div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}><span>Transfer</span></div>
                        <span className="menu-info" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><span className="menu-date">August 2003</span><span style={{ fontSize: 15, color: 'rgb(35, 112, 158)' }}>Payable</span></span>
                    </span>
                </li>
                <li className>
                    <span>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><span style={{ fontWeight: 'bolder' }}>GCash</span><span>0.00</span></div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}><span>Transfer</span></div>
                        <span className="menu-info" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><span className="menu-date">August 2003</span><span style={{ fontSize: 15, color: 'rgb(35, 112, 158)' }}>Payable</span></span>
                    </span>
                </li>
                <li className>
                    <span>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><span style={{ fontWeight: 'bolder' }}>Voucher</span><span>0.00</span></div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}><span>Transfer</span></div>
                        <span className="menu-info" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><span className="menu-date">August 2003</span><span style={{ fontSize: 15, color: 'rgb(35, 112, 158)' }}>Payable</span></span>
                    </span>
                </li>
            </ul>
            <div />
        </div>
    );
}

export default PaymentList;

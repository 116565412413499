import React, { useEffect, useState, useMemo, useRef } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import Header from './component/Header';
import Navbar from './component/Navbar';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from './ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import { Tooltip } from 'primereact/tooltip';
import { CustomerService } from './CustomerService';


const InventoryAssignment = ({hide}) => {
    document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix" data-active="dashboard ';
    document.body.id = 'dashboard';

    const [users, setUser] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [category, setCategory] = useState([]);

    const getInventoryAPI = async () => {
        var formData = new FormData();
        formData.append("assignmentList", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'inventory.php',
            // url: 'http://localhost/stgc/inventory.php',
            data: formData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            setCustomers(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }

    useMemo(async () => {
        var formData = new FormData();
        formData.append("assignmentList", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'inventory.php',
            data: formData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            setCustomers(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    const getUserAPI = async () => {
        var formData = new FormData();
        formData.append("userList", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'inventory.php',
            data: formData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            setUser(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }

    useMemo(async () => {
        var formData = new FormData();
        formData.append("userList", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'inventory.php',
            data: formData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            setUser(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("categoryList", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'inventory.php',
            data: formData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            setCategory(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    const getCategoryAPI = async () => {
        var formData = new FormData();
        formData.append("categoryList", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'inventory.php',
            data: formData,
        }).then((res) => {
            setCategory(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        // getInventoryAPI()
        // getCategoryAPI()
        // UpdateCategory()
        const listener = (event) => {
            if (event.which === 13 && event.which === 91) {
                console.log('Enter key was pressed. Run your function.')
                event.preventDefault()
                // callMyFunction();
            } else if (event.which === 27 && event.which === 27) {
                if (assignOption != null) {
                    setAssignOption({ "id_category": null, "cat_name": null })
                }
                if (SwapBy != null) {
                    console.log('a', event.which, SwapBy)
                    Swap({
                        "id": null,
                        "cat_id": null,
                        "cat_name": null,
                        "product_count": null,
                        "product_stock": null,
                        "representative": {
                            "user_id": null,
                            "name": null,
                            "image": null
                        }
                    })
                }
            }
        }

        // // const a = 0;
        // const updatedCategoryList = category.filter((i) => {
        //     return Number(i.id_category) !== Number(customers.cat_id);
        // });   
        // // setCategory([...updatedCategoryList])
        // // console.log(customers[5].cat_id)
        // console.log([...updatedCategoryList])


        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
        // CustomerService.getCustomersMedium().then((data) => setCustomers(data));
    }, [ENV, customers]); // eslint-disable-line react-hooks/exhaustive-deps



    // const UpdateCategory = () => { 
    //     const updatedCategoryList = category.filter((i) => {
    //         return i.id_category !== customers.cat_id;
    //     });   
    //     // setCategory([...updatedCategoryList])
    //     console.log([...updatedCategoryList], customers)
    //     // return [...updatedCategoryList];
    // }

    // useMemo(async() => {
    //     // (async () => {
    //         var formData = new FormData();
    //         formData.append("assignmentList", 1);
    //         await axios({
    //             method: "post",
    //             url: 'http://localhost/stgc/inventory.php',
    //             data: formData,
    //         }).then((res) => {
    //             setCustomers(res.data)
    //             // console.log(res.data[0].users_role.activity_category)
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    //     // })
    // }, [ENV]);

    // const customer = [
    //     {
    //         id: 1000,
    //         category: 'Casing',
    //         representative: {
    //             user_id: 'Ioni Bowcher',
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         }
    //     },{
    //         id: 1000,
    //         category: 'Casing',
    //         representative: {
    //             user_id: 'Ioni Bowcher',
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         }
    //     },
    // ];

    const countryBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={rowData.country.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} style={{ width: '24px' }} />
                <span>{rowData.country.name}</span>
            </div>
        );
    };

    const [SwapBy, setSwapBy] = useState({
        "id": null,
        "cat_id": null,
        "cat_name": null,
        "product_count": null,
        "product_stock": null,
        "representative": {
            "user_id": null,
            "name": null,
            "image": null
        }
    })

    const [updateSwap, setUpdateSwap] = useState([]);
    const [SwapAlert, setSwapAlert] = useState([]);

    const Swap = (rowData) => {

        // const DisplayData = customers
        //     ?.filter((row) => row?.cat_id?.match(rowData.cat_id) && row?.representative.user_id?.match(rowData.representative.user_id))
        //     ?.map((items) => {
        //         return items;
        //     });
        const updatedcustomersList = customers.filter((i) => {
            return i.representative.user_id != rowData.representative.user_id;
        });
        const _updatedcustomersList = updatedcustomersList.filter((i) => {
            return i.cat_id == rowData.cat_id;
        });


        setSwapAlert(_updatedcustomersList)

        // const ____index = _updatedcustomersList.findIndex(x=>x.representative.user_id != rowData.representative.user_id);
        // updatedInventoryList[____index].representative = __updatedUserId;

        // const _updatedUserId = updatedInventoryList.representative.filter((i) => {
        //     return i.representative.user_id;
        // })

        // console.log(..._updatedcustomersList)
        // console.log(____index)

        setUpdateSwap(_updatedcustomersList)
        setSwapBy(rowData)
    }

    const RemoveCategoryBtn = (rowData) => {
        setSwapBy({
            "id": null,
            "cat_id": rowData.cat_id,
            "cat_name": null,
            "product_count": null,
            "product_stock": null,
            "representative": {
                "user_id": rowData.representative.user_id,
                "name": null,
                "image": null
            },
            'remove': true
        })
    }

    const RemoveCategory = async (rowData) => {
        const updatedInventoryList = customers.filter((i) => {
            return i.id !== rowData.id;
        });

        const __category = category;

        const _index = __category.findIndex(x => x.id_category === rowData.cat_id);

        const _updatedUserId = category.filter((i) => {
            return i.id_category == rowData.cat_id;
        })

        const __updatedUserId = _updatedUserId[index].assign_users.filter((i) => {
            return i.user_id != rowData.representative.user_id;
        })

        const ____index = __category.findIndex(x => x.id_category == rowData.cat_id);
        __category[____index].assign_users = __updatedUserId;

        setCategory([...__category])
        setCustomers([...updatedInventoryList])
        console.log(__category)
        var formData = new FormData();
        formData.append("removeAssignCategory", 1);
        formData.append("id", rowData.id);
        // formData.append("cat_id", rowData.cat_id);
        // formData.append("user_id", rowData.representative.user_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'inventory.php',
            // url: 'http://localhost/stgc/api/inventory.php',
            data: formData,
        }).then((res) => {
            // getInventoryAPI()
            // getCategoryAPI()
            getUserAPI()
            setSwapBy({
                "id": null,
                "cat_id": null,
                "cat_name": null,
                "product_count": null,
                "product_stock": null,
                "representative": {
                    "user_id": null,
                    "name": null,
                    "image": null
                },
                "remove": false,
                "swaphere": false
            })
            // setCustomers([...updatedInventoryList])
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const SwapHereBtn = async (rowData) => {
        setSwapBy({
            "id": null,
            "cat_id": rowData.cat_id,
            "cat_name": null,
            "product_count": null,
            "product_stock": null,
            "representative": {
                "user_id": rowData.representative.user_id,
                "name": null,
                "image": null
            },
            "swaphere": true
        })
    }


    const SwapHere = async (rowData) => {



        const updatedInventoryList = customers.filter((i) => {
            return i.cat_id === rowData.cat_id;
        });

        const _updatedcustomersList = updatedInventoryList.filter((i) => {
            return i.representative.user_id === rowData.representative.user_id;
        });

        // console.log(_updatedcustomersList[0].representative.user_id)
        // console.log([updatedInventoryList])

        if (_updatedcustomersList[0].representative.user_id != rowData.representative.user_id) {
            // console.log(SwapBy.representative.user_id, SwapBy.id)
            // console.log(rowData.representative.user_id, rowData.id)
            // var formData = new FormData();
            // formData.append("swap_here", 1);
            // formData.append("swap_from_user_id", SwapBy.representative.user_id);
            // formData.append("swap_to_user_id", rowData.representative.user_id);
            // formData.append("swap_id_one", SwapBy.id);
            // formData.append("swap_id_two", rowData.id);
            // formData.append("swap_catid_one", SwapBy.cat_id);
            // formData.append("swap_catid_two", rowData.cat_id);
            // await axios({
            //     method: "post",
            //     url: 'http://localhost/stgc/inventory.php',
            //     data: formData,
            // }).then((res) => {
            //     getInventoryAPI()
            //     getCategoryAPI()
            //     setSwapBy({
            //         "id": null,
            //         "cat_id": null,
            //         "cat_name": null,
            //         "product_count": null,
            //         "product_stock": null,
            //         "representative": {
            //             "user_id": null,
            //             "name": null,
            //             "image": null
            //         }
            //     })
            //     console.log(res.data)
            // })
            //     .catch((err) => {
            //         console.log(err);
            //     });
        } else {
            alert('Sorry you cannot Swap Category some category duplicate ')
        }


    }

    const AssignHere = async (rowData) => {


        // const index = customers.findIndex(x=>x.cat_id === assignOption.id_category);
        // const index = assignOption.findIndex(x=>x.assign_users.user_id === rowData.representative.user_id);
        // const _index = category.findIndex(x=>x.assign_users.user_id != rowData.representative.user_id);
        // customers[index].cat_id = false;
        // console.log(assignOption.assign_users[index].user_id, assignOption.assign_users[index].firstname)
        // console.log(assignOption)
        // setCartList([...CartList])
        // console.log(customers[index].cat_id, customers[index].cat_name, assignOption.id_category)

        const updatedUserId = assignOption.assign_users.filter((i) => {
            return i.user_id == rowData.representative.user_id;
        })

        // console.log(customers[index]?.cat_id, assignOption.id_category, updatedUserId[0]?.user_id, rowData.representative.user_id)

        // const updatedInventoryList = customers.filter((i) => {
        // return i.id !== rowData.id;
        // return i.cat_id == rowData.cat_id && i.representative.user_id == rowData.representative.user_id;
        // });   
        // alert(JSON.stringify(customers[index]?.cat_id +' == '+ assignOption.id_category +' == '+ updatedUserId[0]?.user_id))
        // console.log(updatedInventoryList[index])
        if (customers[index]?.cat_id != assignOption.id_category && updatedUserId[0]?.user_id != rowData.representative.user_id) {
            // console.log('asdas')
            var formData = new FormData();
            formData.append("assign_here", 1);
            formData.append("assign_from_user_id", rowData.representative.user_id);
            formData.append("id_category", assignOption.id_category);
            await axios({
                method: "post",
                url: ENV.DOMAIN+'inventory.php',
                // url: 'http://localhost/stgc/inventory.php',
                data: formData,
            }).then((res) => {
                // getInventoryAPI()
                // getCategoryAPI()
                setAssignOption({ "id_category": null, "cat_name": null })
                setCustomers([...customers, {
                    "id": res.data[0].id,
                    "cat_id": assignOption.id_category,
                    "cat_name": assignOption.cat_name,
                    "product_count": assignOption.product_count,
                    "product_stock": assignOption.product_stock,
                    "representative": {
                        "user_id": rowData.representative.user_id,
                        "name": rowData.representative.name,
                        "image": rowData.representative.image,
                    },
                    "remove": false,
                    "swaphere": false
                }]);
                // console.log(res.data[0].id)
            })
                .catch((err) => {
                    console.log(err);
                });

            const _updatedUserId = assignOption.assign_users.filter((i) => {
                return i.user_id;
            })

            ///////////////////////////////

            const newUserAssign = [..._updatedUserId,
            {
                "user_id": rowData.representative.user_id,
                "firstname": rowData.representative.name
            }
            ];

            const __category = category;

            const _index = __category.findIndex(x => x.id_category === assignOption.id_category);
            __category[_index].assign_users = newUserAssign;

            // console.log([...__category])
            setCategory([...category, __category])
        } else {
            return;
        }
        // console.log(category)
        // setCustomers([...customers, {
        //     "id": assignOption.id,
        //     "cat_id": assignOption.id_category,
        //     "cat_name": assignOption.cat_name,
        //     "product_count": assignOption.product_count,
        //     "product_stock": assignOption.product_stock,
        //     "representative": {
        //         "user_id": rowData.representative.user_id,
        //         "name": rowData.representative.name,
        //         "image": rowData.representative.image,
        //     },
        //     "remove": false,
        //     "swaphere": false
        // }])


        // console.log(rowData.representative.name+' : '+rowData.representative.user_id+' : '+assignOption.id_category)

        //     var formData = new FormData();
        //     formData.append("assign_here", 1);
        //     formData.append("assign_from_user_id", rowData.representative.user_id);
        //     formData.append("id_category", assignOption.id_category);
        //     await axios({
        //         method: "post",
        //         url: 'http://localhost/stgc/inventory.php',
        //         data: formData,
        //     }).then((res) => {
        //         getInventoryAPI()
        //         getCategoryAPI()
        //         setAssignOption({"id_category":null,"cat_name":null})
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });

    }

    const DirectAssign = async (rowDataCategory, rowDataUser) => {

        var formData = new FormData();
        formData.append("direct_assign", 1);
        formData.append("user_id", rowDataUser.user_id);
        formData.append("cat_id", rowDataCategory.id_category);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'inventory.php',
            // url: 'http://localhost/stgc/inventory.php',
            data: formData,
        }).then((res) => {
            getInventoryAPI()
            getCategoryAPI()
            getUserAPI()
            setAssignOption({ "id_category": null, "cat_name": null })
            setSelectedUser({ user_id: null, firstname: null, user_image: null })
        })
            .catch((err) => {
                console.log(err);
            });

    }

    const reset_inventory = async () => {
        var formData = new FormData();
        formData.append("reset_inventory", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'inventory.php',
            // url: 'http://localhost/stgc/inventory.php',
            data: formData,
        }).then((res) => {
            
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const resetbyuser = async (rowData) => {
        // alert(JSON.stringify(Number(rowData.representative.user_id)))
        // return;
        var formData = new FormData();
        formData.append("reset_byuser", 1);
        formData.append("user_id", Number(rowData.representative.user_id));
        swal({
            title: "Reset!",
            text: "Are you sure?",
            icon: "info",
            // timer: 1000,
            buttons: {
                cancel: "No",
                confirm: "Yes"
            },
        }).then(async (confirm) => {
            if (confirm) {
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'inventory.php',
                    data: formData,
                }).then((res) => {
                    if (res.data === 'success') {
                        swal({
                            title: "Reset!",
                            text: "Successfull",
                            icon: "success",
                            timer: 1000,
                            button: false,
                        });
                    }
                })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    }

    // console.log(SwapByUserId.representative.user_id, SwapByUserId.cat_id)
    const [assignOption, setAssignOption] = useState({ "id_category": null, "cat_name": null })

    const ClickToAssign = (rowData) => {
        if (assignOption.id_category != null) {
            setAssignOption({ "id_category": null, "cat_name": null })
        } else {
            setAssignOption(rowData)
            // alert(rowData.id_category);
        }
    }

    let index = 0;
    const statusBodyCategory = (rowData) => {
        // index = customers.findIndex(x=>x.cat_id === rowData.id_category);
        // customers[index].cat_id;
        // setAssignOption([...customers]);
        // if(assignOption.id_category == null) {
        //     if(selectedUser.user_id != null) {
        //         return (
        //             <div onClick={()=>DirectAssign(rowData, selectedUser)} style={{backgroundColor:"#fff", padding:0}}>
        //                 <span className="font-bold">{rowData.cat_name}</span>
        //             </div>
        //         )
        //     } else {
        //         return (
        //             <div onClick={()=>setAssignOption(rowData)} style={{backgroundColor:"#fff", padding:0}}>
        //                 <span className="font-bold">{rowData.cat_name}</span>
        //             </div>
        //         )
        //     }
        // } else {
        //     if (rowData.id_category === assignOption.id_category) {
        //         return (
        //             <div onClick={()=>setAssignOption({"id_category":null,"cat_name":null})} style={{backgroundColor:(rowData.id_category === assignOption.id_category) ? "#ddd" : null, padding:0}}>
        //                 <span className="font-bold">{rowData.cat_name}</span>
        //             </div>
        //         )
        //     } else {
        //         return (
        //             <div onClick={()=>setAssignOption(rowData)} style={{backgroundColor:(rowData.id_category === assignOption.id_category) ? "#ddd" : null, padding:0}}>
        //                 <span className="font-bold">{rowData.cat_name}</span>
        //             </div>
        //         )
        //     }
        // }
        if (rowData?.assign_users?.length > 0) {
            return (
                <>
                    <Tooltip target={".tooltip-button-" + rowData.id_category} autoHide={true} position="left" style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ color: "#cbb33f", fontWeight: "800" }}>{rowData?.cat_name}</div>
                        <div style={{ width: "100%", borderTop: "1px solid white" }}></div>
                        {
                            (rowData?.assign_users).map((item) => {
                                return (
                                    <div>
                                        {item?.firstname}
                                        {/* <span style={{ minWidth: '5rem' }}>Count: {count}</span> */}
                                        {/* <Button type="button" icon="pi pi-plus" onClick={() => setCount(count + 1)} className="p-button-rounded p-button-success ml-2"></Button>
                                            <Button type="button" icon="pi pi-minus" onClick={() => setCount(count - 1)} className="p-button-rounded p-button-danger ml-2"></Button> */}
                                    </div>
                                )
                            })
                        }

                    </Tooltip>
                    <div className={"tooltip-button-" + rowData?.id_category} onClick={() => ClickToAssign(rowData)} style={{ display: "flex", cursor: "pointer", flexDirection: "row", justifyContent: "space-between", backgroundColor: (rowData.id_category === assignOption.id_category) ? "#ddd" : null, padding: 0 }}>
                        <span className="font-bold">{rowData?.cat_name}</span>
                        <span className="font-bold">{rowData?.assign_users?.length}</span>
                    </div>
                </>
            )
        } else {
            return (
                <div onClick={() => ClickToAssign(rowData)} style={{ display: "flex", cursor: "pointer", flexDirection: "row", justifyContent: "space-between", backgroundColor: (rowData.id_category === assignOption.id_category) ? "#ddd" : null, padding: 0 }}>
                    <span className="font-bold">{rowData?.cat_name}</span>
                    <span className="font-bold">{rowData?.assign_users}</span>
                </div>
            )
        }

    }
    // console.log(assignOption)

    const AssignBodyTemplate = (rowData) => {
        const updatedUserId = assignOption?.assign_users?.filter((i) => {
            return i.user_id == rowData?.representative?.user_id;
        })

        // console.log(assignOption)
        if (updatedUserId[0]?.user_id != rowData?.representative?.user_id) {
            return (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 5 }}>
                    <a onClick={() => AssignHere(rowData)} style={{ cursor: 'pointer', backgroundColor: "#20837a", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", width: '33%' }}>Assign Here</a>
                </div>
            );
        } else {
            return (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 5 }}>
                    <a style={{ cursor: "not-allowed", backgroundColor: "#dddddd", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", width: '33%' }}>Assign Here</a>
                </div>
            );
        }
    }

    const [selectedUser, setSelectedUser] = useState({
        "user_id": null,
        "firstname": null,
        "user_image": null
    });

    const selectUser = async (rowData) => {
        // setSelectedUser(rowData)
        // console.log(rowData, assignOption.id_category);
        if (assignOption.id_category != null) {
            var formData = new FormData();
            formData.append("direct_assign", 1);
            formData.append("user_id", rowData.user_id);
            formData.append("cat_id", assignOption.id_category);
            await axios({
                method: "post",
                url: ENV.DOMAIN+'inventory.php',
                // url: 'http://localhost/stgc/inventory.php',
                data: formData,
            }).then((res) => {
                // getInventoryAPI()
                // getCategoryAPI()
                getUserAPI()
                setAssignOption({ "id_category": null, "cat_name": null })
                setSelectedUser({ user_id: null, firstname: null, user_image: null })

                setCustomers([...customers, {
                    "id": res.data[0].id,
                    "cat_id": assignOption.id_category,
                    "cat_name": assignOption.cat_name,
                    "product_count": assignOption.product_count,
                    "product_stock": assignOption.product_stock,
                    "representative": {
                        "user_id": rowData.user_id,
                        "name": rowData.firstname,
                        "image": rowData.user_image,
                    },
                    "remove": false,
                    "swaphere": false
                }]);
            })
                .catch((err) => {
                    console.log(err);
                });

            ///////////////////////////////

            const _updatedUserId = assignOption.assign_users.filter((i) => {
                return i.user_id;
            })

            ///////////////////////////////

            const newUserAssign = [..._updatedUserId,
            {
                "user_id": rowData.user_id,
                "firstname": rowData.firstname
            }
            ];

            ///////////////////////////////

            const __category = category;

            const _index = __category.findIndex(x => x.id_category === assignOption.id_category);
            __category[_index].assign_users = newUserAssign;


            // console.log([...category, __category])
            setCategory([...category, __category])


        } else {
            alert('');
        }
    }

    const statusBodyTemplate = (rowData) => {
        // console.log(rowData.cat_id, SwapBy.cat_id)
        // if (hide == true) {
        //     return (
        //         <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 5 }}>
        //             <a onClick={()=> window.location.assign("/inventory/review/"+rowData.representative.user_id+"/"+rowData.cat_id)} style={{ cursor: 'pointer', backgroundColor: "#20837a", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", width: '33%' }}>Review</a>
        //         </div>
        //     );
        // }
        if (SwapBy.remove == false && rowData.representative.user_id === SwapBy.representative.user_id) {
            return (
                <>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, padding: 0 }}>
                        {
                            (rowData.cat_id === SwapBy.cat_id) ?
                                <span className="font-bold" onClick={() => Swap({
                                    "id": null,
                                    "cat_id": null,
                                    "cat_name": null,
                                    "product_count": null,
                                    "product_stock": null,
                                    "representative": {
                                        "user_id": null,
                                        "name": null,
                                        "image": null
                                    }
                                })} style={{ color: "#fff", cursor: "pointer", width: "100%", textAlign: "center", padding: 5, borderRadius: 5, backgroundColor: "#971416", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5 }}>Cancel
                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </span>
                                :
                                <a onClick={() => { Swap(rowData) }} style={{ color: '#fff', backgroundColor: "#23709e", width: "100%", padding: 5, color: "#fff", borderRadius: 5, cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 5 }}>
                                    Swap
                                    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="currentColor" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                    </svg>
                                </a>
                        }
                    </div>
                </>
            );
        } else {
            if (SwapBy.remove == true) {
                if (SwapBy.cat_id == rowData.cat_id && SwapBy.representative.user_id == rowData.representative.user_id) {
                    return (
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 10 }}>
                            <a onClick={() => RemoveCategory(rowData)} style={{ width: "50%", textAlign: "center", backgroundColor: "#00cfbd", padding: 5, color: "#fff", borderRadius: 5 }}>Confirm</a>
                            <a onClick={() => setSwapBy({
                                "id": null,
                                "cat_id": null,
                                "cat_name": null,
                                "product_count": null,
                                "product_stock": null,
                                "representative": {
                                    "user_id": null,
                                    "name": null,
                                    "image": null
                                },
                                "remove": false
                            })} style={{ width: "50%", textAlign: "center", backgroundColor: "#d46b2a", padding: 5, color: "#fff", borderRadius: 5 }}>Cancel</a>
                        </div>
                    );
                } else {
                    return (
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 5 }}>
                            <a onClick={() => null} style={{ cursor: 'pointer', backgroundColor: "#b0a648", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", width: '100%' }}>Assign</a>
                            <a onClick={()=>window.href="/inventory/review/"+rowData.cat_id} style={{ backgroundColor: "#20837a", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", width: '100%' }}>Review</a>
                            <a onClick={() => { Swap(rowData) }} style={{ backgroundColor: "#23709e", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", width: '100%' }}>Swap</a>
                            <a onClick={() => { RemoveCategoryBtn(rowData) }} style={{ backgroundColor: "#971416", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", width: '100%' }}>Remove</a>
                            {/* <a style={{color:'red'}}>Remove</a> */}
                        </div>
                    );
                }
            } else {
                if (SwapBy.cat_id) {
                    const ____index = updateSwap.findIndex(x => x.representative.user_id == rowData.representative.user_id);
                    // console.log(updateSwap[____index].representative.user_id, rowData.representative.user_id);
                    if (updateSwap[____index]?.representative?.user_id != rowData.representative.user_id) {
                        return (
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 10 }}>
                                <a onClick={() => { Swap(rowData) }} style={{ backgroundColor: "#23709e", width: "100%", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", cursor: "pointer" }}>Swap</a>
                                {
                                    SwapBy.cat_id != rowData.cat_id ?
                                        <>
                                            <a onClick={() => { SwapHere(rowData) }} style={{ backgroundColor: "#20837a", width: "100%", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", cursor: "pointer" }}>Swap Here</a>
                                        </>
                                        :
                                        <a style={{ backgroundColor: "#ddd", width: "100%", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", cursor: "not-allowed" }}>Swap Here</a>
                                }

                            </div>
                        );
                    } else {
                        return (
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 10 }}>
                                <a onClick={() => { Swap(rowData) }} style={{ backgroundColor: "#23709e", width: "100%", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", cursor: "pointer" }}>Swap</a>
                                <a style={{ backgroundColor: "#ddd", width: "100%", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", cursor: "not-allowed" }}>Swap Here</a>
                            </div>
                        )
                    }
                } else {
                    // console.log(rowData.cat_id, assignOption.id_category)
                    // console.log(rowData[index]?.cat_id)
                    // if (rowData.findIndex(x=>x.cat_id === assignOption.id_category)) {  
                    if (rowData.cat_id == assignOption.id_category) {
                        // return (
                        //     <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", gap:5}}>
                        //         <a onClick={()=>null} style={{cursor:'pointer', backgroundColor:"#20837a", padding:5, color:"#fff", borderRadius:5, textAlign:"center", width:'33%'}}>Review</a>
                        //         <a onClick={()=>{Swap(rowData)}} style={{cursor:'pointer', backgroundColor:"#23709e", padding:5, color:"#fff", borderRadius:5, textAlign:"center", width:'33%'}}>Swap</a>
                        //         <a onClick={()=>{RemoveCategoryBtn(rowData)}} style={{cursor:'pointer', backgroundColor:"#971416", padding:5, color:"#fff", borderRadius:5, textAlign:"center", width:'33%'}}>Remove</a>
                        //         {/* <a style={{color:'red'}}>Remove</a> */}
                        //     </div>
                        // ); 
                    } else {
                        return (
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 5 }}>
                                <a onClick={()=> window.location.assign("/inventory/review/"+rowData.representative.user_id+"/"+rowData.cat_id)} style={{ cursor: 'pointer', backgroundColor: "#20837a", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", width: '33%' }}>Review</a>
                                <a onClick={() => { Swap(rowData) }} style={{ cursor: 'pointer', backgroundColor: "#23709e", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", width: '33%' }}>Swap</a>
                                <a onClick={() => { RemoveCategoryBtn(rowData) }} style={{ cursor: 'pointer', backgroundColor: "#971416", padding: 5, color: "#fff", borderRadius: 5, textAlign: "center", width: '33%' }}>Remove</a>
                                {/* <a style={{color:'red'}}>Remove</a> */}
                            </div>
                        );
                    }
                }
            }



        }
    };

    const representativeBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={rowData.representative.name} src={`https://x.stgc.online/pages/${rowData.representative.image}`} style={{ width: 70, borderRadius: 10 }} />
                <span className="font-bold">{rowData.representative.name}</span>
            </div>
        );
    };

    const categoryBodyTemplate = (rowData) => {
        if(assignOption.id_category == rowData.cat_id) {
            return (
                <div style={{ backgroundColor: "#ebf8a4" , padding: 0 }}>
                    <span className="font-bold">{rowData.cat_name}</span>
                </div>
            );
        } else {
            const ____index = SwapAlert?.findIndex(x => x?.cat_id == rowData?.cat_id);
            // console.log(SwapAlert[____index]?.cat_id, rowData?.cat_id)
            if (SwapAlert[____index]?.cat_id != rowData?.cat_id) {
                return (
                    <div style={{ backgroundColor: (rowData.cat_id === SwapBy.cat_id && SwapBy.remove == true && SwapBy.representative.user_id == rowData.representative.user_id) ? "#ddd" : null, padding: 0 }}>
                        <span className="font-bold">{rowData.cat_name}</span>
                    </div>
                );
            } else {
                return (
                    <div style={{ backgroundColor: "rgb(151 64 66)", padding: 0, color: "#fff", paddingLeft:10 }}>
                        <span className="font-bold">{rowData.cat_name}</span>
                    </div>
                );
            }
        }
    };

    const ProductandStock = (rowData) => {
        return (
            <div style={{ backgroundColor: (rowData.cat_id === SwapBy.cat_id && SwapBy.remove == true && SwapBy.representative.user_id == rowData.representative.user_id) ? "#ddd" : null, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", gap: 10 }}>
                <div>(P {rowData.product_count})</div>
                <div>(S {rowData.product_stock})</div>
            </div>
        );
    };

    const getSeverity = (status) => {
        switch (status) {
            case 'unqualified':
                return 'danger';

            case 'qualified':
                return 'success';

            case 'new':
                return 'info';

            case 'negotiation':
                return 'warning';

            case 'renewal':
                return null;
        }
    };

    return (

        <>
            <div className="vd_body">
                {/* Header Start */}
                <Header />
                {/* Header Ends */}
                <div className="content">
                    <div className="container">
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div className="row">

                                            <div className={category.length > 0 ? 'col-sm-10' : 'col-sm-12'}>
                                                <div style={{ padding: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                    <div className='hidden-xs'>
                                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 10, overflow: "auto" }}>
                                                                {
                                                                    users.map((item, key) => {
                                                                        if (selectedUser.user_id == item.user_id) {
                                                                            return (
                                                                                <div key={key} onClick={() => selectUser({
                                                                                    "user_id": null,
                                                                                    "firstname": null,
                                                                                    "user_image": null
                                                                                })} style={{ cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: 70, height: 70, backgroundColor: "red", borderRadius: 100 }}>
                                                                                    <img src={`https://stgc.tech/pages/${item.user_image}`} style={{ width: "95%", height: "95%", borderRadius: 100 }} />
                                                                                    <div style={{ position: "absolute", backgroundColor: "#23709e", opacity: 0.5, width: 70, height: 70, borderRadius: 100, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}></div>
                                                                                    <span style={{ position: "absolute", zIndex: 1, fontWeight: "bold", color: "#fff" }}>Selected</span>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <div key={key} onClick={() => selectUser(item)} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: 70, height: 70, backgroundColor: "#23709e", borderRadius: 100 }}>
                                                                                    <img src={`https://stgc.tech/pages/${item.user_image}`} style={{ width: "95%", height: "95%", borderRadius: 100 }} />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='visible-xs'>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 10, overflow: "auto" }}>
                                                            {
                                                                users.map((item, key) => {
                                                                    return (
                                                                        <div key={key} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: 70, height: 70, backgroundColor: "red", borderRadius: 100 }}>
                                                                            <img src={`https://stgc.tech/pages/${item.user_image}`} style={{ width: "95%", height: "95%", borderRadius: 100 }} />
                                                                            <div style={{ position: "absolute", backgroundColor: "#ddd", opacity: 0.5, width: 70, height: 70, borderRadius: 100, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}></div>
                                                                            <span style={{ position: "absolute", zIndex: 1, fontWeight: "bold", color: "#971416" }}>Selected</span>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button onClick={()=>reset_inventory()} style={{borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#23709e", height: 45, width: 150, color: "#fff"}}>Reset all Inventory</button>
                                                    </div>
                                                </div>
                                                <Panel header="Assignment">
                                                    <DataTable value={customers} rowGroupMode="rowspan" groupRowsBy="representative.name"
                                                        sortMode="single" sortField="representative.name" sortOrder={1} tableStyle={{ minWidth: '50rem' }}>
                                                        {/* <Column header="#" headerStyle={{ width: '3rem' }} body={(data, options) => options.rowIndex + 1}></Column> */}
                                                        <Column field="representative.name" header="Representative" body={representativeBodyTemplate} style={{ width: '200px' }}></Column>
                                                        <Column field="cat_name" header="Category" body={categoryBodyTemplate} style={{ minWidth: '200px', padding: 0 }}></Column>
                                                        {/* <Column field="name" header="Name" style={{ minWidth: '200px' }}></Column> */}
                                                        {/* <Column field="name" header="Product & Stock" body={ProductandStock} style={{ minWidth: '150px', padding: 0 }}></Column> */}
                                                        {/* <Column field="company" header="Company" style={{ minWidth: '200px' }}></Column> */}
                                                        {
                                                            assignOption.id_category == null ?
                                                                <Column field="status" header="Action" body={statusBodyTemplate} style={{ minWidth: '100px', padding: 2 }}></Column>
                                                                :
                                                                <Column field="representative.name" header="Action" body={AssignBodyTemplate} style={{ minWidth: '50px', backgroundColor: null }}></Column>
                                                        }
                                                        {/* <Column field="date" header="Date" style={{ minWidth: '100px' }}></Column> */}
                                                        <Column field="representative.name" header="" body={(rowData)=>{
                                                            return (
                                                                <><button onClick={()=>resetbyuser(rowData)} style={{width: "100%", height: 35, fontSize: 15, fontWeight: "bolder", borderRadius: 5}} className='btn btn-warning'>Reset Inventory</button></>
                                                            )
                                                        }} style={{ width: '200px' }}></Column>
                                                    </DataTable>
                                                </Panel>
                                            </div>
                                            {
                                                category.length > 0 ?
                                                    <div className='col-sm-2'>
                                                        <Panel header="Category List">
                                                            <DataTable value={category} rowGroupMode="rowspan" groupRowsBy="representative.name"
                                                                sortMode="single" sortField="cat_name" sortOrder={1}>
                                                                <Column field="cat_name" body={statusBodyCategory} style={{ minWidth: '200px', padding: 0 }}></Column>
                                                                {/* <Column field="status" header="Action" body={statusBodyTemplate} style={{ minWidth: '100px', padding:2 }}></Column> */}
                                                            </DataTable>
                                                        </Panel>
                                                    </div>
                                                    :
                                                    null
                                            }



                                        </div>
                                    </div>
                                    {/* .vd_content-section */}
                                </div>
                                {/* .vd_content */}
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
                {/* .content */}
                {/* Footer Start */}
                <footer className="footer-1" id="footer">
                    <div className="vd_bottom ">
                        <div className="container">
                            <div className="row">
                                <div className=" col-xs-12">
                                    <div className="copyright">
                                        Copyright ©2014 Venmond Inc. All Rights Reserved
                                    </div>
                                </div>
                            </div>{/* row */}
                        </div>{/* container */}
                    </div>
                </footer>
            </div>



        </>

    );
}

export default InventoryAssignment;

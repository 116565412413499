import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useMemo, useState } from 'react';
import ENV from '../../../../ENV';
import ViewSalesStockReport from './ViewSalesStockReport';

const ViewStock = (props) => {
    const [visible, setVisible] = useState(null)
    const [stockdata, setStockData] = useState([])
    const [salesstockdata, setSalesStockData] = useState([])
    const [stocksearch, setSearch] = useState('')
    const [salessearch, setSalesSearch] = useState({})
    const [userstatus, setUserStatus] = useState(null)

    useMemo(async () => {
        var formData = new FormData();
        formData.append("checkUserStatus", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        // setInterval(async()=> {
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/user.php',
            data: formData,
        }).then((res) => {
            setUserStatus([...res.data])
            // console.log(JSON.parse(res.data))
        })
            .catch((err) => {
                console.log(err);
            });
        // }, 5000);
    }, [ENV, props]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("stocklist", 1);
        formData.append("pid", props.item.pid);
        // setInterval(async()=> {
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            setStockData([...res.data])
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
        // }, 5000);
    }, [ENV, props]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("sales_stock_report", 1);
        formData.append("pid", props.item.pid);
        // setInterval(async()=> {
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setSalesStockData([...res.data])
        })
            .catch((err) => {
                console.log(err);
            });
        // }, 3000)
    }, [ENV, props]);

    const totalsold = salesstockdata?.reduce((amount, item) => amount = Number(item.sales_qty) + amount, 0);

    const totalStock = stockdata?.reduce((amount, item) => amount = Number(item.stock_qty) + amount, 0);

    const Data = stockdata.filter((item) => {
        if (stocksearch.length >= 3) {
            return String(item.serial).toLowerCase().includes(String(stocksearch).toLowerCase()) || String(item.supplier_invoice).toLowerCase().includes(String(stocksearch).toLowerCase())
        }
        return item;
    })

    const SalesData = salesstockdata.filter((item) => {
        if ((salessearch?.search_salesrecord)?.length >= 3) {
            return String(item.customer_name).toLowerCase().includes(String(salessearch?.search_salesrecord).toLowerCase()) || String(item.serial_number).toLowerCase().includes(String(salessearch?.search_salesrecord).toLowerCase()) || String(item.sales_no).toLowerCase().includes(String(salessearch?.search_salesrecord).toLowerCase())
        }
        return item;
    })

    const handleclose = () => {
        props.setVisibleExpand(false)
        setSalesSearch(prev => ({...prev, stock_id: Number, "search_salesrecord": String, option: 'searched'}))
    }


    useMemo(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                props.setVisibleExpand(false)
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const ClickOnSearch = (event, option) => {
        if (option ===  'searched') {
            setSalesSearch(prev => ({...prev, stock_id: event.stock_id, "search_salesrecord": event.serial, option: 'searched'}))
            return;
        }
        setSalesSearch(prev => ({...prev, stock_id: 0, "search_salesrecord": '', option: 'searched'}));
    }

    return (

        <Dialog
            blockScroll={true}
            maximized={true}
            draggable={false}
            // header={"Header"} 
            closable={false}
            footer={
                <div style={{ paddingTop: 10 }}>
                    <button onClick={() => handleclose()} className='btn btn-sm btn-danger'>Close</button>
                </div>
            }
            position={"top"}
            visible={props.visibleexpand} style={{ width: '40vw' }} onHide={() => setVisible(false)}>
            <div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                    <div style={{ width: "20%" }}>
                        <img src={ENV.IMG_URL + props.item.product_image} style={{ border: "1px solid #ddd" }} />
                        <div>
                            {/* <div style={{fontSize: 15, padding: 5}}>
                                <button className='btn btn-info btn-block btn-xs' style={{fontSize: 15}}>Stock-In here</button>
                            </div> */}
                            <div style={{ fontSize: 15, padding: 5 }}><span style={{ fontSize: 18, fontWeight: "bolder" }}>{props.item.product_name}</span></div>
                            <div style={{ fontSize: 15, padding: 5 }}>Code: <span style={{ fontSize: 18, fontWeight: "bolder" }}>{props.item.product_code}</span></div>
                            <div style={{ fontSize: 15, padding: 5 }}>Brand: <span style={{ fontSize: 18, fontWeight: "bolder" }}>{props.item.brand_name}</span></div>
                            <div style={{ fontSize: 15, padding: 5 }}>Category: <span style={{ fontSize: 18, fontWeight: "bolder" }}>{props.item.cat_name}</span></div>
                            <div style={{ fontSize: 15, padding: 5 }}>Sub Category: <span style={{ fontSize: 18, fontWeight: "bolder" }}>{props.item.subcat_name}</span></div>
                            <div style={{ fontSize: 15, padding: 5 }}>Description: <span>{props.item.specs}</span></div>
                        </div>
                    </div>
                    <div style={{ width: "60%", padding: 2 }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 5, paddingBottom: 5, borderBottom: "1px solid #ddd" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                <span style={{ fontSize: 20, fontWeight: "bolder" }}>Records: {stockdata.length}</span>
                                <span style={{ fontSize: 20, padding: 3, fontWeight: "bolder", backgroundColor: "#57985f", borderRadius: 5, color: "#fff" }}>
                                    Current Stock:
                                    <span style={{ fontSize: 18, fontWeight: "bolder" }}>
                                        <span style={{ padding: 5 }}>{totalStock}</span>
                                    </span>
                                </span>
                                <span style={{ fontSize: 20, padding: 3, fontWeight: "bolder", backgroundColor: "#57985f", borderRadius: 5, color: "#fff" }}>
                                    Total Sold:
                                    <span style={{ fontSize: 18, fontWeight: "bolder" }}>
                                        <span style={{ padding: 5 }}>{totalsold}</span>
                                    </span>
                                </span>
                                <span style={{ fontSize: 20, padding: 3, fontWeight: "bolder", backgroundColor: "#57985f", borderRadius: 5, color: "#fff" }}>
                                    Overall Total:
                                    <span style={{ fontSize: 18, fontWeight: "bolder" }}>
                                        <span style={{ padding: 5 }}>{Number(totalStock + totalsold)}</span>
                                    </span>
                                </span>
                            </div>
                            <span style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                <span style={{ fontSize: 15 }}>Search:</span>
                                <input className='form-control' onChange={(e) => setSearch(e.target.value)} />
                            </span>
                        </div>
                        <div style={{ overflow: "auto", height: window.innerHeight }} className='hidescroll'>
                            <table className='table table-bordered'>
                                <thead>
                                    <tr style={{ fontSize: 15 }}>
                                        <th style={{ width: 100 }}>Qty</th>
                                        <th>Serial</th>
                                        <th>Supplier / Invoice</th>
                                        <th style={{ width: 200 }}>Date</th>
                                        <th style={{width: 50}}>History</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Data.length > 0 ? (
                                            Data.map((i) => {
                                                return (
                                                    <tr key={i} style={{ fontSize: 15, backgroundColor: salessearch.stock_id === i.stock_id ? "#ddd" : "#fff" }}>
                                                        <td>{i.stock_qty}</td>
                                                        <td>
                                                            <div onClick={() => {
                                                                console.log(typeof(i.no_serial))
                                                                if (i.no_serial === '0' && Number(i.stock_qty) === 0) {
                                                                    salessearch.stock_id === i.stock_id ? ClickOnSearch(i, salessearch.option === 'searched' ? 'unsearch' : 'searched') : ClickOnSearch(i, 'searched')
                                                                }
                                                            }} style={{textDecoration: i.no_serial === '0' ? "underline" : null, cursor: i.no_serial === '0' ? Number(i.stock_qty) === 0 ? "pointer" : null : null}}>{i.serial}</div>
                                                        </td>
                                                        <td>
                                                            <div>Supplier: <b>{i.supplier_name}</b></div>
                                                            <div>Invoice: <b>{i.supplier_invoice}</b></div>
                                                        </td>
                                                        <td>
                                                            <div>Purchased: <b>{i.date_purchace}</b></div>
                                                            <div>Encoded: <b>{i.date_encoded}</b></div>
                                                        </td>
                                                        <td>
                                                            <div style={{display: "flex"}}>
                                                                <button style={{borderRadius: 5, backgroundColor: "rgb(30 115 158)", color: "#fff"}}>
                                                                    <i className='pi pi-history' style={{fontSize: 15}} />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ) : (
                                            <tr style={{ fontSize: 15 }}>
                                                <td colSpan={4}>Loading...</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ width: "20%", padding: 2 }}>
                        <div style={{ paddingTop: 10, paddingBottom: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <span style={{ fontSize: 30, fontWeight: "bolder" }}>Sales Report</span>
                            <span style={{ fontSize: 15, fontWeight: "bolder" }}>Sales Records {salesstockdata.length}</span>
                        </div>
                        <div>
                            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                                <div style={{ display: "flex", gap: 5, flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                    <span style={{ fontSize: 15 }}>Search:</span>
                                    <input name='search_salesrecord' onChange={(e) => setSalesSearch(prev => ({...prev, [e.target.name]:e.target.value}))} className='form-control' style={{ width: 200 }} />
                                </div>
                            </div>
                        </div>
                        <div style={{ border: "1px solid #fff", minHeight: 500, display: "flex", flexDirection: "column", gap: 5, overflow: "auto", height: window.innerHeight }} className='hidescroll'>
                            {
                                SalesData.length > 0 ?
                                    (
                                        SalesData.map((stock) => {
                                            return (
                                                <ViewSalesStockReport key={stock} stock={stock} />
                                            )
                                        })
                                    ) : (
                                        <div>Loading...</div>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default ViewStock;

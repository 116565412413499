import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import ENV from '../../ENV';
import { Checkbox } from 'primereact/checkbox';
import { format } from 'date-fns';

const ModifyBtn = ({OnlySalesData, item, PreviewDetails}) => {
    
    const [showremove, setshowRemoved] = useState(0);
    const [modifyitemLoop, setmodifyitemLoop] = useState(false);
    const [replaceaction, setreplaceAction] = useState(false);

    
    const [afteractionData, setafterActionData] = useState([]);

    const [note, setNote] = useState('');
    const [unitprice, setUnitPrice] = useState(item.stock_price);
    const [warranty, setWarranty] = useState(item.warranty);
    const [remarks, setRemarks] = useState(item.remarks);
    
    const hideCustomerDialog = () => {
        setmodifyitemLoop(false);
        setshowRemoved(null)
        // setSearchExistingCustomer(false)
    }

    const updatebtn = async () => {
        const intex = OnlySalesData.list.findIndex(x => x.sales_stock_id === item?.sales_stock_id);
        OnlySalesData.list[intex].stock_price = unitprice;
        OnlySalesData.list[intex].warranty = warranty;
        OnlySalesData.list[intex].remarks = remarks;
        // console.log(showremove, OnlySalesData.list, afteractionData, item?.sales_stock_id)
        // console.log(OnlySalesData, item?.sales_stock_id)
        PreviewDetails(OnlySalesData);

        var formData = new FormData();
        formData.append("update_sales_stock", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("sales_id", item?.sales_id);
        formData.append("sales_stock_id", item?.sales_stock_id);
        formData.append("stock_price", unitprice);
        formData.append("remarks", remarks);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'sales_report.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            // setafterActionData(res.data)
            hideCustomerDialog()
        });
    }

    const updateandreplace = async () => {
        console.log(showremove, OnlySalesData.list, afteractionData)









        return;
        var formData = new FormData();
        formData.append("sales_report_update_replace", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        // Remove
        formData.append("old_sales_id", item.sales_id);
        formData.append("old_sales_stock_id", item.sales_stock_id);
        formData.append("old_product_name", item.product_name);
        formData.append("old_product_code", item.product_code);
        // new
        formData.append("new_sales_id", afteractionData.sales_id);
        formData.append("new_stock_id", afteractionData.stock_id);
        formData.append("new_product_name", afteractionData.product_name);
        formData.append("new_product_code", afteractionData.product_code);
        formData.append("new_serial_number", afteractionData.serial);
        formData.append("new_sales_qty", afteractionData.stock_qty);
        formData.append("new_stock_price", afteractionData.price_srp);


        // alert(formData)
        // // formData.append("serial", e.target.value);
        // await axios({
        //     method: "post",
        //     url: ENV.DOMAIN + 'sales_report.php',
        //     data: formData,
        // }).then((res) => {
        //     console.log(res.data)
        //     setafterActionData(res.data)
        // });
    }

    const scanserial = async (e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            var formData = new FormData();
            formData.append("sales_report_item_replace", 1);
            formData.append("user_id", localStorage.getItem('user_id'));
            formData.append("serial", e.target.value);
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'sales_report.php',
                data: formData,
            }).then((res) => {
                console.log(res.data)
                setafterActionData(res.data)
            });
        }
    }

    const removeitemLoop = async (items) => {
        // const data = OnlySalesData.list;

        const intex = OnlySalesData.list.findIndex(x => x.sales_stock_id === items?.sales_stock_id);
        // data[intex].stock_status = 'removed';
        // OnlySalesData.list = data;   
        // && val.stock_status!='removed'     
        // const data = OnlySalesData.list.filter((val) => val.sales_stock_id !== items?.sales_stock_id);
        // OnlySalesData.list = data;
        // OnlySalesData.list.options = [];
        OnlySalesData.list[intex].history = [...OnlySalesData.list[intex].history, {
            "sales_id": Number(OnlySalesData.sales_id),
            "user_id": localStorage.getItem('user_id'),
            "employee_name": localStorage.getItem('firstname'),
            "particular": null,
            "note": null,
            "date_time": format(new Date(), 'yyyy-MM-dd HH:mm:ss')
        }];
        console.log(1, OnlySalesData)
        const data = OnlySalesData.list.filter((val) => val !== items);
        OnlySalesData.list = data;
        PreviewDetails(OnlySalesData);

    }

    // console.log(afteractionData?.length)

    const CustomerDialogFooter = (
        <React.Fragment>
            {
                
                item.product_id !== 0 ?
                <>
                    {
                        replaceaction ?
                        <>
                            <button onClick={()=>{
                                setreplaceAction(false);
                                setafterActionData([]);
                                setshowRemoved(null)
                            }} style={{width:110, height:40, fontSize:12, fontWeight:"bold", borderRadius:5, border:"1px solid #ddd"}}>Cancel Replace</button>
                            <button onClick={()=>{
                                if (afteractionData?.length>0) {
                                    updateandreplace()
                                }
                            }} style={{width:110, height:40, fontSize:12, fontWeight:"bold", borderRadius:5, border:"1px solid #ddd"}}>
                                {
                                    afteractionData?.length>0?
                                        <div>Update Replace </div>
                                    :
                                        <div style={{color:"#cccccc"}}>Update Replace </div>
                                }
                            </button>
                        </>
                        :
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                            <button onClick={()=>removeitemLoop(item)} style={{width:110, height:40, fontSize:12, fontWeight:"bold", borderRadius:5, border:"1px solid rgb(235, 37, 37)", color:"rgb(235, 37, 37)"}}>Remove Item</button>
                            <div>
                                <button onClick={()=>setreplaceAction(true)} style={{width:110, height:40, fontSize:12, fontWeight:"bold", borderRadius:5, border:"1px solid #ddd"}}>Replace Item</button>
                                <button onClick={()=>updatebtn()} style={{width:110, height:40, fontSize:12, fontWeight:"bold", borderRadius:5, border:"1px solid #3b82f6", color:"#3b82f6"}}>Update</button>
                            </div>
                        </div>
                    }
                </>
                :
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <button onClick={()=>removeitemLoop(item)} style={{width:110, height:40, fontSize:12, fontWeight:"bold", borderRadius:5, border:"1px solid rgb(235, 37, 37)", color:"rgb(235, 37, 37)"}}>Remove Item</button>
                    <button onClick={()=>updatebtn()} style={{width:110, height:40, fontSize:12, fontWeight:"bold", borderRadius:5, border:"1px solid #3b82f6", color:"#3b82f6"}}>Update</button>
                </div>
            }
            
        </React.Fragment>
    );

    return (
        <div>
            <Dialog visible={modifyitemLoop} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                <>
                    <div style={{ fontSize: 25 }}>{replaceaction ? 'Modify and Replace' : 'Modification'}</div>
                    {/* <div style={{ fontSize: 12 }}>{item.product_name}</div> */}
                </>
            } modal className="p-fluid" footer={CustomerDialogFooter} onHide={hideCustomerDialog}>
                <div style={{maxHeight: window.innerHeight-200, overflowY:"auto"}} className='hidescroll'>
                    {
                        replaceaction ?
                            <>
                                {
                                    afteractionData?.length>0 ?
                                        <>
                                            <div className='well' style={{padding:5, marginBottom:0}}>
                                                <div style={{paddingBottom:0}}>
                                                    <div>
                                                        <label>Product Name</label>
                                                        <div style={{fontSize:15}}>{item.product_name}</div>
                                                        {/* <input value={item.product_name} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} /> */}
                                                    </div>
                                                </div>
                                                <div style={{paddingBottom:0}}>
                                                    <div>
                                                        <label>Product Code</label>
                                                    </div>
                                                    <div style={{fontSize:15}}>{item.product_code}</div>
                                                    {/* <input value={item.product_code} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} /> */}
                                                </div>
                                                <div style={{paddingBottom:0}}>
                                                    <div>
                                                        <label>Serial</label>
                                                    </div>
                                                    <div style={{fontSize:15}}>{item.serial_number}</div>
                                                    {/* <input value={item.serial_number} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} /> */}
                                                </div>
                                                <div style={{paddingBottom:0}}>
                                                    <div>
                                                        <label>Unit Price</label>
                                                    </div>
                                                    <div style={{fontSize:15}}>{item.stock_price}</div>
                                                </div>
                                            </div>
                                            <div style={{padding:5}}>
                                                <div style={{display:"flex", flexDirection:"row", gap:10}}>
                                                    <div onClick={e => setshowRemoved('ReturntoStock')} style={{ display: "flex", flexDirection: "row", alignSelf: "flex-end", gap: 5, cursor:"pointer" }}>
                                                        <Checkbox checked={showremove === 'ReturntoStock' ? true : false}></Checkbox>
                                                        <label>Return to Stock</label>
                                                    </div>
                                                    <div onClick={e => setshowRemoved('Defective')} style={{ display: "flex", flexDirection: "row", alignSelf: "flex-end", gap: 5, cursor:"pointer" }}>
                                                        <Checkbox checked={showremove === 'Defective' ? true : false}></Checkbox>
                                                        <label>Defective</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{padding:5, fontSize:15, fontWeight:600, textAlign:"center"}}>Replace To</div>
                                            <div className='well' style={{padding:5}}>
                                                <div style={{paddingBottom:0}}>
                                                    <div>
                                                        <label>Product Name</label>
                                                        <div style={{fontSize:15}}>{afteractionData[0]?.product_name}</div>
                                                        {/* <input value={item.product_name} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} /> */}
                                                    </div>
                                                </div>
                                                <div style={{paddingBottom:0}}>
                                                    <div>
                                                        <label>Product Code</label>
                                                    </div>
                                                    <div style={{fontSize:15}}>{afteractionData[0]?.product_code}</div>
                                                    {/* <input value={item.product_code} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} /> */}
                                                </div>
                                                <div style={{paddingBottom:0}}>
                                                    <div>
                                                        <label>Serial</label>
                                                    </div>
                                                    <div style={{fontSize:15}}>{afteractionData[0]?.serial}</div>
                                                    {/* <input value={item.serial_number} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} /> */}
                                                </div>
                                                <div style={{paddingBottom:0}}>
                                                    <div>
                                                        <label>Unit Price</label>
                                                    </div>
                                                    <div style={{fontSize:15}}>{afteractionData[0]?.price_srp}</div>
                                                </div>
                                            </div>
                                            {/* <div>
                                                <input onKeyPress={(e)=>scanserial(e)} placeholder='Scan Serial Number' style={{padding:5, width:"100%", height:40, borderRadius:5, border:"1px solid #ddd"}} />
                                            </div> */}
                                        </>
                                    :
                                        <>
                                            <div className='well' style={{padding:5}}>
                                                <div style={{paddingBottom:0}}>
                                                    <div>
                                                        <label>Product Name</label>
                                                        <div style={{fontSize:15}}>{item.product_name}</div>
                                                        {/* <input value={item.product_name} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} /> */}
                                                    </div>
                                                </div>
                                                <div style={{paddingBottom:0}}>
                                                    <div>
                                                        <label>Product Code</label>
                                                    </div>
                                                    <div style={{fontSize:15}}>{item.product_code}</div>
                                                    {/* <input value={item.product_code} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} /> */}
                                                </div>
                                                <div style={{paddingBottom:0}}>
                                                    <div>
                                                        <label>Serial</label>
                                                    </div>
                                                    <div style={{fontSize:15}}>{item.serial_number}</div>
                                                    {/* <input value={item.serial_number} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} /> */}
                                                </div>
                                                <div style={{paddingBottom:0}}>
                                                    <div>
                                                        <label>Unit Price</label>
                                                    </div>
                                                    <div style={{fontSize:15}}>{item.stock_price}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <input onKeyPress={(e)=>scanserial(e)} placeholder='Scan Serial Number' style={{padding:5, width:"100%", height:40, borderRadius:5, border:"1px solid #ddd"}} />
                                            </div>
                                        </>
                                }

                                <>
                                    <div>
                                        <div style={{fontWeight:600, fontSize:18, width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                            Balance: {
                                                        afteractionData?.price_srp !== null ?
                                                            Number(afteractionData?.price_srp)-Number(item?.stock_price ?? 0)
                                                        :
                                                            Number(item?.stock_price ?? 0)
                                                    }
                                        </div>
                                    </div>
                                </>
                            </>
                        :
                        <>
                            {
                                item.product_id !== 0 ?
                                <>
                                    <div className='well' style={{padding:5}}>
                                        <div style={{paddingBottom:10}}>
                                            <div>
                                                <label>Product Name</label>
                                                <div style={{fontSize:15}}>{item.product_name}</div>
                                                {/* <input value={item.product_name} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} /> */}
                                            </div>
                                        </div>
                                        <div style={{paddingBottom:10}}>
                                            <div>
                                                <label>Product Code</label>
                                            </div>
                                            <div style={{fontSize:15}}>{item.product_code}</div>
                                            {/* <input value={item.product_code} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} /> */}
                                        </div>
                                        <div style={{paddingBottom:10}}>
                                            <div>
                                                <label>Serial</label>
                                            </div>
                                            <div style={{fontSize:15}}>{item.serial_number}</div>
                                            {/* <input value={item.serial_number} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} /> */}
                                        </div>
                                    </div>
                                    <div style={{paddingBottom:10}}>
                                        <div>
                                            <label>Unit Price</label>
                                        </div>
                                        <input value={item.stock_price} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5, outline:"none"}} />
                                    </div>
                                    {/* <div style={{paddingBottom:10}}>
                                        <div>
                                            <label>Warranty</label>
                                        </div>
                                        <select style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}}>
                                            <option>Select Warranty</option>
                                            {
                                                [
                                                    {'name':'No Warranty'}, 
                                                    {'name':'7 Days'}, 
                                                    {'name':'1 Month'}, 
                                                    {'name':'3 Month/s'}, 
                                                    {'name':'6 Month/s'}, 
                                                    {'name':'1 Year'}, 
                                                    {'name':'3 Years Warranty Service Center'}
                                                ].map((item, key)=>{
                                                    return (
                                                        <option key={key}>
                                                            {item.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div> */}
                                    <div style={{paddingBottom:10}}>
                                        <div>
                                            <label>Remarks</label>
                                        </div>
                                        <textarea onChange={(e)=>setRemarks(e.target.value)} value={remarks} style={{width:"100%", height:100, resize:"none", borderRadius:5, border:"1px solid #ddd", padding:5, outline:"none"}} />
                                    </div>
                                </>
                                :
                                <>
                                    <div style={{paddingBottom:10}}>
                                        <div style={{width:"100%", borderRadius:5, padding:5}}>
                                            <div style={{fontSize:20, fontWeight:600}}>{item.product_name}</div>
                                            <div style={{fontSize:18}}>{item.options[0].option}</div>
                                        </div>
                                    </div>
                                    {/* <div style={{paddingBottom:10}}>
                                        <div>
                                            <label>Note</label>
                                        </div>
                                        <textarea value={item.stock_price} style={{width:"100%", height:100, resize:"none", borderRadius:5, border:"1px solid #ddd", padding:5}} />
                                    </div> */}
                                    <div style={{paddingBottom:10}}>
                                        <div>
                                            <label>Unit Price</label>
                                        </div>
                                        <input onChange={(e)=>setUnitPrice(e.target.value)} value={unitprice} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} />
                                    </div>
                                    <div style={{paddingBottom:10}}>
                                        <div>
                                            <label>Remarks</label>
                                        </div>
                                        <textarea onChange={(e)=>setRemarks(e.target.value)} value={remarks} style={{width:"100%", height:100, resize:"none", borderRadius:5, border:"1px solid #ddd", padding:5, outline:"none"}} />
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </Dialog>
            <a onClick={() => setmodifyitemLoop(true)} style={{ color: "#3a6589", cursor: "pointer", textDecoration: "underline" }}>modified</a>
        </div>
    );
}

export default ModifyBtn;

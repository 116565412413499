import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import axios from 'axios';
import ENV from '../../../ENV';
import swal from 'sweetalert';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const RelativeBtn = (props) => {
    const [openDialog, setOpenDialog] = useState(false)
    const [inputvalue, setInputValue] = useState({})
    const menuLeft = useRef(null);
    const menuRight = useRef(null);
    const toast = useRef(null);


    const [visible, setVisible] = useState(false);

    // console.log(JSON.stringify(props.row))

    const hideDialog = () => {
        setOpenDialog(false)
    }


    const addRelative = async () => {
        console.log(inputvalue)


        const formData = new FormData();
        formData.append("add_relative", 1);
        formData.append("user_id", props.item.user_id);
        formData.append("relative", JSON.stringify(inputvalue));

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/employee.php',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            console.log(res.data)
            const idx = props.employeelist.findIndex(i => i.user_id == props.item.user_id);
            props.employeelist[idx].relatives = res.data;
            swal({
                title: "Done!",
                text: "Successfully Deleted",
                icon: "success",
                timer: 1000,
                button: false
            }).then(() => {
                inputvalue.firstname = '';
                inputvalue.middlename = '';
                inputvalue.lastname = '';
                inputvalue.contact = '';
                inputvalue.address = '';
                inputvalue.relation = '';
                setInputValue(inputvalue)
                props.setRelativesList(res.data)
                props.setEmployeeList(props.employeelist)
            });
        }).catch((err) => {

        });


    }

    const updateRelative = async () => {
        const idx = props.employeelist.findIndex(i => i.user_id == props.item.user_id);
        const ridx = props.relativelist.findIndex(i => i == props.row);

        props.relativelist[ridx].firstname = inputvalue?.firstname;
        props.relativelist[ridx].middlename = inputvalue?.middlename;
        props.relativelist[ridx].lastname = inputvalue?.lastname;
        props.relativelist[ridx].contact = inputvalue?.contact;
        props.relativelist[ridx].address = inputvalue?.address;
        props.relativelist[ridx].relation = inputvalue?.relation;

        props.employeelist[idx].relatives = props.relativelist;

        const formData = new FormData();
        formData.append("update_relative", 1);
        formData.append("user_id", props.item.user_id);
        formData.append("relative", JSON.stringify(props.relativelist));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/employee.php',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            console.log(res.data)
            swal({
                title: "Done!",
                text: "Successfully Updated",
                icon: "success",
                timer: 1000,
                button: false
            }).then(() => {
                props.setRelativesList(res.data)
                props.setEmployeeList(props.employeelist)
                return;
            });
        }).catch((err) => {

        });
    }


    const accept = async () => {
        const idx = props.employeelist.findIndex(i => i.user_id == props.item.user_id);
        const newData = props.relativelist.filter(i => i != props.row);
        props.employeelist[idx].relatives = newData;
        const formData = new FormData();
        formData.append("delete_relative", 1);
        formData.append("user_id", props.item.user_id);
        formData.append("relative", JSON.stringify(newData));

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/employee.php',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            swal({
                title: "Done!",
                text: "Successfully Deleted",
                icon: "success",
                timer: 1000,
                button: false
            }).then(() => {
                props.setRelativesList(res.data)
                props.setEmployeeList(props.employeelist)
            });
        }).catch((err) => {

        });
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }


    const items = [
        {
            label: 'Options',
            items: [
                {
                    label: 'Edit',
                    icon: 'pi pi-pencil',
                    command: () => {
                        setOpenDialog(true)
                        setInputValue(props.row)
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: () => {
                        setVisible(true) // delete
                    }
                }
            ]
        }
    ];

    // console.log(props.row != undefined)

    return (
        <>
            <ConfirmDialog
                style={{ fontSize: 15 }}
                group="declarative"
                visible={visible}
                onHide={() => setVisible(false)}
                message="Are you sure you want to proceed?"
                header="Confirmation"
                icon="pi pi-exclamation-triangle"
                accept={accept}
                reject={reject}
            />

            <Dialog
                position='top'
                draggable={false}
                style={{ width: '50rem', fontSize: 15 }}
                visible={openDialog}
                onHide={hideDialog}
                header={
                    <>
                        <label> {props.label ? "Add Relative" : "Edit Relative"} </label>
                    </>
                }
                footer={
                    <>
                        <button onClick={() => props.row != undefined ? updateRelative() : addRelative()} className='btn btn-success btn-xs'>{props.row != undefined ? "Update" : "Save New"}</button>
                    </>
                }
            >
                <div>
                    <div>
                        <label>First Name</label>
                        <input name='firstname' onChange={(e) => setInputValue({ ...inputvalue, [e.target.name]: e.target.value })} value={inputvalue.firstname} className='form-control' />
                    </div>
                    <div>
                        <label>Middle Name</label>
                        <input name='middlename' onChange={(e) => setInputValue({ ...inputvalue, [e.target.name]: e.target.value })} value={inputvalue.middlename} className='form-control' />
                    </div>
                    <div>
                        <label>Last Name</label>
                        <input name='lastname' onChange={(e) => setInputValue({ ...inputvalue, [e.target.name]: e.target.value })} value={inputvalue.lastname} className='form-control' />
                    </div>
                    <div>
                        <label>Contact No.</label>
                        <input name='contact' onChange={(e) => setInputValue({ ...inputvalue, [e.target.name]: e.target.value })} value={inputvalue.contact} className='form-control' />
                    </div>
                    <div>
                        <label>Address</label>
                        <textarea name='address' onChange={(e) => setInputValue({ ...inputvalue, [e.target.name]: e.target.value })} value={inputvalue.address} className='form-control'></textarea>
                    </div>
                    <div>
                        <label>Relation</label>
                        <input name='relation' onChange={(e) => setInputValue({ ...inputvalue, [e.target.name]: e.target.value })} value={inputvalue.relation} className='form-control' />
                    </div>
                </div>
            </Dialog>

            <div className="card flex justify-content-center">
                <Toast ref={toast}></Toast>
                <Menu
                    style={{ fontSize: 15 }}
                    model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                <Button label={props.label ? props.label : ''} icon="pi pi-align-right" className="btn btn-xs" onClick={(event) => props.label ? setOpenDialog(true) : menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
            </div>
        </>
    );
}

export default RelativeBtn;

import React, { useEffect, useState, useMemo, useRef } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import Header from './component/Header';
import Navbar from './component/Navbar';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from './ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from "primereact/chips";
import ViewStockBtn from './component/product/ViewStockBtn';
import EditProductBtn from './component/product/EditProductBtn';
import DeleteProductBtn from './component/product/DeleteProductBtn';
import AddProductBtn from './component/product/AddProductBtn';
import ShowDetails from './component/product/showDetails';
import Table from './component/product/Table';
import StockinInfo from './component/stock/StockinInfo';
import { InputText } from "primereact/inputtext";
import AddPayment from './component/stock/AddPayment';
import ViewDetails from './component/invoices/ViewDetails';
import { Calendar } from 'primereact/calendar';
// import { FloatLabel } from "primereact/floatlabel";


const Invoices = () => {
    const toast = useRef(null);
    document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix" data-active="dashboard ';
    document.body.id = 'dashboard';
    const [invoiceList, setInvoicesList] = useState([]);
    const [search, setSearch] = useState('')
    const [date, setDate] = useState(null);


    useMemo(async () => {
        var formData = new FormData();
        formData.append("invoices", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/invoices.php',
            data: formData,
        }).then((res) => {
            console.log(res)
            setInvoicesList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    const newstocks = invoiceList.filter((item)=> {        
        if (search.length >= 3) {
            return String(item.supplier_name).toLowerCase().includes(String(search).toLowerCase()) || String(item.supplier_invoice).toLowerCase().includes(String(search).toLowerCase())
        }
        return item
    }).slice(0,20)

    return (

        <>
            <Toast ref={toast} />
            <div className="vd_body">
                {/* Header Start */}
                <Header />
                {/* Header Ends */}
                <div className="content">
                    <div className="container">
                        <Navbar />

                        {/* Middle Content Start */}
                        <div className="vd_content-wrapper" style={{ minHeight: 1059 }}>
                            <div className="vd_container" style={{ minHeight: 1059 }}>
                                <div className="vd_content clearfix">
                                    <div className="vd_content-section clearfix" style={{ paddingTop: 0 }}>
                                        <div className="row" style={{ padding: 10 }}>

                                            <div className='col-sm-12'>
                                                <div className="panel widget light-widget panel-bd-top">
                                                    <div className='panel-body' style={{ padding: 10 }}>
                                                        <div className='panel-body' style={{ padding: 10 }}>
                                                            {/* {invoiceList.length} */}
                                                            <div style={{paddingBottom: 5, paddingTop: 5}}>
                                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10}}>
                                                                    
                                                                    {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                                                                        <span>Date:</span>
                                                                        <Calendar value={date} onChange={(e) => setDate(e.value)} dateFormat="yy-mm-dd" />
                                                                    </div> */}
                                                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                                                                        <span>Search:</span>
                                                                        <span>
                                                                            <input onChange={(e)=>setSearch(e.target.value)} className='form-control' />
                                                                            </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <table className='table table-bordered table-hover'>
                                                                <thead>
                                                                    <tr>
                                                                        <td>Invoice No.</td>
                                                                        <td>Stock</td>
                                                                        <td>Supplier</td>
                                                                        <td>Pruchase</td>
                                                                        <td>Encoded</td>
                                                                        <td></td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        newstocks.map((item)=>{
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item.supplier_invoice}</td>
                                                                                    <td>{item.qty}</td>
                                                                                    <td>{item.supplier_name}</td>
                                                                                    <td>{item.date_purchace}</td>
                                                                                    <td>{item.date_encoded}</td>
                                                                                    <td>
                                                                                        <ViewDetails item={item} />
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        // .slice(0,20)
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    {/* .vd_content-section */}
                                </div>
                                {/* .vd_content */}
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
            </div>



        </>

    );
}

export default Invoices;

import React from 'react';

const Header = () => {

    const Logout = () => {
        localStorage.removeItem('user_id')
        window.location.assign('/')
    }

    return (
        <>
        
            <header className="header-1" id="header">
                <div className="vd_top-menu-wrapper">
                    <div className="container vd_bg-blue">
                        <div className="vd_top-nav vd_nav-width  ">
                            <div className="vd_panel-header">
                                <div className="logo">
                                    {/* <a href="../index.php"><img alt="logo" src="logo-stgc.png" style={{ position: 'absolute', left: '35%', top: 5, width: 55, borderRadius: 50 }} /></a> */}
                                </div>
                                {/* logo */}
                                {/* <div className="vd_panel-menu  hidden-sm hidden-xs" data-intro="<strong>Minimize Left Navigation</strong><br/>Toggle navigation size to medium or small size. You can set both button or one button only. See full option at documentation." data-step={1}>
                                    <span className="nav-small-button menu" data-toggle="tooltip" data-placement="bottom" data-original-title="F1" data-action="nav-left-small">
                                        <i className="fa fa-ellipsis-v" />
                                    </span>
                                    <span className="nav-medium-button menu" data-toggle="tooltip" data-placement="bottom" data-original-title="F2" data-action="nav-left-medium">
                                        <i className="fa fa-bars" />
                                    </span>
                                </div> */}
                                {/* <div className="vd_panel-menu left-pos visible-sm visible-xs">
                                    <span className="menu" data-action="toggle-navbar-left" style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg>
                                    </span>
                                </div> */}
                                <div className="vd_panel-menu visible-sm visible-xs">
                                    <div className=' visible-xs'>
                                        <span className="menu" data-action="submenu" style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                {/* vd_panel-menu */}
                            </div>
                            {/* vd_panel-header */}
                        </div>
                        <div className="vd_container">
                            <div className="row">
                                {/*        <div class="col-sm-5 col-xs-12">
                  <div class="vd_mega-menu pull-right">
                      <ul class="mega-ul">
                          <li class="one-icon mega-li">sdfsdf</li>
                      </ul>
                  </div>
              </div> */}
                                <div className="col-sm-10">
                                    {/* <div className="vd_white text-center" style={{ height: 55, verticalAlign: 'middle' }}>
                                        <strong style={{ textTransform: 'uppercase', fontSize: 30, lineHeight: 60 }}><marquee>prices and availability changes every minute</marquee></strong>
                                    </div> */}
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <div className="vd_mega-menu-wrapper">
                                        <div className="vd_mega-menu pull-right">
                                            <ul className="mega-ul">
                                                <li id="top-menu-profile" className="profile mega-li">
                                                    <a href="#" className="mega-link" data-action="click-trigger">
                                                        <span className="mega-image">
                                                            {/* <img className="profile_imgzm_1" src="user-image/yXrzD7.jpg" username="Administrator" style={{ width: 36, height: 36 }} /> */}
                                                        </span>
                                                        <span className="mega-name">
                                                            {localStorage.getItem('firstname')} <i className="fa fa-caret-down fa-fw" />
                                                        </span>
                                                    </a>
                                                    <div className="vd_mega-menu-content  width-xs-2  left-xs left-sm" data-action="click-target" style={{ display: 'none' }}>
                                                        <div className="child-menu">
                                                            <div className="content-list content-menu">
                                                                <ul className="list-wrapper pd-lr-10">
                                                                    {/* <li>
                                                                        <a href="profile.php">
                                                                            <div className="menu-icon"><i className=" fa fa-user" /></div>
                                                                            <div className="menu-text">View Profile</div>
                                                                        </a>
                                                                    </li>
                                                                    <li className="line" /> */}
                                                                    {/* href="logout.php"                */}
                                                                    <li>
                                                                        <a id="logout" style={{ cursor: 'pointer' }}>
                                                                            <div className="menu-icon"><i className=" fa fa-sign-out" /></div>
                                                                            <div onClick={()=>Logout()} className="menu-text">Sign Out</div>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            {/* Head menu search form ends */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* container */}
                </div>
                {/* vd_primary-menu-wrapper */}
            </header>

        
        </>

    );
}

export default Header;

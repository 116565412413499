import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

const StockinInfo = (props) => {
    const [infoDialog, setInfoDialog] = useState(false)

    const hideDialog = () => {
        setInfoDialog(false)
    }
    return (
        <>

            <Dialog footer={
                <>
                    <button className='btn btn-danger btn-xs' onClick={()=>hideDialog()}>Close</button>
                </>
            } visible={infoDialog} position={"top"} resizable={false} draggable={false} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '40vw' }} header={props?.item[0].product_name} modal className="p-fluid" onHide={hideDialog}>

                <div style={{fontSize: 15}}>
                    <div>U.P.C.: <span style={{textDecoration: "underline", fontWeight: 600}}>{props.item[0].product_code}</span></div>
                    <div>Serial: <span style={{textDecoration: "underline", fontWeight: 600}}>{props.item[0].serial}</span></div>
                    <div>Supplier Name: <span style={{textDecoration: "underline", fontWeight: 600}}>{props.item[0].supplier_name}</span></div>
                    <div>Invoice: <span style={{textDecoration: "underline", fontWeight: 600}}>{props.item[0].supplier_invoice}</span></div>
                    <div>Purchase: <span style={{textDecoration: "underline", fontWeight: 600}}>{props.item[0].date_purchace}</span></div>
                    <div>Date Encoded: <span style={{textDecoration: "underline", fontWeight: 600}}>{props.item[0].date_encoded}</span></div>
                </div>
                {/* {
                    JSON.stringify(props.item)
                } */}

            </Dialog>
            <span onClick={() => setInfoDialog(true)} className='pi pi-info-circle' style={{ fontSize: 20, color: "red" }}></span>
        </>
    )
}

export default StockinInfo;

import { OverlayPanel } from 'primereact/overlaypanel';
// import { Sidebar } from 'primereact/sidebar';
import React, { useMemo, useRef, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';

const CustomerList = (props) => {
    // const [visible, setVisible] = useState(false);
    // const [selectedPayment, setSelectedPayment] = useState(false);
    // const [selectedOnlinePayment, setSelectedOnlinePayment] = useState(false);

    const [customerlist, setCustomerList] = useState([]);
    const [inputvalue, setInputValue] = useState({"search": "", "id_customer": 0});


    const FetchCustomer = async () => {
        var formData = new FormData();
        formData.append("customer", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/sales.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setCustomerList([...res.data])
        });
    }


    const op = useRef(null);

    const OpenOverlay = (e) => {
        op.current.show(e)
        FetchCustomer()
        // setItem(props?._item)
    }
    const CloseOverlay = (e) => {
        op.current.hide(e)
        setCustomerList([])
        // setItem(props?._item)
    }

    const SelectBtn = async (item) => {
        props.setInputValue(prev=>({...prev, ...item}))
        CloseOverlay()
        setCustomerList([])
    }

    // console.log(inputvalue)

    return (
        <>

            {/* <Sidebar blockScroll={true} dismissable={false} visible={visible} style={{ width: "30%" }} position="right" onHide={() => setVisible(false)}>
                <div>
                    <div>
                        <label>Courier</label>
                    </div>
                    <div>
                        <label>Fee</label>
                    </div>
                    <div>
                        <label>Shipping Address</label>
                    </div>
                </div>
            </Sidebar> */}
            
            <OverlayPanel ref={op} style={{width: 600, border: "2px solid #000"}} showCloseIcon={false} closeOnEscape={false} dismissable={false}>
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    <div>
                        <span style={{fontSize: 20, fontWeight: "bolder"}}>Customer List</span>
                        <input type='text' name='search' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} className='form-control' />
                    </div>
                    <div>
                        {
                            [...customerlist].filter((i)=> {
                                if (inputvalue.search !== '') {
                                    return String(i.customer_name).toLowerCase().includes(String(inputvalue.search).toLowerCase())
                                }
                                return i;
                            }).map((item, key)=> {
                                return (
                                    <div key={key} style={{border: "1px solid #ddd", padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <div style={{fontSize: 18}}>{item?.customer_name}</div>
                                        <div>
                                            <button onClick={(e)=>SelectBtn(item)} style={{borderRadius: 100, width: 80, fontSize: 15, fontWeight: "bold"}}>Select</button>
                                        </div>
                                    </div>
                                    
                                )
                            }).slice(0,10)
                        }
                        <div>
                            <span style={{fontSize: 18, fontWeight: "bolder"}}>Total Records: {[...customerlist].length}</span>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                        <div style={{display: "flex", flexDirection: "row", gap: 5}}>
                            <button onClick={() => CloseOverlay()} className='btn btn-danger'>
                                Close
                            </button>
                            {/* <button onClick={() => SaveNewBtn()} className='btn btn-info'>
                                Save New
                            </button> */}
                        </div>
                    </div>
                </div>
            </OverlayPanel>
            {/* onClick={() => setVisible(true)} */}
            <button onClick={OpenOverlay} style={{ width: 45, height: 45, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center" }}><i className='pi pi-list' style={{ fontSize: 18 }} /></button>
            {/* <button onClick={OpenOverlay} style={{width: 45, height: 45, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <i className='pi pi-plus' style={{fontSize: 18}} />
            </button> */}
            {/* <label onClick={() => setVisible(true)} style={{ fontSize: 20, width: 200, textAlign: "center", height: 40, color: "#000", padding: 5, borderRadius: 5, border: "2px solid #ddd", backgroundColor: "#fff" }}>
                Add Payment
            </label> */}
        </>
    );
}

export default CustomerList;

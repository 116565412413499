import React, { useEffect, useState, useMemo, useRef } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import Header from './component/Header';
import Navbar from './component/Navbar';
import { format } from 'date-fns';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from './ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from "primereact/chips";
import { Tooltip } from 'primereact/tooltip';
import { ProgressBar } from 'primereact/progressbar';
import { SplitButton } from 'primereact/splitbutton';
import { SpeedDial } from 'primereact/speeddial';

// import { QrReader } from 'react-qr-reader';
import QrReader from 'modern-react-qr-reader'
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';


const InventoryReview = () => {
    const history = useHistory()
    const { user_id, cat_id, product_id, year } = useParams();

    document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix" data-active="dashboard ';
    document.body.id = 'dashboard';
    document.title = 'Inventory';

    // console.log(JSON.parse(localStorage.getItem('users_role')).dashboard_login)



    const [InventoryList, setInventoryList] = useState([])
    const [DataOnDay, setDataOnDay] = useState([])
    const [UserData, setUserData] = useState({})
    const [ProductData, setProductData] = useState({})
    const [CategoryUserData, setCategoryUserData] = useState([])
    const [openrowdata, setOpenRowData] = useState({})
    const [openpanel, setOpenPanel] = useState({data: [], status: false, item: {} })
    const [handleScanEnable, sethandleScanEnable] = useState(false)
    const [search, setSearch] = useState('')
    const [panelTab, setPanelTab] = useState('pending')

    const [UseID, setUseID] = useState(user_id)
    const [useYear, setUseYear] = useState(year)
    
    
    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth)
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth)
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, [])

        return (width <= 768)
    }

    // console.log(useCheckMobileScreen())

    useMemo(async () => {
        var formData = new FormData()
        formData.append("Category_userName", 1)
        formData.append("cat_id", cat_id)
        formData.append("product_id", product_id)
        formData.append("year", useYear)
        await axios({
            method: "post",
            // url: 'http://localhost/stgc.local/api/sales_report.php',
            url: ENV.DOMAIN + 'inventory.php',
            data: formData,
        }).then((res) => {
            setCategoryUserData(res.data)
            // console.log((res.data))
        })
            .catch((err) => {
                console.log(err)
            })
    }, [ENV, cat_id, product_id, useYear])

    useMemo(async () => {
        var formData = new FormData()
        formData.append("userName", 1)
        formData.append("user_id", UseID)
        await axios({
            method: "post",
            // url: 'http://localhost/stgc.local/api/sales_report.php',
            url: ENV.DOMAIN + 'inventory.php',
            data: formData,
        }).then((res) => {
            setUserData(res.data[0])
            // console.log((res.data))
        })
            .catch((err) => {
                console.log(err)
            })
    }, [ENV, UseID])

    useMemo(async () => {
        var formData = new FormData()
        formData.append("productName", 1)
        formData.append("product_id", product_id)
        await axios({
            method: "post",
            // url: 'http://localhost/stgc.local/api/sales_report.php',
            url: ENV.DOMAIN + 'inventory.php',
            data: formData,
        }).then((res) => {
            setProductData(res.data[0])
            // console.log((res.data))
        })
            .catch((err) => {
                console.log(err)
            })
    }, [ENV, product_id])

    useMemo(async () => {
        var formData = new FormData()
        formData.append("getInvRecord", 1)
        formData.append("user_id", UseID??0)
        formData.append("product_id", product_id)
        await axios({
            method: "post",
            // url: 'http://localhost/stgc.local/api/sales_report.php',
            url: ENV.DOMAIN + 'inventory.php',
            data: formData,
        }).then((res) => {
            setDataOnDay(res.data)
            // console.log((res.data))
        })
            .catch((err) => {
                console.log(err)
            })
    }, [ENV, UseID, product_id])

    // console.log(DataOnDay)

    const syncData = async () => {
        var formData = new FormData()
        formData.append("inventorylist", 1)
        formData.append("user_id", localStorage.getItem('user_id'))
        await axios({
            method: "post",
            // url: 'http://localhost/stgc.local/api/sales_report.php',
            url: ENV.DOMAIN + 'inventory.php',
            data: formData,
        }).then((res) => {
            setInventoryList(res.data)
            // console.log((res.data))
        })
            .catch((err) => {
                console.log(err)
            })
    }


    const d = new Date().toLocaleString({timeZone: 'Asia/Manila'})

    var FullYear = () => {
        return  new Date().getFullYear();
    }

    var lastday = function(y,m){
        return  new Date(y, m, 0).getDate();
    }

    var firstday = function(y,m){
        return  new Date(y, m, 0).getDate();
    }

    // console.log(firstday(2024,1), 'sadasd')

    const numberdays = (n_days) => {
        let day = [];
        let num = 1;
        for (let index = 1; index <= n_days; index++) {
            day[index] = (
                <>
                    {
                        window.innerWidth == 600 ?
                        <div key={index} style={{ height: 16, fontSize:8, width: 16, backgroundColor: "#23709e", color:"#fff", textAlign: "center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center" }}>{num++}</div>
                        :
                        <div key={index} style={{ height: 35, width: 35, backgroundColor: "#23709e", color:"#fff", textAlign: "center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center" }}>{num++}</div>
                    }
                </>
            );
        }
        return day
    }

    // ).padStart(10, '0')
    
// console.log(format(new Date(), 'yyyy/MM/dd kk:mm:ss'))
// console.log(format(new Date(), 'yyyy'))
// console.log(format(new Date(), 'yyyy'))
// console.log(format(new Date(), 'MMMM'))
// console.log(format(new Date(), 'dd'))
    
    const displayPaddata = (months, index, i) => {
        const dateString = useYear+'-'+String(months).padStart(2,0)+'-'+String(index).padStart(2,0)
        const data = DataOnDay?.findIndex(x => x?.date == String(dateString));
        if (DataOnDay[data]?.date === String(dateString)) {
            return DataOnDay[data]
        }
    }
    

    const items = [
        {
            label: 'Add',
            icon: 'pi pi-pencil',
            command: () => {
                // toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
            }
        },
        {
            label: 'Update',
            icon: 'pi pi-refresh',
            command: () => {
                // toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
            }
        },
        {
            label: 'Delete',
            icon: 'pi pi-trash',
            command: () => {
                // toast.current.show({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
            }
        },
        {
            label: 'React Website',
            icon: 'pi pi-external-link',
            command: () => {
                window.location.href = 'https://facebook.github.io/react/';
            }
        }
    ];


    const [productDialog, setProductDialog] = useState([]);
    const [InvData, setInvData] = useState([]);

    const ProductDialog = (data) => {
        setProductDialog(data)
    }

    useMemo(async () => {
        // console.log(productDialog, 123)
        var formData = new FormData()
        formData.append("getInvData", 1)
        formData.append("product_id", productDialog.product_id)
        formData.append("date", productDialog.date)
        await axios({
            method: "post",
            // url: 'http://localhost/stgc.local/api/sales_report.php',
            url: ENV.DOMAIN + 'inventory.php',
            // url: 'http://localhost/stgc/inventory.php',
            data: formData,
        }).then((res) => {
            setInvData(res.data)
            // console.log((res.data))
        })
            .catch((err) => {
                console.log(err)
            })
    }, [ENV, productDialog])

    const hideDialog = () => {
        setProductDialog([]);
    };

    const [ClickDate, setClickDate] = useState()
    // console.log(ClickDate)

    const paneldays = (n_days, months) => {
        let day = [];
        let i = 0;
        // let dateString = '';
        // let current = '';
        // console.log(new Date().getMonth(), new Date('d',0).getDate())
        // const _data = InvData.filter((i) => i.count!==i.stock);
        // console.log(_data.length, n_days);
        for (let index = 1; index <= n_days; index++) {
            const current = format(new Date(), 'yyyy')+'-'+format(new Date(), 'MM')+'-'+format(new Date(), 'dd')
            const dateString = useYear+'-'+String(months).padStart(2,0)+'-'+String(index).padStart(2,0)
            const data = displayPaddata(String(months).padStart(2,0), index, i);
            if(String(data?.date) == String(dateString)) {
                day[index] = (
                    <>                                            
                        <Dialog visible={productDialog.id == data.id ? true : false} style={{ width: '350px' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="" modal className="p-fluid" onHide={hideDialog}>
                            {
                                InvData.length>0?
                                <>
                                    {
                                        InvData.map((item, key)=>{
                                            return (
                                                <>
                                                    <div style={{width:"100%", height:30, borderBottom:"1px solid #23709e", fontSize:25, fontWeight:"600", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                                        <div style={{fontSize:15}}>{item.firstname}</div>
                                                        <div style={{fontSize:15}}>{format(new Date(dateString), 'MMMM dd, yyyy')}</div>
                                                    </div>
                                                    {
                                                        <>
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                <div style={{textAlign:"center", width:"60%", fontSize:18, height:30, backgroundColor:"#23709e", color:"#fff", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>Time</div>
                                                                <div style={{textAlign:"center", width:"20%", fontSize:18, height:30, backgroundColor:"#23709e", color:"#fff", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>Stock</div>
                                                                <div style={{textAlign:"center", width:"20%", fontSize:18, height:30, backgroundColor:"#23709e", color:"#fff", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>Count</div>
                                                            </div>
                                                            <div style={{display:"flex", flexDirection:"column", gap:10}}>
                                                                {
                                                                    item.list.map((_item, _key) => {
                                                                        return (
                                                                            <>
                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                                    <div style={{textAlign:"center", width:"60%", fontSize:18}}>{format(new Date(_item.date+' '+_item.time), 'hh:ii:ss a')}</div>
                                                                                    <div style={{textAlign:"center", width:"20%", fontSize:18}}>{_item.stock}</div>
                                                                                    <div style={{textAlign:"center", width:"20%", fontSize:18}}>{_item.count}</div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </>
                                :
                                <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                            }
                        </Dialog>
                        {
                            
                            window.innerWidth == 600 ?
                            <div key={index} onClick={() => { ProductDialog(data) }} style={{ cursor:"pointer", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", color: "#fff", borderRadius: 100, height: 16, width: 16, backgroundColor: data?.stock==data?.count ? "#07af0c" : "red", textAlign: "center", position:"relative", fontSize:8 }}>
                                {
                                    data?.count
                                }
                            </div>
                            :
                            <div key={index} onClick={() => { ProductDialog(data) }} style={{ cursor:"pointer", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", color: "#fff", borderRadius: 100, height: 35, width: 35, backgroundColor: data?.stock==data?.count ? "#07af0c" : "red", textAlign: "center", position:"relative" }}>
                                {
                                    data?.count
                                }
                            </div>
                        }
                    </>
                )
            } 
            else 
            {
                if(current >= dateString){
                    if(current == dateString) {
                        window.innerWidth == 600 ?
                        day[index] = (
                            // onClick={()=>console.log(current)}
                            <div key={index} style={{ display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", color:"#fff", borderRadius:0, height: 16, width: 16, backgroundColor: "rgb(255 152 30)", textAlign: "center" }}>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="#000" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                </svg> */}
                            </div>
                        )
                        :
                        day[index] = (
                            // onClick={()=>console.log(current)}
                            <div key={index} style={{ display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", color:"#fff", borderRadius:0, height: 35, width: 35, backgroundColor: "rgb(255 152 30)", textAlign: "center" }}>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="#000" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                </svg> */}
                            </div>
                        )
                    } else {
                        window.innerWidth == 600 ?
                        day[index] = (
                            <div key={index} style={{ display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", color:"#fff", borderRadius:0, height: 16, width: 16, backgroundColor: "rgb(243 191 191)", textAlign: "center" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="red" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                </svg>
                            </div>
                        )
                        :
                        day[index] = (
                            <div key={index} style={{ display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", color:"#fff", borderRadius:0, height: 35, width: 35, backgroundColor: "rgb(243 191 191)", textAlign: "center" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="red" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                </svg>
                            </div>
                        )
                    }
                } else {
                    window.innerWidth == 600 ?
                    day[index] = (
                        <div key={index} style={{ display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", color:"#fff", borderRadius:0, height: 16, width: 16, backgroundColor: "#ddd", textAlign: "center" }}>
                            {/* Coming */}
                        </div>
                    )
                    :
                    day[index] = (
                        <div key={index} style={{ display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", color:"#fff", borderRadius:0, height: 35, width: 35, backgroundColor: "#ddd", textAlign: "center" }}>
                            {/* Coming */}
                        </div>
                    )
                }
            }

            i++
        }
        return day;
    }

    const YearLoop = () => {
        const fullYear = new Date().getFullYear();
        const yearText = [];
        for (let index = 2021; index <= fullYear; index++) {
            if(index == useYear) {
                yearText[index] = (
                    <div style={{minWidth:100, fontSize:18, maxWidth:200, height:30, borderRadius:5, border:"1px solid #ddd", backgroundColor:useYear == index ? "rgb(125 232 167)" : "#dddddd", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>{index}</div>
                )
            } else {
                
            }
        }
        return yearText;
    }
    
    return (

        <>
            <div className="vd_body">
                {/* Header Start */}
                <Header />
                {/* Header Ends */}
                <div className={!useCheckMobileScreen() ? "content" : ""}>
                    <div className="container">
                        {/* {
                            !useCheckMobileScreen() ?
                                <Navbar />
                                :
                                null
                        } */}
                        {/* Middle Content Start */}
                        {/* className={!useCheckMobileScreen() ? "vd_content-wrapper" : ""} style={{ minHeight: 1059 }} */}
                        {/* className={!useCheckMobileScreen() ? "vd_container" : ""} style={{ minHeight: 1059 }} */}
                        {/* className={!useCheckMobileScreen() ? "vd_content clearfix" : ""} */}
                        {/* className={!useCheckMobileScreen() ? "vd_content-section clearfix" : ""} style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}} */}
                        <div>
                            <div>
                                <div>


                                    <div>
                                        <div className={!useCheckMobileScreen() ? '' : "row"} style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                            
                                            <div>
                                                {/* <div style={{fontSize:30}}>Year: {year}</div> */}
                                                {/* <div style={{fontSize:20}}>Name: <span style={{fontSize:25, fontWeight:"600x"}}>{UserData.firstname}</span></div> */}
                                                {
                                                    
                                                    window.innerWidth == 600 ?
                                                    <div style={{ fontSize: 12 }}>Product Name: <span style={{ fontSize: 12, fontWeight: "600x" }}>{ProductData.product_name}</span></div>
                                                    :
                                                    <div style={{ fontSize: 20 }}>Product Name: <span style={{ fontSize: 25, fontWeight: "600x" }}>{ProductData.product_name}</span></div>
                                                }
                                            </div>
                                            <div style={{gap:1, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                <>
                                                    {
                                                        window.innerWidth == 600 ?
                                                        <div style={{height:16, fontSize:8, width:60, backgroundColor:"#23709e", color:"#fff", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>Month</div>
                                                        :
                                                        <div style={{height:35, width:100, backgroundColor:"#23709e", color:"#fff", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>Month</div>
                                                    }
                                                </>
                                                {
                                                    numberdays(31)
                                                }
                                            </div>
                                            <div style={{gap:1, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                {
                                                    window.innerWidth == 600 ?
                                                    <div style={{height:16, width:60, backgroundColor:format(new Date(), 'MMMM') == "January" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", fontSize:8}}>January</div>
                                                    :
                                                    <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "January" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>January</div>
                                                }
                                                {
                                                    paneldays(lastday(2024,1),1)
                                                }
                                            </div>
                                            <div style={{gap:1, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                {
                                                    window.innerWidth == 600 ?
                                                    <div style={{height:16, width:60, backgroundColor:format(new Date(), 'MMMM') == "February" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", fontSize:8}}>February</div>
                                                    :
                                                    <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "February" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>February</div>
                                                }
                                                {/* <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "February" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>February</div> */}
                                                {
                                                    paneldays(lastday(2024,2),2)
                                                }
                                            </div>
                                            <div style={{gap:1, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                {
                                                    window.innerWidth == 600 ?
                                                    <div style={{height:16, width:60, backgroundColor:format(new Date(), 'MMMM') == "March" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", fontSize:8}}>March</div>
                                                    :
                                                    <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "March" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>March</div>
                                                }
                                                {/* <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "March" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>March</div> */}
                                                {
                                                    paneldays(lastday(2024,3),3)
                                                }
                                            </div>
                                            <div style={{gap:1, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                {
                                                    window.innerWidth == 600 ?
                                                    <div style={{height:16, width:60, backgroundColor:format(new Date(), 'MMMM') == "April" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", fontSize:8}}>April</div>
                                                    :
                                                    <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "April" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>April</div>
                                                }
                                                {/* <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "April" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>April</div> */}
                                                {
                                                    paneldays(lastday(2024,4),4)
                                                }
                                            </div>
                                            <div style={{gap:1, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                {
                                                    window.innerWidth == 600 ?
                                                    <div style={{height:16, width:60, backgroundColor:format(new Date(), 'MMMM') == "May" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", fontSize:8}}>May</div>
                                                    :
                                                    <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "May" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>May</div>
                                                }
                                                {/* <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "May" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>May</div> */}
                                                {
                                                    paneldays(lastday(2024,5),5)
                                                }
                                            </div>
                                            <div style={{gap:1, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                {
                                                    window.innerWidth == 600 ?
                                                    <div style={{height:16, width:60, backgroundColor:format(new Date(), 'MMMM') == "June" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", fontSize:8}}>June</div>
                                                    :
                                                    <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "June" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>June</div>
                                                }
                                                {/* <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "June" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>June</div> */}
                                                {
                                                    paneldays(lastday(2024,6),6)
                                                }
                                            </div>
                                            <div style={{gap:1, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                {
                                                    window.innerWidth == 600 ?
                                                    <div style={{height:16, width:60, backgroundColor:format(new Date(), 'MMMM') == "July" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", fontSize:8}}>July</div>
                                                    :
                                                    <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "July" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>July</div>
                                                }
                                                {/* <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "July" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>July</div> */}
                                                {
                                                    paneldays(lastday(2024,7),7)
                                                }
                                            </div>
                                            <div style={{gap:1, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                {
                                                    window.innerWidth == 600 ?
                                                    <div style={{height:16, width:60, backgroundColor:format(new Date(), 'MMMM') == "August" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", fontSize:8}}>August</div>
                                                    :
                                                    <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "August" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>August</div>
                                                }
                                                {/* <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "August" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>August</div> */}
                                                {
                                                    paneldays(lastday(2024,8),8)
                                                }
                                            </div>
                                            <div style={{gap:1, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                {
                                                    window.innerWidth == 600 ?
                                                    <div style={{height:16, width:60, backgroundColor:format(new Date(), 'MMMM') == "September" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", fontSize:8}}>September</div>
                                                    :
                                                    <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "September" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>September</div>
                                                }
                                                {/* <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "September" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>September</div> */}
                                                {
                                                    paneldays(lastday(2024,9),9)
                                                }
                                            </div>
                                            <div style={{gap:1, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                {
                                                    window.innerWidth == 600 ?
                                                    <div style={{height:16, width:60, backgroundColor:format(new Date(), 'MMMM') == "October" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", fontSize:8}}>October</div>
                                                    :
                                                    <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "October" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>October</div>
                                                }
                                                {/* <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "October" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>October</div> */}
                                                {
                                                    paneldays(lastday(2024,10),10)
                                                }
                                            </div>
                                            <div style={{gap:1, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                {
                                                    window.innerWidth == 600 ?
                                                    <div style={{height:16, width:60, backgroundColor:format(new Date(), 'MMMM') == "November" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", fontSize:8}}>November</div>
                                                    :
                                                    <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "November" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>November</div>
                                                }
                                                {/* <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "November" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>November</div> */}
                                                {
                                                    paneldays(lastday(2024,11),11)
                                                }
                                            </div>
                                            <div style={{gap:1, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                {
                                                    window.innerWidth == 600 ?
                                                    <div style={{height:16, width:60, backgroundColor:format(new Date(), 'MMMM') == "December" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", fontSize:8}}>December</div>
                                                    :
                                                    <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "December" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>December</div>
                                                }
                                                {/* <div style={{height:35, width:100, backgroundColor:format(new Date(), 'MMMM') == "December" ? "#ff981e" : "#ddd", textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>December</div> */}
                                                {
                                                    paneldays(lastday(2024,12),12)
                                                }
                                            </div>

                                            <div style={{display:"flex"}}>
                                                <select style={{width:200}} onChange={(e)=>setUseID(e.target.value)}>
                                                    <option value={0}>All</option>
                                                    {
                                                        CategoryUserData.map((item, key)=>{
                                                            return (<option selected={item.user_id == UseID? "selected": null} value={item.user_id}>{item.firstname}</option>)
                                                        })
                                                    }
                                                    
                                                </select>
                                                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", width:100}}>
                                                    
                                                    {
                                                        useYear != 2021 ?
                                                        <div onClick={()=>setUseYear(Number(useYear)-1)} style={{cursor:"pointer", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor" className="bi bi-caret-left" viewBox="0 0 16 16">
                                                                <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                                                            </svg>
                                                        </div>
                                                        :
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="#ddd" className="bi bi-caret-left" viewBox="0 0 16 16">
                                                                <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                                                            </svg>
                                                        </div>
                                                    }
                                                    <div style={{display:"flex", flexDirection:"column", gap:5}}>
                                                        {YearLoop()}
                                                    </div>
                                                    {
                                                        useYear != new Date().getFullYear() ?
                                                        <div onClick={()=>setUseYear(Number(useYear)+1)} style={{cursor:"pointer", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor" className="bi bi-caret-right" viewBox="0 0 16 16">
                                                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                                                            </svg>
                                                        </div>
                                                        :
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="#ddd" className="bi bi-caret-right" viewBox="0 0 16 16">
                                                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                                                            </svg>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            
                                        </div>
                                        {
                                            window.innerWidth == 600 ?
                                            null
                                            :
                                            <div style={{border:"0px solid #ddd", display:"flex", flexDirection:"column", justifyContent:"flex-start", flexShrink:1, width:'100%'}}>
                                                <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", backgroundColor:null, padding:0, textAlign:"center"}}>
                                                    <span style={{fontSize:20, fontWeight:600}}>Summary</span>
                                                </div>
                                                <div style={{ display: window.innerWidth == 600 ? "none" : "block", padding:5, display:"flex", flexDirection:"column", gap:2, overflowY:"auto", height:Number(window.innerHeight-100)}} className='hidescroll'>
                                                    {
                                                        DataOnDay.length>0?
                                                        <>
                                                        {
                                                            DataOnDay.reverse().map((item)=>{
                                                                return (
                                                                    <div style={{width:"100%", minHeight:50, padding:5, border:"1px solid #ddd", backgroundColor:"#dfdede", borderRadius:10}}>
                                                                        
                                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                                                            <div style={{fontWeight:600}}>{item.firstname}</div>
                                                                            <div>{item.date}</div>
                                                                        </div>
                                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                                                            <div style={{fontWeight:600}}>Count: {item.count}</div>
                                                                            <div style={{fontWeight:600}}>Prev Stock: {item.stock}</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        </>
                                                        :
                                                        <div style={{textAlign:"center"}}>Loading...</div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
                {/* .content */}
                {/* Footer Start */}
                <footer className="footer-1" id="footer">
                    <div className="vd_bottom ">
                        <div className="container">
                            <div className="row">
                                <div className=" col-xs-12">
                                    <div className="copyright">
                                        Copyright ©2014 Venmond Inc. All Rights Reserved
                                    </div>
                                </div>
                            </div>{/* row */}
                        </div>{/* container */}
                    </div>
                </footer>
            </div>


        </>

    );
}

export default InventoryReview;

import React, { useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const TenderedList = (props) => {
    const op = useRef(null);
    const [selectedProduct, setSelectedProduct] = useState(null);



    const Tendered = (items) => {
        let total = 0;
        items?.paymentlist.map((item, key) => {
            let TotalTendered = Number(item?.amount_value)
            total += Number(TotalTendered);
        });
        return total;
    }

    const Balance = (items) => {
        var total = 0;
        var totalCost = 0;
        var TotalTendered = 0;

        items?.list.map((item, key) => {
            totalCost += Number(item?.stock_price) * Number(item?.sales_qty);
        });

        items?.paymentlist.map((item, key) => {
            TotalTendered += Number(item?.amount_value);
        });

        return Number(totalCost) - Number(TotalTendered);
    }
    const format = (n, sep, decimals) => {
        // setSubTotal(n);
        sep = sep || "."; // Default to period as decimal separator
        decimals = decimals || 2; // Default to 2 decimals

        return n.toLocaleString().split(sep)[0]
            + sep
            + n.toFixed(decimals).split(sep)[1];
    }

    return (
        <>
            <OverlayPanel ref={op} dismissable={true} style={{ width: 400 }}>

                <DataTable value={props.item?.paymentlist} selectionMode="single" rows={5}>
                    <Column field="amount_value" header="Amount" sortable style={{ minWidth: '12rem', fontSize: 13 }} />
                    <Column field="type" header="Type" style={{ minWidth: '8rem', fontSize: 13 }} />
                    <Column field="date" header="Date Time" style={{ minWidth: '8rem', fontSize: 13 }} />
                </DataTable>
                <div style={{ fontSize: 14, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <span style={{ fontWeight: "bolder" }}>Total Tendered: {format(Tendered(props.item))}</span>
                    {
                        Balance(props.item) ?
                            <span style={{ fontWeight: "bolder", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <span>
                                    {
                                        Balance(props.item) < 0 ?
                                            <span style={{ color: "orange" }}>Over Payment: {Math.abs(Balance(props.item))}</span>
                                            :
                                            <span>Due Balance: {Balance(props.item)}</span>
                                    }
                                </span>
                                
                            </span>
                            : null
                    }

                </div>
                <div>
                    {
                        Balance(props.item) ?
                            (
                                Balance(props.item) < 0 ?
                                    <></>
                                    :
                                    <button className='btn btn-info btn-sm'>Add Payment</button>
                            )
                            :
                            <span style={{ color: "green", fontSize: 12, fontWeight: 600 }}>Completed Payment</span>
                    }
                </div>
            </OverlayPanel>
            <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={(e) => op.current.toggle(e)}>
                {Tendered(props.item)}
            </span>
        </>
    );
}

export default TenderedList;

import React, { useState } from 'react';

const OptionPayment = (props) => {
    const [inputvalue, setInputValue] = useState({...props._item})

    console.log(inputvalue)

    return (
        <select name='status_two' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.status_two}>
            <option value={"srp"}>Srp / Vat</option>
            <option value={"cash"}>Cash Price</option>
            <option value={"card"}>Card Straight</option>
        </select>
    );
}

export default OptionPayment;

import { OverlayPanel } from 'primereact/overlaypanel';
// import { Sidebar } from 'primereact/sidebar';
import React, { useRef, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';

const AddCustomerForm = (props) => {
    // const [visible, setVisible] = useState(false);
    // const [selectedPayment, setSelectedPayment] = useState(false);
    // const [selectedOnlinePayment, setSelectedOnlinePayment] = useState(false);

    const [inputvalue, setInputValue] = useState({
        "id_customer": Number,
        "customer_name": String,
        "customer_contact": String,
        "customer_address": String,
        "contact_person": String,
        "email_address": String,
        "tin": String
    });


    const op = useRef(null);

    const OpenOverlay = (e) => {
        op.current.show(e)
    }
    const CloseOverlay = (e) => {
        op.current.hide(e)
    }

    const SaveNewBtn = async () => {


        var formData = new FormData();
        formData.append("save_new_customer", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/sales.php',
            data: formData,
        }).then((res) => {
            // console.log('test', res.data)
            props.setInputValue(prev => ({ ...prev, ...res.data }))
        });
    }



    return (
        <>
            <OverlayPanel ref={op} style={{ width: 450, border: "2px solid #000" }} showCloseIcon={false} closeOnEscape={false} dismissable={false}>
                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                    <div>
                        <span style={{ fontSize: 25, fontWeight: "bolder" }}>Add Customer</span>
                    </div>
                    <div>
                        <label style={{ fontSize: 20 }}>Fullname / Company Name</label>
                        <input type='text' name='customer_name' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.customer_name} className='form-control' />
                    </div>
                    <div>
                        <label style={{ fontSize: 20 }}>Mobile / Telephone</label>
                        <input type='text' name='customer_contact' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.mobileno} className='form-control' />
                    </div>
                    <div>
                        <label style={{ fontSize: 20 }}>Address </label>
                        <input type='text' name='customer_address' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.address} className='form-control' />
                    </div>
                    <div>
                        <label style={{ fontSize: 20 }}>Contact Person / Contact Number </label>
                        <input type='text' name='contact_person' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.contact_person} className='form-control' />
                    </div>
                    <div>
                        <label style={{ fontSize: 20 }}>Email Address </label>
                        <input type='text' name='email_address' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.email_address} className='form-control' />
                    </div>
                    <div>
                        <label style={{ fontSize: 20 }}>Tin </label>
                        <input type='text' name='tin' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.tin_number} className='form-control' />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                            <button onClick={() => CloseOverlay()} className='btn btn-danger'>
                                Close
                            </button>
                            <button onClick={() => SaveNewBtn()} className='btn btn-info'>
                                Save New
                            </button>
                        </div>
                    </div>
                </div>
            </OverlayPanel>
            {/* onClick={() => setVisible(true)} */}
            <button onClick={OpenOverlay} style={{ width: 45, height: 45, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <i className='pi pi-plus' style={{ fontSize: 18 }} />
            </button>
            {/* <label onClick={() => setVisible(true)} style={{ fontSize: 20, width: 200, textAlign: "center", height: 40, color: "#000", padding: 5, borderRadius: 5, border: "2px solid #ddd", backgroundColor: "#fff" }}>
                Add Payment
            </label> */}
        </>
    );
}

export default AddCustomerForm;

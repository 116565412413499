import axios from 'axios';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import ENV from '../../ENV';

const ServicesBtn = ({OnlySalesData, PreviewDetails}) => {
    const [addcustomerdialog, setAddCustomerDialog] = useState(false);
    const [checkoption, setCheckOption] = useState(0);
    const [options, setOptions] = useState('');
    const [note, setNotes] = useState('');
    const [unitprice, setUnitPrice] = useState('');
    const [qty, setQty] = useState(1);

    // console.log(OnlySalesData)
    const [submitted, setSubmitted] = useState(false);
    const [SearchExistingCustomer, setSearchExistingCustomer] = useState(false)
    const [customerinfo, setCustomerInfo] = useState(JSON.parse(sessionStorage.getItem('customerinfo')) ?? {})

    const Add = async () => {
        // console.log(OnlySalesData.list)

        const data = [{
            "sales_stock_id": null,
            "sales_id": Number(OnlySalesData.sales_id),
            "product_id": 0,
            "product_name": "Services",
            "product_code": "",
            "serial_number": "",
            "stock_price": unitprice,
            "sales_qty": Number(qty),
            "warranty": "",
            "no_serial": 0,
            "options": [{
                "name": options,
                "option": checkoption,
                "note": note
            }],
            "history": [{
                "sales_id": Number(OnlySalesData.sales_id),
                "user_id": Number(localStorage.getItem('user_id')),
                "employee_name": localStorage.getItem('firstname'),
                "particular": null,
                "note": null,
                "date_time": format(new Date(), 'yyyy-MM-dd HH:mm:ss')
            }],
            "user_id": Number(localStorage.getItem('user_id')), 
            "remarks":""
        }]
        // OnlySalesData.list = [...OnlySalesData?.list, data];
        // console.log(data)
        // PreviewDetails(OnlySalesData);
        // return
        var formData = new FormData();
        formData.append("sales_report_item_addservices", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("data", JSON.stringify(data));
        // formData.append("sales_id", Number(OnlySalesData.sales_id));
        // formData.append("product_name", "Services");
        // formData.append("stock_price", unitprice);
        // formData.append("sales_qty", 1);
        // formData.append("no_serial", 0);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'sales_report.php',
            data: formData,
        }).then((res) => {
            data[0].sales_stock_id = res.data;
            data[0].options = JSON.stringify([{
                "name": options,
                "option": checkoption,
                "note": note
            }]);
            OnlySalesData.list = [...OnlySalesData?.list, ...data];
            console.log(OnlySalesData)
            PreviewDetails(OnlySalesData);
        });


    }

    const hideCustomerDialog = () => {
        // setSubmitted(false);
        setAddCustomerDialog(false);
        // setSearchExistingCustomer(false)
    }

    const optionServices = (e) => {
        setOptions(e)
        setCheckOption(null)
        
    }

    const CustomerDialogFooter = (
        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div></div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Button style={{ width: 100, fontSize: 18 }} label="Cancel" icon="pi pi-times" outlined onClick={hideCustomerDialog} />
                    <Button onClick={() => Add()} style={{ width: 100, fontSize: 18 }} label="Add" icon="pi pi-check" />
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <>
            <Dialog visible={addcustomerdialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                <span style={{ fontSize: 18 }}>Add Services</span>
            } modal className="p-fluid" footer={CustomerDialogFooter} onHide={hideCustomerDialog}>
                <div>
                    <select onChange={(e)=>optionServices(e.target.value)} style={{width:"100%", height:35, borderRadius:5, border:"1px solid #ddd"}}>
                        <option value="" selected="" disabled="">Choose one Services...</option>
                        <option value="Reformat">Reformat</option>
                        <option value="Cleaning">Cleaning</option>
                        <option value="Parts Installation">Parts Installation</option>
                        <option value="Desktop Rebuild">Desktop Rebuild</option>
                        <option value="Checkup">Checkup</option>
                        <option value="Backup">Backup</option>
                        <option value="Crimp">Crimp</option>
                        <option value="other">Others</option>
                    </select>
                </div>
                {
                    options !== ''?
                    <>
                        {   
                            options==='Reformat'?                        
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", height:30, gap:10}}>
                                <div style={{ display: "flex", flexDirection: "row", alignSelf: "flex-end", gap: 5 }}>
                                    <Checkbox onChange={e =>{
                                        setCheckOption('Desktop')
                                    }} checked={checkoption === 'Desktop' ? true : false}></Checkbox>
                                    Desktop
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignSelf: "flex-end", gap: 5 }}>
                                    <Checkbox onChange={e =>{
                                        setCheckOption('Laptop')
                                    }} checked={checkoption === 'Laptop' ? true : false}></Checkbox>
                                    Laptop
                                </div>
                            </div>
                            :
                            null
                        }
                        {   
                            options==='Cleaning'?                       
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", height:30, gap:10}}>
                                <div style={{ display: "flex", flexDirection: "row", alignSelf: "flex-end", gap: 5 }}>
                                    <Checkbox onChange={e =>{
                                        setCheckOption('Desktop')
                                    }} checked={checkoption === 'Desktop' ? true : false}></Checkbox>
                                    Desktop
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignSelf: "flex-end", gap: 5 }}>
                                    <Checkbox onChange={e =>{
                                        setCheckOption('Laptop')
                                    }} checked={checkoption === 'Laptop' ? true : false}></Checkbox>
                                    Laptop
                                </div>
                            </div>
                            :
                            null
                        }
                        {   
                            options==='Checkup'?                        
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", height:30, gap:10}}>
                                <div style={{ display: "flex", flexDirection: "row", alignSelf: "flex-end", gap: 5 }}>
                                    <Checkbox onChange={e =>{
                                        setCheckOption('Desktop')
                                    }} checked={checkoption === 'Desktop' ? true : false}></Checkbox>
                                    Desktop
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignSelf: "flex-end", gap: 5 }}>
                                    <Checkbox onChange={e =>{
                                        setCheckOption('Laptop')
                                    }} checked={checkoption === 'Laptop' ? true : false}></Checkbox>
                                    Laptop
                                </div>
                            </div>
                            :
                            null
                        }
                    </>
                    :
                    null
                }
                <div>
                    <div>Note</div>
                    <textarea onChange={(e)=> setNotes(e.target.value)} style={{width:"20%", padding:5, width:"100%", height:100, borderRadius:5, border:"1px solid #ddd"}} />
                </div>
                <div>
                    <div>Qty</div>
                    <input onChange={(e)=>setQty(e.target.value)} style={{width:"20%", padding:5, height:35, borderRadius:5, border:"1px solid #ddd"}} />
                </div>
                <div>
                    <div>Price</div>
                    <input onChange={(e)=>setUnitPrice(e.target.value)} style={{width:"30%", padding:5, height:35, borderRadius:5, border:"1px solid #ddd"}} />
                </div>
                {/* <div>
                    <div>Warranty</div>
                    <input onChange={(e)=>setWarranty(e.target.value)} style={{width:"30%", padding:5, height:35, borderRadius:5, border:"1px solid #ddd"}} />
                </div> */}

            </Dialog>
            <button onClick={()=>{
                setCheckOption(null)
                setNotes(null)
                setAddCustomerDialog(true)
            }} style={{ width: "50%" }}>Add Services</button>
        </>
    );
}

export default ServicesBtn;

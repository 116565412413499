import React, { useEffect, useState, useMemo, useRef } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import Header from './component/Header';
import Navbar from './component/Navbar';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from './ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from "primereact/chips";
// import { QrReader } from 'react-qr-reader';
import QrReader from 'modern-react-qr-reader'
import { QrScanner } from '@yudiel/react-qr-scanner';
import TableByCategory from './component/inventory/TableByCategory';
import TableAllProducts from './component/inventory/TableAllProducts';
import cookie from 'js-cookie';
import RemoveCookie from './component/hooks/removeCookie';


const Inventory = () => {
    document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix" data-active="dashboard ';
    document.body.id = 'dashboard';
    document.title = 'Inventory';
    const [opentable, setOpenTable] = useState('all')
    // const [fix, setFix] = useState(true)

    // const useCheckMobileScreen = () => {
    //     const [width, setWidth] = useState(window.innerWidth)
    //     const handleWindowSizeChange = () => {
    //         setWidth(window.innerWidth)
    //     }

    //     useEffect(() => {
    //         window.addEventListener('resize', handleWindowSizeChange);
    //         return () => {
    //             window.removeEventListener('resize', handleWindowSizeChange);
    //         }
    //     }, [])

    //     return (width <= 768)
    // }

    // console.log(fix)

    // cookie

    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
                // console.log(name)
            });
            // console.log(names)
        });
        // alert('Complete Cache Cleared')
        // console.log(caches)
    };

    useEffect(()=> {
        RemoveCookie('PHPSESSID')
    }, [])

    return (
        <>
        
            {
                opentable == 'all' ?
                    <div>
                        <TableAllProducts opentable={opentable} setOpenTable={setOpenTable} />
                    </div>
                    :
                    null
            }
            {
                opentable == 'by_cat' ?
                    <>
                        <TableByCategory opentable={opentable} setOpenTable={setOpenTable} />
                    </>
                    :
                    null
            }
        </>
    );
}

export default Inventory;

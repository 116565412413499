import React, { useMemo, useRef, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Avatar } from 'primereact/avatar';
import { Card } from 'primereact/card';
import ENV from '../../ENV';
import axios from 'axios';
import PaymentRow from './PaymentRow';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import SplitButtonSalesStock from './extra/SplitButtonSalesStock';
import PrintReceipt from './extra/PrintReceipt';
import EditInformation from './extra/EditInformation';
import AddPaymentSidebar from './extra/AddPaymentSidebar';

const PreviewDetails = (props) => {
    const [visibleRight, setVisibleRight] = useState(false);
    const [salesdata, setSalesData] = useState(null);
    const [salesstock, setSalesStock] = useState([]);
    const [salespayment, setSalesPayment] = useState([...props.item.paymentlist]);
    const [users, setUsers] = useState([]);
    const [inputvalue, setInputValue] = useState(props.item);

    console.log(salespayment)

    // sales, setSales

    const LoadStockData = async (item) => {
        var formData = new FormData();
        formData.append("sales_stock_list", 1);
        formData.append("sales_id", item.sales_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/sales_report.php',
            data: formData,
        }).then((res) => {
            setSalesStock(res.data)
            // console.log(res.data)
        });
    }

    const LoadPayment = async (item) => {
        var formData = new FormData();
        formData.append("sales_payment", 1);
        formData.append("sales_id", item.sales_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/sales_report.php',
            data: formData,
        }).then((res) => {
            setSalesPayment(res.data)
            // console.log(res.data)
        });
    }

    const getusers = async () => {
        var formData = new FormData();
        formData.append("users", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/sales_report.php',
            data: formData,
        }).then((res) => {
            setUsers(res.data)
            // console.log(res.data)
        });
    }


    const _assestedby = (item) => {
        // const data = users.filter((i)=>i.user_id === item.user_id);
        // console.log(data, "asd")
        // return data[0].firstname;
    }



    const openDialog = () => {
        setVisibleRight(true)
        LoadStockData(props.item)
        // LoadPayment(props.item)
        getusers()
    }



    const TotalDue = salesstock.reduce((amount, item) => amount = (item.stock_price * item.sales_qty) + amount, 0);
    
    var TotalReceived = 0;
    salespayment.map((item)=>{
        if (item.type === 'Cash') {
            TotalReceived += Number(item.amount_value);
        }
        if (item.type === 'Online') {
            TotalReceived += Number(item.online_amount);
        }
        if (item.type === 'Credit Card') {
            TotalReceived += Number(item.cc_amount);
        }
        if (item.type === 'Cheque') {
            TotalReceived += Number(item.cheque_amount);
        }        
    })
    
    // const TotalReceived = salespayment.reduce((amount, item) => {
    //     if (item.option === 'Cash') {
    //         amount += Number(item.amount) + amount
    //     }
    //     if (item.option === 'online') {
    //         amount += Number(item.amount) + amount
    //     }
    //     return amount;
    // }, 0);


    const format = (n, sep, decimals) => {
        // setSubTotal(n);
        sep = sep || "."; // Default to period as decimal separator
        decimals = decimals || 2; // Default to 2 decimals

        return n.toLocaleString().split(sep)[0]
            + sep
            + n.toFixed(decimals).split(sep)[1];
    }

    const Cashiername = useMemo(() => {
        return users.filter((i)=>i.user_id === inputvalue.user_id)[0]?.firstname
    }, [users, props, inputvalue])

    return (
        <>
            <Sidebar showCloseIcon={false} blockScroll={true} visible={visibleRight} position="right" onHide={() => setVisibleRight(false)} style={{ width: "70%" }}>
                <div className='row'>
                    <div className='col-sm-7'>
                        <div style={{ padding: "5px 0px 5px 0px" }}>
                            <input type="" style={{ width: 400, borderRadius: 5, height: 40, border: "1px solid #ddd", padding: 5, fontSize: 15, outline: "none" }} placeholder='Search...' />
                        </div>
                        <div>
                            {
                                salesstock.map((item, key) => {
                                    return (
                                        <div key={key} style={{ width: "100%", borderRadius: 3, border: "1px solid #ddd", padding: 5 }}>
                                            <div><span style={{ fontWeight: "bold", fontSize: 15 }}>Name:</span> <span style={{ fontSize: 16 }}>{item.product_name}</span></div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                <div><span style={{ fontWeight: "bold", fontSize: 15 }}>Code:</span> <span style={{ fontSize: 15 }}>{item.product_code}</span></div>
                                                <span> | </span>
                                                <div><span style={{ fontWeight: "bold", fontSize: 15 }}>Serial:</span> <span style={{ fontSize: 15 }}>{item.serial_number}</span></div>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                    <div><span style={{ fontWeight: "bold", fontSize: 15 }}>Qty:</span> <span style={{ fontSize: 15 }}>{item.sales_qty}</span></div>
                                                    <div><span style={{ fontWeight: "bold", fontSize: 15 }}>Price:</span> <span style={{ fontSize: 15 }}>{item.stock_price}</span></div>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                                                    <span style={{ fontWeight: "bold", fontSize: 15 }}>Total: <span>{Number(item.stock_price * item.sales_qty)}</span></span>

                                                    <div>
                                                        <SplitButtonSalesStock item={item} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='col-sm-5' style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 3 }}>
                                <div className='btn btn-info' style={{ width: "100%" }}>Miscellaneous</div>
                                <PrintReceipt item={props.item} />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                            <Card header={
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 5, paddingLeft: 10, paddingRight: 5, paddingBottom: 5, backgroundColor: "#5bc0de", color: "#fff" }}>
                                    <div style={{ fontWeight: "bolder", fontSize: 18 }}>{"Sales No.: " + props.item.sales_no}</div>
                                    <div style={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                        <EditInformation item={props.item} sales={props.sales} setSales={props.setSales} setInputValue={setInputValue} />
                                    </div>
                                </div>
                            }>
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, fontSize: 15, fontWeight: "bolder", borderBottom: "1px solid #ddd" }}>
                                        <span>Client Name:</span>
                                        <span>{inputvalue?.customer_name}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, fontSize: 15, fontWeight: "bolder", borderBottom: "1px solid #ddd" }}>
                                        <span>Contact:</span>
                                        <span>{inputvalue?.contact}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, fontSize: 15, fontWeight: "bolder", borderBottom: "1px solid #ddd" }}>
                                        <span>Address:</span>
                                        <span>{inputvalue?.address}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, fontSize: 15, fontWeight: "bolder", borderBottom: "1px solid #ddd" }}>
                                        <span>Cashier:</span>
                                        <span>{users.filter((i)=>i.user_id === inputvalue?.user_id)[0]?.firstname}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, fontSize: 15, fontWeight: "bolder", borderBottom: "1px solid #ddd" }}>
                                        <span>Receipt No.:</span>
                                        <span>{inputvalue?.receipt_no}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, fontSize: 15, fontWeight: "bolder", borderBottom: "1px solid #ddd" }}>
                                        <span>Assisted By:</span>
                                        <span>{users.filter((i)=>i.user_id === inputvalue?.sale_id)[0]?.firstname}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, fontSize: 15, fontWeight: "bolder", borderBottom: "1px solid #ddd" }}>
                                        <span>Date & Time:</span>
                                        <span>{props.item.sales_date + ' ' + props.item.sales_time}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, fontSize: 15, fontWeight: "bolder", borderBottom: "0px solid #ddd" }}>
                                        <span>Note:</span>
                                        <span>{inputvalue?.note}</span>
                                    </div>
                                </div>
                            </Card>
                            <Card header={
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 5, paddingLeft: 10, paddingRight: 5, paddingBottom: 5, backgroundColor: "#5bc0de", color: "#fff" }}>
                                    <div style={{ fontWeight: "bolder", fontSize: 15 }}>Transaction Payment</div>
                                    <div style={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                        <AddPaymentSidebar item={props.item} sales={props.sales} setSales={props.setSales} salespayment={salespayment} setSalesPayment={setSalesPayment} />
                                        <button style={{ width: 100, height: 30, fontSize: 12, fontWeight: "bold", borderRadius: 5, border: "1px solid #ddd", color: "#000" }}>Trash</button>
                                    </div>
                                </div>
                            }>
                                <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                    <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                        {
                                            salespayment.map((pay_item, key) => {
                                                if (pay_item.status === 'active') {
                                                    return (
                                                        <PaymentRow key={key} pay_item={pay_item} item={props.item} />
                                                    )
                                                }
                                            })
                                        }
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                                        <div style={{ width: "100%", border: "1px solid #ddd", padding: 2, textAlign: "center" }}>
                                            <span style={{ fontWeight: "bolder", fontSize: 15 }}>Total Due</span>
                                            <div style={{ fontSize: 15, fontWeight: "bold", padding: 2 }}>{format(TotalDue)}</div>
                                        </div>
                                        <div style={{ width: "100%", border: "1px solid #ddd", padding: 2, textAlign: "center" }}>
                                            <span style={{ fontWeight: "bolder", fontSize: 15 }}>Cash Received</span>
                                            <div style={{ fontSize: 15, fontWeight: "bold", padding: 2 }}>{Number(TotalReceived).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                        </div>
                                        <div style={{ width: "100%", border: "1px solid #ddd", padding: 2, textAlign: "center", backgroundColor: TotalDue > TotalReceived ? "orange" : Number(TotalDue - TotalReceived) < 0 ? "orange" : "#fff" }}>
                                            {
                                                Number(TotalDue - TotalReceived) < 0 ?
                                                    <>
                                                        <span style={{ fontWeight: "bolder", fontSize: 15, color: "#fff" }}>Over Payment</span>
                                                        <div style={{ fontSize: 15, fontWeight: "bold", padding: 2, color: "#fff" }}>{Number(TotalReceived-TotalDue).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                    </>
                                                    :
                                                    <>
                                                        <span style={{ fontWeight: "bolder", fontSize: 15 }}>Due Balance</span>
                                                        <div style={{ fontSize: 15, fontWeight: "bold", padding: 2 }}>{Number(TotalDue - TotalReceived).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Card header={
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 5, paddingLeft: 10, paddingRight: 5, paddingBottom: 5, backgroundColor: "#5bc0de", color: "#fff" }}>
                                    <div style={{ fontWeight: "bolder", fontSize: 15 }}>Shipping Details</div>
                                    <div>
                                        {
                                            true ?
                                                <button style={{ width: 150, height: 30, fontSize: 12, fontWeight: "bold", borderRadius: 5, border: "1px solid #ddd", color: "#000" }}>Edit Details</button>
                                                :
                                                <button style={{ width: 150, height: 30, fontSize: 12, fontWeight: "bold", borderRadius: 5, border: "1px solid #ddd", color: "#000" }}>Add Details</button>
                                        }
                                    </div>
                                </div>
                            }>
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, fontSize: 15, fontWeight: "bolder" }}>
                                        <span>Courier:</span>
                                        <span>{props.item.customer_name}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, fontSize: 15, fontWeight: "bolder" }}>
                                        <span>Shipping Cost:</span>
                                        <span>{props.item.customer_name}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, fontSize: 15, fontWeight: "bolder" }}>
                                        <span>Address:</span>
                                        <span>{props.item.customer_name}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, fontSize: 15, fontWeight: "bolder" }}>
                                        <span>Traking No:</span>
                                        <span>{props.item.customer_name}</span>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>

            </Sidebar>


            {/* PreviewDetails(item); EditedItem({}); */}
            <button onClick={() => openDialog()} style={{ width: 30, height: 30, backgroundColor: "rgb(86 56 126)", border: 0, borderRadius: 100, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="white" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                    <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>
            </button>
        </>
    );
}

export default PreviewDetails;

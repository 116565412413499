import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import React, { useRef, useState } from 'react';
import ENV from '../../../../ENV';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

const Delete = (props) => {
    const menuLeft = useRef(null);
    const menuRight = useRef(null);
    const toast = useRef(null);
    
    const [visible, setVisible] = useState(false)
    const [modal, setModal] = useState(false)

    const onDelete = async () => {
        var formData = new FormData();
        formData.append("delete_product", 1);
        formData.append("pid", props.item.pid);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/product.php',
            data: formData,
        }).then((res) => {
            // props.products

            console.log(res.data)
            console.log(props.products)
            toast.current.show({ severity: 'success', summary: 'Deleted', detail: 'You have successfully deleted', life: 3000 });
            const index = props.products?.findIndex((i)=>i.pid === props.item.pid);
            props.products[index].pstatus = 3;
            console.log(index, props.products)
            props.setProducts([...props.products]);
            setVisible(false);
            props.hideDialog();




        })
            .catch((err) => {
                console.log(err);
            });
    }



    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    return (
        <>
            <Toast ref={toast} style={{ fontSize: 15 }}></Toast>
            <ConfirmDialog
                style={{ fontSize: 15 }}
                group="declarative"
                visible={modal}
                onHide={() => setModal(false)}
                message="Are you sure you want to proceed?"
                header="Confirmation"
                icon="pi pi-exclamation-triangle"
                accept={onDelete}
                reject={reject}
            />
            <Dialog draggable={false} 
            // header={"Header"} 
            footer={
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems :"center"}}>
                    <button onClick={()=>setModal(true)} className='btn btn-danger'>Delete</button>
                    <button onClick={()=>setVisible(false)} className='btn btn-warning'>Close</button>
                </div>
            }
            position={"top"} 
            visible={visible} style={{ width: '40vw' }} onHide={() => setVisible(false)}>
                <div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                        <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                            <img src={"https://stgc.tech/pages/"+props.item.product_image} style={{border:"1px solid #ddd", width: "50%"}} />
                        </div>
                        <div>
                            <div style={{fontSize: 15, padding: 5, textAlign: "center"}}><span style={{fontSize: 18, fontWeight: "bolder"}}>{props.item.product_name}</span></div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <button onClick={()=>setVisible(props.item)} className='btn btn-sm btn-warning'>Delete</button>
        </>
    );
}

export default Delete;

import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import Header from './component/Header';
import Navbar from './component/Navbar';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from './ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from "primereact/chips";
import AppContext from './context/AppContext';
import RemoveCookie from './component/hooks/removeCookie';
import { VirtualScroller } from 'primereact/virtualscroller';
import Shipping from './component/salesreport/Shipping';
import Payment from './component/salesreport/Payment';
import Information from './component/salesreport/Information';
import Items from './component/salesreport/Items';
import EditInfoBtn from './component/salesreport/EditInfoBtn';
import HistoryBtn from './component/salesreport/HistoryBtn';
import PrintReceipt from './component/salesreport/PrintReceipt';
import ServicesBtn from './component/salesreport/ServicesBtn';
import MiscellaneousBtn from './component/salesreport/MiscellaneousBtn';
import PanelDetails from './component/salesreport/PanelDetails';
import PreviewDetails from './component/salesreport/PreviewDetails';
import TenderedList from './component/salesreport/TenderedList';


const SalesReport = ({ userInfo }) => {

    document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix" data-active="dashboard ';
    document.body.id = 'dashboard';
    document.title = 'Sales Report';
    // const { state, dispatch } = useContext(AppContext);

    const [sales, setSales] = useState([])
    const [record, setRecord] = useState(25)
    const [search, setSearch] = useState('')
    // const data = JSON.stringify(state.REPORT_LIST);

    const sales_report_list = async () => {
        // console.log(1);
        // axios.get('https://stgc.tech/api/sales_report.php?sales_report_list').then(resp => {
        //     console.log(resp.data);
        //     setSales(resp.data)
        // });


        // console.log(1)
        var formData = new FormData();
        formData.append("sales_report_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/sales_report.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setSales(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }
    

    const SalesData = useMemo(() => {
        let data = sales;
        if (search?.length>=3) {
            //     // i?.sales_id?.padStart(10, '0').match(search.trim()) || 
            data = sales?.filter((i) =>
                String(i?.customer_name).toLowerCase().includes(String(search).toLowerCase()) ||
                String(i?.sales_id).toLowerCase().includes(String(search).toLowerCase()) ||
                String(i?.sales_no).toLowerCase().includes(String(search).toLowerCase())
            );
            // console.log(search)
            return data;
        }
        return data;
    }, [sales, search])

    const num = 1;

    const [previewData, setPreviewData] = useState([])
    const [previewDataId, setPreviewDataId] = useState('')
    const [OnlyReceiptData, setOnlyReceiptData] = useState({ list: [{ stock_price: 0, sales_qty: 0 }] })

    const [OnlySalesData, setPanelOnlySalesData] = useState([])

    const [ItemList, setItemList] = useState([])
    const [printpreviewereceipt, setPrintPreviewReceipt] = useState(false)

    const [printing, setPrinting] = useState(false)
    const [successprinting, setSuccessPrinting] = useState(false)
    // const [printpreviewereceipt, setPrintPreviewReceipt] = useState(false)

    const PrintPreview = async (item) => {
        // setPreviewDataId(item.sales_id)

        // setPrintPreviewReceipt(true)

        // setItemList(item)
        // setOnlyReceiptData(item)
        // setPanelOnlySalesData(item)

        
        // console.log(item)
        // event.preventDefault();
        // var formData = new FormData();
        // formData.append("sales_details", 1);
        // formData.append("sales_id", item.sales_id);

        // await axios({
        //     method: "post",
        //     url: 'https://stgc.tech/api/sales_details.php',
        //     data: formData,
        // }).then((res) => {
        // console.log(res.data)
        // setPanelOnlySalesData(res.data)
        // console.log(JSON.stringify(res.data))
        // });
    }

    // console.log(OnlySalesData?.length)


    const printReceipt = async (data, val) => {
        //     setPrinting(true)

        // console.log(data?.list)

        // var formData = new FormData();
        // formData.append("print_sales_details", 1);
        // formData.append("print_details", JSON.stringify(data));
        // formData.append("product_list", JSON.stringify(data?.list));
        // formData.append("policy", val);
        // await axios({
        //     method: "post",
        //     // url: 'http://192.168.1.99/api/print_receipt.php',
        //     url: 'http://localhost/stgc/api/print_receipt.php',
        //     data: formData,
        // }).then((res) => {
        //     console.log(res.data)
        //     if (res.data == 'success') {
        //         setSuccessPrinting(true)
        //         setTimeout(() => {
        //             setPrinting(false)
        //             setPrintPreviewReceipt(false)
        //             setSuccessPrinting(false)
        //             setPanelOnlySalesData(data)
        //             setOnlyReceiptData({ list: [{ stock_price: 0, sales_qty: 0 }] })
        //         }, 1000)
        //     } else {

        //     }
        // });

    }


    // const PreviewDetails = (item) => {
    //     setInputPriceItem({ amount: 0, sales_stock_id: 0 })
    //     setPreviewDataId(item.sales_id)
    //     setPanelOnlySalesData(item)
    //     setPreviewData(JSON.stringify(item))
    // }

    // console.log(OnlySalesData.list)


    const CalTotal = (item) => {
        let total = 0;
        item?.list.map((item, key) => {
            // console.log(item)
            let subTotal = item.stock_price * item.sales_qty
            total = total + subTotal;
        });
        return total;
    }



    const format = (n, sep, decimals) => {
        // setSubTotal(n);
        sep = sep || "."; // Default to period as decimal separator
        decimals = decimals || 2; // Default to 2 decimals

        return n.toLocaleString().split(sep)[0]
            + sep
            + n.toFixed(decimals).split(sep)[1];
    }


    const [InputPriceItem, setInputPriceItem] = useState({ amount: 0, sales_stock_id: 0 })
    const [EditedItemId, setEditedItemId] = useState({ sales_stock_id: null })

    const EditedItem = (item) => {
        setEditedItemId(item);
        // setInputPriceItem({ amount: item.stock_price, sales_stock_id: 0 })

        // console.log(item)

    }


    const updateItem = (OnlySalesData, item) => {
        const data = OnlySalesData;
        const index = data.list.findIndex(x => x.sales_stock_id == item.sales_stock_id);
        data.list[index].stock_price = InputPriceItem?.amount ? InputPriceItem?.amount : data.list[index].stock_price;

        // update for customers item
        // code


        //////////////////////
        const salesIndex = SalesData?.findIndex(x => x.sales_id == data.list[index].sales_id);
        SalesData[salesIndex].list[index].stock_price = InputPriceItem?.amount ? InputPriceItem?.amount : data.list[index].stock_price;

        // console.log(SalesData);

        setInputPriceItem({ amount: 0, sales_stock_id: 0 })
        /// put this function to requested data
        setSales(SalesData);
        setPanelOnlySalesData(data)
        setEditedItemId({ sales_stock_id: null })
    }

    const RemoveItem = () => {
        EditedItem({ sales_stock_id: null })
    }

    const TotalSales = (items) => {
        var total = 0;
        items?.list.map((item, key) => {
            total += Number(item?.stock_price) * Number(item?.sales_qty)
            // total = Number(total)+ Number(subTotal);
        });
        if(items.shipping_details != null){
            return Number(total);
        }
        return total;
    }

    const Balance = (items) => {
        var total = 0;
        var totalCost = 0;
        var TotalTendered = 0;
        
        items?.list.map((item, key) => {
            totalCost += Number(item?.stock_price) * Number(item?.sales_qty);
        });

        items?.paymentlist.map((item, key) => {
            TotalTendered += Number(item?.amount_value);
        });

        return Number(totalCost)-Number(TotalTendered);
    }

    const totalSummary = (item) => {
        let total = 0;
        item.map((item, key) => {
            let subTotal = item.stock_price * item.sales_qty
            total = total + subTotal;
        });
        return total;
    }

    const TotalQty = (item) => {
        let total = 0;
        item.map((item, key) => {
            total = total + item.sales_qty
            // total = total + subTotal;
        });
        return total;
    }


    const ReceiptSubTotal = OnlyReceiptData.list?.reduce((amount, item) => amount = (item.stock_price * item.sales_qty) + amount, 0);
    const SalesDataSubTotal = OnlySalesData.list?.reduce((amount, item) => amount = (item.stock_price * item.sales_qty) + amount, 0);
    // const totalPayment = OnlySalesData.list?.reduce((amount, item) => amount = (item.stock_price * item.sales_qty) + amount, 0);
    // const SubTotal = OnlySalesData.list?.reduce((amount, item) => amount = (item.stock_price * item.sales_qty) + amount, 0);

    // console.log(SalesDataSubTotal)


    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
                // console.log(name)
            });
            // console.log(names)
        });
        // alert('Complete Cache Cleared')
        // console.log(caches)
    };

    useEffect(() => {
        sales_report_list();
        // clearCacheData()
        RemoveCookie('PHPSESSID')
    }, []);


    // const groupedData = OnlySalesData?.list?.reduce((groups, item) => {
    //     const { product_id, product_name } = item;
    //     if (product_id == 0) {
    //         if (!groups[product_id, product_name]) {
    //             groups[product_id, product_name] = [];
    //         }
    //         groups[product_id, product_name].push(item);
    //     } else {
    //         if (!groups[product_id]) {
    //             groups[product_id] = [];
    //         }
    //         groups[product_id].push(item);
    //     }
    //     return groups;
    // }, {});

    // console.log(EditedItemId, EditedItemId[0]?.sales_stock_id)

    // const [removeid, setRemoveID] = useState(0)
    const removeitemLoop = (items) => {
        // console.log(Object.entries(groupedData))


        const data = OnlySalesData.list.filter((val) => val.sales_stock_id != items.sales_stock_id);
        OnlySalesData.list = data;
        // PreviewDetails(OnlySalesData);

        // console.log(data)

        // const index = OnlySalesData.findIndex(x => x.sales_id == items.sales_id);
        // console.log(OnlySalesData)

        // 
        // console.log([OnlySalesData, {"list": data}]);
    }

    // const totalPayment = () => {

    // }


    let key = 0;
    return (

        <>
            <div className="vd_body">
                <Header />
                <div className="content">
                    <div className="container">
                        <Navbar />
                        <div className="vd_content-wrapper" style={{ minHeight: 1059 }}>
                            <div className="vd_container" style={{ minHeight: 1059 }}>
                                <div className="vd_content clearfix">
                                    {
                                        previewData.length > 0 ?
                                            <>
                                                <div onClick={() => { setPreviewData({}); setInputPriceItem({ amount: 0, sales_stock_id: 0 }) }} style={{ position: "absolute", width: "100vw", height: "100%", zIndex: 2, backgroundColor: "#23709e", opacity: 0.5 }}></div>
                                                <div style={{ height: "100%", width: previewData.length > 0 ? window.innerWidth - 525 : 0, boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px", border: "1px solid #ddd", transition: "width 0.09s", overflow: "hidden", backgroundColor: "#fff", position: "fixed", zIndex: 3, right: 0, top: 0, paddingTop: 55 }}>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                        <div style={{ width: "60%" }}>
                                                            {/* <button onClick={() => sales_report_list()}>click</button> */}
                                                            <div style={{ alignSelf: "flex-start", padding: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                <div style={{ alignSelf: "flex-start", padding: null, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                                                    <input type='' value={null} style={{ width: window.innerWidth - 1200, paddingLeft:5, paddingRight:5, height: 40, border: "1px solid #ddd", borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }} />
                                                                    <button style={{ height: 40, width: 40, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderTopRightRadius: 5, borderBottomRightRadius: 5, border: "1px solid #ddd" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <HistoryBtn OnlySalesData={OnlySalesData} />
                                                            </div>
                                                            <div style={{ padding: 10, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                <div></div>
                                                                <div>
                                                                    <div style={{ fontWeight: '700', fontSize: 40 }}>
                                                                        &#8369;. {Number(parseFloat(SalesDataSubTotal).toFixed(2) ?? 0).toLocaleString('en')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='hidescroll' style={{ overflow: "scroll", height: window.innerHeight - 200, padding: 10, display: "flex", gap: 5, flexDirection: "column" }}>
                                                                <Items sales_report_list={sales_report_list} OnlySalesData={OnlySalesData} />
                                                                {/* PreviewDetails={PreviewDetails} */}
                                                            </div>
                                                        </div>
                                                        <div className='hidescroll' style={{ padding: 0, paddingTop:10, width: "40%", height:"90vh", overflowY:"auto", borderLeft: "1px solid #ddd" }}>
                                                            <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 1 }}>
                                                                    <EditInfoBtn OnlySalesData={OnlySalesData} /> 
                                                                    {/* PreviewDetails={PreviewDetails} */}
                                                                    <PrintReceipt OnlySalesData={OnlySalesData} />
                                                                    
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 1 }}>
                                                                    <MiscellaneousBtn OnlySalesData={OnlySalesData} />
                                                                     {/* PreviewDetails={PreviewDetails} */}
                                                                    <ServicesBtn OnlySalesData={OnlySalesData} />
                                                                     {/* PreviewDetails={PreviewDetails} */}
                                                                </div>
                                                            </div>
                                                            <div>                                                            
                                                                <Information OnlySalesData={OnlySalesData} />
                                                                <Shipping sales={sales} setSales={setSales} OnlySalesData={OnlySalesData} />
                                                                {/* PreviewDetails={PreviewDetails} */}
                                                                <Payment sales_report_list={sales_report_list} OnlySalesData={OnlySalesData} SalesDataSubTotal={SalesDataSubTotal} />
                                                                {/* PreviewDetails={PreviewDetails} */}
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null

                                    }
                                    <div className="vd_content-section clearfix">
                                        <div className="row">
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingBottom: 10 }}>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                    <select onChange={(e) => setRecord(e.target.value)} value={record} style={{ width: 70, height: 30, borderRadius: 5, outline: "none" }}>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                        <option value="500">500</option>
                                                        <option value={sales.length}>All</option>
                                                    </select>
                                                    <div>Record per page</div>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                    <b style={{ fontSize: 20 }}>Sales Report</b>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                    <div>Search</div>
                                                    <div>
                                                        <input type='text' onChange={(e) => setSearch(e.target.value)} style={{ height: 30, width: 200, border: "1px solid #ddd", backgroundColor: "#fff", borderRadius: 5, outline: "none" }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", gap: 0, flexDirection: "column" }}>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", backgroundColor: "#23709e", padding: 5, gap: 2, borderRadius: 0, border: "1px solid #ddd" }}>
                                                    <div style={{ display: "flex", width: 140, flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>

                                                    </div>
                                                    <div style={{ display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "space-between", backgroundColor: "#23709e", padding: 5, gap: 5 }}>

                                                        <div style={{ display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "flex-start", backgroundColor: "#23709e", padding: 5, gap: 5 }}>
                                                            <div style={{ width: 100, fontWeight: "800", backgroundColor: "#23709e", color: "#fff" }}>Sales No.</div>
                                                            <div style={{ width: 150, fontWeight: "800", backgroundColor: "#23709e", color: "#fff" }}>Date Time</div>
                                                            <div style={{ width: "80%", fontWeight: "800", backgroundColor: "#23709e", color: "#fff" }}>Customer Name</div>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", backgroundColor: "#23709e", padding: 5, gap: 5 }}>
                                                            <div style={{ minWidth: 50, fontWeight: "800", backgroundColor: "#23709e", color: "#fff", textAlign: "center" }}>Item/s</div>
                                                            <div style={{ minWidth: 100, fontWeight: "800", backgroundColor: "#23709e", color: "#fff", textAlign: "end" }}>Total Sales</div>
                                                            <div style={{ minWidth: 100, fontWeight: "800", backgroundColor: "#23709e", color: "#fff", textAlign: "end" }}>Tendered</div>
                                                            <div style={{ minWidth: 100, fontWeight: "800", backgroundColor: "#23709e", color: "#fff", textAlign: "end" }}>Balance</div>
                                                            <div style={{ minWidth: 200, fontWeight: "800", backgroundColor: "#23709e", color: "#fff", textAlign: "right" }}>Receipt</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    SalesData.length > 0 ?
                                                        <>
                                                            {
                                                                SalesData.slice(0, search!='' ? 20 : record).map((item)=>{

                                                                    // console.log(item)

                                                                    const totalPayment = item?.Mpayment?.reduce((amount, v) => v?.removeBy != 1 ? amount = Number(v?.amount_value) + Number(amount) : 0 , 0);
                                                                    const totalDue = item?.list?.reduce((amount, item) => amount = (item?.stock_price * item?.sales_qty) + amount, 0);

                                                                    return (
                                                                        <div key={key++} style={{ display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "flex-start", backgroundColor: previewDataId == item.sales_id ? 'rgb(205 226 239)' : '#fff', padding: 5, gap: 2, borderRadius: 5, border: "1px solid #ddd" }}>
                                                                            <div style={{ display: "flex", width: 140, flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                                                <div style={{ minWidth: 40, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>{(key++ + 1)}</div>
                                                                                <button onClick={() => { PrintPreview(item); }} style={{ width: 30, cursor: "pointer", height: 30, backgroundColor: "#779177", border: 0, borderRadius: 100, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="white" className="bi bi-printer-fill" viewBox="0 0 16 16">
                                                                                        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                                                                                        <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                                                                    </svg>
                                                                                </button>
                                                                                <PreviewDetails item={item} sales={sales} setSales={setSales} />
                                                                            </div>
                                                                            <div style={{ display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "space-between", backgroundColor: previewDataId == item.sales_id ? 'rgb(205 226 239)' : "#fff", padding: 5, gap: 5 }}>
                                                                                <div style={{ display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "flex-start", backgroundColor: previewDataId == item.sales_id ? 'rgb(205 226 239)' : "#fff", padding: 5, gap: 5 }}>
                                                                                    <div style={{ width: 100, backgroundColor: previewDataId == item.sales_id ? 'rgb(205 226 239)' : "#fff" }}>{item?.sales_id?.padStart(10, '0')}</div>
                                                                                    <div style={{ width: 150 }}>{item?.sales_date}</div>
                                                                                    <div style={{ width: "80%", }}>{item?.customer_name}</div>
                                                                                </div>
                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", backgroundColor: previewDataId == item.sales_id ? 'rgb(205 226 239)' : "#fff", padding: 5, gap: 5 }}>
                                                                                    <div style={{ minWidth: 50, textAlign: "center" }}>{item?.item}</div>
                                                                                    <div style={{ minWidth: 100, textAlign: "end" }}>{TotalSales(item)}</div>
                                                                                    <div style={{ minWidth: 100, textAlign: "end" }}><TenderedList item={item} /> </div>
                                                                                    {/* {Tendered(item)} */}
                                                                                    <div style={{ minWidth: 100, textAlign: "end" }}>
                                                                                        {
                                                                                            Balance(item) < 0 ?
                                                                                            (
                                                                                                <span style={{display:"flex", flexDirection: "column"}}>
                                                                                                    <span style={{color: "orange", fontSize: 10}}>Overpayment</span>
                                                                                                    <span>{Math.abs(Balance(item))}</span>
                                                                                                </span>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {Balance(item)}
                                                                                                </>
                                                                                            )
                                                                                        }

                                                                                    </div>
                                                                                    <div style={{ minWidth: 200, textAlign: "right" }}>{item?.receipt_no}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* .vd_content-section */}
                                </div>
                                {/* .vd_content */}
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
                {/* .content */}
                {/* Footer Start */}
                <footer className="footer-1" id="footer">
                    <div className="vd_bottom ">
                        <div className="container">
                            <div className="row">
                                <div className=" col-xs-12">
                                    <div className="copyright">
                                        Copyright ©2014 Venmond Inc. All Rights Reserved
                                    </div>
                                </div>
                            </div>{/* row */}
                        </div>{/* container */}
                    </div>
                </footer>
            </div>
        </>
    );
}

export default SalesReport;

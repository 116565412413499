import React, { useEffect, useState, useMemo, useRef } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import Header from './component/Header';
import Navbar from './component/Navbar';
import { format } from 'date-fns';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from './ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from "primereact/chips";
// import { QrReader } from 'react-qr-reader';
import QrReader from 'modern-react-qr-reader'
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';


const InventoryCategory = () => {
    const history = useHistory()
    const {user_id, cat_id, product_id, year } = useParams();

    document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix" data-active="dashboard ';
    document.body.id = 'dashboard';
    document.title = 'Inventory';

    // console.log(JSON.parse(localStorage.getItem('users_role')).dashboard_login)



    const [InventoryList, setInventoryList] = useState([])
    const [Data, setData] = useState([])
    const [openrowdata, setOpenRowData] = useState({})
    const [openpanel, setOpenPanel] = useState({data: [], status: false, item: {} })
    const [handleScanEnable, sethandleScanEnable] = useState(false)
    const [search, setSearch] = useState('')
    const [panelTab, setPanelTab] = useState('pending')
    
    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth)
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth)
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, [])

        return (width <= 768)
    }

    // console.log(useCheckMobileScreen())

    useMemo(async () => {
        var formData = new FormData()
        formData.append("getInvProductAssign", 1)
        // formData.append("user_id", user_id)
        formData.append("cat_id", cat_id,)
        await axios({
            method: "post",
            // url: 'http://localhost/stgc.local/api/sales_report.php',
            url: ENV.DOMAIN + 'inventory.php',
            data: formData,
        }).then((res) => {
            setData(res.data)
            console.log(res.data)
        })
            .catch((err) => {
                console.log(err)
            })
    }, [ENV])
    
    const searchData = (data, search) => {
        if (search) {
            data = data.filter((i) => (i?.product_name.toLowerCase().includes(search.trim().toLowerCase())));
        }
        return data        
    }

    
    return (

        <>
            <div className="vd_body">
                {/* Header Start */}
                <Header />
                {/* Header Ends */}
                <div className={!useCheckMobileScreen() ? "content" : ""}>
                    <div className="container">
                        {/* {
                            !useCheckMobileScreen() ?
                                <Navbar />
                                :
                                null
                        } */}
                        {/* Middle Content Start */}
                        {/* className={!useCheckMobileScreen() ? "vd_content-wrapper" : ""} style={{ minHeight: 1059 }} */}
                        {/* className={!useCheckMobileScreen() ? "vd_container" : ""} style={{ minHeight: 1059 }} */}
                        {/* className={!useCheckMobileScreen() ? "vd_content clearfix" : ""} */}
                        {/* className={!useCheckMobileScreen() ? "vd_content-section clearfix" : ""} */}
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div className={!useCheckMobileScreen() ? '' : "row"} style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                            
                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", padding:10, gap:5, borderBottom:null}}>
                                                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:10}}>
                                                    <input onChange={(e)=>setSearch(e.target.value)} type="" placeholder={Data.length+" Products"} style={{width:200, border:"2px solid #ddd", height:35, paddingLeft:5, borderRadius:5, outline:"none"}} />
                                                    <span style={{fontSize:15}}>Search</span>
                                                </div>
                                            </div>

                                            <div style={{display:"flex", flexDirection:"column", gap:5}}>
                                                {
                                                    Data.length > 0 ?
                                                        searchData(Data, search).map((item, key) => {
                                                            return (
                                                                <div onClick={() => window.location.assign("/inventory/review/" + user_id + "/" + cat_id + "/" + item.id + "/2024")} key={key + 1} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", minHeight: 40, padding: 5, width: "100%", backgroundColor: "#fff", borderRadius: 5, fontWeight: "600", paddingLeft: 10, borderLeft: "5px solid #23709e", borderBottom: "2px solid #23709e", cursor: "pointer" }}>{item.product_name}</div>
                                                            )
                                                        })
                                                        :
                                                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                                            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                                            <div>Please wait...</div>
                                                        </div>
                                                }
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
                {/* .content */}
                {/* Footer Start */}
                <footer className="footer-1" id="footer">
                    <div className="vd_bottom ">
                        <div className="container">
                            <div className="row">
                                <div className=" col-xs-12">
                                    <div className="copyright">
                                        Copyright ©2014 Venmond Inc. All Rights Reserved
                                    </div>
                                </div>
                            </div>{/* row */}
                        </div>{/* container */}
                    </div>
                </footer>
            </div>


        </>

    );
}

export default InventoryCategory;

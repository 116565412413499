import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import { format } from 'date-fns';

const PaymentDetails = ({OnlySalesData, item, PreviewDetails}) => {
    // OnlySalesData?.Mpayment
    const [dialog, setDialog] = useState(false);
    const [dialogedit, setDialogEdit] = useState(false);
    
    const [onlineremitance, setOnlineRemittance] = useState(item.credited);

    const [cash, setCash] = useState(item.amount_value);
    const [bank_name, setbank_name] = useState(item?.payment_desc[0]?.bank_name);
    const [card_type, setcard_type] = useState(item?.payment_desc[0]?.card_type);
    const [account_number, setaccount_number] = useState(item?.payment_desc[0]?.account_number);
    const [gcash_number, setgcash_number] = useState(item?.payment_desc[0]?.gcash_number);
    const [maya_number, setmaya_number] = useState(item?.payment_desc[0]?.maya_number);
    const [agent_name, setagent_name] = useState(item?.payment_desc[0]?.agent_name);    
    const [account_name, setaccount_name] = useState(item?.payment_desc[0]?.account_name);
    const [reference_number, setreference_number] = useState(item?.payment_desc[0]?.reference_number);
    const [trace_number, settrace_number] = useState(item?.payment_desc[0]?.trace_number);
    const [note, setnote] = useState('');
    const [amount, setamount] = useState(item?.payment_desc[0]?.amount);
    const [issuername, setissuername] = useState('');
    const [cheque_number, setcheque_number] = useState('');
    const [date_issued, setdate_issued] = useState('');
    
    const UpdatePayment = async (item) => {
        const index = OnlySalesData.Mpayment.findIndex(x => x === item);
        
        if (OnlySalesData.Mpayment[index].credited === 'Cash') {
            OnlySalesData.Mpayment[index].amount_value = cash;
            const data = {
                payment_id: "",
                reserve_id: "",
                shopee_id: "",
                sales_id: OnlySalesData.sales_id,
                credited: "Cash",
                type: "Cash",
                date: format(new Date(), 'yyyy-MM-dd'),
                time: format(new Date(), 'HH:mm:ss'),
                amount_value: cash,
                monthly_install: "",
                payment_desc: [],
                status: "",
                user_id: localStorage.getItem('user_id'),
                dateRemoved: "",
                removeBy: "",
                timespan: "",
                note: "123"
            }
            OnlySalesData.payment_history = [...OnlySalesData?.payment_history, {title: 'payment', type: 'update', user_id: localStorage.getItem('user_id'), addedby: localStorage.getItem('firstname'), date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), data: data}];    
        
            // console.log(OnlySalesData.Mpayment);

            // OnlySalesData.Mpayment = [...OnlySalesData?.Mpayment, data];
            PreviewDetails(OnlySalesData)

            var formData = new FormData();
            formData.append("payment_details", 1);
            formData.append("user_id", localStorage.getItem('user_id'));
            formData.append("sales_id", OnlySalesData?.sales_id);
            formData.append("reference", item.reference);
            formData.append("status", 'update');
            formData.append("Mpayment", JSON.stringify(data));
            formData.append("payment_history", JSON.stringify(OnlySalesData.payment_history));
            // formData.append("Mpayment", data.length>0 ? JSON.stringify(OnlySalesData.Mpayment) : '');
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'sales_report.php',
                data: formData,
            }).then((res) => {
                console.log(res.data)
                // sales_report_list()
                // setRemoveDialog(false)
                if (res.data === 'success') {
                    // setRemoveDialog(false)
                } else {

                }
            });
        } else if (OnlySalesData.Mpayment[index].type === 'Online / Remittance') {
            let data = {};
            if (OnlySalesData.Mpayment[index].credited === "GCash") {
                data = {
                    payment_id: "",
                    reserve_id: "",
                    shopee_id: "",
                    sales_id: OnlySalesData?.sales_id,
                    credited: onlineremitance,
                    type: "Online / Remittance",
                    date: format(new Date(), 'yyyy-MM-dd'),
                    time: format(new Date(), 'HH:mm:ss'),
                    amount_value: amount,
                    monthly_install: "",
                    payment_desc: {
                            sales_id: OnlySalesData?.sales_id,
                            bank_name: onlineremitance,
                            gcash_number: gcash_number,
                            account_name: account_name,
                            amount: amount,
                            reference_number: reference_number,
                            note_date: ''
                    },
                    status: "",
                    user_id: localStorage.getItem('user_id'),
                    dateRemoved: "",
                    removeBy: localStorage.getItem('firstname'),
                    timespan: "",
                    note:note
                };
            } else if (OnlySalesData.Mpayment[index].credited === "Maya") {
                data = {
                    payment_id: "",
                    reserve_id: "",
                    shopee_id: "",
                    sales_id: OnlySalesData?.sales_id,
                    credited: onlineremitance,
                    type: "Online / Remittance",
                    date: format(new Date(), 'yyyy-MM-dd'),
                    time: format(new Date(), 'HH:mm:ss'),
                    amount_value: amount,
                    monthly_install: "",
                    payment_desc: {
                            sales_id: OnlySalesData?.sales_id,
                            bank_name: onlineremitance,
                            maya_number: maya_number,
                            account_name: account_name,
                            amount: amount,
                            reference_number: reference_number,
                            note_date: ''
                    },
                    status: "",
                    user_id: localStorage.getItem('user_id'),
                    dateRemoved: "",
                    removeBy: localStorage.getItem('firstname'),
                    timespan: "",
                    note:note
                };
            } else if (OnlySalesData.Mpayment[index].credited === "HomeCredit") {
                data = {
                    payment_id: "",
                    reserve_id: "",
                    shopee_id: "",
                    sales_id: OnlySalesData?.sales_id,
                    credited: onlineremitance,
                    type: "Online / Remittance",
                    date: format(new Date(), 'yyyy-MM-dd'),
                    time: format(new Date(), 'HH:mm:ss'),
                    amount_value: amount,
                    monthly_install: "",
                    payment_desc: {
                            sales_id: OnlySalesData?.sales_id,
                            bank_name: onlineremitance,
                            agent_name: agent_name,
                            amount: amount,
                            note_date: ''
                    },
                    status: "",
                    user_id: localStorage.getItem('user_id'),
                    dateRemoved: "",
                    removeBy: "",
                    timespan: "",
                    note:note
                };
            }

            // API CODE
            // OnlySalesData.payment_history = [...OnlySalesData?.payment_history, {title: 'payment', type: 'update', user_id: localStorage.getItem('user_id'), addedby: localStorage.getItem('firstname'), date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), data: data}];    
        
            // console.log(OnlySalesData.Mpayment);

            // OnlySalesData.Mpayment = [...OnlySalesData?.Mpayment, data];
            // PreviewDetails(OnlySalesData)

            var formData = new FormData();
            formData.append("payment_details", 1);
            formData.append("user_id", localStorage.getItem('user_id'));
            formData.append("sales_id", OnlySalesData?.sales_id);
            formData.append("reference", item.reference);
            formData.append("status", 'update');
            formData.append("Mpayment", JSON.stringify(data));
            formData.append("payment_history", JSON.stringify(OnlySalesData.payment_history));
            // formData.append("Mpayment", data.length>0 ? JSON.stringify(OnlySalesData.Mpayment) : '');
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'sales_report.php',
                data: formData,
            }).then((res) => {
                console.log(res.data)
                // sales_report_list()
                // setRemoveDialog(false)
                if (res.data === 'success') {
                    // setRemoveDialog(false)
                } else {

                }
            });

        } else if (OnlySalesData.Mpayment[index].credited === 'Card Swipe') {
            const data = {
                payment_id: "",
                reserve_id: "",
                shopee_id: "",
                sales_id: "",
                credited: "Card Swipe",
                type: "Card Swipe",
                date: "2023-10-25",
                time: "19:35:18",
                amount_value: "34234",
                monthly_install: "",
                payment_desc: { 
                    sales_id: "29472", 
                    bank_name: "3423", 
                    card_type: "MasterCard", 
                    account_name: "234234", 
                    account_number: "2342", 
                    amount: "34234", 
                    trace_number: "234", 
                    reference_number: "23423", 
                    note_date: "" 
                },
                status: "",
                user_id: "1",
                dateRemoved: "",
                removeBy: "",
                timespan: "",
                note: "4234234"
            }

        } else if (OnlySalesData.Mpayment[index].credited === 'Cheque') {
            
        }

    }
    
    const hideDialog = () => {
        // RemoveCookie('PHPSESSID')
        setDialog(false)
        setDialogEdit(false)
        // setRemoveDialog(false)
        // setPayment('')


        ///////////////////////////////
        ///////////////////////////////
        setCash(item.amount_value);
        setbank_name(item?.payment_desc[0]?.bank_name);
        setcard_type(item?.payment_desc[0]?.card_type);
        setaccount_number(item?.payment_desc[0]?.account_number);
        setgcash_number(item?.payment_desc[0]?.gcash_number);
        setmaya_number(item?.payment_desc[0]?.maya_number);
        setagent_name(item?.payment_desc[0]?.agent_name);    
        setaccount_name(item?.payment_desc[0]?.account_name);
        setreference_number(item?.payment_desc[0]?.reference_number);
        settrace_number(item?.payment_desc[0]?.trace_number);
        setnote('');
        setamount(item?.payment_desc[0]?.amount);



        // setTimeout(() => {
        //     openHistory(false)
        //     HistoryList([])
        // }, 500)
    };
    const DialogFooter = (
        <React.Fragment>
            {
                dialogedit ?
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Button label="Remove" onClick={(e) => {
                        // removePayment(removedialogitem)
                    }} className='btn-block' style={{ backgroundColor: "#db5353" }} />
                    <div>
                        <Button label="Update" onClick={() => { UpdatePayment(item) }} className='btn-block' style={{ backgroundColor: "#3e7645" }} />
                        <Button label="Back" onClick={() => { setDialogEdit(false) }} className='btn-block' style={{ backgroundColor: "#3e7645" }} />
                        <Button label="Close" onClick={() => { hideDialog() }} className='btn-block' style={{ backgroundColor: "#fff", color: "#000" }} />
                        {/* <Button label="Close" onClick={() => {hideDialog()}} className='btn-block' style={{ backgroundColor: "#ddd", color:"#000" }} /> */}
                    </div>
                </div>
                :
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div>                        
                        <Button label="Edit Payment" onClick={() => { setDialogEdit(true) }} className='btn-block' style={{ backgroundColor: "#3e7645" }} />
                    </div>
                    <div>
                        <Button label="Close" onClick={() => { hideDialog() }} className='btn-block' style={{ backgroundColor: "#fff", color: "#000" }} />
                    </div>
                </div>                
            }
        </React.Fragment>
    )
    return (
        <>

            <Dialog visible={dialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                <>
                    {/* {JSON.stringify(removedialogitem)} */}
                    {/* <div>{removedialogitem.type}</div> */}
                    <div>Payment Details</div>
                </>
            } modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>
                {/* <div style={{ fontWeight: 600, fontSize: 18 }}> {removedialogitem?.credited}</div>
                <div style={{ fontWeight: 600, fontSize: 18 }}> {removedialogitem?.amount_value}</div> */}
                {
                    item.credited === "Cash" ?
                    <div>
                        {
                            dialogedit ? 
                                <input onKeyPress={(e) => null} onChange={(e) => setCash(e.target.value)} value={cash} style={{ width: "100%", height: 40, textAlign: "center", fontSize: 20, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} />
                            :
                            <div style={{fontSize:18, fontWeight:600}}>
                                Amount: {Number(parseFloat(cash).toFixed(2)).toLocaleString('en')}
                            </div>
                        }                        
                    </div>
                    :null
                }
                {
                    item.type === "Online / Remittance" ?
                    <>
                        {
                            dialogedit ? 
                                <>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
            
                                        <div style={{ paddingBottom: 5, width: "30%" }}>
                                            <label style={{ fontSize: 15 }}>Bank Name</label>
                                            {/* setbank_name */}
                                            <select onChange={(e) => setOnlineRemittance(e.target.value)} value={onlineremitance} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }}>
                                                <option value={''} selected disabled>Select</option>
                                                <option value={'GCash'}>GCash</option>
                                                <option value={'Maya'}>Maya</option>
                                                {/* <option value={'BPI'}>BPI</option>
                                                <option value={'BDO'}>BDO</option>
                                                <option value={'MetroBank'}>MetroBank</option> */}
                                                <option value={'HomeCredit'}>HomeCredit</option>
                                                <option value={'Others'}>Others</option>
                                            </select>
                                        </div>
                                        {
                                            onlineremitance === 'GCash' ?
                                            <>
                                                <div style={{ paddingBottom: 5, width: "70%" }}>
                                                    <label style={{ fontSize: 15 }}>GCash Number</label>
                                                    <input ref={null} onChange={(e) => setgcash_number(e.target.value)} value={gcash_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                        {
                                            onlineremitance === 'Maya' ?
                                            <>
                                                <div style={{ paddingBottom: 5, width: "70%" }}>
                                                    <label style={{ fontSize: 15 }}>Maya Number</label>
                                                    <input ref={null} onChange={(e) => setmaya_number(e.target.value)} value={maya_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                        {
                                            onlineremitance === 'HomeCredit' ?
                                            <>
                                                <div style={{ paddingBottom: 5, width: "70%" }}>
                                                    <label style={{ fontSize: 15 }}>Agent Name</label>
                                                    <input ref={null} onChange={(e) => setagent_name(e.target.value)} value={agent_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                        {
                                            onlineremitance === 'Others' ?
                                            <div style={{ paddingBottom: 5, width: "70%" }}>
                                                <label style={{ fontSize: 15 }}>Bank Name</label>
                                                <input ref={null} onChange={(e) => setbank_name(e.target.value)} value={bank_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                    {
                                        onlineremitance !== 'HomeCredit' ? 
                                        <>
                                            <div style={{ paddingBottom: 5 }}>
                                                <label style={{ fontSize: 15 }}>Account Name / Bank Source</label>
                                                <input ref={null} onChange={(e) =>  setaccount_name(e.target.value)} value={account_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                <div style={{ paddingBottom: 5 }}>
                                                    <label style={{ fontSize: 15 }}>Amount</label>
                                                    <input ref={null} onChange={(e) =>  setamount(e.target.value)} value={amount} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                </div>
                                                <div style={{ paddingBottom: 5 }}>
                                                    <label style={{ fontSize: 15 }}>Reference Number</label>
                                                    <input ref={null} onChange={(e) =>   setreference_number(e.target.value)} value={reference_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div>
                                            <div style={{ paddingBottom: 5 }}>
                                                <label style={{ fontSize: 15 }}>Amount</label>
                                                <input ref={null} onChange={(e) =>  setamount(e.target.value)} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                            </div>
                                        </div>
                                    }
                                    <div style={{ paddingBottom: 5 }}>
                                        <label style={{ fontSize: 15 }}>Note / Date</label>
                                        <input ref={null} onChange={(e) => setnote(e.target.value)} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                    </div>
                                </>
                            :
                                <div>
                                {
                                    onlineremitance === 'GCash' ?
                                    <>
                                        <div style={{ paddingBottom: 5, width: "70%" }}>
                                            <div style={{ fontSize: 15, fontWeight:600, fontSize:20 }}>GCash</div>
                                            <div style={{ fontSize: 15 }}>Account Name / Bank Source: {account_name}</div>
                                            <div style={{ fontSize: 15 }}>Maya Number: {maya_number}</div>
                                            <div style={{ fontSize: 15 }}>Amount: {amount}</div>
                                            <div style={{ fontSize: 15 }}>Reference Number: {reference_number}</div>
                                            <div style={{ fontSize: 15 }}>Note / Date: </div>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                {
                                    onlineremitance === 'Maya' ?
                                    <>
                                        <div style={{ paddingBottom: 5, width: "70%" }}>
                                            <div style={{ fontSize: 15, fontWeight:600, fontSize:20 }}>Maya</div>
                                            <div style={{ fontSize: 15 }}>Account Name / Bank Source: {account_name}</div>
                                            <div style={{ fontSize: 15 }}>Maya Number: {maya_number}</div>
                                            <div style={{ fontSize: 15 }}>Amount: {amount}</div>
                                            <div style={{ fontSize: 15 }}>Reference Number: {reference_number}</div>
                                            <div style={{ fontSize: 15 }}>Note / Date: </div>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                {
                                    onlineremitance === 'HomeCredit' ?
                                    <>
                                        <div style={{ paddingBottom: 5, width: "70%" }}>
                                            <label style={{ fontSize: 15 }}>Agent Name: {agent_name}</label>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                </div>
                        }
                    </>
                    :
                    null
                }
                {
                    item.type === "Card Swipe" ?
                    <>
                    {
                        dialogedit ? 
                        <>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10, marginBottom: 10 }}>
                                <div style={{ cursor: "pointer", borderRadius: 5, width: 100, backgroundColor: "#459c5d", color: "#fff", fontWeight: "bolder", fontSize: 18, padding: 5, border: "1px solid #ddd", textAlign: "center" }}>Maya</div>
                                <div style={{ cursor: "pointer", borderRadius: 5, width: 100, padding: 0, border: "1px solid #ddd", textAlign: "center", backgroundImage: "url(https://www.adobomagazine.com/wp-content/uploads/2019/08/BPI-new-logo.jpg)", backgroundSize: "100px 34.6px", backgroundRepeat: "no-repeat" }}>
                                    {/* <img src={"https://www.adobomagazine.com/wp-content/uploads/2019/08/BPI-new-logo.jpg"} style={{width:100, height:34.6, backgroundSize:"cover"}} /> */}
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                <div style={{ paddingBottom: 5, width: "70%" }}>
                                    <label style={{ fontSize: 15 }}>Bank Name</label>
                                    <input onChange={(e) => setbank_name(e.target.value)} value={bank_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                </div>
                                <div style={{ paddingBottom: 5, width: "30%" }}>
                                    <label style={{ fontSize: 15 }}>Card Type</label>
                                    <select ref={null} onChange={(e) => setcard_type(e.target.value)} value={card_type} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }}>
                                        <option value={""}>Select...</option>
                                        <option value={"Visa"}>Visa</option>
                                        <option value={"MasterCard"}>MasterCard</option>
                                        <option value={"JCB"}>JCB</option>
                                        <option value={"AMEX"}>AMEX</option>
                                        <option value={"Others"}>Others</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Account Name</label>
                                <input onChange={(e) => setaccount_name(e.target.value)} value={account_name} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                            </div>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Account Number / Card Number</label>
                                <input onChange={(e) => setaccount_number(e.target.value)} value={account_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                <div style={{ paddingBottom: 5, width: "50%" }}>
                                    <label style={{ fontSize: 15 }}>Amount</label>
                                    <input onChange={(e) => setamount(e.target.value)} value={amount} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                </div>
                                <div style={{ paddingBottom: 5 }}>
                                    <label style={{ fontSize: 15 }}>Trace Number</label>
                                    <input onChange={(e) => settrace_number(e.target.value)} value={trace_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                                </div>
                            </div>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Reference Number</label>
                                <input onChange={(e) => setreference_number(e.target.value)} value={reference_number} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                            </div>
                            <div style={{ paddingBottom: 5 }}>
                                <label style={{ fontSize: 15 }}>Note / Date</label>
                                <input onChange={(e) => setnote(e.target.value)} value={null} style={{ width: "100%", height: 40, paddingLeft: 10, outline: "none", fontSize: 18, borderRadius: 10, border: "1px solid rgb(129 129 129)" }} onFocus={(e) => e.target.select()} />
                            </div>
                        </>
                        :
                        <>
                            <div style={{ paddingBottom: 5, width: "70%" }}>
                                <div style={{ fontSize: 15, fontWeight:600, fontSize:20 }}>Card Swipe</div>
                                <div style={{ fontSize: 15 }}>Back Name: {bank_name}</div>
                                <div style={{ fontSize: 15 }}>Card Type: {card_type}</div>
                                <div style={{ fontSize: 15 }}>Account Name: {account_name}</div>
                                <div style={{ fontSize: 15 }}>Account Number / Card Number: {account_number}</div>
                                <div style={{ fontSize: 15 }}>Amount: {amount}</div>
                                <div style={{ fontSize: 15 }}>Trace Number: {trace_number}</div>
                                <div style={{ fontSize: 15 }}>Reference Number: {reference_number}</div>
                                <div style={{ fontSize: 15 }}>Note / Date: </div>
                            </div>
                        </>
                    }
                    </>
                    :
                    null
                }
            </Dialog>

            {
                // item.credited !== "Cash" ?
                <span onClick={(e) => { setDialog(true) }} style={{ padding: 1, paddingLeft: 5, paddingRight: 5, cursor: "pointer", backgroundColor: "#fff", borderRadius: 5, color: "#3b82f6", border:"1px solid #3b82f6" }}>
                    <i className='pi pi-list' /> Details
                </span>
                // :
                // null
            }
        </>
    );
}

export default PaymentDetails;

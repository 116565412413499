import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import swal from 'sweetalert';
import ENV from '../../../ENV';
import axios from 'axios';
import { ConfirmDialog } from 'primereact/confirmdialog';

const JobExpPanel = (props) => {
    const [openDialog, setOpenDialog] = useState(false)
    const menuLeft = useRef(null);
    const menuRight = useRef(null);
    const toast = useRef(null);


    const [visible, setVisible] = useState(false);

    const deleteRow = (item) => {
        swal({
            title: "Are you Sure?",
            icon: "info",
            buttons: {
                confirm: "Yes sure",
                cancel: "No"
            }
        }).then(async (confirm) => {
            if (confirm) {
                const data = props?.item?.experiences?.filter((exp) => exp != item);
                const idx = props.employeelist.findIndex(i => i.user_id == props.item.user_id);
                props.employeelist[idx].experiences = data;

                const formData = new FormData();
                formData.append("update_experience", 1);
                formData.append("user_id", props.item.user_id);
                formData.append("experiences", JSON.stringify(data));

                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'api/employee.php',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    swal({
                        title: "Done!",
                        text: "Successfully Deleted",
                        icon: "success",
                        timer: 1000,
                        button: false
                    }).then(() => {
                        props.setEmployeeList(props.employeelist)
                        props.setExperiences(data.reverse())
                        return;
                    });
                }).catch((err) => {

                });
            }
        });
    }

    const items = [
        {
            label: 'Options',
            items: [
                // {
                //     label: 'Edit',
                //     icon: 'pi pi-pencil',
                //     command: () => {
                //         setOpenDialog(true)
                //     }
                // },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: () => {
                        setVisible(true)
                    }
                }
            ]
        }
    ];

    const hideDialog = () => {
        setOpenDialog(false)
    }

    const accept = async () => {
        const data = props?.item?.experiences?.filter((exp) => exp != props.row);
        const idx = props.employeelist.findIndex(i => i.user_id == props.item.user_id);
        props.employeelist[idx].experiences = data;

        const formData = new FormData();
        formData.append("update_experience", 1);
        formData.append("user_id", props.item.user_id);
        formData.append("experiences", JSON.stringify(data));

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/employee.php',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            swal({
                title: "Done!",
                text: "Successfully Deleted",
                icon: "success",
                timer: 1000,
                button: false
            }).then(() => {
                props.setEmployeeList(props.employeelist)
                props.setExperiences(data.reverse())
                return;
            });
        }).catch((err) => {

        });
    }

    const reject = () => {
        toast.current.show({ 
            severity: 'warn', 
            summary: 'Rejected', 
            detail: 'You have rejected', 
            life: 3000 
        });
    }

    return (
        <>

            <ConfirmDialog
                 style={{fontSize: 15}}
                group="declarative"
                visible={visible}
                onHide={() => setVisible(false)}
                message="Are you sure you want to proceed?"
                header="Confirmation"
                icon="pi pi-exclamation-triangle"
                accept={accept}
                reject={reject}
            />

            <Dialog
                position='top' 
                draggable={false}
                style={{ width: '50rem' }}
                visible={openDialog}
                onHide={hideDialog}
                header={
                    <>
                        <label>Edit</label>
                    </>
                }
            >

            </Dialog>

            <div className="card flex justify-content-center">
                <Toast ref={toast}></Toast>
                <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                <Button icon="pi pi-align-right" className="mr-2" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
            </div>
        </>
    );
}

export default JobExpPanel;
